/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Image/File Upload
 * @Date: 2020-12-16 09:04:56
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-12-16 09:04:56
 */
import React, { Component, createRef } from "react";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { get, size } from "lodash";
import axios from "axios";

import { changeInputFn, setFormErrorFn } from "../../../store/boda-boda/form/actions";
import { getValueByFilter } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import FileDisplay from "./includes/FileDisplay";
import { setPageLoader } from "../../../store/boda-boda/ui/actions";
import { fileUploadAPI } from "../../../config/boda-boda/apiUrls.config";
import { fileGetAPIUrl } from "../../../config/boda-boda/core.config";
import { checkValidFileType } from "../../../helpers/boda-boda/file-upload-helpers/file-upload.helpers";


// Upload Types
const uploadTypes = {
    image: "image",
    file: "file"
};

// Valid Image Types
const validImageTypes = {
    png: "png",
    jpg: "jpg",
    jpeg: "jpeg",
};

// Valid File Types
const validFileTypes = {
    txt: "txt",
    pdf: "pdf",
    docx: "docx",
    xlsx: "xlsx",
};

class FileUpload extends Component {

    static defaultProps = {
        elementWrapperStyle: "",
        uploadSliderStyle: "",
        displayFileStyle: "",
        uploadBtnStyle: "",
        isRequired: false,
        isDisable: false,
        labelText: "",
        helperText: "",
        formGroupName: "",
        formKey: "",
        isDownload: true,
        isDelete: true,
        uploadType: uploadTypes.image,
        uploadApiUrl: fileUploadAPI.url,
        getApiUrl: fileGetAPIUrl,
        validTypes: [validImageTypes.png, validImageTypes.jpeg, validImageTypes.jpg]
    }

    constructor(props) {
        super(props);

        this.inputRef = createRef();
    }

    onChangeFn = (event) => {
        let {
            uploadApiUrl, setPageLoader, changeInputFn, formGroupName,
            formKey, uploadType, validTypes, setFormErrorFn, formState
        } = this.props;

        if (size(event.target.files) > 0) {
            const file = event.target.files[0];

            if (checkValidFileType(file.type, uploadType, validTypes) === true) {
                let errorObject = [...get(formState, `${formGroupName}._errors`, [])];
                errorObject = errorObject.filter((element) => element.property !== formKey);

                setFormErrorFn(formGroupName, errorObject);
                setPageLoader(true);

                axios.get(`${uploadApiUrl}?${uploadType === uploadTypes.image ? `fileType=${file.type.split("/")[1]}` : "fileType=csv"}`, {
                    filename: file.name,
                    filetype: file.type
                })
                .then((result) => {
                    const signedUrl = result.data.uploadURL;
                    const fileName = result.data.fileName;

                    const options = {
                        headers: {
                            'Content-Type': file.type
                        }
                    };

                    axios.put(signedUrl, file, options).then((result) => {
                        changeInputFn(formGroupName, formKey, "", fileName);
                        setPageLoader(false);
                    }).catch((error) => {
                        setPageLoader(false);
                    });
                })
                .catch((error) => {
                    setPageLoader(false);
                })
            } else {
                const errorObject = [...get(formState, `${formGroupName}._errors`, [])];
                errorObject.push({
                    "property": formKey,
                    "error": "Invalid file format"
                });

                setFormErrorFn(formGroupName, errorObject);
            }
        }
    }

    deleteFile = () => {
        let { changeInputFn, formGroupName, formKey } = this.props;
        changeInputFn(formGroupName, formKey, "", "");
    }

    render() {
        let {
            elementWrapperStyle, labelText, isRequired, helperText, formState,
            formGroupName, formKey, uploadBtnStyle, uploadType, isDisable, displayFileStyle,
            getApiUrl, isDownload, isDelete
        } = this.props;

        return (
            <div className={`defaultUploadComponentWrapper ${elementWrapperStyle}`}>
                <div>
                    <Label
                        className={`${getValueByFilter(get(formState,`${formGroupName}._errors`,[]), ["property", formKey], "error", "", "") ? "selectBoxLabelStyle" : ""}`}
                    >
                        {labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}
                    </Label>
                </div>

                <div className={"d-inline-flex"}>
                    <FileDisplay
                        elementStyle={displayFileStyle}
                        formGroupName={formGroupName}
                        formKey={formKey}
                        file={get(formState, `${formGroupName}.${formKey}`, "")}
                        apiUrl={getApiUrl}
                        isDownload={isDownload}
                        isDelete={isDelete}
                        onDeleteFn={this.deleteFile}
                    />

                    {
                        (get(formState, `${formGroupName}.${formKey}`, "") === "") ? (
                            <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                                <div className="btn text-center" onClick={() => this.inputRef.current.click() }>
                                    <i className={`${(uploadType === "image") ? "fas fa-camera" : "far fa-file-alt"}`} />
                                    <h5>Upload</h5>
                                </div>

                                <input ref={this.inputRef} type="file" disabled={isDisable} onChange={(event) => this.onChangeFn(event)} />
                            </div>
                        ) : null
                    }
                </div>

                <div>
                    <small className={`${getValueByFilter(get(formState,`${formGroupName}._errors`,[]), ["property", formKey], "error", "", "") ? "helperTextErrorStyle" : ""}`}>
                        {
                            getValueByFilter(get(formState,`${formGroupName}._errors`,[]), ["property", formKey], "error", "", "")
                                ? getValueByFilter(get(formState,`${formGroupName}._errors`,[]), ["property", formKey], "error", "", "")
                                : helperText
                        }
                    </small>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    changeInputFn,
    setPageLoader,
    setFormErrorFn
})(FileUpload);
