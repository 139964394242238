import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import {
  DatePickerBoxWithState,
  FormWrapper,
  InputButton,
} from "../../../components/boda-boda/Forms";
import { shipmentDataAPI } from "../../../config/boda-boda/apiUrls.config";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setFormErrorFn } from "../../../store/actions";

const ShipmentDetailCards = (props) => {
  const [toBePicked, setToBePicked] = useState([]);
  const [currentPending, setCurrentPending] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [terminated, setTerminated] = useState([]);
  const formState = useSelector((state) => state.FormData);
  const authState = useSelector((state) => state.AuthState);
  const dispatch = useDispatch();

  const merchantId = authState?.authUser?.merchant?.id;

  const currentDate = new Date();
  const initialToDate = new Date(
    new Date(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    ).setSeconds(
      new Date(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      ).getSeconds() - 1
    )
  );
  const initialFromDate = new Date(
    new Date(
      new Date(initialToDate).setMonth(new Date(initialToDate).getMonth() - 3)
    ).getFullYear(),
    new Date(
      new Date(initialToDate).setMonth(new Date(initialToDate).getMonth() - 3)
    ).getMonth() + 1,
    1
  );

  useEffect(() => {
    callApi(
      `${
        shipmentDataAPI.url
      }?merchantId=${merchantId}&dateTo=${initialToDate.toISOString()}&dateFrom=${initialFromDate.toISOString()}`
    )
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        let toBePickedUpShipments = 0;
        let currentPendingShipments = 0;
        let deliveredShipments = 0;
        let terminatedShipments = 0;
        get(result, "data.data.totalPickDeliveredShipments", []).map(
          (value, index) => {
            toBePickedUpShipments = value?.totalToBePickedupShipments;
            deliveredShipments = value?.totalDeliveredShipments;
          }
        );

        get(result, "data.data.totalPendingDeliveryShipments", []).map(
          (value, index) => {
            currentPendingShipments = value?.totalPendingDeliveryShipments;
          }
        );

        get(result, "data.data.totalfailedShipments", []).map(
          (value, index) => {
            terminatedShipments = value?.totalFailedShipments;
          }
        );
        setToBePicked(toBePickedUpShipments);
        setCurrentPending(currentPendingShipments);
        setDelivered(deliveredShipments);
        setTerminated(terminatedShipments);
      });
  }, []);

  const onSubmit = () => {
    if (
      get(formState, "filter_dates.dateFrom", "") !== null &&
      get(formState, "filter_dates.dateTo", "") !== null && 
      get(formState, "filter_dates.dateFrom", "") < get(formState, "filter_dates.dateTo", "")
    ) {
      dispatch(setFormErrorFn("filter_dates", []));

      let dateTo = new Date(get(formState, "filter_dates.dateTo", ""));
      let dateFrom = new Date(get(formState, "filter_dates.dateFrom", ""));

      callApi(
        `${
          shipmentDataAPI.url
        }?merchantId=${merchantId}&dateTo=${dateTo.toISOString()}&dateFrom=${dateFrom.toISOString()}`
      )
        .headers(true)
        .method("get")
        .send()
        .then((result) => {
          let toBePickedUpShipments = 0;
          let currentPendingShipments = 0;
          let deliveredShipments = 0;
          let terminatedShipments = 0;
          get(result, "data.data.totalPickDeliveredShipments", []).map(
            (value, index) => {
              toBePickedUpShipments = value?.totalToBePickedupShipments;
              deliveredShipments = value?.totalDeliveredShipments;
            }
          );

          get(result, "data.data.totalPendingDeliveryShipments", []).map(
            (value, index) => {
              currentPendingShipments = value?.totalPendingDeliveryShipments;
            }
          );

          get(result, "data.data.totalfailedShipments", []).map(
            (value, index) => {
              terminatedShipments = value?.totalFailedShipments;
            }
          );
          setToBePicked(toBePickedUpShipments);
          setCurrentPending(currentPendingShipments);
          setDelivered(deliveredShipments);
          setTerminated(terminatedShipments);
        });
    } else {
      let errors = [];

      if (get(formState, "filter_dates.dateFrom", "") === null) {
        errors.push({
          property: "dateFrom",
          error: "This field is required",
        });
      }

      if (get(formState, "filter_dates.dateTo", "") === null) {
        errors.push({
          property: "dateTo",
          error: "This field is required",
        });
      }

      if(get(formState, "filter_dates.dateFrom", "") > get(formState, "filter_dates.dateTo", "")){
        errors.push({
          property: "dateTo",
          error: "The Date To field should not be less than the Date From field",
        });
      }

      if (errors.length > 0) {
        dispatch(setFormErrorFn("filter_dates", errors));
      }
    }
  };

  return (
    <React.Fragment>
      <FormWrapper
        setGroupName={"filter_dates"}
        onDestroyUnsetFormObject={true}
        setFormObject={{
          dateFrom: initialFromDate,
          dateTo: initialToDate,
        }}
      >
        <div className={"row"}>
          <div className="col-md-4">
            <DatePickerBoxWithState
              isRequired={true}
              labelText="Date From"
              inputName={"dateFrom"}
              formGroupName={"filter_dates"}
            />
          </div>
          <div className="col-md-4">
            <DatePickerBoxWithState
              isRequired={true}
              labelText="Date To"
              inputName={"dateTo"}
              formGroupName={"filter_dates"}
            />
          </div>
          <div className="col-md-4 datepicker-botton-margin-top">
            <InputButton
              btnText={"Submit"}
              elementStyle={"btn btn-primary waves-effect waves-light"}
              onClickBtnFn={() => onSubmit()}
            />
          </div>
        </div>
      </FormWrapper>
      <Row>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-primary text-white">
            <CardBody className="card-height">
              <div className="mb-4">
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  To be Picked Up Shipments
                </h5>
                <h4 className="font-weight-medium font-size-24">
                  {toBePicked}
                </h4>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-primary text-white">
            <CardBody className="card-height">
              <div className="mb-4">
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  Current Pending Shipments
                </h5>
                <h4 className="font-weight-medium font-size-24">
                  {currentPending}
                </h4>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-primary text-white">
            <CardBody className="card-height">
              <div className="mb-4">
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  Delivered Shipments
                </h5>
                <h4 className="font-weight-medium font-size-24">{delivered}</h4>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-primary text-white">
            <CardBody className="card-height">
              <div className="mb-4">
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  Terminated Shipments
                </h5>
                <h4 className="font-weight-medium font-size-24">
                  {terminated}
                </h4>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ShipmentDetailCards;
