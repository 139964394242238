import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { getManifestListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { PickRequestFilterUI } from "../common-includes/common-includes";
import { modalTypes } from "../../../../../components/boda-boda/Modal/config/model.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { mapStatusesNames } from "../../../../../config/boda-boda/db_config/db_status.config";
import { formatToDateHelper, isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { ManifestPrintBody, PrintManifestBtn } from "../common-includes/PrintUI";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";


class PendingRequests extends Component {
    render() {
        let { history, location } = this.props;
        return (
            <Fragment>
                <CrudComponent
                    title={"Pending"}
                    apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&status=PUBLISH`}
                    dataTableStateKey={getManifestListAPI.key}
                    isFilter={true}
                    defaultFilterFormObject={{
                        pickupDateFrom: null,
                        pickupDateTo: null,
                        code: "",
                        articleId: "",
                        locationPickId: ""
                    }}
                    clearErrors={() => {
                        const { setFormErrorFn } = this.props;
                        setFormErrorFn("manifestListAPIKey_search", [])
                    }}
                    onRebuildFilterObjFn={(filterData) => {
                        const data = filterData;
                        let { setFormErrorFn } = this.props;
                        const errors = [];

                        if (new Date(filterData.pickupDateFrom) > new Date(filterData.pickupDateTo)) {
                            errors.push({
                                "property":"pickupDateTo",
                                "error":"The Date To field should not be less than the Date From field"
                            })
                        }

                        if(errors.length > 0 ){
                            setFormErrorFn("manifestListAPIKey_search", errors);
                            return ;
                        }else{
                            setFormErrorFn("manifestListAPIKey_search", []);
                        }

                        if (!isEmptyValue(filterData.pickupDateTo)) {
                            data["pickupDateTo"] = formatToDateHelper(filterData.pickupDateTo);
                        }

                        if (!isEmptyValue(filterData.pickupDateFrom)) {
                            data["pickupDateFrom"] = filterData.pickupDateFrom.toISOString();
                        }

                        return data;
                    }}
                    filterFromComponent={PickRequestFilterUI}
                    isSetCreateAction={false}
                    tableHeaderList={[
                        { displayName: "Manifest Code", key: "code" },
                        { displayName: "Created Time", key: "createdAt", onPrintFn: (rawData) => (rawData.createdAt) ? dateObjectToString(rawData.createdAt, "YY-mm-dd HH:MM:II") : "-" },
                        { displayName: "To be Picked Up On", key: "pickAt", onPrintFn: (rawData) => (rawData.pickAt) ? dateObjectToString(rawData.pickAt, "YY-mm-dd") : "-" },
                        { displayName: "Pick Up Location", key: "locationPick.name" },
                        {
                            displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                return mapStatusesNames[rawData.status];
                            }
                        }
                    ]}
                    actionList={[
                        {
                            actionType: actionBtnTypes.view,
                            isSetModel: false,
                            actionBtn: {
                                onClickBtnFn: (rawData) => history.push(`/pick-request/${location.pathname.split('/')[2]}/view/${rawData.id}`),
                            }
                        },
                        {
                            actionType: actionBtnTypes.other,
                            isSetModel: true,
                            actionBtn: {
                                icon: "fas fa-print",
                                color: "primary",
                                tooltip: "Print",
                                btnText: "print"
                            },
                            modalProps: {
                                modalTitle: "Manifest",
                                modalType: modalTypes.large,
                                modalBodyComponent: ManifestPrintBody,
                                modalFooterComponent: PrintManifestBtn,
                                isSetClose: true,
                                isSetFooterClose: true,
                            }
                        }
                    ]}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
}) (withRouter(PendingRequests));
