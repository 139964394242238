import React, { Component } from "react";

import { SelectBoxWithState } from "../../../../../../components/boda-boda/Forms";
import { getDistrictListAPI } from "../../../../../../config/boda-boda/apiUrls.config"


class FilterUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"District"}
                            apiUrl={`${getDistrictListAPI.url}`}
                            apiStateKey={getDistrictListAPI.key}
                            inputName={"districtId"}
                            placeHolderText={"District"}
                            formGroupName={this.props.searchFromKey}
                            onRebuildResponseFn={(response) => {
                                const data = [];

                                response.forEach((value) => {
                                    data.push({
                                        id: `${value.id}`,
                                        value: `${value.name}`,
                                    });
                                });

                                return {
                                    data: {
                                        data: data,
                                    },
                                };
                            }}
                        />
                    </div>
                </div>

            </div>
        )
    }
}

export { FilterUI };
