import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { get, filter, find } from "lodash";
import parsePhoneNumber from 'libphonenumber-js'

import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    SubmitButton,
} from "../../../../../../components/boda-boda/Forms";
import {
    getOrdersListAPI,
    getChannelListAPI,
    getCityListAPI,
    merchantContractAPI,
    countryCodeAPI, getDistrictListAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import {
    orderStatusObject,
    deliveryTypes,
    paymentMethods
} from "../../../../../../config/boda-boda/db_config/db_status.config";
import { apiResponseStoringType } from "../../../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject, formObjectValidationHelper } from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { isEmptyValue } from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { TabComponent, SubTab } from "../../../../../../components/boda-boda/Tab/Tab";
import UploadCSV from "./create-includes/UploadCSV";
import { defaultCountryCode } from "../../../../../../config/boda-boda/core.config";
import { changeInputFn } from "../../../../../../store/boda-boda/form/actions";
import { setApiResponse } from "../../../../../../store/boda-boda/core/actions";
import UploadPackageCSV from "./create-includes/UploadPackageCSV";
import { setFlashMessage } from "../../../../../../store/actions";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import TermsAndConditionUIMessage from "../../../../terms-and-condition/TermsAndConditionUIMessage";


class CreateUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentTypes: [],
            activeTab: 0,
            cities: [],
            loadingCities: true
        }
    }

    checkPaymentMethodTypes = (response) => {
        const types = [paymentMethods[2]];

        if (response) {
            if (get(response, "cashOnDelivery", false) === true) {
                types.push(paymentMethods[0]);
            }

            if (get(response, "cardOnDelivery", false) === true) {
                types.push(paymentMethods[1]);
            }
        }

        this.setState({
            ...this.state,
            paymentTypes: types
        })
    }


    getCityData = (districtId) => {
        this.setState({loadingCities: true})
        callApi (`${getCityListAPI.url}/all/order?districtId=${districtId}`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                if (get(result, "_statue", "") === false) {
                    this.setState({loadingCities: true})
                } else {
                    let cities = result.data.data.map(result => {
                        return {id: result.id, value: result.name}
                    })
                    this.setState({cities: cities})
                    this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.city.id`, "");
                    this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.postalCode`, "");
                    this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.cityList`, result.data.data);
                    this.props.setApiResponse(getCityListAPI.key, result.data.data);
                    this.setState({loadingCities: false})
                }
            })
            .catch((error) => {
                this.setState({loadingCities: true})
            })
    }

    render() {
        let { formState, coreState, history, changeInputFn, setApiResponse } = this.props;

        return (
            <div className="container-fluid">
                <div className={"pt-4"}>
                    <TabComponent
                        activeTab={this.state.activeTab}
                        onClickFn={(tab) => {
                            this.setState({
                                ...this.state,
                                activeTab: tab
                            })
                        }}
                    >
                        <SubTab tabId={0} tabName={"Create Order"}>
                            <FormWrapper
                                setGroupName={`${getOrdersListAPI.key}_create`}
                                onDestroyUnsetFormObject={true}
                                apiUrl={`${merchantContractAPI.url}?page=1&limit=20`}
                                onRebuildResponseFn={(response) => {
                                    this.checkPaymentMethodTypes(get(response, "[0]", []));

                                    return {
                                        cityList: [],
                                        "_onLoad": false
                                    }
                                }}
                            >
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        {/*<div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>*/}
                                        {/*    <h4 className="font-size-18">Create an Order</h4>*/}
                                        {/*</div>*/}
                                    </Col>

                                    <Col sm={6}>
                                        <div className={"d-flex justify-content-end"} style={{ padding: "16px 0 16px 0" }}>
                                            <CreateBtn
                                                historyFn={history}
                                                coreState={coreState}
                                                countryCode={get(formState, `${getOrdersListAPI.key}_create.countryCode.id`, "").toString().split("/")}
                                            />

                                            <BackBtnComponent
                                                path={"/orders/pending"}
                                                btnName={"Cancel"}
                                                icon={"far fa-times-circle"}
                                                btnColor={"danger"}
                                                isModal={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title mb-4">Order Details</h4>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            labelText="Channel Order No"
                                                            placeHolderText={"Enter Channel Order No"}
                                                            inputName={"channelOrderNo"}
                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                            maxLength={20}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            labelText="Channel Order Reference"
                                                            placeHolderText={"Enter Channel Order Reference"}
                                                            inputName={"channelOrderRef"}
                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                            maxLength={15}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Channel"
                                                            inputStatePath={`${getOrdersListAPI.key}_create.channel.id`}
                                                            placeHolderText={"Select a Channel"}
                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                            apiUrl={`${getChannelListAPI.url}/all`}
                                                            apiStateKey={getChannelListAPI.key}
                                                            keyName={"id"}
                                                            valueName={"name"}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Delivery Type"
                                                            inputName={"deliveryType"}
                                                            placeHolderText={"Select a Delivery Type"}
                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                            dataList={deliveryTypes}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Payment Method"
                                                            inputName={"paymentMethod"}
                                                            placeHolderText={"Select a Payment Method"}
                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                            dataList={this.state.paymentTypes}
                                                        />
                                                    </div>

                                                    {
                                                        (get(formState, `${getOrdersListAPI.key}_create.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                                                            <div className="col-md-6">
                                                                <InputBoxWithState
                                                                    isRequired={true}
                                                                    labelText="Amount"
                                                                    placeHolderText={"Enter Order Amount"}
                                                                    inputName={"amount"}
                                                                    inputType={"number"}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={10}
                                                                />
                                                            </div>
                                                        ) : null
                                                    }

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Signature Required"
                                                            inputName={"signatureRequired"}
                                                            placeHolderText={"Select Signature Required Status"}
                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                            dataList={[
                                                                {
                                                                    id: "true",
                                                                    value: "Yes"
                                                                },
                                                                {
                                                                    id: "false",
                                                                    value: "No"
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title mb-4">Receiver Details</h4>

                                                        <div className={"row"}>
                                                            <div className="col-md-6">
                                                                <InputBoxWithState
                                                                    isRequired={true}
                                                                    labelText="Customer Name"
                                                                    placeHolderText={"Enter Customer Name"}
                                                                    inputName={"name"}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={100}
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className={"row"}>
                                                                    <div className={"col-md-3"}>
                                                                        <SelectBoxWithState
                                                                            isRequired={true}
                                                                            labelText="Country Code"
                                                                            inputStatePath={`${getOrdersListAPI.key}_create.countryCode.id`}
                                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                                            apiUrl={`${countryCodeAPI.url}/all`}
                                                                            apiStateKey={countryCodeAPI.key}
                                                                            onRebuildResponseFn={(response) => {
                                                                                const data = [];

                                                                                response.forEach((value) => {
                                                                                    data.push({
                                                                                        id: `${value.id}/${value.code}/${value.prefix}`,
                                                                                        value: value.code
                                                                                    });
                                                                                });

                                                                                return {
                                                                                    "data": {
                                                                                        "data": data
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className={"col-md-9"}>
                                                                        <InputBoxWithState
                                                                            isRequired={true}
                                                                            labelText="Phone"
                                                                            inputName={"phone"}
                                                                            placeHolderText={"Enter Phone Number"}
                                                                            formGroupName={`${getOrdersListAPI.key}_create`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <InputBoxWithState
                                                                    isRequired={false}
                                                                    labelText="Email"
                                                                    inputName={"email"}
                                                                    placeHolderText={"Enter Email Address"}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={50}
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <SelectBoxWithState
                                                                    isRequired={true}
                                                                    labelText="ID Verification Required"
                                                                    inputName={"idVerificationRequired"}
                                                                    placeHolderText={"Select ID Verification Required Status"}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    dataList={[
                                                                        {
                                                                            id: "true",
                                                                            value: "YES"
                                                                        },
                                                                        {
                                                                            id: "false",
                                                                            value: "NO"
                                                                        }
                                                                    ]}
                                                                />
                                                            </div>

                                                            {/*{*/}
                                                            {/*    (get(formState, `${getOrdersListAPI.key}_create.idVerificationRequired`, "false") === "true") ? (*/}
                                                            {/*        <Fragment>*/}
                                                            {/*            <div className="col-md-6">*/}
                                                            {/*                <SelectBoxWithState*/}
                                                            {/*                    isRequired={true}*/}
                                                            {/*                    labelText="ID Type"*/}
                                                            {/*                    inputName={"idVerificationType"}*/}
                                                            {/*                    placeHolderText={"Select ID Type"}*/}
                                                            {/*                    formGroupName={`${getOrdersListAPI.key}_create`}*/}
                                                            {/*                    dataList={idVerificationTypes}*/}
                                                            {/*                />*/}
                                                            {/*            </div>*/}

                                                            {/*            <div className="col-md-6">*/}
                                                            {/*                <InputBoxWithState*/}
                                                            {/*                    isRequired={true}*/}
                                                            {/*                    labelText="ID Reference"*/}
                                                            {/*                    inputName={"idVerificationReference"}*/}
                                                            {/*                    placeHolderText={"Enter ID Reference"}*/}
                                                            {/*                    formGroupName={`${getOrdersListAPI.key}_create`}*/}
                                                            {/*                    maxLength={20}*/}
                                                            {/*                />*/}
                                                            {/*            </div>*/}
                                                            {/*        </Fragment>*/}
                                                            {/*    ) : null*/}
                                                            {/*}*/}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title mb-4">Shipping Location</h4>

                                                        <div className={"row"}>
                                                            <div className={"col-md-6"}>
                                                                <InputBoxWithState
                                                                    isRequired={true}
                                                                    labelText="Location Name"
                                                                    placeHolderText={"Enter the Location Name"}
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.name`}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={50}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <InputBoxWithState
                                                                    isRequired={true}
                                                                    labelText="Address"
                                                                    placeHolderText={"Enter the Shipping Address"}
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.address`}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={250}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <InputBoxWithState
                                                                    isRequired={false}
                                                                    labelText="Postal Code"
                                                                    placeHolderText={"Enter the Postal Code"}
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.postalCode`}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={10}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <InputBoxWithState
                                                                    isRequired={false}
                                                                    labelText="Plus Code"
                                                                    placeHolderText={"Enter the Google Plus Code"}
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.plusCode`}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={10}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <InputBoxWithState
                                                                    isRequired={false}
                                                                    labelText="Latitude"
                                                                    placeHolderText={"Enter the Latitude"}
                                                                    // inputName={"lat"}
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.lat`}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={12}
                                                                    inputType={"number"}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <InputBoxWithState
                                                                    isRequired={false}
                                                                    labelText="Longitude"
                                                                    placeHolderText={"Enter the Longitude"}
                                                                    // inputName={"lon"}
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.lon`}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    maxLength={13}
                                                                    inputType={"number"}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <SelectBoxWithState
                                                                    isRequired={true}
                                                                    labelText="District"
                                                                    inputName={"district"}
                                                                    placeHolderText={"Select a District"}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    apiUrl={getDistrictListAPI.url}
                                                                    apiStateKey={getDistrictListAPI.key}
                                                                    keyName={"id"}
                                                                    valueName={"name"}
                                                                    onChangeFn={(eventData) => {this.getCityData(eventData.value)}}
                                                                />
                                                            </div>

                                                            <div className={"col-md-6"}>
                                                                <SelectBoxWithState
                                                                    isRequired={true}
                                                                    isDisabled={this.state.loadingCities}
                                                                    labelText="City"
                                                                    inputStatePath={`${getOrdersListAPI.key}_create.shippingLocation.city.id`}
                                                                    placeHolderText={"Select a City"}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                    apiStateKey={getCityListAPI.key}
                                                                    dataList={this.state.cities}
                                                                    onChangeFn={(eventData) => {
                                                                        const city = find(
                                                                            get(formState, `${getOrdersListAPI.key}_create.cityList`, []),
                                                                            (e) => e.id === eventData.value
                                                                        );

                                                                        if (city && get(city, "postalCode", null) != null) {
                                                                            changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.postalCode`, get(city, "postalCode", ""));
                                                                        } else {
                                                                            changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.postalCode`, "");
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className={"row"}>
                                                            <div className="col-md-6">
                                                                <InputBoxWithState
                                                                    labelText="Public Note"
                                                                    inputName={"notesPublic"}
                                                                    inputType={"textarea"}
                                                                    rows={3}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <InputBoxWithState
                                                                    labelText="Private Note"
                                                                    inputName={"notesPrivate"}
                                                                    inputType={"textarea"}
                                                                    rows={3}
                                                                    formGroupName={`${getOrdersListAPI.key}_create`}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <TermsAndConditionUIMessage isShowRequired={true}/>
                            </FormWrapper>
                            
                        </SubTab>

                        <SubTab tabId={1} tabName={"Upload Orders"}>
                            <UploadPackageCSV history={history} />
                        </SubTab>
                    </TabComponent>
                </div>
            </div>
        )
    }
}

const CreateBtn = (props) => {

    const dispatch = useDispatch();
    const submitBtnDisable = useSelector(state => state?.FormData?.confirmation?.termsAndConditions);

    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            isBtnDisabled={submitBtnDisable? false: true}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getOrdersListAPI.key}_create`}
            dataTableKey={getOrdersListAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Customer Name",
                    email: "Email",
                    idVerificationRequired: "ID Verification",
                    // idVerificationType: "ID Type",
                    // idVerificationReference: "ID Reference",
                    phone: "Phone",
                    "countryCode.id": "Country Code",
                    channelOrderNo: "Channel Order No",
                    "channel.id": "Channel",
                    status: "Status",
                    deliveryType: "Delivery Type",
                    paymentMethod: "Payment Method",
                    signatureRequired: "Signature Required",
                    amount: "Amount",
                    "shippingLocation.name": "Location Name",
                    "shippingLocation.address": "Address",
                    "shippingLocation.postalCode": "Postal Code",
                    "shippingLocation.plusCode": "Plus Code",
                    "shippingLocation.lat": "Latitude",
                    "shippingLocation.lon": "Longitude",
                    "shippingLocation.status": "Status",
                    "shippingLocation.city.id": "City",
                },
                rules: {
                    name: "required|min:4|max:50",
                    email: "email",
                    "channel.id": "required",
                    idVerificationRequired: "required",
                    // idVerificationType: "requiredIf:idVerificationRequired,true",
                    // idVerificationReference: "requiredIf:idVerificationRequired,true",
                    phone: `required|phoneNumber:${get(props, "countryCode[1]", defaultCountryCode)},countryCode.id`,
                    "countryCode.id": "required",
                    status: "required",
                    deliveryType: "required",
                    paymentMethod: "required",
                    signatureRequired: "required",
                    amount: "requiredIfMultiple:paymentMethod,CASH_ON_DELIVERY,CARD_ON_DELIVERY|min:0|max:10",
                    "shippingLocation.name": "required|min:4",
                    "shippingLocation.address": "required|max:114",
                    "shippingLocation.city.id": "required",
                    "shippingLocation.postalCode": "min:5",
                },
                message: {
                    "amount.max": "The amount may not be greater than 10 digits",
                    "amount.min": "The amount must be at least 0",
                    "amount.requiredIfMultiple": "The Amount field is required when payment method is either Cash on Delivery or Card on Delivery",
                    "shippingLocation.postalCode.min": "The Postal Code must be at least 5 characters",
                    "shippingLocation.name.min": "The Location Name must be at least 4 characters"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Order has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: getOrdersListAPI.url,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject({ ...formObject });
                let temp = {
                    ...data,
                    idVerificationRequired: formObjectValidationHelper(data, "idVerificationRequired", (data) => { return data === "true" }),
                    signatureRequired: formObjectValidationHelper(data, "signatureRequired", (data) => { return data === "true" }),
                    status: orderStatusObject.PENDING,
                    amount: formObjectValidationHelper(data, "amount", (data) => { return Number(data) }),
                    paid: data.paymentMethod === "PREPAID",
                    channel: {
                        id: formObjectValidationHelper(data, "channel.id", (data) => { return Number(data) })
                    },
                    shippingLocation: {
                        ...data.shippingLocation,
                        default: true,
                        status: "ACTIVE"
                    },
                    lat: undefined,
                    lon: undefined,
                    cityList: undefined,
                    district: undefined,
                    countryCode: { ...data.countryCode }
                }

                if (!isEmptyValue(get(data, "shippingLocation.lat", ""))) {
                    temp["shippingLocation"]["lat"] = Number(data?.shippingLocation?.lat);
                } else {
                    temp["shippingLocation"]["lat"] = null;
                }

                if (!isEmptyValue(get(data, "shippingLocation.lon", ""))) {
                    temp["shippingLocation"]["lon"] = Number(data?.shippingLocation?.lon);
                } else {
                    temp["shippingLocation"]["lon"] = null;
                }

                if (isEmptyValue(get(data, "channelOrderNo", ""))) {
                    delete temp["channelOrderNo"];
                }

                if (isEmptyValue(get(data, "channelOrderRef", ""))) {
                    delete temp["channelOrderRef"];
                }

                if (isEmptyValue(get(data, "email", ""))) {
                    delete temp["email"];
                }

                if (get(data, "paymentMethod", "") === "PREPAID") {
                    delete temp["amount"];
                }

                // if (get(data, "idVerificationRequired", "") === "false") {
                //     delete temp["idVerificationType"];
                //     delete temp["idVerificationReference"];
                // }

                if (isEmptyValue(get(data, "shippingLocation.postalCode", ""))) {
                    delete temp["shippingLocation"]["postalCode"];
                }

                if (isEmptyValue(get(data, "shippingLocation.plusCode", ""))) {
                    delete temp["shippingLocation"]["plusCode"];
                }

                if (isEmptyValue(get(data, "notesPublic", ""))) {
                    delete temp["notesPublic"];
                }

                if (isEmptyValue(get(data, "notesPrivate", ""))) {
                    delete temp["notesPrivate"];
                }

                if (!isEmptyValue(props.countryCode[1])) {
                    temp["countryCode"]["id"] = parseInt(props.countryCode[0]);
                    temp["countryCodePrefix"] = props.countryCode[2];
                }

                if (!isEmptyValue(get(data, "phone", "")) && get(props, "countryCode[1]", "OTHER") !== "OTHER") {
                    const phoneNumber = parsePhoneNumber(get(data, "phone", ""), get(props, "countryCode[1]", defaultCountryCode));

                    if (phoneNumber) {
                        temp["phone"] = phoneNumber.number;
                    }
                }

                return temp;
            }}
            onResponseCallBackFn={(error, result) => {  
                if (!error) {
                    props.historyFn.push(`/orders/pending/view/${result.data.id}`);
                } else {
                    dispatch(setFlashMessage({
                        status: true,
                        message: get(error, "errors[0].error", "Something went wrong!"),
                        type: "danger"
                    }));
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const CreateUIWithState = connect(mapStateToProps,  {
    changeInputFn,
    setApiResponse
})(CreateUI);

export {
    CreateUIWithState
}
