import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { DeactivateModel } from "./DeactivateUI";


class ViewUI extends Component {
    render() {
        let { match, history, formState, authState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserStaffList.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            userId: get(response, "userId", ""),
                            name: get(response, "name", ""),
                            email: get(response, "email", ""),
                            phone: get(response, "phone", ""),
                            identification: get(response, "identification", ""),
                            branch: get(response, "merchantBranch.name", ""),
                            credential: get(response, "user.credential", ""),
                            status: get(response, "status", ""),
                            deletedAt: get(response, "deletedAt", null),
                            role: get(response, "user.role.name", null),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Staff</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/users"}
                                />

                                <InputButton
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Edit"}
                                    startIcon={"mdi mdi-square-edit-outline"}
                                    onClickBtnFn={() => history.push(`/users/edit/${match.params.id}`)}
                                />

                                {
                                    (
                                        get(formState, `${getUserStaffList.key}_view.status`, "") === "ACTIVE"
                                        &&
                                        get(authState, "authUser.id", "") !== get(formState, `${getUserStaffList.key}_view.userId`, "")
                                    ) ? (
                                        <DeactivateModel
                                            btnText={"Deactivate"}
                                            startIcon={"mdi mdi-trash-can-outline"}
                                            btnColor={"danger"}
                                            message={"Are you sure you want to deactivate this user?"}
                                            id={match.params.id}
                                            history={history}
                                            status={"INACTIVE"}
                                            flashMessage={"Successfully deactivated the user"}
                                        />
                                    ) : get(authState, "authUser.id", "") !== get(formState, `${getUserStaffList.key}_view.userId`, "") ? (
                                            <DeactivateModel
                                            btnText={"Activate"}
                                            startIcon={"far fa-check-circle"}
                                            btnColor={"success"}
                                            message={"Are you sure you want to activate this user?"}
                                            id={match.params.id}
                                            history={history}
                                            status={"ACTIVE"}
                                            flashMessage={"Successfully activated the user"}
                                            />
                                    ) : null
                                }
                            </div>
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    {/*<h4 className="card-title mb-3">User Details</h4>*/}

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Name</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Identification</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.identification`, "")}</dt>

                                                <dd className={"col-sm-4"}>E-mail</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.email`, "")}</dt>

                                                <dd className={"col-sm-4"}>Phone</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.phone`, "")}</dt>

                                                {get(formState, `${getUserStaffList.key}_view.deletedAt`) !== null ? (
                                                    <Fragment>
                                                        <dd className={"col-sm-4"}>Deleted At</dd>
                                                        <dt className={"col-sm-8"}>{dateObjectToString(
                                                            get(formState, `${getUserStaffList.key}_view.deletedAt`, ""),
                                                            "YY-mm-dd HH:MM:II"
                                                        )
                                                        }</dt>
                                                    </Fragment>

                                                ) : null

                                                }
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Branch</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.branch`, "-") ? get(formState, `${getUserStaffList.key}_view.branch`, "-") : "-"}</dt>

                                                <dd className={"col-sm-4"}>Credential</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.credential`, "")}</dt>

                                                <dd className={"col-sm-4"}>Role</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.role`, "-")}</dt>

                                                <dd className={"col-sm-4"}>Status</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getUserStaffList.key}_view.status`, "")}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
    ViewUIWithState
}
