import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { cashOnDeliveryReportAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { fromObjectToQueryString } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";


const TableLastRow = (props) => {
    return (
        <Fragment>
            <tr>
                <td colSpan={"5"}></td>
                <td>Total</td>
                <td>{get(props, "tableBodyLastProps.amount", "")}</td>
                <td>{get(props, "tableBodyLastProps.cashDeliveryPrice", "")}</td>
                <td>{get(props, "tableBodyLastProps.cardDeliveryPrice", "")}</td>
            </tr>
        </Fragment>
    )
}

class CashCardOnDeliveryView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaderStatus: false
        }
    }

    getQueryString = () => {
        let { formState } = this.props;

        let dateTo = new Date(get(formState, "cash_card_delivery_report.dateTo", new Date()));
        dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
        dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

        const queryObj = {
            dateFrom: get(formState, "cash_card_delivery_report.dateFrom", new Date()).toISOString(),
            dateTo: dateTo.toISOString(),
            paymentMethod: get(formState, "cash_card_delivery_report.paymentMethod", "")
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    render() {
        let { formState, authState, coreState } = this.props;

        return (
            <React.Fragment>
                {
                    (get(formState, "cash_card_delivery_report.dateFrom", "") === "" || get(formState, "cash_card_delivery_report.dateTo", "") === "") ? (
                        <Redirect to={"/reports/cash-or-card-on-delivery-report"} />
                    ) : (
                        <div className="container-fluid">
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box">
                                        <h4 className="font-size-18">Cash / Card on Delivery Report</h4>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            From<strong className={"mx-2"}>{dateObjectToString(get(formState, "cash_card_delivery_report.dateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "cash_card_delivery_report.dateTo"), "dd/mm/YY")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Merchant:<strong className={"mx-2"}>{get(authState, "authUser.merchant.name", "")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Payment Type:<strong className={"mx-2"}>{get(formState, "cash_card_delivery_report.paymentMethod", "") === "" ? "All" : get(formState, "cash_card_delivery_report.paymentMethod", "")}</strong>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <BackBtnComponent
                                            path={"/reports/cash-or-card-on-delivery-report"}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className={"row mt-3"}>
                                <CrudComponent
                                    title={"Cash / Card on Delivery Report"}
                                    apiURL={`${cashOnDeliveryReportAPI.url}?page=1&limit=20${this.getQueryString()}`}
                                    dataTableStateKey={cashOnDeliveryReportAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={false}
                                    isSetAction={false}
                                    tableHeaderList={[
                                        { displayName: "Transaction Date & Time", key: "deliveredAt", onPrintFn: (rawData) => dateObjectToString(rawData.deliveredAt, "YY-mm-dd HH:MM:II")  },//delivery time
                                        { displayName: "Consignment ID", key: "consignmentNo" },
                                        { displayName: "Merchant Code", key: "merchant.code" },
                                        { displayName: "Number of Articles", key: "_count.packages" },
                                        { displayName: "Delivery Driver", key: "courier.staffs[0].name", default:"-" },
                                        { displayName: "Payment Type", key: "paymentMethod" },
                                        { displayName: "Amount", key: "amount", onPrintFn: (rawData) => {
                                            return rawData.amount === null ? 0 : rawData.amount;
                                        }},
                                        { displayName: "Cash on Delivery Fee", key: "cashDeliveryPrice", onPrintFn: (rawData) => {
                                            if (rawData.paymentMethod === "CASH_ON_DELIVERY") {
                                                return rawData.cashDeliveryPrice;
                                            } else {
                                                return 0;
                                            }
                                        }},
                                        { displayName: "Card on Delivery Fee", key: "cardDeliveryPrice", onPrintFn: (rawData) => {
                                            if (rawData.paymentMethod === "CARD_ON_DELIVERY") {
                                                return rawData.cardDeliveryPrice;
                                            } else {
                                                return 0;
                                            }
                                        }}
                                    ]}
                                    tableBodyLastComponent={TableLastRow}
                                    tableBodyLastProps={get(coreState, `dataTableResponses.${cashOnDeliveryReportAPI.key}.totalsRow`, {})}
                                    isForceShowLoader={this.state.loaderStatus}
                                />
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
        coreState: state.CoreState,
    };
};

export default connect(mapStateToProps, null)(CashCardOnDeliveryView);
