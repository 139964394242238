import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  VariableToComponent,
  ToolTipComponent,
} from '../../CommonElements/BaseElements';
import { commonActionBtnPops } from '../configs/crud.config';
import ActionBtn from './ActionBtn';
import {
  CheckBoxWithState,
} from '../../../../components/boda-boda/Forms';
import {
  setBulkValues,
  setSelectAllValue,
} from '../../../../store/boda-boda/form/actions';
import { removeFeildsFromBulkPrintPackages } from '../../../../helpers/boda-boda/common-helpers/common.helpers';

const emptyFun = (...para) => undefined;

class Table extends Component {
  static defaultProps = {
    dataTableStateKey: '',
    tableHeaderList: [],
    tableBodyList: [],
    actionList: [],
    isSetAction: true,
    isDropdownActionBtn: false,
    actionButtonListComponent: null,
    actionButtonListProps: {},
    tableBodyLastComponent: null,
    tableBodyLastProps: {},
    manageModalFn: emptyFun,
    setModelStatusFn: emptyFun,
    inputName: '',
    isBulkAdd: false,
    isBulkPrint: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      actionBtnDropdowns: {},
      selectAll: false,
    };
  }

  componentDidUpdate(prevProp, prevState) {
    const prev = get(prevProp, 'formData.packageListAPIKey', '');
    const current = get(this.props, 'formData.packageListAPIKey', '');
    const results =
      this.props?.coreState?.dataTableResponses?.packageListAPIKey?.results;
    const tempArray = JSON.parse(sessionStorage.getItem('selectedPackages'))
    const tempBulkPrint = tempArray ? tempArray : []

    // for the saving all selected packages in session storage
    const packageIds = []
    if (results){
      for (let i = 0; i < tempBulkPrint.length; i++) {
          packageIds.push(tempBulkPrint[i]?.id)
      }

      results.map((item, key) => {
          if (this.props?.isBulkPrint || this.props?.isBulkAdd) {
              if (current[`printChk${item.id}`] || current[`addChk${item.id}`]) {
                  if (!packageIds.includes(item.id)) {
                      // Remove unwanted items from the object
                      const newPackage = removeFeildsFromBulkPrintPackages(item)
                      tempBulkPrint.push(newPackage)
                  }
              } else if (current[`printChk${item.id}`] === false || current[`addChk${item.id}`] === false) {
                  if (packageIds.includes(item.id)) {
                      const index = packageIds.indexOf(item.id);
                      tempBulkPrint.splice(index, 1);
                      packageIds.splice(index, 1)
                  }
              }
          }
      });
      if (tempBulkPrint.length > 0) {
          //localStorage.removeItem('selectedPackages')//, JSON.stringify(tempBulkPrint))
          sessionStorage.setItem('selectedPackages', JSON.stringify(tempBulkPrint))
      }
      if (current.selectall === false && tempBulkPrint.length === 0) {
          sessionStorage.setItem('selectedPackages', JSON.stringify([]))
      }
    }
  

    if (prev.selectall !== current.selectall && results) {
      let selectedPackages = [];
      if (this.props?.isBulkPrint) {
        results.map((item, key) => {
          selectedPackages.push(`printChk${item.id}`);
        });
      } else if (this.props?.isBulkAdd) {
        results.map((item, key) => {
          selectedPackages.push(`addChk${item.id}`);
        });
      }

      let selectedCounter = 0;
      results.map((item, key) => {
        if (this.props?.isBulkPrint) {
          if (current[`printChk${item.id}`]) {
            selectedCounter++;
          }
        } else if (this.props?.isBulkAdd) {
          if (current[`addChk${item.id}`]) {
            selectedCounter++;
          }
        }
      });

      let selectedPacks = [];

      if (prev.selectall && !current.selectall && selectedCounter !== results?.length) {
        if (this.props?.isBulkPrint) {
          results.map((item, key) => {
            let pair = {};
            let value = selectedPackages[key];
            pair[value] = current[`printChk${item.id}`];
            selectedPacks.push(pair);
          });
        } else if (this.props?.isBulkAdd) {
          results.map((item, key) => {
            let pair = {};
            let value = selectedPackages[key];
            pair[value] = current[`addChk${item.id}`];
            selectedPacks.push(pair);
          });
        }
      } else {
        selectedPackages.map((item, key) => {
          let pair = {};
          let value = selectedPackages[key];
          pair[value] = current.selectall;
          selectedPacks.push(pair);
        });
      }

      results.map((item, key) => {
        this.props.setBulkValues('packageListAPIKey', selectedPacks[key]);
      });
    }

    if (this.props?.isBulkPrint) {
      if (prev.selectall == true) {
        let count = 0;
        results.map((item, key) => {
          if (!current[`printChk${item.id}`]) {
            count++;
          }
          count > 0 &&
            this.props.setSelectAllValue('packageListAPIKey', false, 'selectall');
        });
      }
    }
    if (this.props?.isBulkAdd) {
      if (prev.selectall == true) {
        let count = 0;
        results.map((item, key) => {
          if (!current[`addChk${item.id}`]) {
            count++;
          }
          count > 0 &&
            this.props.setSelectAllValue('packageListAPIKey', false, 'selectall');
        });
      }
    }
  }

  onClickAction(actionData, row) {
    if (actionData.isSetModel === true) {
      this.props.manageModalFn(actionData, row);
    } else {
      const onClickFn = get(actionData, 'actionBtn.onClickBtnFn', emptyFun);
      onClickFn(row);
    }
  }

  render() {
    let {
      tableHeaderList,
      tableBodyList,
      actionList,
      isSetAction,
      actionButtonListComponent,
      setModelStatusFn,
      actionButtonListProps,
      dataTableStateKey,
      isDropdownActionBtn,
      tableBodyLastComponent,
      tableBodyLastProps,
      isBulkPrint,
      isBulkAdd,
      inputName,
    } = this.props;

    return (
      <table id='tech-companies-1' className='table table-striped'>
        <thead>
          <tr>
            {isBulkPrint ? (
              <th key=''>
                <CheckBoxWithState
                  inputName={`selectall`}
                  inputValue={get(
                    this.props,
                    'formData.packageListAPIKey.selectall',
                    false
                  )}
                  formGroupName={`${dataTableStateKey}`}
                />
              </th>
            ) : null}
            {isBulkAdd ? (
              <th key=''>
                <CheckBoxWithState
                  inputName={`selectall`}
                  inputValue={get(
                    this.props,
                    'formData.packageListAPIKey.selectall',
                    false
                  )}
                  formGroupName={`${dataTableStateKey}`}
                />
              </th>
            ) : null}
            {tableHeaderList.map((value, index) => {
              return <th key={index}>{value.displayName}</th>;
            })}
            {isSetAction === true ? <th key='ac1'>Actions</th> : null}
          </tr>
        </thead>
        <tbody>
          {tableBodyList.map((bodyData, index) => {
            return (
              <tr key={index}>
                {isBulkPrint ? (
                  <td>
                    <CheckBoxWithState
                      inputName={`printChk${bodyData.id}`}
                      inputValue={''}
                      formGroupName={`${dataTableStateKey}`}
                    />
                  </td>
                ) : null}
                {isBulkAdd ? (
                  <td>
                    <CheckBoxWithState
                      inputName={`addChk${bodyData.id}`}
                      inputValue={''}
                      formGroupName={`${dataTableStateKey}`}
                    />
                  </td>
                ) : null}
                {tableHeaderList.map((headerValue, index) => {
                  return (
                    <td key={index}>
                      {get(headerValue, 'onPrintFn', null) === null
                        ? get(
                          bodyData,
                          headerValue.key,
                          get(headerValue, 'default', '')
                        )
                        : get(headerValue, 'onPrintFn', emptyFun)(bodyData)}
                    </td>
                  );
                })}

                {isSetAction === true ? (
                  <Fragment>
                    {isDropdownActionBtn ? (
                      <td key={'ac1'}>
                        <ActionBtn
                          isOpen={
                            this.state.actionBtnDropdowns[
                            `actionDropdown_${index}`
                            ]
                          }
                          actionList={actionList}
                          bodyData={bodyData}
                          onClickActionFn={(action, bodyData) =>
                            this.onClickAction(action, bodyData)
                          }
                          toggleFn={(value) =>
                            this.setState({
                              actionBtnDropdowns: {
                                [`actionDropdown_${index}`]: value,
                              },
                            })
                          }
                        />
                      </td>
                    ) : (
                      <td key={'ac1'}>
                        {actionButtonListComponent !== null ? (
                          <Fragment>
                            <VariableToComponent
                              component={actionButtonListComponent}
                              props={{
                                row: bodyData,
                                rowIndex: index,
                                showModelFn: (action, row) =>
                                  this.onClickAction(action, row),
                                setModelStatusFn: (status) =>
                                  setModelStatusFn(status),
                                actionButtonListProps: actionButtonListProps,
                              }}
                            />
                          </Fragment>
                        ) : null}

                        {actionList.map((action, i) => {
                          action = {
                            ...action,
                            actionBtn: {
                              ...get(
                                commonActionBtnPops,
                                action.actionType,
                                {}
                              ),
                              ...(action.actionBtn || {}),
                            },
                          };
                          const checkBeforeActionRender =
                            get(action, 'checkBeforeActionRenderFn', null) ===
                              null
                              ? true
                              : get(
                                action,
                                'checkBeforeActionRenderFn',
                                emptyFun
                              )({
                                rowData: bodyData,
                              });

                          if (checkBeforeActionRender === true) {
                            return (
                              <Fragment key={i}>
                                <Button
                                  id={`${dataTableStateKey}_${index}_${get(
                                    action,
                                    'actionBtn.btnText',
                                    ''
                                  )}`}
                                  onClick={() => {
                                    if (
                                      get(action, 'isSetModel', false) !== false
                                    ) {
                                      this.onClickAction(action, bodyData);
                                    } else {
                                      get(
                                        action,
                                        'actionBtn.onClickBtnFn',
                                        emptyFun
                                      )(bodyData);
                                    }
                                  }}
                                  color={get(action, 'actionBtn.color')}
                                  className={`btn waves-effect waves-light ml-1 mr-1 ${get(
                                    action,
                                    'actionBtn.elementStyle'
                                  )}`}
                                >
                                  <i
                                    className={get(action, 'actionBtn.icon')}
                                  ></i>
                                </Button>

                                {get(action, 'actionBtn.tooltip', '') !== '' ? (
                                  <ToolTipComponent
                                    target={`${dataTableStateKey}_${index}_${get(
                                      action,
                                      'actionBtn.btnText',
                                      ''
                                    )}`}
                                    tooltipText={get(
                                      action,
                                      'actionBtn.tooltip',
                                      ''
                                    )}
                                  />
                                ) : null}
                              </Fragment>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </td>
                    )}
                  </Fragment>
                ) : null}
              </tr>
            );
          })}

          {tableBodyLastComponent !== null ? (
            <Fragment>
              <VariableToComponent
                component={tableBodyLastComponent}
                props={{
                  bodyData: tableBodyList,
                  tableBodyLastProps: tableBodyLastProps,
                }}
              />
            </Fragment>
          ) : null}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.FormData,
    coreState: state.CoreState,
  };
};

export default connect(mapStateToProps, { setBulkValues, setSelectAllValue })(
  Table
);
