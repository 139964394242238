import React, { Fragment, useState } from "react";

import { SubmitButton, InputButton } from "../../../../../../../components/boda-boda/Forms";
import { getOrdersListAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../../../../components/boda-boda/Modal/ModalUI";


const RestoreUIMessage = () => {
    return(
        <p>Are you sure you want to restore this order?</p>
    );
};

const RestoreBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getOrdersListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Order has successfully restored",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: props.type === "datatable" ? apiResponseStoringType.dateTableLinkedForm : apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${props.row.id}`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    "status": "PENDING"
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.type === "datatable") {
                        props.setModelStatusFn(false);
                    } else {
                        props.history.push("/orders/all");
                    }
                }
            }}
        />
    );
};

const RestoreModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                elementWrapperStyle={"ml-2"}
                btnText={"Restore"}
                startIcon={"fas fa-redo"}
                btnColor={"success"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<RestoreUIMessage />}
                modalFooterComponent={<RestoreBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={"form"}
                    row={{ "id": props.orderId }}
                    history={props.history}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    RestoreUIMessage,
    RestoreBtn,
    RestoreModal
}
