import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {filter, find, get} from "lodash";

import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    SubmitButton,
} from "../../../../../../components/boda-boda/Forms";
import {
    getOrdersListAPI,
    getChannelListAPI,
    getCityListAPI,
    merchantContractAPI, countryCodeAPI, getDistrictListAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import {
    deliveryTypes,
    paymentMethods,
} from "../../../../../../config/boda-boda/db_config/db_status.config";
import { apiResponseStoringType } from "../../../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject, isEmptyValue
} from "../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { defaultCountryCode } from "../../../../../../config/boda-boda/core.config";
import parsePhoneNumber from "libphonenumber-js";
import {changeInputFn} from "../../../../../../store/boda-boda/form/actions";
import {setApiResponse} from "../../../../../../store/boda-boda/core/actions";
import { setFlashMessage } from "../../../../../../store/actions";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";


class EditUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentTypes: [],
            cities: [],
            loadingCities: true
        }
    }

    componentDidUpdate(prevProps) {
        const currentDistrict = get(this.props, "formState.ordersListAPIKey_edit.district", "")
        const prevDistrict = get(prevProps, "formState.ordersListAPIKey_edit.district", "")
        if (currentDistrict !== prevDistrict) {
            this.getCityData(get(this.props, "formState.ordersListAPIKey_edit.district", ""));
        }
      }

    checkPaymentMethodTypes = (response) => {
        const types = [paymentMethods[2]];

        if (response) {
            if (get(response, "cashOnDelivery", false) === true) {
                types.push(paymentMethods[0]);
            }

            if (get(response, "cardOnDelivery", false) === true) {
                types.push(paymentMethods[1]);
            }
        }

        this.setState({
            ...this.state,
            paymentTypes: types
        })
    }

    getCityData = (districtId) => {
        this.setState({loadingCities: true})
        callApi (`${getCityListAPI.url}/all/order?districtId=${districtId}`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                if (get(result, "_statue", "") === false) {
                    this.setState({loadingCities: true})
                } else {
                    let cities = result.data.data.map(result => {
                        return {id: result.id, value: result.name}
                    })
                    this.setState({cities: cities})
                    this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.city.id`, "");
                    this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.shippingLocation.postalCode`, "");
                    this.props.changeInputFn(`${getOrdersListAPI.key}_create`, "", `${getOrdersListAPI.key}_create.cityList`, result.data.data);
                    this.props.setApiResponse(getCityListAPI.key, result.data.data);
                    this.setState({loadingCities: false})
                }
            })
            .catch((error) => {
                this.setState({loadingCities: true})
            })
    }

    render() {
        let { history, match, formState, coreState, changeInputFn, setApiResponse } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${merchantContractAPI.key}_check`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${merchantContractAPI.url}?page=1&limit=20`}
                    onRebuildResponseFn={(response) => {
                        this.checkPaymentMethodTypes(get(response, "[0]", []));

                        return {
                            "_onLoad": false
                        }
                    }}
                >
                    <FormWrapper
                        setGroupName={`${getOrdersListAPI.key}_edit`}
                        onDestroyUnsetFormObject={true}
                        apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
                        onRebuildResponseFn={(response) => {
                            return {
                                name: response.name,
                                email: response.email,
                                consignmentNo: response.consignmentNo,
                                idVerificationRequired: response.idVerificationRequired,
                                // idVerificationType: response.idVerificationType,
                                // idVerificationReference: response.idVerificationReference,
                                phone: response.phone,
                                channelOrderNo: response.channelOrderNo,
                                channelOrderRef: response.channelOrderRef,
                                channel: {
                                    id: response.channelId
                                },
                                amount: response.amount,
                                notesPublic: response.notesPublic,
                                notesPrivate: response.notesPrivate,
                                status: response.status,
                                deliveryType: response.deliveryType,
                                paymentMethod: response.paymentMethod,
                                signatureRequired: response.signatureRequired,
                                paid: response.paid,
                                // lat: get(response, "shippingLocation.lat", ""),
                                // lon: get(response, "shippingLocation.lon", ""),
                                shippingLocation: {
                                    name: get(response, "shippingLocation.name", ""),
                                    address: get(response, "shippingLocation.address", ""),
                                    postalCode: get(response, "shippingLocation.postalCode", ""),
                                    plusCode: get(response, "shippingLocation.plusCode", ""),
                                    lat: get(response, "shippingLocation.lat", ""),
                                    lon: get(response, "shippingLocation.lon", ""),
                                    status: get(response, "shippingLocation.status", ""),
                                    default: get(response, "shippingLocation.default", ""),
                                    city: {
                                        id: get(response, "shippingLocation.cityId", ""),
                                    }
                                },
                                countryCodePrefix: get(response, "countryCodePrefix", ""),
                                countryCode: {
                                    id: `${get(response, "countryCode.id", "")}/${get(response, "countryCode.code", "")}/${get(response, "countryCode.prefix", "")}`
                                },
                                cityList: [],
                                district: get(response, "shippingLocation.city.districtId", ""),
                                "_onLoad": false,
                            }
                        }}
                    >
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                    <h4 className="font-size-18">Edit an Order</h4>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className={"d-flex justify-content-end"}>
                                    <EditBtn
                                        historyFn={history}
                                        coreState={coreState}
                                        orderId={match.params.id}
                                        countryCode={get(formState, `${getOrdersListAPI.key}_edit.countryCode.id`, "").toString().split("/")}
                                    />

                                    <BackBtnComponent
                                        path={"/orders/pending"}
                                        btnName={"Cancel"}
                                        icon={"far fa-times-circle"}
                                        btnColor={"danger"}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Order Details</h4>

                                        <div className={"row"}>
                                            <div className={"col-md-6"}>
                                                <dl className={"row mb-0"}>
                                                    <dt className={"col-sm-4"}>Order ID</dt>
                                                    <dd className={"col-sm-8"}>{match.params.id}</dd>

                                                    <dt className={"col-sm-4"}>Consignment No</dt>
                                                    <dd className={"col-sm-8"}>{get(formState, `${getOrdersListAPI.key}_edit.consignmentNo`, "")}</dd>
                                                </dl>
                                            </div>

                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    <dt className={"col-sm-4"}>Status</dt>
                                                    <dd className={"col-sm-8"}>{get(formState, `${getOrdersListAPI.key}_edit.status`, "")}</dd>
                                                </dl>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Channel Order No"
                                                    placeHolderText={"Enter Channel Order No"}
                                                    inputName={"channelOrderNo"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={20}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Channel Order Reference"
                                                    placeHolderText={"Enter Channel Order Reference"}
                                                    inputName={"channelOrderRef"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={15}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Channel"
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.channel.id`}
                                                    placeHolderText={"Select a Channel"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    apiUrl={`${getChannelListAPI.url}/all`}
                                                    apiStateKey={getChannelListAPI.key}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Delivery Type"
                                                    inputName={"deliveryType"}
                                                    placeHolderText={"Select a Delivery Type"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    dataList={deliveryTypes}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Signature Required"
                                                    inputName={"signatureRequired"}
                                                    placeHolderText={"Select a Option"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    dataList={[
                                                        {
                                                            id: true,
                                                            value: "Yes"
                                                        },
                                                        {
                                                            id: false,
                                                            value: "No"
                                                        }
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="Payment Method"
                                                    inputName={"paymentMethod"}
                                                    placeHolderText={"Select a Payment Method"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    dataList={this.state.paymentTypes}
                                                />
                                            </div>

                                            {
                                                (get(formState, `${getOrdersListAPI.key}_edit.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Amount"
                                                            placeHolderText={"Enter Amount"}
                                                            inputName={"amount"}
                                                            inputType={"number"}
                                                            formGroupName={`${getOrdersListAPI.key}_edit`}
                                                            maxLength={10}
                                                        />
                                                    </div>
                                                ) : null
                                            }

                                            {/*<div className="col-md-6">*/}
                                            {/*    <SelectBoxWithState*/}
                                            {/*        isRequired={true}*/}
                                            {/*        labelText="Status"*/}
                                            {/*        inputName={"status"}*/}
                                            {/*        placeHolderText={"Select a Status"}*/}
                                            {/*        formGroupName={`${getOrdersListAPI.key}_edit`}*/}
                                            {/*        dataList={orderStatusList}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Receiver Details</h4>

                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    isRequired={true}
                                                    labelText="Customer Name"
                                                    placeHolderText={"Enter Customer Name"}
                                                    inputName={"name"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={100}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    isRequired={false}
                                                    labelText="E-mail"
                                                    inputName={"email"}
                                                    placeHolderText={"Enter E-mail"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                     maxLength={50}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <div className={"row"}>
                                                    <div className={"col-md-3"}>
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText="Country Code"
                                                            inputStatePath={`${getOrdersListAPI.key}_edit.countryCode.id`}
                                                            formGroupName={`${getOrdersListAPI.key}_edit`}
                                                            apiUrl={`${countryCodeAPI.url}/all`}
                                                            apiStateKey={countryCodeAPI.key}
                                                            onRebuildResponseFn={(response) => {
                                                                const data = [];

                                                                response.forEach((value) => {
                                                                    data.push({
                                                                        id: `${value.id}/${value.code}/${value.prefix}`,
                                                                        value: value.code
                                                                    });
                                                                });

                                                                return {
                                                                    "data": {
                                                                        "data": data
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className={"col-md-9"}>
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Phone"
                                                            inputName={"phone"}
                                                            placeHolderText={"Enter Phone"}
                                                            formGroupName={`${getOrdersListAPI.key}_edit`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="ID Verification Required"
                                                    inputName={"idVerificationRequired"}
                                                    placeHolderText={"Select a Status"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    dataList={[
                                                        {
                                                            id: true,
                                                            value: "YES"
                                                        },
                                                        {
                                                            id: false,
                                                            value: "NO"
                                                        }
                                                    ]}
                                                />
                                            </div>

                                            {/*{*/}
                                            {/*    (get(formState, `${getOrdersListAPI.key}_edit.idVerificationRequired`, false) === true) ? (*/}
                                            {/*        <Fragment>*/}
                                            {/*            <div className="col-md-6">*/}
                                            {/*                <SelectBoxWithState*/}
                                            {/*                    isRequired={true}*/}
                                            {/*                    labelText="ID Type"*/}
                                            {/*                    inputName={"idVerificationType"}*/}
                                            {/*                    placeHolderText={"Select a Type"}*/}
                                            {/*                    formGroupName={`${getOrdersListAPI.key}_edit`}*/}
                                            {/*                    dataList={idVerificationTypes}*/}
                                            {/*                />*/}
                                            {/*            </div>*/}

                                            {/*            <div className="col-md-6">*/}
                                            {/*                <InputBoxWithState*/}
                                            {/*                    isRequired={true}*/}
                                            {/*                    labelText="ID Reference"*/}
                                            {/*                    inputName={"idVerificationReference"}*/}
                                            {/*                    placeHolderText={"Enter ID Verification Reference"}*/}
                                            {/*                    formGroupName={`${getOrdersListAPI.key}_edit`}*/}
                                            {/*                />*/}
                                            {/*            </div>*/}
                                            {/*        </Fragment>*/}
                                            {/*    ) : null*/}
                                            {/*}*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Shipping Location</h4>

                                        <div className={"row"}>
                                            <div className={"col-md-6"}>
                                                <InputBoxWithState
                                                    isRequired={true}
                                                    labelText="Location Name"
                                                    placeHolderText={"Enter Location Name"}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.name`}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={50}
                                                />
                                            </div>

                                            <div className={"col-md-6"}>
                                                <InputBoxWithState
                                                    isRequired={true}
                                                    labelText="Address"
                                                    placeHolderText={"Enter Address"}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.address`}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={250}
                                                />
                                            </div>

                                            <div className={"col-md-6"}>
                                                <InputBoxWithState
                                                    isRequired={false}
                                                    labelText="Postal Code"
                                                    placeHolderText={"Enter Postal Code"}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.postalCode`}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={10}
                                                />
                                            </div>

                                            <div className={"col-md-6"}>
                                                <InputBoxWithState
                                                    isRequired={false}
                                                    labelText="Plus Code"
                                                    placeHolderText={"Enter Plus Code"}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.plusCode`}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={10}
                                                />
                                            </div>

                                            <div className={"col-md-6"}>
                                                <InputBoxWithState
                                                    isRequired={false}
                                                    labelText="Latitude"
                                                    placeHolderText={"Enter Latitude"}
                                                    // inputName={"lat"}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.lat`}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={12}
                                                    inputType={"number"}
                                                />
                                            </div>

                                            <div className={"col-md-6"}>
                                                <InputBoxWithState
                                                    isRequired={false}
                                                    labelText="Longitude"
                                                    placeHolderText={"Enter Longitude"}
                                                    // inputName={"lon"}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.lon`}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    maxLength={13}
                                                    inputType={"number"}
                                                />
                                            </div>

                                            <div className={"col-md-6"}>
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="District"
                                                    inputName={"district"}
                                                    placeHolderText={"Select a District"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    apiUrl={getDistrictListAPI.url}
                                                    apiStateKey={getDistrictListAPI.key}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onChangeFn={(eventData) => {this.getCityData(eventData.value)}}
                                                />
                                            </div>
                                            <div className={"col-md-6"}>
                                                <SelectBoxWithState
                                                    isRequired={true}
                                                    labelText="City"
                                                    isDisabled={this.state.loadingCities}
                                                    inputStatePath={`${getOrdersListAPI.key}_edit.shippingLocation.city.id`}
                                                    placeHolderText={"Select a City"}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                    apiStateKey={getCityListAPI.key}
                                                    dataList={this.state.cities}
                                                    onChangeFn={(eventData) => {
                                                        const city = find(
                                                            get(formState, `${getOrdersListAPI.key}_edit.cityList`, []),
                                                            (e) => e.id === eventData.value
                                                        );

                                                        if (city && get(city, "postalCode", null) != null) {
                                                            changeInputFn(`${getOrdersListAPI.key}_edit`, "", `${getOrdersListAPI.key}_edit.shippingLocation.postalCode`, get(city, "postalCode", ""));
                                                        } else {
                                                            changeInputFn(`${getOrdersListAPI.key}_edit`, "", `${getOrdersListAPI.key}_edit.shippingLocation.postalCode`, "");
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className="card">
                                    <div className="card-body">

                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Public Note"
                                                    inputName={"notesPublic"}
                                                    inputType={"textarea"}
                                                    rows={3}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Private Note"
                                                    inputName={"notesPrivate"}
                                                    inputType={"textarea"}
                                                    rows={3}
                                                    formGroupName={`${getOrdersListAPI.key}_edit`}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                </FormWrapper>
            </div>
        )
    }
}

const EditBtn = (props) => {
   
    const dispatch = useDispatch();
    
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getOrdersListAPI.key}_edit`}
            dataTableKey={getOrdersListAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    name: "Customer Name",
                    email: "Email",
                    idVerificationRequired: "ID Verification",
                    // idVerificationType: "ID Type",
                    // idVerificationReference: "ID Reference",
                    phone: "Phone",
                    "countryCode.id": "Country Code",
                    channelOrderNo: "Channel Order No",
                    "channel.id": "Channel",
                    status: "Status",
                    amount: "Amount",
                    deliveryType: "Delivery Type",
                    paymentMethod: "Payment Method",
                    signatureRequired: "Signature Required",
                    "shippingLocation.name": "Location Name",
                    "shippingLocation.address": "Address",
                    "shippingLocation.postalCode": "Postal Code",
                    "shippingLocation.plusCode": "Plus Code",
                    "shippingLocation.lat": "Latitude",
                    "shippingLocation.lon": "Longitude",
                    "shippingLocation.status": "Status",
                    "shippingLocation.city.id": "City",
                },
                rules: {
                    name: "required|min:4|max:50",
                    email: "email",
                    "channel.id": "required",
                    idVerificationRequired: "required",
                    // idVerificationType: "requiredIf:idVerificationRequired,true",
                    // idVerificationReference: "requiredIf:idVerificationRequired,true",
                    phone: `required|phoneNumber:${get(props, "countryCode[1]", defaultCountryCode)},countryCode.id`,
                    "countryCode.id": "required",
                    status: "required",
                    deliveryType: "required",
                    paymentMethod: "required",
                    signatureRequired: "required",
                    amount: "requiredIfMultiple:paymentMethod,CASH_ON_DELIVERY,CARD_ON_DELIVERY|min:0|max:10",
                    "shippingLocation.name": "required|min:4",
                    "shippingLocation.address": "required|max:114",
                    "shippingLocation.city.id": "required",
                    "shippingLocation.postalCode": "min:5",
                },
                message: {
                    "amount.max": "The amount may not be greater than 10 digits",
                    "amount.min": "The amount must be at least 0",
                    "amount.requiredIfMultiple": "The Amount field is required when payment method is either Cash on Delivery or Card on Delivery",
                    "shippingLocation.postalCode.min": "The Postal Code must be at least 5 characters",
                    "shippingLocation.name.min": "The Location Name must be at least 4 characters"
                }
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Order has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${props.orderId}`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let temp = {
                    ...data,
                    idVerificationRequired: formObjectValidationHelper(data, "idVerificationRequired", (data) => { return data === true }),
                    signatureRequired: formObjectValidationHelper(data, "signatureRequired", (data) => { return data === true }),
                    amount: formObjectValidationHelper(data, "amount", (data) => { return Number(data) }),
                    paid: data.paymentMethod === "PREPAID",
                    channel: {
                        id: formObjectValidationHelper(data, "channel.id", (data) => { return Number(data) })
                    },
                    shippingLocation: {
                        ...data.shippingLocation,
                        default: get(data, "shippingLocation.default", true),
                        status: get(data, "shippingLocation.status", "")
                    },
                    lat: undefined,
                    lon: undefined,
                    cityList: undefined,
                    district: undefined,
                    countryCode: { ...data.countryCode }
                }

                if (!isEmptyValue(get(data, "shippingLocation.lat", ""))) {
                    temp["shippingLocation"]["lat"] = Number(data?.shippingLocation?.lat);
                } else {
                    temp["shippingLocation"]["lat"] = null;
                }

                if (!isEmptyValue(get(data, "shippingLocation.lon", ""))) {
                    temp["shippingLocation"]["lon"] = Number(data?.shippingLocation?.lon);
                } else {
                    temp["shippingLocation"]["lon"] = null;
                }

                if (isEmptyValue(get(data, "channelOrderNo", ""))) {
                    delete temp["channelOrderNo"];
                }

                if (isEmptyValue(get(data, "channelOrderRef", ""))) {
                    delete temp["channelOrderRef"];
                }

                if (isEmptyValue(get(data, "email", ""))) {
                    delete temp["email"];
                }

                if (get(data, "paymentMethod", "") === "PREPAID") {
                    delete temp["amount"];
                }

                // if (get(data, "idVerificationRequired", "") === "false") {
                //     delete temp["idVerificationType"];
                //     delete temp["idVerificationReference"];
                // }

                if (isEmptyValue(get(data, "shippingLocation.postalCode", ""))) {
                    delete temp["shippingLocation"]["postalCode"];
                }

                if (isEmptyValue(get(data, "shippingLocation.plusCode", ""))) {
                    delete temp["shippingLocation"]["plusCode"];
                }

                if (isEmptyValue(get(data, "notesPublic", ""))) {
                    delete temp["notesPublic"];
                }

                if (isEmptyValue(get(data, "notesPrivate", ""))) {
                    delete temp["notesPrivate"];
                }

                if (!isEmptyValue(props.countryCode[1])) {
                    temp["countryCode"]["id"] = parseInt(props.countryCode[0]);
                    temp["countryCodePrefix"] = props.countryCode[2];
                }

                if (!isEmptyValue(get(data, "phone", "")) && get(props, "countryCode[1]", "OTHER") !== "OTHER") {
                    const phoneNumber = parsePhoneNumber(get(data, "phone", ""), get(props, "countryCode[1]", defaultCountryCode));

                    if (phoneNumber) {
                        temp["phone"] = phoneNumber.number;
                    }
                }

                return temp;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push("/orders/pending");
                } else {
                    dispatch(setFlashMessage({
                        status: true,
                        message: get(error, "errors[0].error", "Something went wrong!"),
                        type: "danger"
                    }));
                }
            }}
        />
    )
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const EditUIWithState = connect(mapStateToProps, {
    changeInputFn,
    setApiResponse
})(EditUI);

export { EditUIWithState }
