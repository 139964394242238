export const initFormGroupKey = '@FormContext/SET_INIT_FORM';
export const removeFormGroupKey = '@FormContext/REMOVE_FORM_GROUP';
export const setErrorsKey = '@FormContext/SET_INPUT_ERRORS';
export const setInputValueChangeKey = '@FormContext/SET_FORM_INPUT_CHANGE';
export const setComplexInputValueChangeKey =
  '@FormContext/SET_COMPLEX_FORM_INPUT_CHANGE';
export const mergeFormObjectKey = '@FormContext/MERGE_FROM_ONJECT';
export const setInitialState = '@FormContext/SET_INITIAL_STATE';
export const setBulkValuesKey = '@FormContext/SET_BULK_VALUES';
export const setSelectAllValuesKey = 'FormContext/SET_SELECT_ALL_VALUES';
