/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-08-25 16:57:23 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-09-04 10:16:45
 */
import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { findIndex, get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { getOrdersListAPI, getOrdersItemsListAPI } from "../../../../../../config/boda-boda/apiUrls.config";
import { InputButton, FormWrapper } from "../../../../../../components/boda-boda/Forms";
import { apiResponseStoringType } from "../../../../../../config/boda-boda/apiResponseKey";
import { orderStatusObject, currency, mapOrderPaymentMethods } from "../../../../../../config/boda-boda/db_config/db_status.config";
import { generateApiCallObjFn } from "../../../../../../store/boda-boda/core/actions";
import { requestAPIDataKey } from "../../../../../../store/boda-boda/core/actionTypes";
import { CreateOrderItem, CreateOrderItemBtnWithProps } from "./view-includes/CreateOrderItem";
import { RemoveBtn, RemoveMessage } from "./DeleteUI";
import ExpansionPanel from "../../../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import PickingSlip from "./view-includes/PickingSlip";
import { CancelModal } from "./view-includes/DeleteUI";
import { RestoreModal } from "./view-includes/RestoreUI";
import UpdateStatusModal from "../../common-includes/UpdateStatusModal";
import { ToolTipComponent } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { UpdateOrderAmountModal } from "./view-includes/UpdateOrderAmount";


const StatusList = (props) => {
  const dispatch = useDispatch();

  const onClickFn = (status) => {
    let apiObj = generateApiCallObjFn(`${getOrdersItemsListAPI.url}/${props.row.id}`)
      .setLoader(true)
      .setFullObject({
        setLoader: true,
        storingType: apiResponseStoringType.dateTableLinkedForm,
        mergeToSuccessResponse: null,
        mergeToErrorResponse: null,
      },
        {
          isSetHeaders: true,
          multipart: false,
          method: "put",
          onUpload: false
        })
      .body({
        "status": status
      })
      .setInitStoring(apiResponseStoringType.dateTableLinkedForm, {
        "form": "",
        "responseKey": "dummy_key",
        "dataTable": getOrdersItemsListAPI.key
      })
      .setFlashMessages({
        "success": {
          status: true,
          message: "Status has successfully updated",
          type: "success"
        }
      })
      .generate();

    dispatch({
      type: requestAPIDataKey,
      playload: apiObj
    })
  }

  return (
    <div className="btn-group mo-mb-2" style={{ marginRight: "4px" }}>
      <button
        id={"_item-pending"}
        type="button"
        className={`btn waves-light waves-effect ${props.row.status === orderStatusObject.PENDING ? "btn-success" : "grayBackgroundColor"}`}
        onClick={() => onClickFn("PENDING")}
      >
        <i className="fas fa-history"></i>
      </button>

      <ToolTipComponent
        tooltipText={"Pending"}
        target={"_item-pending"}
      />

      <button
        id={"_item-picked"}
        type="button"
        className={`btn waves-light waves-effect ${props.row.status === orderStatusObject.PICKED ? "btn-success" : "grayBackgroundColor"}`}
        onClick={() => onClickFn("PICKED")}
      >
        <i className="fas fa-clipboard-check"></i>
      </button>

      <ToolTipComponent
        tooltipText={"Picked"}
        target={"_item-picked"}
      />

      <button
        id={"_item-unavailable"}
        type="button"
        className={`btn waves-light waves-effect ${props.row.status === orderStatusObject.UNAVAILABLE ? "btn-success" : "grayBackgroundColor"}`}
        onClick={() => onClickFn("UNAVAILABLE")}
      >
        <i className="fas fa-archive"></i>
      </button>

      <ToolTipComponent
        tooltipText={"Unavailable"}
        target={"_item-unavailable"}
      />
    </div>
  );
};


class ViewUI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderPanel: true,
      receiverPanel: false,
      locationPanel: false,
      notePanel: false
    }
  }

  checkOrderDeleteCapability = () => {
    let { formState } = this.props;

    if (get(formState, `${getOrdersListAPI.key}_view.packages`, []).length === 0) {
      return true;
    } else {
      const check = findIndex(get(formState, `${getOrdersListAPI.key}_view.packages`, []), (element) => {
        return element.manifestId !== null;
      });

      return check === -1;
    }
  }

  render() {
    let { match, history, formState } = this.props;

    return (
      <div className="container-fluid">
        <FormWrapper
          setGroupName={`${getOrdersListAPI.key}_view`}
          onDestroyUnsetFormObject={true}
          apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
          onRebuildResponseFn={(response) => {
            return {
              id: response.id,
              name: response.name,
              trackingNumber: response.trackingNumber,
              email: response.email,
              idVerificationRequired: response.idVerificationRequired,
              idVerificationType: response.idVerificationType,
              idVerificationReference: response.idVerificationReference,
              phone: response.phone,
              channelOrderNo: response.channelOrderNo,
              channelOrderRef: response.channelOrderRef,
              channel: response.channel,
              status: response.status,
              deliveryType: response.deliveryType,
              amount: response.amount,
              paymentMethod: mapOrderPaymentMethods[response.paymentMethod],
              shippingLocation: response.shippingLocation,
              consignmentNo: response.consignmentNo,
              notesPublic: response.notesPublic,
              notesPrivate: response.notesPrivate,
              deletedAt: response.deletedAt,
              signatureRequired: response.signatureRequired,
              packages: response.packages,
              codSettlementStatus: response.codSettlementStatus,
              hubSettlement: response.hubSettlement,
              headOfficeSettlement: response.headOfficeSettlement,
              "_onLoad": false,
            }
          }}
        >
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                <h4 className="font-size-18">View Pending Order</h4>
              </div>
            </Col>

            <Col sm={6}>
              <div className={"d-flex justify-content-end"}>
                <InputButton
                  elementWrapperStyle={"mr-2"}
                  btnText={"Edit"}
                  startIcon={"mdi mdi-square-edit-outline"}
                  onClickBtnFn={() => history.push(`/orders/pending/edit/${match.params.id}`)}
                />

                <PickingSlip
                  orderId={match.params.id}
                />

                {
                  (get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY) && (get(formState, `${getOrdersListAPI.key}_view.status`) !== "DELIVERED") ? (
                    <UpdateOrderAmountModal
                      orderId={match.params.id}
                      amount={get(formState, `${getOrdersListAPI.key}_view.amount`, "")}
                      history={history}
                      path={`/orders/pending/view/${match.params.id}`}
                    />
                  ) : null
                }

                <UpdateStatusModal
                  orderId={match.params.id}
                  btnText={"Picked"}
                  message={"Are you sure you want to update order status into PICKED?"}
                  type={"picked"}
                  status={"PICKED"}
                />

                {
                  get(formState, `${getOrdersListAPI.key}_view.status`) === "CANCELLED" ? (
                    <RestoreModal
                      orderId={match.params.id}
                      history={history}
                    />
                  ) : (
                    (this.checkOrderDeleteCapability() === true) ? (
                      <CancelModal
                        orderId={match.params.id}
                        history={history}
                      />
                    ) : null
                  )
                }
              </div>
            </Col>
          </Row>

          <ExpansionPanel
            title={"Order Details"}
            isOpen={this.state.orderPanel}
            onClickFn={() => this.setState((prevState) => {
              return {
                ...prevState,
                orderPanel: !prevState.orderPanel
              }
            })}
          >
            <div className={"row"}>
              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dd className={"col-sm-5"}>Consignment No</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.consignmentNo`, "")}</dt>

                  <dd className={"col-sm-5"}>Channel Order No</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channelOrderNo`, "")}</dt>

                  <dd className={"col-sm-5"}>Channel</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channel.name`, "")}</dt>

                  <dd className={"col-sm-5"}>Channel Order Reference</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channelOrderRef`, "")}</dt>

                  <dd className={"col-sm-5"}>Delivery Type</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.deliveryType`, "")}</dt>
                </dl>
              </div>

              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dd className={"col-sm-5"}>Tracking No</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.trackingNumber`, "-")}</dt>

                  <dd className={"col-sm-5"}>Payment Method</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "")}</dt>

                  <dd className={"col-sm-5"}>Signature Required</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.signatureRequired`, false) === false ? "No" : "Yes"}</dt>

                  {
                    (get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                      <Fragment>
                        <dd className={"col-sm-5"}>Amount</dd>
                        <dt className={"col-sm-7"}>
                          {
                            get(formState, `${getOrdersListAPI.key}_view.amount`, null) === null ? "" : get(formState, `${getOrdersListAPI.key}_view.amount`, "") + ` ${currency}`
                          }
                        </dt>
                      </Fragment>
                    ) : null
                  }

                  <dd className={"col-sm-5"}>Status</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.status`, "")}</dt>

                  {get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY && get(formState, `${getOrdersListAPI.key}_view.status`) === "DELIVERED" ?
                    <Fragment>
                      <dd className={"col-sm-5"}>Cash on Delivery Status</dd>
                      <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "")}</dt>
                    </Fragment> : null}

                  {get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY && get(formState, `${getOrdersListAPI.key}_view.status`) === "DELIVERED" ?
                    <Fragment>
                      <dd className={"col-sm-5"}>Reference Number</dd>
                      <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HUB_SETTLED" ?
                        get(formState, `${getOrdersListAPI.key}_view.hubSettlement.reference`, "-")
                        : get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HEAD_OFFICE_SETTLED" ?
                          get(formState, `${getOrdersListAPI.key}_view.headOfficeSettlement.reference`, "-")
                          : "N/A"}
                      </dt>
                    </Fragment> : null}
                </dl>
              </div>
            </div>
          </ExpansionPanel>

          <ExpansionPanel
            title={"Receiver Details"}
            isOpen={this.state.receiverPanel}
            onClickFn={() => this.setState((prevState) => {
              return {
                ...prevState,
                receiverPanel: !prevState.receiverPanel
              }
            })}
          >
            <div className={"row"}>
              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dd className={"col-sm-5"}>Customer Name</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.name`, "")}</dt>

                  <dd className={"col-sm-5"}>Email</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.email`, "")}</dt>

                  <dd className={"col-sm-5"}>Phone</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.phone`, "")}</dt>
                </dl>
              </div>

              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dd className={"col-sm-5"}>ID Verification Required</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === false ? "No" : "Yes"}</dt>

                  {
                    (get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === true) ? (
                      <Fragment>
                        {
                          (get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, null) !== null && get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, null) !== null) ? (
                            <Fragment>
                              <dd className={"col-sm-5"}>ID Verification Type</dd>
                              <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, "")}</dt>
                            </Fragment>
                          ) : null
                        }

                        {
                          (get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, null) !== null) ? (
                            <Fragment>
                              <dd className={"col-sm-5"}>ID Verification Reference</dd>
                              <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, "")}</dt>
                            </Fragment>
                          ) : null
                        }
                      </Fragment>
                    ) : null
                  }
                </dl>
              </div>
            </div>
          </ExpansionPanel>

          <ExpansionPanel
            title={"Shipping Location"}
            isOpen={this.state.locationPanel}
            onClickFn={() => this.setState((prevState) => {
              return {
                ...prevState,
                locationPanel: !prevState.locationPanel
              }
            })}
          >
            <div className={"row"}>
              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dd className={"col-sm-5"}>Location Name</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.name`, "")}</dt>

                  <dd className={"col-sm-5"}>Address</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.address`, "")}</dt>

                  <dd className={"col-sm-5"}>Postal Code</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.postalCode`, "")}</dt>

                  <dd className={"col-sm-5"}>Latitude</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lat`, "")}</dt>

                  <dd className={"col-sm-5"}>Longitude</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lon`, "")}</dt>
                </dl>
              </div>

              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dd className={"col-sm-5"}>Plus Code</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.plusCode`, "")}</dt>

                  <dd className={"col-sm-5"}>Province</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.province.name`, "")}</dt>

                  <dd className={"col-sm-5"}>District</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.name`, "")}</dt>

                  <dd className={"col-sm-5"}>City</dd>
                  <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.name`, "")}</dt>
                </dl>
              </div>
            </div>
          </ExpansionPanel>

          <ExpansionPanel
            title={"Notes"}
            isOpen={this.state.notePanel}
            onClickFn={() => this.setState((prevState) => {
              return {
                ...prevState,
                notePanel: !prevState.notePanel
              }
            })}
          >
            <div className={"row"}>
              <div className={"col-md-6"}>
                <dl className={"row"}>
                  <dt className={"col-sm-3"}>Public Note</dt>
                  <dd className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPublic`, "")}</dd>

                  <dt className={"col-sm-3"}>Private Note</dt>
                  <dd className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPrivate`, "")}</dd>
                </dl>
              </div>
            </div>
          </ExpansionPanel>

          <div className="row">
            <CrudComponent
              title="Order Items"
              apiURL={`${getOrdersItemsListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
              dataTableStateKey={getOrdersItemsListAPI.key}
              isFilter={false}
              isSetCreateAction={true}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: true,
                actionBtn: {
                  btnText: "Add Item"
                },
                modalProps: {
                  modalTitle: "Create Order Item",
                  //modalType: "",
                  modalBodyComponent: CreateOrderItem,
                  modalFooterComponent: CreateOrderItemBtnWithProps,
                  isSetClose: true,
                  isSetFooterClose: true,
                  parentToModelProps: {
                    orderId: match.params.id,
                    type: "create"
                  }
                }
              }}
              tableHeaderList={[
                { displayName: "Product No", key: "productNo" },
                { displayName: "Name", key: "name" },
                { displayName: "Quantity", key: "quantity" },
                { displayName: "Status", key: "status" }
              ]}
              actionButtonListComponent={StatusList}
              actionList={[
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Edit Order Item",
                    //modalType: "",
                    modalBodyComponent: CreateOrderItem,
                    modalFooterComponent: CreateOrderItemBtnWithProps,
                    isSetClose: true,
                    isSetFooterClose: true,
                    parentToModelProps: {
                      type: "edit"
                    }
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Remove an Order Item",
                    //modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                    closeBtnName: "No"
                  }
                }
              ]}
            />
          </div>
        </FormWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData
  };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
  ViewUIWithState
};
