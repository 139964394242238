import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { InputButton } from "../../../../../../../components/boda-boda/Forms";
import { orderCSVUploadAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { ModalUI } from "../../../../../../../components/boda-boda/Modal/ModalUI";
import UploadPackageModalBody from "./UploadPackageModalBody";
import { callApi } from "../../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {
  setPageLoader,
  setFlashMessage,
} from "../../../../../../../store/boda-boda/ui/actions";
import { resetDataTable } from "../../../../../../../store/boda-boda/core/actions";
import { removeFromGroupFn } from "../../../../../../../store/boda-boda/form/actions";
import { Link } from "react-router-dom";
import { downloadCSV } from "../../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import TermsAndConditionUIMessage from "../../../../../terms-and-condition/TermsAndConditionUIMessage";

const UploadPackageCSVBtn = (props) => {
  const formState = useSelector((state) => state.FormData);
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (get(formState, "upload_csv_data.data.file", null) === null) {
      dispatch(
        setFlashMessage({
          status: true,
          message: "Please select a csv file",
          type: "danger",
        })
      );
    } else {
      dispatch(setPageLoader(true));

      callApi(orderCSVUploadAPI.url)
        .headers(true)
        .method("post")
        .body({
          name: get(formState, "upload_csv_data.data.fileName", ""),
          url: get(formState, "upload_csv_data.data.url", ""),
          fileName: get(formState, "upload_csv_data.data.file.name", ""),
          category: "PACKAGE",
          type: "CSV",
        })
        .send()
        .then((result) => {
          dispatch(resetDataTable(orderCSVUploadAPI.key));
          dispatch(removeFromGroupFn("upload_csv_data"));

          props.setModelStatusFn(false);
          dispatch(setPageLoader(false));
          if (get(result, "data.statusCode", "") === 403) {
            dispatch(
              setFlashMessage({
                status: true,
                message: "Permission denied to access",
                type: "danger",
              })
            );
          } else {
            dispatch(
              setFlashMessage({
                status: true,
                message: "CSV file has uploaded successfully",
                type: "success",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(setPageLoader(false));
        });
    }
  };

  return (
    <Fragment>
      <TermsAndConditionUIMessage />
      <InputButton
        btnText={"Upload"}
        isBtnDisabled={formState?.confirmation?.termsAndConditions ? false : true}
        elementStyle={"btn btn-primary waves-effect waves-light"}
        onClickBtnFn={() => onSubmit()}
      />
    </Fragment>
  );
};

const generatePackageCSVData = () => {
  const headers = [
    "CHANNEL_ORDER_NO",
    "NAME",
    "EMAIL",
    "PHONE",
    "AMOUNT",
    "PAYMENT_METHOD",
    "SHIPPING_LOCATION_ADDRESS",
    "SHIPPING_LOCATION_POSTAL_CODE",
    "PACKAGE_WEIGHT",
    "PACKAGE_PICK_LOCATION_ID",
    "PACKAGE_NOTES_PUBLIC",
    "PACKAGE_NOTES_PRIVATE",
  ];

  const finalDataSet = [headers];

  const csvDataContent = finalDataSet.map((e) => e.join(",")).join("\n");
  downloadCSV(csvDataContent, "CSV Upload - Template");
};

const UploadPackageModalUI = () => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={"d-flex justify-content-end"}>
        {/* <InputButton
                btnText={"Upload Order CSV"}
                startIcon={"mdi mdi-content-save-all-outline"}
                onClickBtnFn={() => setOpen(true)}
            /> */}
        <InputButton
          elementWrapperStyle={"mr-2"}
          btnText={"Upload Order CSV"}
          startIcon={"mdi mdi-content-save-all-outline"}
          onClickBtnFn={() => setOpen(true)}
        />

        <Link to="/pdf/User Guide for Order Uploads_V1.1.pdf" target="_blank">
          <InputButton
            elementWrapperStyle={"mr-2"}
            btnText={"Download Manual"}
            startIcon={"fas fa-file-download"}
          />
        </Link>

        <InputButton
          btnText={"Download Template"}
          startIcon={"fas fa-file-download"}
          onClickBtnFn={() => generatePackageCSVData()}
        />
      </div>

      <ModalUI
        isOpen={isOpen}
        modalTitle={"Upload CSV"}
        modalBodyComponent={<UploadPackageModalBody />}
        modalFooterComponent={
          <UploadPackageCSVBtn setModelStatusFn={(value) => setOpen(value)} />
        }
        showHideToggleFn={() => {
          setOpen(false);
          dispatch(removeFromGroupFn("upload_csv_data"));
        }}
      />
    </Fragment>
  );
};

export default UploadPackageModalUI;
