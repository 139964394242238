import React, { Component } from "react";

import {DatePickerBoxWithState, InputBoxWithState, SelectBoxWithState} from "../../../../components/boda-boda/Forms";
import { packageSearchFilters, deliveryTypes, paymentMethods, shippedSearchFilters } from "../../../../config/boda-boda/db_config/db_status.config";
import { getPackageCategoryListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";


class FilterUI extends Component {
    render() {
        let { dataTableStateKey } = this.props;

        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Article No"
                            inputName={"articleNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Article No"}
                            maxLength={17}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Tracking Number"
                            inputName={"trackingNumber"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Tracking Number"}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Channel Order No."
                            inputName={"channelOrderNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Channel Order No."}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Customer Name"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Delivery Type"}
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryTypes}
                            placeHolderText={"Delivery Type"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Payment Method"}
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"Delivered From Date"}
                            inputName={"deliveredDateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"Delivered From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"Delivered To Date"}
                            inputName={"deliveredDateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"Delivered To Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"From Date"}
                            inputName={"dateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"To Date"}
                            inputName={"dateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"To Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Package Category"}
                            inputName={"categoryId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getPackageCategoryListAPI.url}`}
                            apiStateKey={getPackageCategoryListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            placeHolderText={"Package Category"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={dataTableStateKey === getPackageListAPI.key ? packageSearchFilters : shippedSearchFilters}
                            placeHolderText={"Status"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default FilterUI;
