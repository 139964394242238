import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import ChartistGraph from 'react-chartist';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerBoxWithState, FormWrapper, InputButton } from '../../../components/boda-boda/Forms';
import { pickUpDeliveryGraphAPI } from '../../../config/boda-boda/apiUrls.config';
import { callApi } from '../../../helpers/boda-boda/common-helpers/callApi.helpers';
import { setFormErrorFn } from '../../../store/actions';

const LineChart = (props) => {

    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState();
    const formState = useSelector(state => state.FormData);
    const authState = useSelector(state => state.AuthState);
    const dispatch = useDispatch();

    const currentDate = new Date();
    const initialToDate = new Date(new Date(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)).setSeconds(new Date(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)).getSeconds() - 1));
    const initialFromDate = new Date(new Date(new Date(initialToDate).setMonth(new Date(initialToDate).getMonth() - 3)).getFullYear(), new Date(new Date(initialToDate).setMonth(new Date(initialToDate).getMonth() - 3)).getMonth() + 1, 1);

    const merchantId = authState?.authUser?.merchant?.id;

    useEffect(() => {
        callApi(
            `${pickUpDeliveryGraphAPI.url}?merchantId=${merchantId}&dateTo=${initialToDate.toISOString()}&dateFrom=${initialFromDate.toISOString()}`
        )
            .headers(true)
            .method("get")
            .send()
            .then((result) => {
                let labelsArray = [];
                let seriesPickUpArray = [];
                let seriesDeliveredArray = [];
                get(result, "data.data.totalPickedUpShipments", []).map((value, index) => {
                    labelsArray.push(value?.monthYear);
                    seriesPickUpArray.push(value?.count);
                });
                get(result, "data.data.totalDeliveredShipments", []).map((value, index) => {
                    // labelsArray.push(value?.monthYear);
                    seriesDeliveredArray.push(value?.count);
                });

                setLabels(labelsArray);
                setSeries([seriesPickUpArray, seriesDeliveredArray]);

            });
    }, []);


    const onSubmit = () => {
        if (get(formState, 'graph_filter_dates.dateFrom', '') !== null && get(formState, 'graph_filter_dates.dateTo', '') !== null) {
            dispatch(setFormErrorFn("graph_filter_dates", []));

            let dateTo = new Date(get(formState, 'graph_filter_dates.dateTo', ''));
            if (dateTo.getTime() !== initialToDate.getTime()) {
                dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + 1));
                dateTo = new Date(dateTo.setDate(dateTo.getDate() - 1));
            }

            let dateFrom = new Date(get(formState, 'graph_filter_dates.dateFrom', ''));

            callApi(
                `${pickUpDeliveryGraphAPI.url}?merchantId=${merchantId}&dateTo=${dateTo.toISOString()}&dateFrom=${dateFrom.toISOString()}`
            )
                .headers(true)
                .method("get")
                .send()
                .then((result) => {
                    let labelsArray = [];
                    let seriesPickUpArray = [];
                    let seriesDeliveredArray = [];
                    get(result, "data.data.totalPickedUpShipments", []).map((value, index) => {
                        labelsArray.push(value?.monthYear);
                        seriesPickUpArray.push(value?.count);
                    });
                    get(result, "data.data.totalDeliveredShipments", []).map((value, index) => {
                        // labelsArray.push(value?.monthYear);
                        seriesDeliveredArray.push(value?.count);
                    });

                    setLabels(labelsArray);
                    setSeries([seriesPickUpArray, seriesDeliveredArray]);

                });
        } else {
            let errors = [];

            if (get(formState, 'graph_filter_dates.dateFrom', '') === null) {
                errors.push({
                    "property": "dateFrom",
                    "error": "This field is required"
                });
            }

            if (get(formState, 'graph_filter_dates.dateTo', '') === null) {
                errors.push({
                    "property": "dateTo",
                    "error": "This field is required"
                });
            }

            if (errors.length > 0) {
                dispatch(setFormErrorFn("graph_filter_dates", errors));
            }
        }
    }

    let lineChartData = {
        labels: labels,
        series: series
    };
    let lineChartOptions = {
        low: 0,
    }

    return (
        <React.Fragment>
            <FormWrapper
                setGroupName={"graph_filter_dates"}
                onDestroyUnsetFormObject={true}
                setFormObject={{
                    dateFrom: initialFromDate,
                    dateTo: initialToDate,
                }}>

                <div className={"row"}>
                    <div className="col-md-4">
                        <DatePickerBoxWithState
                            isRequired={true}
                            labelText="Month From"
                            inputName={"dateFrom"}
                            formGroupName={"graph_filter_dates"}
                            monthYearPicker={true}
                            dateFormat={"MM/yyyy"}
                            maxDate={get(formState, 'graph_filter_dates.dateTo', '') !== null ? new Date(new Date(get(formState, 'graph_filter_dates.dateTo', '')).setMonth(new Date(get(formState, 'graph_filter_dates.dateTo', '')).getMonth() - 2)) : new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1)}
                            minDate={new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 1, 1)}
                        />
                    </div>
                    <div className="col-md-4">
                        <DatePickerBoxWithState
                            isRequired={true}
                            labelText="Month To"
                            inputName={"dateTo"}
                            formGroupName={"graph_filter_dates"}
                            monthYearPicker={true}
                            dateFormat={"MM/yyyy"}
                            maxDate={new Date()}
                            minDate={get(formState, 'graph_filter_dates.dateFrom', '') !== null ? new Date(new Date(get(formState, 'graph_filter_dates.dateFrom', '')).setMonth(new Date(get(formState, 'graph_filter_dates.dateFrom', '')).getMonth() + 2)) : new Date(currentDate.getFullYear(), currentDate.getMonth() - 9, 1)}
                        />
                    </div>
                    <div className="col-md-4 datepicker-botton-margin-top">
                        <InputButton
                            btnText={"Submit"}
                            elementStyle={"btn btn-primary waves-effect waves-light"}
                            onClickBtnFn={() => onSubmit()}
                        />
                    </div>
                </div>

            </FormWrapper>
            <ChartistGraph data={lineChartData} style={{ height: "290px" }} options={lineChartOptions} type={'Line'} />
            <p className={'graphLineNames'} ><span style={{ backgroundColor: "#8A6DDC" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp; Total Picked Up Shipments</p>
            <p className={'graphLineNames'} ><span style={{ backgroundColor: "#02A499" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp; Total Delivered Shipments </p>
        </React.Fragment>
    );
}

export default LineChart;