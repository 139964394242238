import React, { Component, Fragment, useEffect } from 'react';
import { findIndex, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import JsBarcode from 'jsbarcode';

import {
    FormWrapper,
} from '../../../components/boda-boda/Forms'
import { Button, Col, Row } from 'reactstrap';
import { printModalHelperFn } from '../../../helpers/boda-boda/common-helpers/common.helpers';
import { getPackageListAPI } from '../../../config/boda-boda/apiUrls.config';
import { setFlashMessage, setPageLoader } from '../../../store/actions';
import { printerServiceAPIUrl } from '../../../config/boda-boda/core.config';
import { callApi } from '../../../helpers/boda-boda/common-helpers/callApi.helpers';
import { SnackbarList } from '../../../components/boda-boda/Snackbar/Snackbar';
import { CustomLoader } from '../../../components/boda-boda/CommonElements/BaseElements';
import { ShippingLabelUI } from '../packages/includes/ShippingLabelUI';

const ShippingLabel = (props) => {
    const formState = useSelector((state) => state.FormData);
    const UiState = useSelector((state) => state.UIState);
    const dispatch = useDispatch();
    let { match } = props;

    // useEffect(() => {
    //     if (get(formState, 'publicShippinglabel._onLoad', true) === false) {
    //         JsBarcode('#shippingLabelBarcode', get(formState, `publicShippinglabel.articleNo`, ''), {
    //             width: 1,
    //             height: 50,
    //             margin: 0,
    //         });
    //     }
    // }, [get(formState, 'publicShippinglabel._onLoad', true)]);

    const getPackageNo = (data = [], id) => {
        const index = findIndex(data, { articleNo: id });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return '';
        }
    };

    const sendPrintRequest = async (printType) => {
        try {
            dispatch(setPageLoader(true));

            // if (printType !== get(authState, "authUser.staff.printerType", "NORMAL")) {
            //     const staffAPIResponse = await callApi(`${getUserStaffList.url}/${get(authState, "authUser.staff.id")}`)
            //         .headers(true)
            //         .method("put")
            //         .body({ printerType: printType })
            //         .send();

            //     if (staffAPIResponse._statue === true) {
            //         dispatch(updateAuthUserPrinterTypeFn(printType));
            //     }
            // }

            if (printType === "NORMAL") {
                dispatch(setPageLoader(false));
                printModalHelperFn("print_preview_multiple");
            } else {
                let dataObj = {
                    "labelType": 1
                }

                dataObj["barcodeLabels"] = [
                    {
                        "collectionNo": null,
                        "orderNo": get(formState, `publicShippinglabel.order.channelOrderNo`, ""),
                        "articledID": get(formState, `publicShippinglabel.articleNo`, ""),
                        "consignmentNo": get(formState, `publicShippinglabel.order.consignmentNo`, ""),
                        "packageNo": getPackageNo(get(formState, `publicShippinglabel.order.packages`, []), get(formState, `publicShippinglabel.articleNo`, "")),
                        "packageCategory": get(formState, `publicShippinglabel.category.name`, ""),
                        "signatureOnDelivery": get(formState, `publicShippinglabel.order.signatureRequired`, false) === false ? "No" : "Yes",
                        "paymentType": get(formState, `publicShippinglabel.order.paymentMethod`, ""),
                        "delivertoName": `${get(formState, `publicShippinglabel.order.name`, "")}, ${get(formState, `publicShippinglabel.order.shippingLocation.address`, "")}, ${get(formState, `publicShippinglabel.order.shippingLocation.city.name`, "")}, ${get(formState, `publicShippinglabel.order.shippingLocation.city.district.name`, "")}, ${get(formState, `publicShippinglabel.order.shippingLocation.city.district.province.name`, "")}`,
                        "delivertoPhone": get(formState, `publicShippinglabel.order.phone`, ""),
                        "senderName": `${get(formState, `publicShippinglabel.merchant.name`, "")}, ${get(formState, `publicShippinglabel.merchantBranch.name`, "")}, ${get(formState, `publicShippinglabel.merchantBranch.location.address`, "")}, ${get(formState, `publicShippinglabel.merchantBranch.location.city.name`, "")}, ${get(formState, `publicShippinglabel.merchantBranch.location.postalCode`, "")}`,
                        "senderPhone": get(formState, `publicShippinglabel.merchant.contacts[0].phone`, ''),
                        "specialNotes": get(formState, `publicShippinglabel.notesPublic`, "")
                    }
                ];


                const printerAPIResponse = await callApi(printerServiceAPIUrl)
                    .method("post")
                    .headers(true)
                    .body(dataObj)
                    .send();

                dispatch(setPageLoader(false));

                if (printerAPIResponse._statue === true) {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "Request has sent to the printer",
                        type: "success",
                    }));

                } else {
                    dispatch(setFlashMessage({
                        status: true,
                        message: "An error has occurred",
                        type: "danger",
                    }));
                }

            }
        } catch (error) {
            dispatch(setPageLoader(false));

            dispatch(setFlashMessage({
                status: true,
                message: "An error has occurred",
                type: "danger",
            }));
        }
    }

    return (
        <div className='container-fluid'>
            {
                (UiState.setPageLoader === true) ? (
                    <div className={"fullWidthDiv defaultMarginTopBottom"}>
                        <center>
                            <CustomLoader />
                        </center>
                    </div>
                ) : (
                    <React.Fragment>
                        <SnackbarList />
                        <FormWrapper
                            setGroupName={`publicShippinglabel`}
                            apiUrl={`${getPackageListAPI.url}/uuid/${match.params.id}`}
                            onDestroyUnsetFormObject={true}
                            onRebuildResponseFn={(response) => {
                                return {
                                    id: response?.id,
                                    category: {
                                        name: response?.category?.name,
                                    },
                                    order: {
                                        channelOrderNo: response?.order?.channelOrderNo,
                                        consignmentNo: response?.order?.consignmentNo,
                                        packages: response?.order?.packages,
                                        signatureRequired: response?.order?.signatureRequired,
                                        paymentMethod: response?.order?.paymentMethod,
                                        name: response?.order?.name,
                                        phone: response?.order?.phone,
                                        shippingLocation: {
                                            address: response?.order?.shippingLocation?.address,
                                            city: {
                                                name: response?.order?.shippingLocation?.city?.name,
                                                district: {
                                                    name: response?.order?.shippingLocation?.city?.district?.name,
                                                    province: {
                                                        name: response?.order?.shippingLocation?.city?.district?.province?.name
                                                    }
                                                },
                                            },
                                        }
                                    },
                                    merchant: {
                                        name: response?.merchant?.name,
                                        contacts: [
                                            {
                                                phone: response?.merchant?.contacts[0]?.phone,
                                            },
                                        ]
                                    },
                                    merchantBranch: {
                                        name: response?.merchantBranch?.name,
                                        location: {
                                            address: response?.merchantBranch?.location?.address,
                                            city: {
                                                name: response?.merchantBranch?.location?.city?.name,
                                            },
                                            postalCode: response?.merchantBranch?.location?.postalCode,
                                        }
                                    },
                                    articleNo: response?.articleNo,
                                    notesPublic: response?.notesPublic,
                                    "_onLoad": false,
                                }
                            }}

                        >
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                        <h4 className="font-size-18">Shipping Label</h4>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <Button
                                            color='primary'
                                            className='btn btn-primary waves-effect waves-light mr-3'
                                            onClick={() => sendPrintRequest("NORMAL")}
                                        >
                                            Print as A4
                                        </Button>

                                        <Button
                                            color='primary'
                                            className='btn btn-primary waves-effect waves-light'
                                            onClick={() => sendPrintRequest("LABEL")}
                                        >
                                            Print as Sticker
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className="card">
                                <div className="card-body">
                                    <ShippingLabelUI packages={[formState?.publicShippinglabel]}/>
                                </div>
                            </div>
                        </FormWrapper>
                    </React.Fragment>
                )}
        </div>
    );
}


export { ShippingLabel };