import { all, fork, takeEvery, put } from 'redux-saga/effects';
import { setFlashMessage } from "./actions";
import { addFlashMessageKey } from "./actionTypes";

function* addFlashMessageFn(data) {
    yield put(setFlashMessage(data.payload));
    //yield put(removeFlashMessage());
    //setTimeout(() => put(removeFlashMessage()), 5000);
}

function* watchFlashMessageArrayInit() {
    yield takeEvery(addFlashMessageKey, addFlashMessageFn);
}

function* uiSaga() {
    yield all([
        fork(watchFlashMessageArrayInit)
    ])
}

export default uiSaga;
