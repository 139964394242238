import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./includes/FilterUI";
import { getPackageListAPI } from "../../../config/boda-boda/apiUrls.config";
import {
  mapOrderPaymentMethods,
  mapPackageStatusesNames,
  shippedStatusList
} from "../../../config/boda-boda/db_config/db_status.config";
import {
  customerNameShortHelperFn,
  formatToDateHelper,
  isEmptyValue
} from "../../../helpers/boda-boda/common-helpers/common.helpers";
import {dateObjectToString} from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";


class Shipped extends Component {
  render() {
    let { history } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Shipped</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Shipped</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Shipped"
              apiURL={`${getPackageListAPI.url}?page=1&limit=20&statuses=[${shippedStatusList}]`}
              dataTableStateKey={`${getPackageListAPI.key}_shipped`}
              filterFromComponent={FilterUI}
              searchButtonOnTop={true}
              defaultFilterFormObject={{
                  articleNo: "",
                  name: "",
                  deliveryType: "",
                  paymentMethod: "",
                  categoryId: "",
                  status: "",
                  dateTo: null,
                  dateFrom: null,
                  trackingNumber: "",
                  channelOrderNo: "",
                  deliveredDateTo: null,
                  deliveredDateFrom: null,
              }}
              clearErrors={() => {
                const { setFormErrorFn } = this.props;
                setFormErrorFn("packageListAPIKey_shipped_search", [])
              }}
              onRebuildFilterObjFn={(filterData) => {
                const data = filterData;
                let { setFormErrorFn } = this.props;
                const errors = [];

                if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                  errors.push({
                    "property":"dateTo",
                    "error":"The Date To field should not be less than the Date From field"
                  });
                }

                if(errors.length > 0 ){
                  setFormErrorFn("packageListAPIKey_shipped_search", errors);
                  return ;
                }else{
                  setFormErrorFn("packageListAPIKey_shipped_search", []);
                }

                if (!isEmptyValue(filterData.dateTo)) {
                  data["dateTo"] = formatToDateHelper(filterData.dateTo);
                }

                if (!isEmptyValue(filterData.dateFrom)) {
                  data["dateFrom"] = filterData.dateFrom.toISOString();
                }
                
                if (new Date(filterData.deliveredDateFrom) > new Date(filterData.deliveredDateTo)) {
                  errors.push({
                      "property":"deliveredDateTo",
                      "error":"The Delivered To Date field should not be less than the Delivered From Date field "
                  })
                  }

                  if(errors.length > 0 ){
                      setFormErrorFn("packageListAPIKey_shipped_search", errors);
                      return;
                  }else{
                      setFormErrorFn("packageListAPIKey_shipped_search", []);
                  }

              if (!isEmptyValue(filterData.deliveredDateTo)) {
                  data["deliveredDateTo"] = formatToDateHelper(filterData.deliveredDateTo);
              }

              if (!isEmptyValue(filterData.deliveredDateFrom)) {
                  data["deliveredDateFrom"] = filterData.deliveredDateFrom.toISOString();
              }

                return data;
              }}
              isSetCreateAction={false}
              tableHeaderList={[
                { displayName: "Article No", key: "articleNo" },
                { displayName: "Customer Name", key: "order.name", onPrintFn: (rawData) => customerNameShortHelperFn(rawData.order.name) },
                { displayName: "Delivery Type", key: "deliveryType" },
                { displayName: "Payment Method", key: "order.paymentMethod", onPrintFn: (rawData) => mapOrderPaymentMethods[rawData.order.paymentMethod] },
                { displayName: "Package Category", key: "category.name" },
                { displayName: "Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt)},
                { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status]},
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/package/shipped/view/${rawData.id}`),
                  }
                },
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData
  };
};

export default connect(mapStateToProps, {
  setFormErrorFn,
}) (Shipped);
