import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DatePickerBoxWithState,
  FormWrapper,
  InputButton,
} from "../../../components/boda-boda/Forms";
import { codTransactionDataAPI } from "../../../config/boda-boda/apiUrls.config";
import { roundUpTypes } from "../../../config/boda-boda/core.config";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { roundValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../store/actions";

const CodTransaction = () => {
  const [countCash, setCountCash] = useState();
  const [coundCard, setCoundCard] = useState();
  const [sumCash, setSumCash] = useState();
  const [sumCard, setSumCard] = useState();

  const formState = useSelector((state) => state.FormData);
  const authState = useSelector((state) => state.AuthState);
  const dispatch = useDispatch();

  const currentDate = new Date();
  const initialToDate = new Date(new Date(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)).setSeconds(new Date(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)).getSeconds() - 1));
  const initialFromDate = new Date(new Date(new Date(initialToDate).setMonth(new Date(initialToDate).getMonth() - 3)).getFullYear(), new Date(new Date(initialToDate).setMonth(new Date(initialToDate).getMonth() - 3)).getMonth() + 1, 1);
  let merchantId = authState?.authUser?.merchant?.id;

  useEffect(() => {
    callApi(
      `${
        codTransactionDataAPI.url
      }?merchantId=${merchantId}&dateTo=${initialToDate.toISOString()}&dateFrom=${initialFromDate.toISOString()}`
    )
      .headers(true)
      .method("get")
      .send()
      .then((result) => {
        setCountCash(result.data.data.totalCodTransactions[0].countCashOnDelivery);
        setCoundCard(result.data.data.totalCodTransactions[0].CountCardOnDelivery);
        setSumCash(result.data.data.totalCodTransactions[0].sumCashOnDelivery);
        setSumCard(result.data.data.totalCodTransactions[0].sumCardOnDelivery);
      });
  }, []);
  const onSubmit = () => {
    if (
      get(formState, "transaction_filter_dates.dateFrom", "") !== null &&
      get(formState, "transaction_filter_dates.dateTo", "") !== null &&
      get(formState, "transaction_filter_dates.dateFrom", "") < get(formState, "transaction_filter_dates.dateTo", "")
    ) {
      dispatch(setFormErrorFn("transaction_filter_dates", []));

      let dateTo = new Date(get(formState, 'transaction_filter_dates.dateTo', ''));
      let dateFrom = new Date(get(formState, 'transaction_filter_dates.dateFrom', ''));
      callApi(
        `${
          codTransactionDataAPI.url
        }?merchantId=${merchantId}&dateTo=${dateTo.toISOString()}&dateFrom=${dateFrom.toISOString()}`
      )
        .headers(true)
        .method("get")
        .send()
        .then((result) => {
          setCountCash(
            result.data.data.totalCodTransactions[0].countCashOnDelivery
          );
          setCoundCard(
            result.data.data.totalCodTransactions[0].CountCardOnDelivery
          );
          setSumCash(
            result.data.data.totalCodTransactions[0].sumCashOnDelivery
          );
          setSumCard(
            result.data.data.totalCodTransactions[0].sumCardOnDelivery
          );
        });
    } else {
      let errors = [];

      if (get(formState, "transaction_filter_dates.dateFrom", "") === null) {
        errors.push({
          property: "dateFrom",
          error: "This field is required",
        });
      }

      if (get(formState, "transaction_filter_dates.dateTo", "") === null) {
        errors.push({
          property: "dateTo",
          error: "This field is required",
        });
      }

      if(get(formState, "transaction_filter_dates.dateFrom", "") > get(formState, "transaction_filter_dates.dateTo", "")){
        errors.push({
          property: "dateTo",
          error: "The Date To field should not be less than the Date From field",
        });
      }

      if (errors.length > 0) {
        dispatch(setFormErrorFn("transaction_filter_dates", errors));
      }
    }
  };

  return (
    <React.Fragment>
      <FormWrapper
        setGroupName={"transaction_filter_dates"}
        onDestroyUnsetFormObject={true}
        setFormObject={{
          dateFrom: initialFromDate,
          dateTo: initialToDate,
        }}
      >
        <div className={"row"}>
          <div className="col-md-4">
            <DatePickerBoxWithState
              isRequired={true}
              labelText="Date From"
              inputName={"dateFrom"}
              formGroupName={"transaction_filter_dates"}             
            />
          </div>
          <div className="col-md-4">
            <DatePickerBoxWithState
              isRequired={true}
              labelText="Date To"
              inputName={"dateTo"}
              formGroupName={"transaction_filter_dates"}
            />
          </div>
         
          <div className="col-md-4 datepicker-botton-margin-top">
            <InputButton
              btnText={"Submit"}
              elementStyle={"btn btn-primary waves-effect waves-light"}
              onClickBtnFn={() => onSubmit()}
            />
         <br/>
          </div>
        </div>
      </FormWrapper>
      <br></br>
      <div className={"row"}>
        <div className={"col-md-4"}>
          <dl className={"row"}>
            <dd className={"col-sm-8"}>Total Card on Delivery Shipments</dd>
            <dt className={"col-sm-4"}>{(coundCard)? (coundCard) :0}</dt>

            <dd className={"col-sm-8"}>Total Cash on Delivery Shipments</dd>
            <dt className={"col-sm-4"}>{(countCash)? (countCash) :0}</dt>
          </dl>
        </div>

        <div className={"col-md-5"}>
          <dl className={"row"}>
            <dd className={"col-sm-8"}>
              Total Card on Delivery Amount Handled
            </dd>
            <dt className={"col-sm-3"}>{roundValue(sumCard, 2, roundUpTypes.round)}</dt>

            <dd className={"col-sm-8"}>
              Total Cash on Delivery Amount Handled
            </dd>
            <dt className={"col-sm-3"}>{roundValue(sumCash, 2, roundUpTypes.round)}</dt>
          </dl>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CodTransaction;
