import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get, filter } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { FormWrapper } from "../../../../../../components/boda-boda/Forms";
import { TabComponent, SubTab } from "../../../../../../components/boda-boda/Tab/Tab";
import {
    getOrderImageAPI,
    getOrdersItemsListAPI,
    getOrdersListAPI,
    getPackageListAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import ExpansionPanel from "../../../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import { dateObjectToString } from "../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {
    currency,
    mapOrderPaymentMethods,
    mapPackageStatusesNames
} from "../../../../../../config/boda-boda/db_config/db_status.config";
import ViewPackage from "../../../../pick-request/includes/common-includes/ViewPackage";
import { RestoreModal } from "../../pending-orders/includes/view-includes/RestoreUI";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";


class DeliveredTerminateView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            orderPanel: true,
            receiverPanel: false,
            locationPanel: false,
            notePanel: false,
            imagesArray: [],
        }
    }

    componentDidMount() {
        this.getImages()
    }

    getImages = () => {
        let { match } = this.props;

        callApi(`${getOrderImageAPI.url}/all?orderId=${match.params.id}`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                if (get(result, "data.statusCode", 0) === 403) {

                } else {
                    this.setState({
                        ...this.state,
                        imagesArray: get(result, "data.data", []),
                    })
                }
                // this.setState({
                //     data: get(result, "data.meta.totalsRow", {}),
                //     loaderStatus: false
                // })
            })
    }

    getPageName = () => {
        let { location } = this.props;
        const temp = location.pathname.split('/');

        if (temp[3] === "cancelled") {
            return "View Cancelled Order";
        } else if (temp[3] === "delivered") {
            return "View Delivered Order";
        } else {
            return "View Terminated Order";
        }
    }

    getRelevantImages = (type) => {

        let images = [];
        if (type === "signature") {
            images = filter(get(this.state, `imagesArray`, []), (value) => value.type === "SIGNATURE");
        } else {
            images = filter(get(this.state, `imagesArray`, []), (value) => value.type !== "SIGNATURE");
        }
        return images;
    }

    zoomImage = (src) => {
        let modal = document.getElementById("orderImageModal");
        let modalImg = document.getElementById("img01");

        modal.style.display = "block";
        modalImg.src = src;
    }

    closeModal = () => {
        let modal = document.getElementById("orderImageModal");
        modal.style.display = "none";
    }

    render() {
        let { match, formState, history } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getOrdersListAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        this.getImages()
                        return {
                            id: response.id,
                            name: response.name,
                            email: response.email,
                            idVerificationRequired: response.idVerificationRequired,
                            idVerificationType: response.idVerificationType,
                            idVerificationReference: response.idVerificationReference,
                            phone: response.phone,
                            channelOrderNo: response.channelOrderNo,
                            channelOrderRef: response.channelOrderRef,
                            channel: response.channel,
                            status: response.status,
                            deliveryType: response.deliveryType,
                            amount: response.amount,
                            paymentMethod: mapOrderPaymentMethods[response.paymentMethod],
                            shippingLocation: response.shippingLocation,
                            consignmentNo: response.consignmentNo,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            deletedAt: response.deletedAt,
                            signatureRequired: response.signatureRequired,
                            remarkDelivery: response.remarkDelivery,
                            deliveredAt: response.deliveredAt,
                            images: response.images,
                            courier: response.courier,
                            codSettlementStatus: response.codSettlementStatus,
                            hubSettlement: response.hubSettlement,
                            headOfficeSettlement: response.headOfficeSettlement,
                            packages: response?.packages,
                            returnedAt: response?.returnedAt,
                            remarkReturn: response?.remarkReturn,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">{this.getPageName()}</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    path={"/orders/all"}
                                    isGoBack={true}
                                />

                                {
                                    get(formState, `${getOrdersListAPI.key}_view.status`) === "CANCELLED" ? (
                                        <RestoreModal
                                            orderId={match.params.id}
                                            history={history}
                                        />
                                    ) : null
                                }
                            </div>
                        </Col>
                    </Row>


                    <ExpansionPanel
                        title={"Order Details"}
                        isOpen={this.state.orderPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                orderPanel: !prevState.orderPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Consignment No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.consignmentNo`, "")}</dt>

                                    <dd className={"col-sm-5"}>Channel Order No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channelOrderNo`, "")}</dt>

                                    <dd className={"col-sm-5"}>Channel</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channel.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Channel Order Reference</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channelOrderRef`, "")}</dt>

                                    <dd className={"col-sm-5"}>Delivery Type</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.deliveryType`, "")}</dt>

                                    <dd className={"col-sm-5"}>Status Reason</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.packages[0].notDeliveredReason`, "")}</dt>

                                    <dd className={"col-sm-5"}>Returned Date & Time</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.returnedAt`, "") ? dateObjectToString(get(formState, `${getOrdersListAPI.key}_view.returnedAt`, ""), "YY-mm-dd HH:MM:II") : ""}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Payment Method</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "")}</dt>

                                    <dd className={"col-sm-5"}>Signature Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.signatureRequired`, false) === false ? "No" : "Yes"}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Amount</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${getOrdersListAPI.key}_view.amount`, null) === null ? "" : get(formState, `${getOrdersListAPI.key}_view.amount`, "") + ` ${currency}`
                                                    }
                                                </dt>
                                            </Fragment>
                                        ) : null
                                    }

                                    <dd className={"col-sm-5"}>Status</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.status`, "")}</dt>

                                    {get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY && get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED" ?
                                        (<Fragment>
                                            <dd className={"col-sm-5"}>Cash on Delivery Status</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "")}</dt>

                                            <dd className={"col-sm-5"}>Reference Number</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HUB_SETTLED" ?
                                                get(formState, `${getOrdersListAPI.key}_view.hubSettlement.reference`, "-")
                                                : get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HEAD_OFFICE_SETTLED" ?
                                                    get(formState, `${getOrdersListAPI.key}_view.headOfficeSettlement.reference`, "-")
                                                    : "N/A"}
                                            </dt>
                                        </Fragment>) : null}
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Customer Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.phone`, "")}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Receiver Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.remarkDelivery`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Delivered Date and Time</dd>
                                                <dt className={"col-sm-7"}>{dateObjectToString(get(formState, `${getOrdersListAPI.key}_view.deliveredAt`, ""), "YY-mm-dd HH:MM:II")}</dt>
                                            </Fragment>
                                        ) : null
                                    }

                                    <dd className={"col-sm-5"}>Remark</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.remarkReturn`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>ID Verification Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === false ? "No" : "Yes"}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === true) ? (
                                            <Fragment>
                                                {
                                                    (get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, null) !== null && get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, null) !== null) ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, "")}</dt>
                                                        </Fragment>
                                                    ) : null
                                                }

                                                {
                                                    (get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, null) !== null) ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, "")}</dt>
                                                        </Fragment>
                                                    ) : null
                                                }
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>
                        </div>

                        {
                            get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED" || get(formState, `${getOrdersListAPI.key}_view.status`, "") === "RETURNED_TO_MERCHANT" ? (
                                <Fragment>
                                    <hr />

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <h4 className="card-title">Signature Images</h4>

                                            <div className={"row"}>
                                                {
                                                    this.getRelevantImages("signature").length === 0 ? (
                                                        <div className={"col-md-3"}>Nothing to show</div>
                                                    ) : (
                                                        this.getRelevantImages("signature").map((value, index) => {
                                                            return (
                                                                <div className={"col-md-3"} key={index}>
                                                                    <img
                                                                        className={"img-thumbnail signature-picture"}
                                                                        src={value.url}
                                                                        onClick={() => this.zoomImage(value.url)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>

                                            <h4 className="card-title mt-3">Other Images</h4>

                                            <div className={"row"}>
                                                {
                                                    this.getRelevantImages("other").length === 0 ? (
                                                        <div className={"col-md-3"}>Nothing to show</div>
                                                    ) : (
                                                        this.getRelevantImages("other").map((value, index) => {
                                                            return (
                                                                <div className={"col-md-3"} key={index}>
                                                                    <img
                                                                        className={"img-thumbnail signature-picture"}
                                                                        src={value.url}
                                                                        onClick={() => this.zoomImage(value.url)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>

                                            <div className={"mb-3"}></div>
                                        </div>
                                    </div>

                                    <div id="orderImageModal" className="modalOrder">
                                        <span
                                            className="closeOrderModal"
                                            onClick={() => this.closeModal()}
                                        >
                                            &times;
                                        </span>

                                        <img className="modal-content-order" id="img01" />
                                    </div>
                                </Fragment>
                            ) : null
                        }
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.postalCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lat`, "")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lon`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.name`, "")}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        elementWrapperStyle={"mb-3"}
                        title={"Notes"}
                        isOpen={this.state.notePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                notePanel: !prevState.notePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dt className={"col-sm-3"}>Public Note</dt>
                                    <dd className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPublic`, "")}</dd>

                                    <dt className={"col-sm-3"}>Private Note</dt>
                                    <dd className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPrivate`, "")}</dd>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <TabComponent
                        activeTab={this.state.activeTab}
                        onClickFn={(tab) => this.setState((prevState) => {
                            return {
                                ...prevState,
                                activeTab: tab
                            }
                        })}
                    >
                        <SubTab tabId={0} tabName={"Order Items"}>
                            <div className="row">
                                <CrudComponent
                                    title="Items"
                                    apiURL={`${getOrdersItemsListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                                    dataTableStateKey={`${getOrdersItemsListAPI.key}_orderItems`}
                                    isFilter={false}
                                    isSetAction={false}
                                    isSetCreateAction={false}
                                    tableHeaderList={[
                                        { displayName: "Product No", key: "productNo" },
                                        { displayName: "Name", key: "name" },
                                        { displayName: "Quantity", key: "quantity" },
                                        { displayName: "Status", key: "status" }
                                    ]}
                                />
                            </div>
                        </SubTab>

                        <SubTab tabId={1} tabName={"Packages"}>
                            <div className={"row"}>
                                <CrudComponent
                                    title="Packages"
                                    apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                                    dataTableStateKey={`${getOrdersItemsListAPI.key}_packages`}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isSetAction={true}
                                    tableHeaderList={[
                                        { displayName: "Article No", key: "articleNo" },
                                        { displayName: "Customer Name", key: "order.name", default: "-" },
                                        { displayName: "Delivery Type", key: "deliveryType" },
                                        { displayName: "Payment Method", key: "order.paymentMethod" },
                                        { displayName: "Package Category", key: "category.name" },
                                        { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                    ]}
                                    actionList={[
                                        {
                                            actionType: actionBtnTypes.view,
                                            isSetModel: true,
                                            modalProps: {
                                                modalTitle: "View Package",
                                                modalType: "modal-lg",
                                                modalBodyComponent: ViewPackage,
                                                isSetClose: true,
                                                isSetFooterClose: true,
                                            }
                                        },
                                    ]}
                                />
                            </div>
                        </SubTab>
                    </TabComponent>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(DeliveredTerminateView);
