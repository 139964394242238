import React, { Component, Fragment, useEffect } from 'react';
import { findIndex, get } from 'lodash';
import { useSelector } from 'react-redux';
import JsBarcode from 'jsbarcode';
import ShippingLabelLogo from "../../../../assets/images/BodaBoda_logoFA-01.png"

import {
  InputButton,
  FormWrapper,
} from '../../../../components/boda-boda/Forms';
import {
  modalTypes,
  ModalUI,
} from '../../../../components/boda-boda/Modal/ModalUI';
import PrintLabelBtn from './PrintLabelBtn';
import { merchantAPI } from '../../../../config/boda-boda/apiUrls.config';
import { ShippingLabelUI } from './ShippingLabelUI';

class ShippingLabel extends Component {
  constructor(props) {
    super(props);
    this.state = { isShow: false };
  }

  onClickFn = () => {
    this.setState({ isShow: true });
  };

  render() {
    return (
      <Fragment>
        <InputButton
          btnText='Shipping Label'
          onClickBtnFn={() => this.onClickFn()}
          elementWrapperStyle={'mr-2'}
          startIcon={'fa fa-barcode'}
        />

        <ModalUI
          isOpen={this.state.isShow}
          modalTitle={'Shipping Label'}
          modalType={modalTypes.large}
          modalBodyComponent={<ShippingLabelUI packages={[this.props.package]} />}
          showHideToggleFn={() => this.setState({ isShow: false })}
          modalFooterComponent={
            <PrintLabelBtn
              type={'create'}
              closeModalFn={() => this.setState({ isShow: false })}
            />
          }
        />
      </Fragment>
    );
  }
}

export { ShippingLabel };
