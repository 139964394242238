import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { Col, Row } from "reactstrap";

import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import ViewPackage from "../common-includes/ViewPackage";
import { mapPackageStatusesNames, mapStatusesNames } from "../../../../../config/boda-boda/db_config/db_status.config";
import { BulkShippingLabelPrintByURL } from "../../../packages/includes/BulkShippingLabelPrintByURL";


const ViewRejectedRequest = (props) => {
    let { history, location, match } = props;

    const formState = useSelector(state => state.FormData);

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        code: response.code,
                        status: response.status,
                        createdAt: response.createdAt,
                        locationPick: response.locationPick,
                        locationDrop: response.locationDrop,
                        notes: response.notes,
                        pickAt: response.pickAt,
                        packages: response.packages,
                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View Manifest</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={`/pick-request/${location.pathname.split('/')[2]}`}
                                btnName={"Back"}
                                btnColor={"primary"}
                            />

                            <InputButton
                                elementWrapperStyle={""}
                                btnText={"Re-Draft"}
                                startIcon={"mdi mdi-square-edit-outline"}
                                onClickBtnFn={() => history.push(`/pick-request/rejected/redraft/${match.params.id}`)}
                            />
                        </div>
                    </Col>
                </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Date</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""),"YY-mm-dd")}
                                            </dt>


                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Status</dd>
                                            <dt className={"col-sm-8"}>
                                                {
                                                    mapStatusesNames[get(formState, `${getManifestListAPI.key}_view.status`, "")]
                                                }
                                            </dt>
                                            <dd className={"col-sm-4"}>Created At</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.createdAt`, ""),"YY-mm-dd")}
                                            </dt>

                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Packages"
                        apiURL={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=20`}
                        dataTableStateKey={getPackageListAPI.key}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        bulkPrintAll={true}
                        bulkPrintAllBtn={<React.Fragment>
                            <BulkShippingLabelPrintByURL
                                type='bulk'
                                printURL={`${getManifestListAPI.url}/${match.params.id}/published-packages/all`}
                                className='btn btn-primary waves-effect waves-light mr-3'
                            />
                            {" "}
                        </React.Fragment>}
                        tableHeaderList={[
                            { displayName: "Article No", key: "articleNo" },
                            { displayName: "Channel Order No", key: "order.channelOrderNo" },
                            { displayName: "Customer Name", key: "order.name" },
                            { displayName: "Delivery Type", key: "deliveryType" },
                            { displayName: "Payment Method", key: "order.paymentMethod" },
                            { displayName: "Package Category", key: "category.name" },
                            { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                        ]}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: true,
                                modalProps: {
                                    modalTitle: "View Package",
                                    modalType: "modal-lg",
                                    modalBodyComponent: ViewPackage,
                                    isSetClose: true,
                                    isSetFooterClose: true,
                                    parentToModelProps: {
                                        rejected: true
                                    },
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notes</h4>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        {
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []) != null && get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div>None</div>
                                            ) 
                                            
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

export default ViewRejectedRequest;
