import React, { Component } from "react";
import { get } from "lodash";

import { InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { getRoleListAPI, getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { userStatusList } from "../../../../config/boda-boda/db_config/db_status.config";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";


class FilterUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [
                {
                    id: "",
                    name: "All"
                }
            ]
        }
    }

    componentDidMount() {
        this.getRoleList();
    }

    getRoleList = () => {
        callApi(`${getRoleListAPI.url}/all?type=MERCHANT&isDefault=true`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                this.setState({
                    roles: [
                        {
                            id: "",
                            name: "All"
                        },
                        ...get(result, "data.data", [])
                    ]
                })
            })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Name"
                            inputName={"name"}
                            placeHolderText={"Name"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Phone"
                            inputName={"phone"}
                            placeHolderText={"Phone"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={20}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Role"}
                            inputName={"roleId"}
                            placeHolderText={"Role"}
                            formGroupName={this.props.searchFromKey}
                            // apiUrl={getRoleListAPI.url}
                            // apiStateKey={getRoleListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            dataList={this.state.roles}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText="Status"
                            inputName={"status"}
                            placeHolderText={"Status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={[
                                {
                                    id: "",
                                    value: "All"
                                },
                                ...userStatusList
                            ]}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default FilterUI;
