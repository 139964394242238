import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { FormWrapper, InputButton } from "../../../../../components/boda-boda/Forms";
import { merchantBranchAPI, merchantBranchContactAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { currency } from "../../../../../config/boda-boda/db_config/db_status.config";


class ViewUI extends Component {
    render() {
        let { match, formState,history } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${merchantBranchAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${merchantBranchAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: get(response, "name", ""),
                            pickLocationId: get(response, "locationId", ""),
                            status: get(response, "status", ""),
                            province: get(response, "location.city.district.province.name", ""),
                            district: get(response, "location.city.district.name", ""),
                            pickAddress: get(response, "location.address", ""),
                            city: get(response, "location.city.name", ""),
                            contactPerson: get(response, "contacts[0].fullName", null),
                            phone: get(response, "contacts[0].phone", null),
                            email: get(response, "contacts[0].email", null),
                            postalCode: get(response, "location.postalCode", null),
                            plusCode: get(response, "location.plusCode", null),
                            facility: get(response, "facility.name", ""),
                            chargingMethod: get(response,"merchant.contracts[0].chargingMethod",""),
                            merchantId: get(response, "merchant.id", ""),
                            pickupPrice: get(response, "pickupPrice", "0"),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Merchant Branch</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                        
                            <div className={"d-flex justify-content-end"}>
                            
                                <BackBtnComponent
                                    path={"/merchant-branch"}
                                    elementWrapperStyle={"mr-2"}
                                />
                                
                                    {
                                    (get(formState, `${merchantBranchAPI.key}_view.chargingMethod`, "") === "VOLUMETRIC") ? 
                                    
                                    <InputButton
                                        elementWrapperStyle={"mr-2"}
                                        btnText={"Volumetric Weights"}
                                        startIcon={"fas fa-table"}
                                        onClickBtnFn={() => history.push(`/merchant/${get(formState, `${merchantBranchAPI.key}_view.merchantId`, "")}/merchant-branch/view/${match.params.id}/volumetric-weight/${get(formState, `${merchantBranchAPI.key}_view.name`, "")}`)}
                                    />
                                    : 
                                    <InputButton
                                        elementWrapperStyle={"mr-2"}
                                        btnText={"Actual Weights"}
                                        startIcon={"fas fa-table"}
                                        onClickBtnFn={() => history.push(`/merchant/${get(formState, `${merchantBranchAPI.key}_view.merchantId`, "")}/merchant-branch/view/${match.params.id}/actual-weight/${get(formState, `${merchantBranchAPI.key}_view.name`, "")}`)}
                                    />
                                    }
                                     
                            </div>
                            
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    {/*<h4 className="card-title mb-3">User Details</h4>*/}

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dt className={"col-md-6"}>Branch Name</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.name`, "")}</dd>
                                                
                                                <dt className={"col-md-6"}>Pick Location Id</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.pickLocationId`, "")}</dd>

                                                <dt className={"col-md-6"}>Status</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.status`, "")}</dd>

                                                <dt className={"col-md-6"}>Delivery Center</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.facility`, "")}</dd>

                                                <dt className={"col-md-6"}>Pick Up Cost</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.pickupPrice`, "-") !== null ? get(formState, `${merchantBranchAPI.key}_view.pickupPrice`, "-") : "-"} {currency}</dd>

                                                <dt className={"col-md-6"}>Postal Code</dt>
                                                <dd className={"col-md-6"}>
                                                    {
                                                        get(formState, `${merchantBranchAPI.key}_view.postalCode`, null) === null ? "-" : get(formState, `${merchantBranchAPI.key}_view.plusCode`, null)
                                                    }
                                                </dd>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>

                                                <dt className={"col-md-6"}>Address</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.pickAddress`, "")}</dd>

                                                <dt className={"col-md-6"}>Province</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.province`, "")}</dd>

                                                <dt className={"col-md-6"}>District</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.district`, "")}</dd>

                                                <dt className={"col-md-6"}>City</dt>
                                                <dd className={"col-md-6"}>{get(formState, `${merchantBranchAPI.key}_view.city`, "")}</dd>

                                                <dt className={"col-md-6"}>Plus Code</dt>
                                                <dd className={"col-md-6"}>
                                                    {
                                                        get(formState, `${merchantBranchAPI.key}_view.plusCode`, null) === null ? "-" : get(formState, `${merchantBranchAPI.key}_view.plusCode`, null)
                                                    }
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <CrudComponent
                                        title={"Contact Details"}
                                        isSetAction={false}
                                        isSetCreateAction={false}
                                        isFilter={false}
                                        dataTableStateKey={merchantBranchContactAPI.key}
                                        apiURL={`${merchantBranchContactAPI.url}?merchantBranchId=${match.params.id}&limit=20&page=1`}
                                        tableHeaderList={[
                                            { displayName: "Contact Person", key: "fullName"},
                                            { displayName: "Phone No.", key: "phone"},
                                            { displayName: "Email", key: "email"},
                                            {
                                                displayName: "Receive Notification", key: "notification", onPrintFn: (rawData) => {
                                                    return rawData.notification === true ? "Yes" : "No"
                                                }
                                            },
                                        ]}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
    ViewUIWithState
};
