import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";

import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
    SubmitButton,
} from "../../../../../../../components/boda-boda/Forms";
import { CrudComponent } from "../../../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    getOrdersListAPI,
    getPackageTypesListAPI,
    getPackageCategoryListAPI,
    getOrdersItemsListAPI,
    getPackageListAPI,
    getMerchantBranchAllAPI,
} from "../../../../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../../../../store/actions";
import { apiResponseStoringType } from "../../../../../../../config/boda-boda/apiResponseKey";
import { orderStatusObject, weightMetric, lengthMetric } from "../../../../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../../../../components/boda-boda/CommonElements/BaseElements";
import { getDataByFormObject, formObjectValidationHelper,isEmptyValue } from "../../../../../../../helpers/boda-boda/common-helpers/common.helpers";


const ToggleBtn = (props) => {
    return (
        get(props, "row.status", "") === "UNAVAILABLE"
            ? null
            : <SubmitButton
                elementStyle={"btn btn-primary"}
                startIcon={props.row.status === orderStatusObject.PACKED ? "fas fa-archive" : "fas fa-clipboard-check"}
                btnColor={props.row.status === orderStatusObject.PACKED ? "success": "primary"}
                dataTableKey={getOrdersItemsListAPI.key}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: `${getOrdersItemsListAPI.url}/${props.row.id}`,
                        key: "dummy_key"
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    return {
                        "status": props.row.status === orderStatusObject.PACKED ? orderStatusObject.PICKED : orderStatusObject.PACKED
                    }
                }}
            />
    )
}

class CreatePackage extends Component {
    constructor(props) {
        super(props);
    }

    packageTypeOnchangeFn = (eventData) => {
        let { changeInputFn } = this.props;
        const typeObj = get(this.props, `coreState.apiResponses[${getPackageTypesListAPI.key}].result`, []).find(e => e.id === eventData.value);

        if (typeObj !== undefined) {
            changeInputFn("createPackage", "weight", "", typeObj.weight);
            changeInputFn("createPackage", "height", "", typeObj.height);
            changeInputFn("createPackage", "length", "", typeObj.length);
            changeInputFn("createPackage", "width", "", typeObj.width);
        }
    }

    render() {
        let { match, formState } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={"createPackage"}
                    apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            id: response.id,
                            deliveryType: response.deliveryType,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            chargingMethod: response.contract.chargingMethod,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Create a Package</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <CreatePackageBtnWithProps  
                                    formState={formState} 
                            />
                                
                                <BackBtnComponent
                                    path={"/orders/picked"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Order No</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "createPackage.id", "")}</dd>

                                                <dt className={"col-sm-5"}>Delivery Type</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "createPackage.deliveryType", "")}</dd>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Customer Name</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "createPackage.name", "")}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"Package Type"}
                                                isRequired={true}
                                                placeHolderText={"Package Type"}
                                                inputStatePath={"createPackage.type.id"}
                                                formGroupName={"createPackage"}
                                                apiUrl={`${getPackageTypesListAPI.url}/all`}
                                                apiStateKey={getPackageTypesListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                onChangeFn={(eventData) => this.packageTypeOnchangeFn(eventData)}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"Package Category"}
                                                isRequired={true}
                                                placeHolderText={"Package Category"}
                                                inputStatePath={"createPackage.category.id"}
                                                formGroupName={"createPackage"}
                                                apiUrl={`${getPackageCategoryListAPI.url}`}
                                                apiStateKey={getPackageCategoryListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                            />
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={`Weight (${weightMetric})`}
                                                isRequired={get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? false :true}
                                                placeHolderText={"Weight"}
                                                inputName={"weight"}
                                                formGroupName={"createPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={`Height (${lengthMetric})`}
                                                isRequired={get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? true : false}
                                                placeHolderText={"Height"}
                                                inputName={"height"}
                                                formGroupName={"createPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={`Length (${lengthMetric})`}
                                                isRequired={get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? true : false }
                                                placeHolderText={"Length"}
                                                inputName={"length"}
                                                formGroupName={"createPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={`Width (${lengthMetric})`}
                                                isRequired={get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? true : false }
                                                placeHolderText={"Width"}
                                                inputName={"width"}
                                                formGroupName={"createPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"Pick Location"}
                                                isRequired={true}
                                                placeHolderText={"Pick Location"}
                                                inputStatePath={"createPackage.locationPick.id"}
                                                formGroupName={"createPackage"}
                                                apiUrl={`${getMerchantBranchAllAPI.url}`}
                                                apiStateKey={getMerchantBranchAllAPI.key}
                                                keyName={"locationId"}
                                                valueName={"name"}
                                            />
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Public Note"
                                                inputName={"notesPublic"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={"createPackage"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Private Note"
                                                inputName={"notesPrivate"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={"createPackage"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Order Items"
                            apiURL={`${getOrdersItemsListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                            dataTableStateKey={getOrdersItemsListAPI.key}
                            isFilter={false}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Product No", key: "productNo" },
                                { displayName: "Name", key: "name" },
                                { displayName: "Quantity", key: "quantity" },
                                { displayName: "Status", key: "status" }
                            ]}
                            actionButtonListComponent={ToggleBtn}
                        />
                    </div>

                </FormWrapper>
            </div>
        )
    }
}

const CreatePackageBtn = (props) => {
    const { formState } = props;
    console.log(formState,"formState")
    return (
        <Fragment>
            <SubmitButton
                elementWrapperStyle={"mr-2"}
                btnText={"Save"}
                startIcon={"mdi mdi-content-save-all-outline"}
                isValidate={true}
                dataTableKey={getOrdersListAPI.key}
                formGroupName={"createPackage"}
                validationObject={{
                    fields: {
                        "type.id": "Package Type",
                        "category.id": "Package Category",
                        "locationPick.id": "Pick Location",
                        weight: "Weight",
                        height: "Height",
                        length: "Length",
                        width: "Width",
                    },
                    rules: {
                        "type.id": "required",
                        "category.id": "required",
                        "locationPick.id": "required",
                        weight: get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? "max:5" : "required|max:5",
                        height: get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? "required|max:5" : "max:5",
                        length: get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? "required|max:5" : "max:5",
                        width: get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC" ? "required|max:5" : "max:5",
                    },
                    message: {
                        "weight.max": "The Weight may not be greater than 5 digits",
                        "height.max": "The Height may not be greater than 5 digits",
                        "length.max": "The Length may not be greater than 5 digits",
                        "width.max": "The Width may not be greater than 5 digits",
                    }
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Package has successfully created",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.apiRequestOnly,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: getPackageListAPI.url,
                        key: getPackageListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);
                    let {chargingMethod, ...newData} = data;
                    
                    let temp = {
                        ...newData,
                        status: "PACKED",
                        order: {
                            id: Number(props.match.params.id)
                        },
                        metric: get(data, "chargingMethod", "") === "VOLUMETRIC" ? "cm" : "kg",
                        weight: formObjectValidationHelper(data, "weight", (value) => { return Number(value) }),
                        height: formObjectValidationHelper(data, "height", (value) => { return Number(value) }),
                        length: formObjectValidationHelper(data, "length", (value) => { return Number(value) }),
                        width: formObjectValidationHelper(data, "width", (value) => { return Number(value) }),
                        id: undefined,
                        name: undefined
                    }
                

                    // if (get(formState, "createPackage.chargingMethod", "") === "VOLUMETRIC"){
                    //     if (isEmptyValue(get(data, "weight", ""))) {
                    //         temp["weight"] = 0;
                    //     }
                    // }
                    // if (get(formState, "createPackage.chargingMethod", "") === "WEIGHT"){
                    //     if (isEmptyValue(get(data, "length", ""))) {
                    //         temp["length"] = 0;
                    //     }

                    //     if (isEmptyValue(get(data, "height", ""))) {
                    //         temp["height"] = 0;
                    //     }

                    //     if (isEmptyValue(get(data, "width", ""))) {
                    //         temp["width"] = 0;
                    //     }
                    // }

                    return temp;
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.history.push("/orders/picked");
                    }
                }}
            />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        coreState: state.CoreState,
        formState: state.FormData
    };
};

const CreatePackageBtnWithProps = withRouter(CreatePackageBtn);
const CreatePackageComponent = connect(mapStateToProps, { changeInputFn })(CreatePackage);

export {
    CreatePackageComponent
};
