import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent, actionBtnTypes } from "../../../../../../../components/boda-boda/CRUD/CrudComponent";
import { orderCSVUploadAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import UploadModalUI from "./UploadModalUI";
import { callApi } from "../../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setPageLoader } from "../../../../../../../store/boda-boda/ui/actions";
import { downloadCSV, isEmptyValue } from "../../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { fileGetAPIUrl } from "../../../../../../../config/boda-boda/core.config";


class UploadCSV extends Component {
    onDownloadFn = (id, fileName) => {
        let { setPageLoader } = this.props;

        setPageLoader(true);

        callApi(`${orderCSVUploadAPI.url}/${id}/data`)
            .headers(true)
            .method("get")
            .send()
            .then((result) => {
                this.generateCSVData(get(result, "data.data", []), fileName);
                setPageLoader(false);
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    generateCSVData = (data = [], fileName = "") => {
        const headers = ["channelId", "channelOrderNo", "channelOrderRef", "name", "email", "countryCode", "phone", "deliveryType", "idVerificationRequired",
            "notesPublic", "notesPrivate", "amount", "paymentMethod", "signatureRequired", "shippingLocationName", "shippingLocationAddress",
            "shippingLocationPostalCode", "shippingLocationPlusCode", "shippingLocationLat", "shippingLocationLon", "shippingLocationCityId",
            "itemName", "itemQuantity", "itemProductNo", "remark", "errors"
        ];

        const dataList = [];

        data.forEach((value) => {
            const temp = [];

            temp.push(get(value, "data.channelId", ""));
            temp.push(get(value, "data.channelOrderNo", ""));
            temp.push(get(value, "data.channelOrderRef", ""));
            temp.push(get(value, "data.name", ""));
            temp.push(get(value, "data.email", ""));
            temp.push(get(value, "data.countryCodePrefix", ""));
            temp.push(get(value, "data.phone", ""));
            temp.push(get(value, "data.deliveryType", ""));
            temp.push(get(value, "data.idVerificationRequired", ""));
            temp.push(get(value, "data.notesPublic", ""));
            temp.push(get(value, "data.notesPrivate", ""));
            temp.push(get(value, "data.amount", ""));
            temp.push(get(value, "data.paymentMethod", ""));
            temp.push(get(value, "data.signatureRequired", ""));
            temp.push(get(value, "data.shippingLocationName", ""));
            temp.push(get(value, "data.shippingLocationAddress", ""));
            temp.push(get(value, "data.shippingLocationPostalCode", ""));
            temp.push(get(value, "data.shippingLocationPlusCode", ""));
            temp.push(get(value, "data.shippingLocationLat", ""));
            temp.push(get(value, "data.shippingLocationLon", ""));
            temp.push(get(value, "data.shippingLocationCityId", ""));
            temp.push(get(value, "data.itemName", ""));
            temp.push(get(value, "data.itemQuantity", ""));
            temp.push(get(value, "data.itemProductNo", ""));
            temp.push(get(value, "remark", ""));

            let errors = "";

            if (get(value, "errors", null) !== null) {
                get(value, "errors", []).forEach((error, index) => {
                    if (index === 0) {
                        errors = error.error;
                    } else {
                        errors = `${errors} / ${error.error}`;
                    }
                })
            }

            temp.push(errors);
            dataList.push(temp);
        });

        const finalDataSet = [headers];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>

                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"} style={{ padding: "16px 0 16px 0" }}>
                                <UploadModalUI />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title="Uploaded Orders"
                            apiURL={`${orderCSVUploadAPI.url}?page=1&limit=20`}
                            dataTableStateKey={orderCSVUploadAPI.key}
                            isFilter={false}
                            isSetCreateAction={false}
                            tableHeaderList={[
                                { displayName: "Uploaded At", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt, "YY-mm-dd HH:MM:II") },
                                { displayName: "File Name", key: "name", onPrintFn: (rawData) => {
                                    if (isEmptyValue(rawData.fileName)) {
                                        return rawData.name;
                                    } else {
                                        return rawData.fileName;
                                    }
                                }},
                                { displayName: "Status", key: "status" }
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.other,
                                    isSetModel: false,
                                    actionBtn: {
                                        tooltip: "Download",
                                        btnText: "download",
                                        icon: "fas fa-file-download",
                                        color: "primary",
                                        onClickBtnFn: (rawData) => {
                                            if (rawData.status === "PENDING") {
                                                window.open(`${fileGetAPIUrl}${rawData.name}`);
                                            } else {
                                                let fileName = rawData.fileName;

                                                if (isEmptyValue(rawData.fileName)) {
                                                    fileName = rawData.name;
                                                }

                                                this.onDownloadFn(rawData.id, fileName);
                                            }
                                        },
                                    }
                                }
                            ]}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(null, { setPageLoader })(UploadCSV);
