/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-02 14:06:59 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-09-03 13:36:42
 */
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import DraftRequest from "./includes/draft-requests/DraftRequest";
import AllRequests from "./includes/all-requests/AllRequests";
import AcceptedRequests from "./includes/accepted-requests/AcceptedRequests";
import PendingRequests from "./includes/pending-requests/PendingRequests";
import RejectedRequests from "./includes/rejected-requests/RejectedRequests";


class PickRequest extends Component {

    constructor(props) {
        super(props);
    }

    getPageName=()=>{
        let { location } = this.props;
        const temp = location.pathname.split('/');

        if(temp[2]==="all"){
            return "All Pick Requests";
        }else if(temp[2]==="drafts"){
            return "Drafts";
        }else if(temp[2]==="pending"){
            return "Pending";
        }else if(temp[2]==="accepted"){
            return "Accepted";
        }else{
            return "Rejected";
        }
    }

    render() {
        let { location } = this.props;

        return (
            <React.Fragment>
              <div className="container-fluid">
                <Row className="align-items-center">
                  <Col sm={6}>
                    <div className="page-title-box">
                        <h4 className="font-size-18">{this.getPageName()}</h4>
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">{this.getPageName()}</li>
                      </ol>
                    </div>
                  </Col>
                </Row>

                <Row>
                    {
                        (location.pathname.split('/')[2] === "all") ? (
                            <AllRequests />
                        ) : (
                            (location.pathname.split('/')[2] === "drafts") ? (
                                <DraftRequest />
                            ) : (
                                (location.pathname.split('/')[2] === "pending") ? (
                                    <PendingRequests />
                                ) : (
                                    (location.pathname.split('/')[2] === "accepted") ? (
                                        <AcceptedRequests />
                                    ) : (
                                        <RejectedRequests />
                                    )
                                )
                            )
                        )
                    }
                </Row>
              </div>
            </React.Fragment>
        )
    }
}

export default PickRequest;
