import React, { Fragment, useState } from "react";

import {
  ModalUI,
} from "../../../../../../../components/boda-boda/Modal/ModalUI";
import {
  InputButton,
  FormWrapper,
  InputBoxWithState,
  SubmitButton,
} from "../../../../../../../components/boda-boda/Forms";
import { getOrdersListAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

const UpdateOrderAmountBody = (props) => {
  return (
    <FormWrapper
      setGroupName={`${getOrdersListAPI.key}_edit`}
      onDestroyUnsetFormObject={true}
      setFormObject={{
        amount: props.row.amount
      }}
    >
      <InputBoxWithState
        labelText="Amount"
        placeHolderText={"Amount"}
        inputName={"amount"}
        formGroupName={`${getOrdersListAPI.key}_edit`}
        maxLength={15}
        inputType={"number"}
      />
    </FormWrapper>
  );
};

const UpdateBtn = (props) => {
  const dispatch = useDispatch()
  const formState = useSelector(state => state.FormData);

  return (
    <SubmitButton
      isBtnDisabled={get(formState, `${getOrdersListAPI.key}_edit.amount`, "") === get(formState, `${getOrdersListAPI.key}_view.amount`, "")}
      btnText={"Update"}
      formGroupName={`${getOrdersListAPI.key}_edit`}
      elementStyle={"btn btn-primary waves-effect waves-light"}
      isValidate={false}
      flashMessages={{
        success: {
          status: true,
          message: "Order amount has successfully updated",
          type: "success",
        },
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "put",
        onUpload: false,
      }}
      onGetAPIEndPointFn={(formObject) => {
        return {
          url: `${getOrdersListAPI.url}/${props.row.id}/amount`,
          key: getOrdersListAPI.key,
        };
      }}
      onChangeRequestBodyFn={(formObject) => {
        return {
          amount: parseFloat(formObject.amount),
        };
      }}
      onResponseCallBackFn={(error, result) => {
        if (!error) {
          props.setModelStatusFn(false);
          dispatch(changeInputFn(`${getOrdersListAPI.key}_view`, "amount", "", result?.data?.amount));
          props.history.push(props.path);
        }
      }}
    />
  );
};

const UpdateOrderAmountModal = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <InputButton
        btnText={"Update Order Amount"}
        elementWrapperStyle={"mr-2"}
        onClickBtnFn={() => setOpen(true)}
      />

      <ModalUI
        isOpen={isOpen}
        modalTitle={"Update Order Amount"}
        modalBodyComponent={
          <UpdateOrderAmountBody row={{ amount: props.amount }} />
        }
        modalFooterComponent={
          <UpdateBtn
            setModelStatusFn={(value) => setOpen(value)}
            row={{ id: props.orderId }}
            history={props.history}
            path={props.path}
          />
        }
        showHideToggleFn={() => setOpen(false)}
        closeBtnName={"Cancel"}
      />
    </Fragment>
  );
};

export { UpdateOrderAmountModal };

