import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";

import { SubmitButton } from "../../../../../components/boda-boda/Forms";
import { getManifestListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import {apiResponseStoringType} from "../../../../../config/boda-boda/apiResponseKey";
import {
    formObjectValidationHelper,
    getDataByFormObject
} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";


const CreateManifestBtn = (props) => {
    return (
        <Fragment>
            <SubmitButton
                btnText={"Create"}
                startIcon={"mdi mdi-content-save-all-outline"}
                formGroupName={`${getManifestListAPI.key}`}
                dataTableKey={getManifestListAPI.key}
                isValidate={true}
                validationObject={{
                    fields: {
                        pickAt: "Pick Up Date",
                        "locationPick.id": "Pick Up Location",
                    },
                    rules: {
                        pickAt: "required",
                        "locationPick.id": "required",
                    },
                    messages: {}
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "post",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: getManifestListAPI.url,
                        key: getManifestListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);

                    return {
                        ...data,
                        "status": "DRAFT",
                        "type": "MERCHANT",
                        "notes": []
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.setModelStatusFn(false);
                        props.history.push(`/pick-request/drafts/create/${result.data.id}`);
                    }
                }}
            />
        </Fragment>
    )
}

export default withRouter(CreateManifestBtn);
