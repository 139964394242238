import React, {Fragment, useState} from "react";

import { InputButton, SubmitButton } from "../../../../components/boda-boda/Forms";
import { getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";

const RemoveMessage = () => {
    return(
        <p>Are your sure you want to delete this package?</p>
    );
}

const RemoveBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getPackageListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Package has successfully deleted",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: props.type === "datatable" ? apiResponseStoringType.dateTableLinkedForm : apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getPackageListAPI.url}/${props.row.id}`,
                    key: getPackageListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.type === "datatable") {
                        props.setModelStatusFn(false);
                    } else {
                        props.history.push("/package/packages");
                    }
                }
            }}
        />
    );
}

const DeleteModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                btnText={"Delete"}
                startIcon={"mdi mdi-trash-can-outline"}
                btnColor={"danger"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<RemoveMessage />}
                modalFooterComponent={<RemoveBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={"form"}
                    row={{ "id": props.packageId }}
                    history={props.history}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    RemoveBtn,
    RemoveMessage,
    DeleteModal
}
