import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { get } from "lodash";

import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { requestAPIDataKey } from "./actionTypes";
import { apiResponseStoringType, formErrorKey } from "../../../config/boda-boda/apiResponseKey";
import { mergedDataTableResult, setErrorObject, setDataTable, resetDataTable, setApiResponse } from "./actions";
import { setPageLoader, setFlashMessage } from "../ui/actions";
import { setFormErrorFn, removeFromGroupFn, mergeFormObject } from "../form/actions";
import validateMethod from "../../../helpers/boda-boda/validation";

const emptyFn = (...para) => undefined;

function* requestAPI(data) {
    let validationResponse = {
        _status: true
    };

    if (get(data, 'playload.validationObject', null) !== null) {
        validationResponse = yield call(() => validateMethod(
                get(data, 'playload.callApiObj.body', {}),
                get(data, 'playload.validationObject.fileds', null),
                get(data, 'playload.validationObject.rules', null),
                get(data, 'playload.validationObject.message', null),
            )
        );

        if (validationResponse._status === false) {
            yield put(setFormErrorFn(get(data, 'playload.storingKey.form', ""), validationResponse.errors));
        } else {
            yield put(setFormErrorFn(get(data, 'playload.storingKey.form', ""), []));
        }
    }

    if (validationResponse._status === true) {
        if (get(data, 'playload.setLoader', false) === true) {
            yield put(setPageLoader(true));
        }

        let response = yield call(() => callApi().setFullObject(data.playload.callApiObj).send());

        if (get(data, 'playload.setLoader', false) === true) {
            yield put(setPageLoader(false));
        }

        if (response._statue === false) {
            if (get(data, `playload.flashMessages.error`, false) !== false) {
                yield put(setFlashMessage(get(data, `playload.flashMessages.error`, {})));
            } else if (get(response, "data.statusCode", 0) === 406) {
                yield put(setFlashMessage({
                    status: true,
                    message: get(response, "data.message", "Something went wrong...!!"),
                    type: "danger"
                }));
            } else if (get(response, "data.statusCode", 0) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger"
                }));
            } else if (get(response, "data.code", 0) === 400) {
                yield put(setFormErrorFn(get(data, 'playload.storingKey.form', ""), get(response, "data.errors", "")));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger"
                }));
            }

            switch (data.playload.storingType) {
                case apiResponseStoringType.dataTable:
                    yield put(mergedDataTableResult(data.playload.storingKey, {
                        "results": [],
                        "fetching": "error"
                    }));

                    get(data, "playload.onResponseCallBackFn", emptyFn)(get(response, "data", {}));
                    return;

                case apiResponseStoringType.dateTableLinkedForm:
                    if (get(response, 'data.message', null) === formErrorKey) {
                        let errorStateKey = get(data, 'playload.storingKey.form', "");

                        if (errorStateKey.includes("dataTableResponses") || errorStateKey.includes("apiResponses")) {
                            errorStateKey = errorStateKey.replace("results", "errors");
                            yield put(setErrorObject(errorStateKey, get(response, 'data.errors', [])));
                        } else {
                            yield put(setFormErrorFn(get(data, 'playload.storingKey.form', null), get(response, 'data.errors', [])));
                        }
                    }

                    get(data, "playload.onResponseCallBackFn", emptyFn)(get(response, "data", {}));
                    return;

                case apiResponseStoringType.responseLinkToFrom:
                    if (get(response, 'data.message', null) === formErrorKey) {
                        let errorStateKey = get(data, 'playload.storingKey.form', "");

                        if (errorStateKey.includes("dataTableResponses") || errorStateKey.includes("apiResponses")) {
                            errorStateKey = errorStateKey.replace("results", "errors");
                            yield put(setErrorObject(errorStateKey, get(response, 'data.errors', [])));
                        } else {
                            yield put(setFormErrorFn(get(data, 'playload.storingKey.form', null), get(response, 'data.errors', [])));
                        }
                    }

                    get(data, "playload.onResponseCallBackFn", emptyFn)(get(response, "data", {}));
                    return;

                case apiResponseStoringType.apiRequestOnly:
                    if (get(response, 'data.message', null) === formErrorKey) {
                        yield put(setFormErrorFn(get(data, 'playload.storingKey.form', null), get(response, 'data.errors', [])));
                    }

                    get(data, "playload.onResponseCallBackFn", emptyFn)(get(response, "data", {}));
                    return;

                default:
                    get(data, "playload.onResponseCallBackFn", emptyFn)(get(response, "data", {}));
                    return;
            }
        } else {
            if (get(data, `playload.flashMessages.success`, false) !== false) {
                yield put(setFlashMessage(get(data, `playload.flashMessages.success`, {})));
            }

            switch (data.playload.storingType) {
                case apiResponseStoringType.dataTable:
                    if (get(data, "playload.onRebuildResponseFn", false) !== false) {
                        response = data.playload.onRebuildResponseFn(get(response, 'data.data', {}));
                    }

                    yield put(setDataTable(
                        data.playload.storingKey,
                        get(response, 'data.data', []),
                        'done',
                        get(response, 'data.meta.currentPage', 1),
                        get(response, 'data.meta.totalPages', null),
                        (get(response, 'data.data', [])).length,
                        get(response, 'data.meta.totalItems', null),
                        get(response, 'data.meta.pageSize	', 20),
                        get(response, 'data.meta.totalsRow', null)
                    ));

                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;

                case apiResponseStoringType.dateTableLinkedForm:
                    yield put(resetDataTable(get(data, 'playload.storingKey.dataTable', null)));
                    yield put(removeFromGroupFn(get(data, 'playload.storingKey.form', null)));

                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;

                case apiResponseStoringType.responseLinkToFrom:
                    if (get(data, 'playload.storingKey.dataTable', null) !== null) {
                        yield put(resetDataTable(get(data, 'playload.storingKey.dataTable', null)));
                    }

                    yield put(setApiResponse(
                        get(data, 'playload.storingKey.responseKey', null),
                        get(response, 'data.data', null)
                    ));

                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;

                case apiResponseStoringType.setResponse:
                    if (get(data, "playload.onRebuildResponseFn", false) !== false) {
                        response = data.playload.onRebuildResponseFn(get(response, 'data.data', {}));
                    }

                    yield put(setApiResponse(
                        get(data, 'playload.storingKey.responseKey', null),
                        get(response, 'data.data', null)
                    ));

                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;

                case apiResponseStoringType.apiResponseToFormState:
                    yield put(mergeFormObject(
                        data.playload.storingKey,
                        data.playload.onRebuildResponseFn === false ? get(response, 'data.data', {}) : data.playload.onRebuildResponseFn(get(response, 'data.data', {}))
                    ));

                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;

                case apiResponseStoringType.apiRequestOnly:
                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;

                default:
                    get(data, "playload.onResponseCallBackFn", emptyFn)(null, get(response, "data", {}));
                    return;
            }
        }
    }
}

function* watchAPIcallRequest() {
    yield takeEvery(requestAPIDataKey, requestAPI);
}

function* coreSaga() {
    yield all([
        fork(watchAPIcallRequest)
    ])
}

export default coreSaga;
