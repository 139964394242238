import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { headOfficeSettlementAPI, merchantSettlementReportAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { downloadCSV, fromObjectToQueryString, roundValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const TableLastRow = (props) => {
    return (
        <Fragment>
            <tr>
                <td colSpan={"3"}></td>
                <td>Total</td>
                <td>{roundValue(get(props, "tableBodyLastProps.total", ""))}</td>
            </tr>
        </Fragment>
    )
}

class CashCardOnDeliveryView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaderStatus: true
        }
    }

    componentDidMount() {
        this.getTotalValues();
    }

    getTotalValues = () => {
        callApi(`${merchantSettlementReportAPI.url}?${this.getQueryString()}`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                this.setState({
                    data: get(result, "data.meta.totalsRow", {}),
                    loaderStatus: false
                })
            })
    }

    getQueryString = () => {
        let { formState } = this.props;

        let dateTo = new Date(get(formState, "merchant_settlement_transaction_report.dateTo", new Date()));
        dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
        dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

        const queryObj = {
            settlementDateFrom: get(formState, "merchant_settlement_transaction_report.dateFrom", new Date()).toISOString(),
            settlementDateTo: dateTo.toISOString(),
            // settleToMerchantId: get(formState, "merchant_settlement_transaction_report.merchantId").split("/")[0],
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    getCsv = (id) => {
        callApi(`${headOfficeSettlementAPI.url}/${id}/orders`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                downloadCSV(result.data.data.toString(), "Orders.csv")
            })
    }

    render() {
        let { formState } = this.props;

        return (
            <React.Fragment>
                {
                    (get(formState, "merchant_settlement_transaction_report.dateFrom", "") === "" || get(formState, "merchant_settlement_transaction_report.dateTo", "") === "") ? (
                        <Redirect to={"/reports/merchant-settlement-transaction-report"} />
                    ) : (
                        <div className="container-fluid">
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box">
                                        <h4 className="font-size-18">Merchant Settlement Transaction Report </h4>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            From<strong className={"mx-2"}>{dateObjectToString(get(formState, "merchant_settlement_transaction_report.dateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "merchant_settlement_transaction_report.dateTo"), "dd/mm/YY")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Merchant:<strong className={"mx-2"}>{get(formState, "merchant_settlement_transaction_report.merchantId", "") === "" ? "All" : get(formState, "merchant_settlement_transaction_report.merchantId", "").split("/")[1]}</strong>
                                        </div>

                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <BackBtnComponent
                                            path={"/reports/merchant-settlement-transaction-report"}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className={"row mt-3"}>
                                <CrudComponent
                                    title={"Merchant Settlement Transaction Report "}
                                    apiURL={`${merchantSettlementReportAPI.url}?page=1&limit=20${this.getQueryString()}`}
                                    dataTableStateKey={merchantSettlementReportAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={false}
                                    isSetAction={false}
                                    tableHeaderList={[
                                        { displayName: "Transaction Date", key: "settlementDate", onPrintFn: (rawData) => dateObjectToString(rawData.settlementDate, "YY-mm-dd") },
                                        { displayName: "Transaction Number", key: "reference", onPrintFn: (rawData) => <button className="merchant_settlement_list_button" onClick={() => this.getCsv(rawData.id)}>{rawData.reference}</button> },
                                        { displayName: "Merchant Code", key: "settleToMerchant.code" },
                                        { displayName: "Merchant Name", key: "settleToMerchant.name" },
                                        {
                                            displayName: "Amount", key: "total", onPrintFn: (rawData) => {
                                                return rawData.total === null ? 0 : rawData.total;
                                            }
                                        },
                                        { displayName: "Remark", key: "remark" },
                                    ]}
                                    tableBodyLastComponent={TableLastRow}
                                    tableBodyLastProps={this.state.data}
                                    isForceShowLoader={this.state.loaderStatus}
                                />
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(CashCardOnDeliveryView);
