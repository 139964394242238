import React, { Fragment, useState } from "react";
import { Spinner, Tooltip } from 'reactstrap';
import { withRouter } from "react-router-dom";

import { InputButton } from "../Forms";
import { ModalUI } from "../Modal/ModalUI";

const emptyFn = (...para) => undefined;

const VariableToComponent = ({
    component = (<Fragment></Fragment>),
    props = {}
}) => {

    const UIComponent = component;

    return <UIComponent {...props} />

}

const CustomLoader = ({
    elementWrapperStyle = "",
    elementStyle = "",
    type = "border",
    size = "md",
    color = "primary"
}) => {
    return (
        <div className={`${elementWrapperStyle}`}>
            <Spinner
                className={elementStyle}
                type={type}
                size={size}
                color={color}
            />
        </div>
    )
}

const ToolTipComponent = ({
    placement = "bottom",
    tooltipText = "",
    target = ""
}) => {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);

    return (
        <Tooltip
            placement={placement}
            target={target}
            isOpen={show}
            toggle={toggle}
            trigger={"hover"}
        >
            {tooltipText}
        </Tooltip>
    )
}

const BackBtn = ({
    elementWrapperStyle = "",
    path = "",
    icon = "fas fa-angle-double-left",
    btnName = "Back",
    btnColor = "primary",
    isGoBack = false,
    isModal = false,
    history = emptyFn
}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            {
                (!isModal) ? (
                    <InputButton
                        elementWrapperStyle={elementWrapperStyle}
                        btnText={btnName}
                        startIcon={icon}
                        btnColor={btnColor}
                        onClickBtnFn={() => isGoBack ? history.goBack() : history.push(path)}
                    />
                ) : (
                    <Fragment>
                        <InputButton
                            elementWrapperStyle={elementWrapperStyle}
                            btnText={btnName}
                            startIcon={icon}
                            btnColor={btnColor}
                            onClickBtnFn={() => setOpen(true)}
                        />

                        <ModalUI
                            isOpen={isOpen}
                            modalTitle={"Please Confirm"}
                            modalBodyComponent={<div>Are you sure want to cancel?</div>}
                            modalFooterComponent={<InputButton
                                btnText={"Yes"}
                                btnColor={"primary"}
                                onClickBtnFn={() => isGoBack ? history.goBack() : history.push(path)}
                            />}
                            showHideToggleFn={() => setOpen(false)}
                            closeBtnName={"No"}
                        />
                    </Fragment>
                )
            }
        </Fragment>
    )
}

const BackBtnComponent = withRouter(BackBtn);

export {
    VariableToComponent,
    CustomLoader,
    ToolTipComponent,
    BackBtnComponent
}
