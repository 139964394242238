import React, { Component, Fragment, useEffect } from 'react';
import { findIndex, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import JsBarcode from 'jsbarcode';
import {
    InputButton,
    FormWrapper,
} from '../../../components/boda-boda/Forms'
import { Button, Col, Row } from 'reactstrap';
import { printModalHelperFn } from '../../../helpers/boda-boda/common-helpers/common.helpers';
import { getManifestListAPI } from '../../../config/boda-boda/apiUrls.config';
import { dateObjectToString } from '../../../helpers/boda-boda/common-helpers/dateTime.helpers';
const Manifest = (props) => {

    let { match } = props;
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, 'publicManifest_print._onLoad', true) === false  && get(formState, `publicManifest_print_published_packages._onLoad`, true) === false) {
            
            JsBarcode('#manifestLabelBarcode', get(formState, `publicManifest_print.code`, '') ,{
                width: 1,
                height: 50,
                margin: 0,
            });
            JsBarcode(".articleID").init();
        }
    }, [get(formState, 'publicManifest_print._onLoad', true),
        get(formState, `publicManifest_print_published_packages._onLoad`, true)]);
 
    const getPackagesData = () => {
        if (get(formState, `publicManifest_print.status`, "") === "DRAFT") {
            return get(formState, `publicManifest_print.packages`, []);
        } else {
            return get(formState, `publicManifest_print_published_packages.packages`, []);
        }
    }

    
    return (
        <div className='container-fluid'>
            <FormWrapper
                setGroupName={`publicManifest_print`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/uuid/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        merchant: response?.merchant,
                        merchantCode: response?.merchant?.code,
                        code: response?.code,
                        locationPick: response?.locationPick,
                        pickAt: response?.pickAt,
                        driver: response?.driver,
                        packages: response?.packages,
                        status: response?.status,
                        "_onLoad": false,
                    }
                }}
            >
                <FormWrapper
                        setGroupName={`publicManifest_print_published_packages`}
                        onDestroyUnsetFormObject={true}
                        apiUrl={`${getManifestListAPI.url}/uuid/${match.params.id}/published-packages`}
                        onRebuildResponseFn={(response) => {
                            return {
                                packages: response,
                                "_onLoad": false,
                            }
                        }}
                    >

                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">Manifest</h4>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <Button
                                color='primary'
                                className='btn btn-primary waves-effect waves-light'
                                onClick={() => printModalHelperFn("print_preview")}
                            >
                            Print
                            </Button>

                        </div>
                    </Col>
                </Row>
                <div className="card">
                    <div className="card-body">
                        <div id={'print_preview'}>
                            <div className={'printPreviewWrapper'}>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <dl className={'row'}>
                                            <dd className={"col-sm-5"}>Merchant Name</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `publicManifest_print.merchant.name`, '-')}</dt>
                                            <dd className={"col-sm-5"}>Merchant Code</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `publicManifest_print.merchantCode`, '-')}</dt>
                                            <dd className={"col-sm-5"}>Manifest Code</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `publicManifest_print.code`, '-')}</dt>

                                            <dd className={"col-sm-5"}></dd>
                                            <dt className={"col-sm-7"}><img id="manifestLabelBarcode" /></dt>
                                            
                                        </dl>
                                        <br></br>
                                    </div>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-5"}>Pick Up Details</dd>
                                            <dt className={"col-sm-7"}> {
                                                get(formState, `publicManifest_print.locationPick.address`, "")
                                            }
                                                ,
                                                {
                                                    get(formState, `publicManifest_print.locationPick.city.name`, "")
                                                }
                                            </dt>
                                            <dd className={"col-sm-5"}>Pick Up Date</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `publicManifest_print.pickAt`, "-") ? dateObjectToString(get(formState, `publicManifest_print.pickAt`, ""), "YY-mm-dd") : "-"}</dt>
                                            <dd className={"col-sm-5"}>Driver Name</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `publicManifest_print.driver.name`, "-")}</dt>
                                        </dl>
                                    </div>
                                    
                                    
                                    <div className={"col-md-12"}>
                                        <div className="table-rep-plugin">
                                            <div
                                                className="table-responsive mb-0"
                                                data-pattern="priority-columns"
                                            >
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Article ID</th>
                                                            <th>Consignment No. Name & Address</th>
                                                            <th>Payment Method</th>
                                                            <th>Signature Required</th>
                                                            <th>ID Verification Required</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            getPackagesData().map((bodyData, index) => {
                                                                console.log("bodyData", bodyData)
                                                                return (
                                                                    <tr key={index}>
                                                                         <td>
                                                                    <svg
                                                                        className="articleID"
                                                                        jsbarcode-value={get(bodyData, "articleNo", "-")}
                                                                        jsbarcode-width="1"
                                                                        jsbarcode-height="35"
                                                                        jsbarcode-margin="0"
                                                                        jsbarcode-fontsize="15"

                                                                    />
                                                                </td>
                                                                        <td>
                                                                            {get(bodyData, "order.consignmentNo", "")}<br />
                                                                            {get(bodyData, "order.name", "")}<br />
                                                                            {get(bodyData, "order.shippingLocation.name", "")}<br />
                                                                            {get(bodyData, "order.shippingLocation.address", "")}
                                                                        </td>
                                                                        <td>{get(bodyData, "order.paymentMethod", "-")}</td>
                                                                        <td>
                                                                            {
                                                                                (get(bodyData, "order.signatureRequired", "") === true) ? (
                                                                                    "Yes"
                                                                                ) : (
                                                                                    "No"
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                (get(bodyData, "order.idVerificationRequired", "") === true) ? (
                                                                                    "Yes"
                                                                                ) : (
                                                                                    "No"
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>

                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </FormWrapper>
            </FormWrapper>

        </div>

    )
}
export { Manifest };