import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { useSelector } from "react-redux";

import {
    SelectBoxWithState,
    FormWrapper,
    InputButton, InputBoxWithState, DatePickerBoxWithState
} from "../../../../../components/boda-boda/Forms";
import {
    ModalUI,
    modalTypes,
} from "../../../../../components/boda-boda/Modal/ModalUI";
import CreateManifest from "./CreateManifesto";
import CreateManifestBtn from "./CreateManifestBtn";
import { actionBtnTypes, CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    mapPackageStatusesNames,
    mapStatusesNames,
    pickUpRequestStatus
} from "../../../../../config/boda-boda/db_config/db_status.config";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { getManifestListAPI, getMerchantBranchAllAPI, getPackageListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { SendRequestUIModal } from "./SendRequestUI";
import { CancelUIModal } from "./CancelUI";
import ViewPackage from "./ViewPackage";
import { ManifestPrintModal } from "./PrintUI";
import { BulkShippingLabelPrintByURL } from "../../../packages/includes/BulkShippingLabelPrintByURL";

const CreateUI = (props) => {
    return (
        <FormWrapper
            setGroupName={getManifestListAPI.key}
            setFormObject={{
                pickAt: new Date()
            }}
        >
            <SelectBoxWithState
                labelText={"Pick Up Location"}
                isRequired={true}
                placeHolderText={"Pick Up Location"}
                inputStatePath={`${getManifestListAPI.key}.locationPick.id`}
                formGroupName={getManifestListAPI.key}
                apiUrl={getMerchantBranchAllAPI.url}
                apiStateKey={getMerchantBranchAllAPI.key}
                keyName={"locationId"}
                valueName={"name"}
            />

            <DatePickerBoxWithState
                labelText={"Pick Up Date"}
                isRequired={true}
                inputName={"pickAt"}
                formGroupName={getManifestListAPI.key}
                minDate={new Date()}
            />
        </FormWrapper>
    );
};

const CreateBtn = (props) => {
    const [isShow, setShow] = useState(false);

    const onClickFn = () => {
        props.history.push("/pick-request/drafts/create");
    };

    const modalCloseFn = () => {
        setShow(false);
        props.setModelStatusFn(false);
    };

    return (
        <Fragment>
            <InputButton btnText="Select" onClickBtnFn={() => onClickFn()} />

            <ModalUI
                isOpen={isShow}
                modalTitle={"Create Manifest"}
                modalType={modalTypes.large}
                modalBodyComponent={<CreateManifest />}
                modalFooterComponent={
                    <CreateManifestBtn modalCloseFn={modalCloseFn} type={"create"} />
                }
                showHideToggleFn={modalCloseFn}
            />
        </Fragment>
    );
};

const CreateBtnWithProps = withRouter(CreateBtn);

const ViewUI = (props) => {
    let { history, location, match } = props;

    const formState = useSelector(state => state.FormData);

    const getTableHeaderList = () => {
        const headers = [
            { displayName: "Article No", key: "articleNo" },
            { displayName: "Channel Order No", key: "order.channelOrderNo" },
            { displayName: "Customer Name", key: "order.name" },
            { displayName: "Delivery Type", key: "deliveryType" },
            { displayName: "Payment Method", key: "order.paymentMethod" },
            { displayName: "Package Category", key: "category.name" },
        ];

        const status = get(formState, `${getManifestListAPI.key}_view.status`, "");

        if (status === "DRAFT" || status === "PUBLISH" || status === "ACCEPTED") {
            headers.push({ displayName: "Pick Location", key: "locationPick.name" });
        } else {
            headers.push({ displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] });
        }

        return headers;
    }

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        code: response.code,
                        status: response.status,
                        createdAt: response.createdAt,
                        pickedAt: response.pickedAt,
                        locationPick: response.locationPick,
                        locationDrop: response.locationDrop,
                        notes: response.notes,
                        pickAt: response.pickAt,
                        packages: response.packages,
                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View Manifest</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={`/pick-request/${location.pathname.split('/')[2]}`}
                                btnName={"Back"}
                                btnColor={"primary"}
                            />

                            {
                                (get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT") ? (
                                    <Fragment>
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Edit"}
                                            startIcon={"mdi mdi-square-edit-outline"}
                                            onClickBtnFn={() => history.push(`/pick-request/${location.pathname.split('/')[2]}/edit/${match.params.id}`)}
                                        />

                                        <SendRequestUIModal
                                            id={match.params.id}
                                            locationPickId={get(formState, `${getManifestListAPI.key}_view.locationPick.id`, "")}
                                            history={history}
                                            packages={get(formState, `${getManifestListAPI.key}_view.packages`, "")}
                                            pickAt={get(formState, `${getManifestListAPI.key}_view.pickAt`, "")}
                                        />
                                    </Fragment>
                                ) : null
                            }

                            {
                                (get(formState, `${getManifestListAPI.key}_view.status`, "") === "REJECTED") ? (
                                    <Fragment>
                                        <InputButton
                                            elementWrapperStyle={""}
                                            btnText={"Re-Draft"}
                                            startIcon={"mdi mdi-square-edit-outline"}
                                            onClickBtnFn={() => history.push(`/pick-request/rejected/redraft/${match.params.id}`)}
                                        />
                                    </Fragment>
                                ) : (
                                    <ManifestPrintModal
                                        manifest={{ id: match.params.id }}
                                    />
                                )
                            }

                            {
                                (get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" || get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH") ? (
                                    <CancelUIModal
                                        id={match.params.id}
                                        locationPickId={get(formState, `${getManifestListAPI.key}_view.locationPick.id`, "")}
                                        history={history}
                                    />
                                ) : null
                            }
                        </div>
                    </Col>
                </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>To Be Picked Up</dd>
                                            <dt className={"col-sm-8"}>
                                                {get(formState, `${getManifestListAPI.key}_view.pickAt`, "") ? dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""), "YY-mm-dd") : "-"}
                                            </dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Status</dd>
                                            <dt className={"col-sm-8"}>
                                                {
                                                    mapStatusesNames[get(formState, `${getManifestListAPI.key}_view.status`, "")]
                                                }
                                            </dt>
                                            <dd className={"col-sm-4"}>Pick Up Created Time</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.createdAt`, ""), "YY-mm-dd HH:MM:II")}
                                            </dt>
                                            <dd className={"col-sm-4"}>Picked Up Time</dd>
                                            <dt className={"col-sm-8"}>
                                                {get(formState, `${getManifestListAPI.key}_view.pickedAt`) === null ? "-" : dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickedAt`, "-"), "YY-mm-dd HH:MM:II")}
                                            </dt>

                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Packages"
                        apiURL={get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" ? `${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}` : `${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=20`}
                        dataTableStateKey={getPackageListAPI.key}
                        isFilter={false}
                        isSetAction={true}
                        isSetCreateAction={false}
                        bulkPrintAll={true}
                        bulkPrintAllBtn={<React.Fragment>
                            <BulkShippingLabelPrintByURL
                                type='bulk'
                                printURL={get(formState, `${getManifestListAPI.key}_view.status`, "") === "DRAFT" ? `${getPackageListAPI.url}/all?manifestId=${match.params.id}` : `${getManifestListAPI.url}/${match.params.id}/published-packages/all`}
                                className='btn btn-primary waves-effect waves-light mr-3'
                            />
                            {" "}
                        </React.Fragment>}
                        tableHeaderList={getTableHeaderList()}
                        actionList={[
                            {
                                actionType: actionBtnTypes.view,
                                isSetModel: true,
                                modalProps: {
                                    modalTitle: "View Package",
                                    modalType: "modal-lg",
                                    modalBodyComponent: ViewPackage,
                                    isSetClose: true,
                                    isSetFooterClose: true,
                                }
                            }
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notes</h4>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        {
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []) != null && get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div>None</div>
                                            )

                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

const EditUI = (props) => {
    return (
        <p>Are you sure want to change this manifest's status into "Pending"?</p>
    );
};

const EditBtn = (props) => {
    return (
        <InputButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary  waves-effect waves-light"}
            onClickBtnFn={() => props.setModelStatusFn(false)}
        />
    );
};

const FilterUI = (props) => {
    const getStatuses = () => {
        let { location } = props;
        const temp = location.pathname.split('/');

        if (temp[2] === "all") {
            return [
                {
                    id: "",
                    value: "All"
                },
                ...pickUpRequestStatus
            ];
        } else {
            return false;
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Manifest Code"
                        inputName={"code"}
                        formGroupName={props.searchFromKey}
                        maxLength={17}
                        placeHolderText={"Manifest Code"}
                    />
                </div>

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText="Article ID"
                        inputName={"articleId"}
                        formGroupName={props.searchFromKey}
                        maxLength={17}
                        placeHolderText={"Article ID"}
                    />
                </div>

                {
                    (getStatuses() !== false) ? (
                        <div className="col-md-6">
                            <SelectBoxWithState
                                labelText={"Status"}
                                inputName={"status"}
                                formGroupName={props.searchFromKey}
                                dataList={getStatuses()}
                                placeHolderText={"All"}
                            />
                        </div>
                    ) : null
                }

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"Pick Up From Date"}
                        inputName={"pickupDateFrom"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"Pick Up From Date"}
                    />
                </div>

                <div className="col-md-6">
                    <DatePickerBoxWithState
                        labelText={"Pick Up To Date"}
                        inputName={"pickupDateTo"}
                        formGroupName={props.searchFromKey}
                        placeholderText={"Pick Up To Date"}
                    />
                </div>

                <div className="col-md-6">
                    <SelectBoxWithState
                        labelText={"Pick Up Location"}
                        placeHolderText={"All"}
                        inputName={"locationPickId"}
                        formGroupName={props.searchFromKey}
                        apiUrl={getMerchantBranchAllAPI.url}
                        apiStateKey={getMerchantBranchAllAPI.key}
                        keyName={"locationId"}
                        valueName={"name"}
                    />
                </div>

            </div>
        </div>
    )
}

const PickRequestFilterUI = withRouter(FilterUI);

export {
    CreateUI,
    CreateBtnWithProps,
    ViewUI,
    EditUI,
    EditBtn,
    PickRequestFilterUI
};
