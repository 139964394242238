import React, { Component, createRef } from "react";
import { size } from "lodash";
import { connect } from "react-redux";
import axios from "axios";

import csv from "../../../../../../../assets/images/upload-csv.png";
import { checkValidFileType } from "../../../../../../../helpers/boda-boda/file-upload-helpers/file-upload.helpers";
import { setPageLoader } from "../../../../../../../store/boda-boda/ui/actions";
import { fileUploadAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { FormWrapper } from "../../../../../../../components/boda-boda/Forms";
import { changeInputFn } from "../../../../../../../store/boda-boda/form/actions";


class UploadPackageModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            fileName: null,
            url: null,
        }

        this.inputRef = createRef();
    }

    onChangeFn = (event) => {
        let { setPageLoader, changeInputFn } = this.props;

        if (size(event.target.files) > 0) {
            const file = event.target.files[0];

            if (checkValidFileType(file.type, "file", ["csv"]) === true) {
                setPageLoader(true);

                axios.get(`${fileUploadAPI.url}?fileType=csv`, {
                    filename: file.name,
                    filetype: file.type
                })
                    .then((result) => {
                        const signedUrl = result.data.uploadURL;
                        const fileName = result.data.fileName;

                        const options = {
                            headers: {
                                'Content-Type': 'text/csv'
                            }
                        };

                        axios.put(signedUrl, file, options).then((result) => {
                            this.setState({
                                file: file,
                                fileName: fileName,
                                url: signedUrl.split("?")[0]
                            }, () => {
                                changeInputFn("upload_csv_data", "data", "", this.state);
                                setPageLoader(false);
                            })
                        }).catch((error) => {
                            setPageLoader(false);
                        });
                    })
                    .catch((error) => {
                        setPageLoader(false);
                    })
            }
        }
    }

    render() {
        let { changeInputFn } = this.props;

        return (
            <FormWrapper
                setGroupName={"upload_csv_data"}
            >
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className={"card mb-0"}>
                            <div className={"card-body"}>

                                <div className={"order-create-div"} onClick={() => {
                                    if (this.state.file === null) {
                                        this.inputRef.current.click();
                                    }
                                }}>
                                    {
                                        (this.state.file === null) ? (
                                            <div className={"text-center"}>
                                                <h4 className="card-title setElementToCenter">Click here to select the csv file</h4>
                                            </div>
                                        ) : (
                                            <div className={"d-inline-flex setElementToCenter"}>
                                                <div className={`display-image`}>
                                                    <img
                                                        src={csv}
                                                        className={"select-image"}
                                                    />

                                                    <div className={"image-hover"}>
                                                        <i
                                                            className={"fas fa-trash-alt"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    file: null,
                                                                    fileName: null,
                                                                    url: null
                                                                });

                                                                changeInputFn("upload_csv_data", "data", "", {});
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                <input
                                    ref={this.inputRef}
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(event) => this.onChangeFn(event)}
                                    onClick={(event) => {
                                        event.currentTarget.value = null
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        )
    }
}

export default connect(null, {
    setPageLoader,
    changeInputFn
})(UploadPackageModalBody);
