/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-08-24 14:48:50 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-04 09:08:35
 */

import PendingOrders from "../pages/boda-boda/orders/pages/pending-orders/PendingOrders";
import Packages from "../pages/boda-boda/packages/Packages";
import LoginPage from "../pages/boda-boda/auth/LoginPage";
import ForgetPassword from "../pages/boda-boda/auth/FogetPassword";
import ForgetPasswordOTP from "../pages/boda-boda/auth/FogetPasswordOTP";
import ForgetPasswordOTPVerify from "../pages/boda-boda/auth/FogetPasswordOTPVerify";
import ForgetPasswordReset from "../pages/boda-boda/auth/FogetPasswordReset";
import PickedOrders from "../pages/boda-boda/orders/pages/picked-orders/PickedOrders";
import PackedOrders from "../pages/boda-boda/orders/pages/packed-orders/PackedOrders";
import Shipped from "../pages/boda-boda/packages/Shipped";
import PickRequest from "../pages/boda-boda/pick-request/PickRequest";
import Outbound from "../pages/boda-boda/shipping/Outbound";
import UserUI from "../pages/boda-boda/users/UserUI";
import { ViewUIWithState as PendingOrdersView } from "../pages/boda-boda/orders/pages/pending-orders/includes/ViewUI";
import { CreatePackageComponent } from "../pages/boda-boda/orders/pages/picked-orders/includes/view-includes/CreatePackage";
import { ViewUIWithState as PackedOrdersView } from "../pages/boda-boda/orders/pages/packed-orders/includes/ViewUI";
import { ViewUIWithState as PackageView } from "../pages/boda-boda/packages/includes/ViewUI";
import { EditPackageComponent } from "../pages/boda-boda/packages/includes/EditUI";
import { CreateUI as UserCreate } from "../pages/boda-boda/users/user_includes/CreateUI";
import { UserEdit } from "../pages/boda-boda/users/user_includes/EditUI";
import { ViewUI as OutboundView } from "../pages/boda-boda/shipping/includes/ViewUI";
import { ViewUI as PickRequestView } from "../pages/boda-boda/pick-request/includes/common-includes/common-includes";
import CreateManifest from "../pages/boda-boda/pick-request/includes/common-includes/CreateManifesto";
import { ShippedViewUIWithState } from "../pages/boda-boda/packages/includes/ShippedViewUI";
import { CreateUIWithState as CreateOrder } from "../pages/boda-boda/orders/pages/pending-orders/includes/CreateUI";
import { EditUIWithState as EditOrder } from "../pages/boda-boda/orders/pages/pending-orders/includes/EditUI";
import { ViewUIWithState as UserView } from "../pages/boda-boda/users/user_includes/ViewUI";
import { ViewUIWithState as PickedOrdersView } from "../pages/boda-boda/orders/pages/picked-orders/includes/ViewUI";
import AllOrders from "../pages/boda-boda/orders/pages/all-orders/AllOrders";
import MerchantProfile from "../pages/boda-boda/merchant/profile/MerchantProfile";
import { MerchantBranch } from "../pages/boda-boda/merchant/branch/MerchantBranch";
import { MerchantContract } from "../pages/boda-boda/merchant/contract/MerchantContract";
import { ViewUIWithState as BranchView } from "../pages/boda-boda/merchant/branch/includes/ViewUI";
import { ContractViewWithState as ViewC } from "../pages/boda-boda/merchant/contract/includes/ContractView";
import Dashboard from "../pages/Dashboard";
import RedraftPickRequest from "../pages/boda-boda/pick-request/includes/common-includes/RedraftPickRequest";
import UserProfile from "../pages/boda-boda/users-profile/UserProfile";
import EditProfile from "../pages/boda-boda/users-profile/EditProfile";
import ViewRejectedRequest from "../pages/boda-boda/pick-request/includes/rejected-requests/ViewRejectedRequest";
import ReconciliationReport from "../pages/boda-boda/reports/reconciliation-report/ReconciliationReport";
import CashCardOnDelivery from "../pages/boda-boda/reports/cash-card-on-delivery-report/CashCardOnDelivery";
import ReconciliationReportView from "../pages/boda-boda/reports/reconciliation-report/includes/ReconciliationReportView";
import CashCardOnDeliveryView from "../pages/boda-boda/reports/cash-card-on-delivery-report/includes/CashCardOnDeliveryView";
import DeliveredTerminateView from "../pages/boda-boda/orders/pages/all-orders/includes/DeliveredTerminateView";
import {WeightView} from "../pages/boda-boda/merchant/branch/includes/Weight";
import {VolumetricView} from "../pages/boda-boda/merchant/branch/includes/Volumetric";
import { ShippingLabel } from "../pages/boda-boda/public-pages/ShippingLabel";
import { Manifest } from "../pages/boda-boda/public-pages/Manifest";
import PackageStatusReport from "../pages/boda-boda/reports/package-status-report/PackageStatusReport"
import PackageStatusReportView from "../pages/boda-boda/reports/package-status-report/includes/PackageStatusReportView"
import MerchantSettlementTransactionReport from "../pages/boda-boda/reports/merchant-settlement-transaction-report/MerchantSettlementTransactionReport"
import MerchantSettlementTransactionReportView from "../pages/boda-boda/reports/merchant-settlement-transaction-report/includes/MerchantSettlementTransactionReportView"

const bodaRoutes = [
    { path: "/", component: Dashboard },
    { path: "/orders/all", component: AllOrders },
    { path: "/orders/all/:type/view/:id", component: DeliveredTerminateView },
    { path: "/orders/pending", component: PendingOrders },
    { path: "/orders/pending/create", component: CreateOrder },
    { path: "/orders/pending/view/:id", component: PendingOrdersView },
    { path: "/orders/pending/edit/:id", component: EditOrder },
    { path: "/orders/picked", component: PickedOrders },
    { path: "/orders/picked/view/:id", component: PickedOrdersView },
    { path: "/orders/picked/create/:id", component: CreatePackageComponent },
    { path: "/orders/packed", component: PackedOrders },
    { path: "/orders/packed/view/:id", component: PackedOrdersView },
    { path: "/package/packages", component: Packages },
    { path: "/package/packages/view/:id", component: PackageView },
    { path: "/package/packages/edit/:id", component: EditPackageComponent },
    { path: "/package/shipped", component: Shipped },
    { path: "/package/shipped/view/:id", component: ShippedViewUIWithState },
    { path: "/pick-request/all", component: PickRequest },
    { path: "/pick-request/drafts", component: PickRequest },
    { path: "/pick-request/pending", component: PickRequest },
    { path: "/pick-request/accepted", component: PickRequest },
    { path: "/pick-request/rejected", component: PickRequest },
    { path: "/pick-request/rejected/view/:id", component: ViewRejectedRequest },
    { path: "/pick-request/:type/view/:id", component: PickRequestView },
    { path: "/pick-request/:type/create/:id", component: CreateManifest },
    { path: "/pick-request/:type/edit/:id", component: CreateManifest },
    { path: "/pick-request/:type/redraft/:id", component: RedraftPickRequest },
    { path: "/users", component: UserUI },
    { path: "/users/view/:id", component: UserView },
    { path: "/users/create", component: UserCreate },
    { path: "/users/edit/:id", component: UserEdit },
    { path: "/shipping/outbound", component: Outbound },
    { path: "/shipping/outbound/view/:id", component: OutboundView },
    { path: "/merchant-profile", component: MerchantProfile },
    { path: "/merchant-branch", component: MerchantBranch },
    { path: "/merchant-branch/view/:id", component: BranchView },
    { path: "/merchant-contract", component: MerchantContract },
    { path: "/merchant-contract/view/:id", component: ViewC },
    { path: "/user-profile/view", component: UserProfile },
    { path: "/user-profile/edit", component: EditProfile },
    { path: "/reports/lodgement-reconciliation-report", component: ReconciliationReport },
    { path: "/reports/lodgement-reconciliation-report/view", component: ReconciliationReportView },
    { path: "/reports/cash-or-card-on-delivery-report", component: CashCardOnDelivery },
    { path: "/reports/cash-or-card-on-delivery-report/view", component: CashCardOnDeliveryView },
    { path: "/merchant/:merchantId/merchant-branch/view/:id/actual-weight/:name", component: WeightView },
    { path: "/merchant/:merchantId/merchant-branch/view/:id/volumetric-weight/:name", component: VolumetricView },
    { path: "/reports/package-status-report", component: PackageStatusReport },
    { path: "/reports/package-status-report/view", component: PackageStatusReportView },
    { path: "/reports/merchant-settlement-transaction-report", component: MerchantSettlementTransactionReport },
    { path: "/reports/merchant-settlement-transaction-report/view", component: MerchantSettlementTransactionReportView }
];

const publicBodaRoutes = [
    { path: "/login", component: LoginPage },
    { path: "/forgot-password", component: ForgetPassword },
    { path: "/forgot-password-otp", component: ForgetPasswordOTP },
    { path: "/forgot-password-otp-verify", component: ForgetPasswordOTPVerify },
    { path: "/forgot-password-reset", component: ForgetPasswordReset },
    { path: "/package/shipping-label/:id/print", component: ShippingLabel },
    { path: "/manifest/:id/print", component: Manifest },
];

export {
    bodaRoutes,
    publicBodaRoutes
}
