/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-02 16:22:03
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-02-09 17:06:19
 */
import React, { Component, Fragment } from "react";
import { Col, Label, Row } from "reactstrap";
import _, { get } from "lodash";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearSelectedPackages, isEmptyValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    FormWrapper,
    InputButton,
    DatePickerBoxWithState,
    InputBoxWithState, SubmitButton
} from "../../../../../components/boda-boda/Forms";
import PackagesFilterUI from "./PackagesFilterUI";
import {
    getPackageListAPI,
    getManifestListAPI,
} from "../../../../../config/boda-boda/apiUrls.config";
import ViewPackage from "./ViewPackage";
import { mergedDataTableResult, requestAPICallFn } from "../../../../../store/boda-boda/core/actions";
import { updateManifestId } from "../../../../../store/boda-boda/transaction_request/actions";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setBulkValues } from "../../../../../store/actions";


class CreateManifesto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            isScan: false
        };
    }

    addPackage = (row) => {
        const body = {
            "manifest": {
                "id": Number(this.props.match.params.id)
            }
        }

        const flashMsjObj = {
            status: true,
            message: "Package has successfully added",
            type: "success"
        };

        this.props.updateManifestId(row.id, body, flashMsjObj);
        clearSelectedPackages(this.props.formState, this.props.setBulkValues);
    }

    deletePackage = (row) => {
        const body = {
            "manifest": null
        }

        const flashMsjObj = {
            status: true,
            message: "Package has successfully removed",
            type: "success"
        };

        this.props.updateManifestId(row.id, body, flashMsjObj);
        clearSelectedPackages(this.props.formState, this.props.setBulkValues);
    }

    componentWillUnmount(){
        sessionStorage.removeItem('selectedPackages')
    }

    render() {
        let { match, formState, history, location } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={getManifestListAPI.key}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            code: response.code,
                            pickAt: new Date(response.pickAt),
                            locationDrop: response.locationDrop,
                            locationPick: response.locationPick,
                            note: response.notes,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">
                                    {
                                        (location.pathname.split('/')[3] === "create") ? "Create a Manifest" : "Edit a Manifest"
                                    }
                                </h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <CreateBtn
                                        history={history}
                                        location={location}
                                        id={match.params.id}
                                    />

                                    <BackBtnComponent
                                        elementWrapperStyle={""}
                                        path={"/pick-request/drafts"}
                                        btnName={"Cancel"}
                                        icon={"far fa-times-circle"}
                                        btnColor={"danger"}
                                    />
                                </Fragment>
                            </div>

                        </Col>

                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                    <div className="row">

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-4"}>Manifest Code</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}.code`, "")}</dd>

                                                <dt className={"col-sm-4"}>Pick Up Location</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}.locationPick.name`, "")}</dd>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-4"}>Drop Location</dt>
                                                <dd className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}.locationDrop.name`, "")}</dd>
                                            </dl>

                                            <DatePickerBoxWithState
                                                labelText={"Pick Up Date"}
                                                isRequired={true}
                                                inputName={"pickAt"}
                                                formGroupName={getManifestListAPI.key}
                                                minDate={new Date()}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            {
                                (this.state.isScan) ? (
                                    <InputButton
                                        btnText={"Remove Scan"}
                                        elementWrapperStyle={"float-right mb-2"}
                                        startIcon={"mdi mdi-scanner-off"}
                                        onClickBtnFn={() => this.setState({
                                            ...this.state,
                                            isScan: false
                                        })}
                                    />
                                ) : (
                                    <InputButton
                                        btnText={"Scan Now"}
                                        elementWrapperStyle={"float-right mb-2"}
                                        startIcon={"mdi mdi-scanner"}
                                        onClickBtnFn={() => this.setState({
                                            ...this.state,
                                            isScan: true
                                        })}
                                    />
                                )
                            }
                        </div>
                    </div>

                    <FormWrapper
                        setGroupName={getPackageListAPI.key}
                        onDestroyUnsetFormObject={true}
                    >

                        <div className={"row"}>
                            {
                                (!this.state.isScan) ? (
                                    get(this.props, 'UIState.setPageLoader', false) !== true ? (
                                        <div className="col-md-12 padding-none">
                                            <CrudComponent
                                                title="Packages"
                                                apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=null&status=PACKED&orderStatus=PACKED`}
                                                dataTableStateKey={getPackageListAPI.key}
                                                isBulkAdd={true}
                                                defaultFilterFormObject={{
                                                    articleNo: "",
                                                    name: "",
                                                    deliveryType: "",
                                                    paymentMethod: "",
                                                    categoryId: "",
                                                    locationPickId: ""
                                                }}
                                                filterFromComponent={PackagesFilterUI}
                                                isSetCreateAction={false}
                                                tableHeaderList={[
                                                    { displayName: "Article No", key: "articleNo" },
                                                    { displayName: "Customer Name", key: "order.name" },
                                                    { displayName: "Delivery Type", key: "deliveryType" },
                                                    { displayName: "Payment Method", key: "order.paymentMethod" },
                                                    { displayName: "Package Category", key: "category.name" },
                                                    { displayName: "Pick Location", key: "locationPick.name" },
                                                ]}
                                                actionList={[
                                                    {
                                                        actionType: actionBtnTypes.view,
                                                        isSetModel: true,
                                                        modalProps: {
                                                            modalTitle: "View Package",
                                                            modalType: "modal-lg",
                                                            modalBodyComponent: ViewPackage,
                                                            isSetClose: true,
                                                            isSetFooterClose: true,
                                                        }
                                                    },
                                                    {
                                                        actionType: actionBtnTypes.create,
                                                        isSetModel: false,
                                                        actionBtn: {
                                                            tooltip: "Add",
                                                            btnText: "add",
                                                            onClickBtnFn: (data) => this.addPackage(data)
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                    ) : null
                                ) : null
                            }
                        </div>
                    </FormWrapper>

                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Items"
                            apiURL={`${getPackageListAPI.url}?page=1&limit=20&manifestId=${match.params.id}`}
                            dataTableStateKey={`${getPackageListAPI.key}_manifest`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={true}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Customer Name", key: "order.name" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                                { displayName: "Pick Location", key: "locationPick.name" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Package",
                                        modalType: "modal-lg",
                                        modalBodyComponent: ViewPackage,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                },
                                {
                                    actionType: actionBtnTypes.delete,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (data) => this.deletePackage(data)
                                    }
                                },
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        {
                                            (location.pathname.split('/')[3] === "edit") ? (
                                                <div className="col-md-6">
                                                    <label>Notes</label>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}.note`, []).length > 0 ? (
                                                            <ul style={{ paddingLeft: "17px" }}>
                                                                {
                                                                    get(formState, `${getManifestListAPI.key}.note`, []).map((element, index) => {
                                                                        return (
                                                                            <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                        );
                                                                    })
                                                                }
                                                            </ul>
                                                        ) : (
                                                            <div>None</div>
                                                        )
                                                    }

                                                </div>
                                            ) : null
                                        }

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={location.pathname.split('/')[3] === "edit" ? "New Note" : "Note"}
                                                inputName={"notes"}
                                                inputType={"textarea"}
                                                rows={3}
                                                formGroupName={`${getManifestListAPI.key}`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState,
        UIState: state.UIState,
    };
};

const CreateManifest = withRouter(CreateManifesto);

const CreateBtn = (props) => {
    const authState = useSelector(state => state.AuthState);

    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Save"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getManifestListAPI.key}`}
            dataTableKey={getManifestListAPI.key}
            isValidate={true}
            validationObject={{
                fields: {
                    pickAt: "Pick Up Date",
                    "locationPick.id": "Pick Up Location",
                },
                rules: {
                    pickAt: "required",
                    "locationPick.id": "required",
                },
                messages: {}
            }}
            flashMessages={{
                "success": {
                    status: true,
                    message: props.location.pathname.split('/')[3] === "edit" ? "Pick request has successfully updated" : "Pick request has successfully created",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getManifestListAPI.url}/${props.id}`,
                    key: getManifestListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);

                let res = {
                    ...data,
                    locationDrop: {
                        id: data.locationDrop.id
                    },
                    locationPick: {
                        id: data.locationPick.id
                    },
                    code: undefined,
                    note: undefined
                }

                if (props.location.pathname.split('/')[3] === "edit") {

                    if (!isEmptyValue(get(data, 'notes', ""))) {
                        res["notes"] = [
                            ...data.note,
                            {
                                "name": get(authState, "authUser.merchant.name", ""),
                                "at": new Date(),
                                "note": data.notes,
                                "createdBy": get(authState, "authUser.merchant.id", "")
                            }
                        ]
                    }
                } else {
                    if (!isEmptyValue(get(data, 'notes', ""))) {
                        res["notes"] = [
                            {
                                "name": get(authState, "authUser.merchant.name", ""),
                                "at": new Date(),
                                "note": data.notes,
                                "createdBy": get(authState, "authUser.merchant.id", "")
                            }
                        ]
                    }
                }

                return res;
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.location.pathname.split('/')[3] === "edit") {
                        if (props.location.pathname.split('/')[2] === "drafts") {
                            props.history.push(`/pick-request/drafts`);
                        } else {
                            props.history.goBack();
                        }
                    } else {
                        props.history.push(`/pick-request/drafts`);
                    }
                }
            }}
        />
    )
}

export default connect(mapStateToProps, {
    mergedDataTableResult,
    requestAPICallFn,
    updateManifestId,
    setBulkValues,
})(CreateManifest);
