import React, { useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import _, { findIndex, get } from 'lodash';
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap';

import { callApi } from '../../../../helpers/boda-boda/common-helpers/callApi.helpers';
import {
  getPackageListAPI,
  getUserStaffList,
} from '../../../../config/boda-boda/apiUrls.config';
import { printerServiceAPIUrl } from '../../../../config/boda-boda/core.config';
import {
  setPageLoader,
  setFlashMessage,
} from '../../../../store/boda-boda/ui/actions';
import { printModalHelperFn } from '../../../../helpers/boda-boda/common-helpers/common.helpers';
import { updateAuthUserPrinterTypeFn } from '../../../../store/boda-boda/auth/actions';
import {
  modalTypes,
  ModalUI,
} from '../../../../components/boda-boda/Modal/ModalUI';
import { ShippingLabelUI } from './ShippingLabelUI';

const BulkPrintLabelBtn = (props) => {
  const formState = useSelector((state) => state.FormData);
  const authState = useSelector((state) => state.AuthState);
  const packages = useSelector(
    (state) => state?.CoreState?.dataTableResponses?.packageListAPIKey?.results
  );
  const dispatch = useDispatch();
  const [dropdownState, setDropDownState] = useState(false);
  const [show, setShow] = useState(false);
  const [option, setOption] = useState('');
  const [printShow, setPrintShow] = useState(false);

  const onClickFn = () => {
    setShow(true);
  };

  const printingTypes = {
    NORMAL: 'A4',
    LABEL: 'Sticker',
  };

  const getSelectedPackages = () => {
    const tempArray = JSON.parse(sessionStorage.getItem('selectedPackages'));
    const tempBulkPrint = tempArray ? tempArray : [];
    let selecteditemList = [];
    const chkList = get(formState, 'packageListAPIKey', null);

    let selectedKeys = [];
    if (chkList !== null) {
      for (const [key, value] of Object.entries(chkList)) {
        if (value === true) {
          selectedKeys.push(key);
        }
      }
    }

    selecteditemList = selectedKeys.map((item) => {
      if (_.startsWith(item, 'printChk')) {
        return item.replace('printChk', '');
      }
    });

    selecteditemList = selecteditemList.filter(function (element) {
      return element !== undefined;
    });

    let selectedObjects = [];
    if (tempBulkPrint !== null) {
      for (let i = 0; i < tempBulkPrint?.length; i += 1) {
        for (let j = 0; j < selecteditemList?.length; j += 1) {
          if (tempBulkPrint[i]?.id == selecteditemList[j]) {
            selectedObjects.push(tempBulkPrint[i]);
          }
        }
      }
    }

    return selectedObjects;
  };

  const getPackageNo = (data = [], id) => {
    const index = findIndex(data, { id: parseInt(id) });

    if (index !== -1) {
      return `${index + 1}/${data.length}`;
    } else {
      return '';
    }
  };

  const sendPrintRequest = async (printType) => {
    try {
      dispatch(setPageLoader(true));
      if (
        printType !== get(authState, 'authUser.staff.printerType', 'NORMAL')
      ) {
        const staffAPIResponse = await callApi(
          `${getUserStaffList.url}/${get(authState, 'authUser.staff.id')}`
        )
          .headers(true)
          .method('put')
          .body({ printerType: printType })
          .send();

        if (staffAPIResponse._statue === true) {
          dispatch(updateAuthUserPrinterTypeFn(printType));
        }
      }

      if (printType === 'NORMAL') {
        let selectedPackages = getSelectedPackages();
        if (selectedPackages?.length === 0) {
          dispatch(
            setFlashMessage({
              status: true,
              message: 'No packages has been selected',
              type: 'danger',
            })
          );
        } else {
          setPrintShow(true);
        }
        setShow(false);
        dispatch(setPageLoader(false));
        // printModalHelperFn("print_preview");
      } else {
        let dataObj = {
          labelType: 1,
        };
        if (get(props, 'type', '') === 'bulk') {
          let apiBodyArray = [];
          let selectedPackages = getSelectedPackages();
          if (selectedPackages !== null && selectedPackages?.length !== 0) {
            for (let i = 0; i < selectedPackages.length; i += 1) {
              let item = {
                collectionNo: null,
                orderNo: get(selectedPackages[i], 'order.channelOrderNo', ''),
                articledID: get(selectedPackages[i], 'articleNo', ''),
                consignmentNo: get(
                  selectedPackages[i],
                  'order.consignmentNo',
                  ''
                ),
                packageNo: getPackageNo(
                  get(selectedPackages[i], 'order.packages', []),
                  get(selectedPackages[i], 'id', '')
                ),
                packageCategory: get(selectedPackages[i], 'category.name', ''),
                signatureOnDelivery:
                  get(selectedPackages[i], 'order.signatureRequired', false) ===
                    false
                    ? 'No'
                    : 'Yes',
                paymentType: get(
                  selectedPackages[i],
                  'order.paymentMethod',
                  ''
                ),
                delivertoName:
                  get(selectedPackages[i], 'order.name', '') +
                  ', ' +
                  get(
                    selectedPackages[i],
                    'order.shippingLocation.address',
                    ''
                  ) +
                  ', ' +
                  get(
                    selectedPackages[i],
                    'order.shippingLocation.city.name',
                    ''
                  ) +
                  ', ' +
                  get(
                    selectedPackages[i],
                    'order.shippingLocation.city.district.name',
                    ''
                  ) +
                  ', ' +
                  get(
                    selectedPackages[i],
                    'order.shippingLocation.city.district.province.name',
                    ''
                  ),
                delivertoPhone: get(selectedPackages[i], 'order.phone', ''),
                senderName: `${get(
                  selectedPackages[i],
                  `merchant.name`,
                  ''
                )}, ${get(
                  selectedPackages[i],
                  `merchantBranch.name`,
                  ''
                )}, ${get(
                  selectedPackages[i],
                  `merchantBranch.location.address`,
                  ''
                )}, ${get(
                  selectedPackages[i],
                  `merchantBranch.location.city.name`,
                  ''
                )}, ${get(
                  selectedPackages[i],
                  `merchantBranch.location.postalCode`,
                  ''
                )}`,
                senderPhone: get(
                  selectedPackages[i],
                  `merchant.contacts[0].phone`,
                  ''
                ),
                specialNotes: get(selectedPackages[i], 'notesPublic', ''),
              };
              apiBodyArray.push(item);
            }
            //loop here and add to array.
            dataObj['barcodeLabels'] = [...apiBodyArray];
          }
        }

        let selectedPackages = getSelectedPackages();
        if (
          get(props, 'type', '') === 'bulk' &&
          (selectedPackages === null || selectedPackages?.length === 0)
        ) {
          dispatch(
            setFlashMessage({
              status: true,
              message: 'No packages has been selected',
              type: 'danger',
            })
          );
          dispatch(setPageLoader(false));
          setShow(false);
        } else {
          const printerAPIResponse = await callApi(printerServiceAPIUrl)
            .method('post')
            .headers(true)
            .body(dataObj)
            .send();

          dispatch(setPageLoader(false));
          setShow(false);

          if (printerAPIResponse._statue === true) {
            dispatch(
              setFlashMessage({
                status: true,
                message: 'Request has sent to the printer',
                type: 'success',
              })
            );

          } else {
            dispatch(
              setFlashMessage({
                status: true,
                message: 'An error has occurred',
                type: 'danger',
              })
            );
          }
        }
      }
    } catch (error) {
      dispatch(setPageLoader(false));
      setShow(false);

      dispatch(
        setFlashMessage({
          status: true,
          message: 'An error has occurred 11',
          type: 'danger',
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ButtonDropdown
        isOpen={dropdownState}
        toggle={() => setDropDownState(!dropdownState)}
        direction='left'
      >
        <Button
          color='primary'
          onClick={() => {
            if (
              get(authState, 'authUser.staff.printerType', 'NORMAL') ===
              'NORMAL'
            ) {
              onClickFn();
              setOption('A4');
            } else {
              onClickFn();
              setOption('Sticker');
            }
          }}
        >
          Print as{' '}
          {
            printingTypes[
            get(authState, 'authUser.staff.printerType', 'NORMAL')
            ]
          }
        </Button>

        {/*<DropdownToggle caret color={"primary"}>*/}
        {/*    Print <i className="mdi mdi-chevron-down"></i>*/}
        {/*</DropdownToggle>*/}

        <DropdownToggle split caret color='info'>
          <i className='mdi mdi-chevron-down'></i>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              onClickFn();
              setOption('A4');
            }}
          >
            A4
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              onClickFn();
              setOption('Sticker');
            }}
          >
            Sticker
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      <ModalUI
        isOpen={show}
        modalTitle={'Please Confirm'}
        modalBodyComponent={`Are you sure you want to print ${getSelectedPackages()?.length
          } Shipping Labels`}
        showHideToggleFn={() => setShow(false)}
        closeBtnName='No'
        modalFooterComponent={
          <Button
            color='primary'
            onClick={() =>
              option === 'A4'
                ? sendPrintRequest('NORMAL')
                : sendPrintRequest('LABEL')
            }
          >
            Yes
          </Button>
        }
      />
      <ModalUI
        isOpen={printShow}
        modalType={modalTypes.large}
        modalTitle={<HeaderWithButton size={getSelectedPackages()?.length} />}
        modalBodyComponent={<ShippingLabelUI packages={getSelectedPackages()} />}
        showHideToggleFn={() => setPrintShow(false)}
        modalFooterComponent={
          <Button
            color='primary'
            onClick={() => printModalHelperFn('print_preview_multiple')}
          >
            Print as A4
          </Button>
        }
      />
    </React.Fragment>
  );
};

const HeaderWithButton = ({ size }) => {

  return (
    <React.Fragment>
      <div className={'row'}>
        <div>
          <p>Shipping Label</p>
        </div>
        {size > 1 ?
          <div className={'modalButton'}>
            <Button
              color="primary"
              onClick={() => printModalHelperFn("print_preview_multiple")}
            >
              Print as A4
            </Button>
          </div>
          :
          null}

      </div>
    </React.Fragment>
  )
}

export default BulkPrintLabelBtn;
