/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Common Helpers
 * @Date: 2020-02-18 12:25:52
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-08-12 17:04:04
 */

import _, { forEach, get } from 'lodash';
import {
  defaultPrecisionPoint,
  defaultPrecisionValue,
  defaultRoundUpType,
  roundUpTypes,
} from '../../../config/boda-boda/core.config';

/**
 * @description Get the type of given value
 * @param (*) value
 */

const getType = (value) => {
  if (value === null) return 'undefined';

  return typeof value;
};

const getDataByFormObject = ({ ...formObject }) => {
  _.unset(formObject, '_uibackProsess');
  _.unset(formObject, '_formGroupLinkKey');
  _.unset(formObject, '_uiFormGroup');
  _.unset(formObject, '_uiFormDescription');
  _.unset(formObject, '_updateStatus');
  _.unset(formObject, '_errors');
  _.unset(formObject, '_onLoad');
  return formObject;
};

const fromObjectToQueryString = ({ ...formObject }) => {
  let queryString = '';

  Object.keys(formObject).forEach((key) => {
    if (!isEmptyValue(formObject[key])) {
      queryString = queryString + `${key}=${formObject[key]}&`;
    }
  });

  if (queryString.substr(queryString.length - 1) === '&') {
    queryString = queryString.substring(0, queryString.length - 1);
  }
  return queryString;
};

const sortObjectToQueryString = ({ ...formObject }) => {
  let queryString = '';

  Object.keys(formObject).forEach((key) => {
    queryString = queryString + `${key}|${formObject[key] ? 'asc' : 'desc'},`;
  });

  if (queryString.substr(queryString.length - 1) === ',') {
    queryString = queryString.substring(0, queryString.length - 1);
  }

  return queryString ? `sort_by=${queryString}` : queryString;
};

const generateQueryString = (requestPage = 1, formData = {}, sortData = {}, isDisablePageNo = false) => {
  let url = isDisablePageNo ? '' : `page=${requestPage}`;
  const searchFormStr = fromObjectToQueryString(getDataByFormObject(formData));
  const sortStr = sortObjectToQueryString(getDataByFormObject(sortData));
  url = searchFormStr ? `${url}&${searchFormStr}` : url;
  url = sortStr ? `${url}&${sortStr}` : url;
  return url;
};

/**
 * @Author: Nisal Madusanka(EruliaF)
 * @description get from inputs for form validation
 * @param {Object|Array} formValue form data list
 * @param {string} key form value key
 */
const getInputsForValidate = (formValue, key) => {
  let value = _.get(formValue, key, '');

  switch (typeof value) {
    case 'string': {
      value = value.trim();
      break;
    }
    default: {
      break;
    }
  }
  return value;
};

/**
 * @author Nisal Madusanka(EruliaF)
 * @description genarate map key for form validation
 * @param {string} realInputKey known key
 * @param {string} keyToMap key should find
 */
const mapInputKey = (realInputKey, keyToMap) => {
  let arrayMatch = realInputKey.match(/(\.\d*\.)/g);
  let key = 0;
  let returnData = keyToMap.replace(/(\.\**\.)/g, (match) => {
    var value = arrayMatch[key];
    key++;
    return value;
  });
  return returnData;
};

/**
 * @author Nisal Madusanka(EruliaF)
 * @param (Object) object
 * @param (Array|Function) finder
 * @param {String} valueKey required value path
 * @param {any} defaultValue default Value
 * @param {any} ifNotFound return value if not found data
 */
const getValueByFilter = (
  object,
  finder,
  valueKey = '',
  defaultValue = null,
  ifNotFound = null
) => {
  const index = _.findIndex(object, finder);
  if (index !== -1) {
    return _.get(
      object,
      valueKey ? `${index}.${valueKey}` : `${index}`,
      defaultValue
    );
  } else {
    return ifNotFound;
  }
};

/**
 * @author Nisal Madusanka(EruliaF)
 * @param (any) value
 */
const isEmptyValue = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === 'null' ||
    value === 'undefined'
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * @author Nisal Madusanka(EruliaF)
 * @param (string|int|boolean) value
 */
const toBoolean = (value) => {
  if (value === true || value === 1 || value === '1' || value === 'true') {
    return true;
  } else {
    return false;
  }
};

/**
 * @author Chanaka Wickramasinghe
 * @description trim object values
 * @param (object) object
 */
const formObjectToTrimValues = (object) => {
  const keys = Object.keys(object);
  let trimmedObject = {};

  keys.forEach((value) => {
    if (getType(object[value]) === 'string') {
      trimmedObject[value] = object[value].trim();
    } else {
      trimmedObject[value] = object[value];
    }
  });

  return trimmedObject;
};

const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const formObjectValidationHelper = (object, path, additionalMethod) => {
  const data = _.get(object, path, '');

  if (isEmptyValue(data)) {
    return null;
  } else {
    return additionalMethod(data);
  }
};

const convertPhoneNumber = (value) => {
  const charArray = [...value.toString()];

  if (isEmptyValue(value)) {
    return null;
  } else if (charArray[0] === '+') {
    charArray.splice(0, 3);
    return charArray.join('');
  } else {
    return value;
  }
};

const downloadCSV = (csv, filename) => {
  let csvFile;
  let downloadLink;

  csvFile = new Blob([csv], { type: 'text/csv' });
  downloadLink = document.createElement('a');
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

const getCountryCodePrefix = (data = [], id) => {
  const obj = _.find(data, { id: id });
  return obj.prefix;
};

const printModalHelperFn = (divName) => {
  // Create and insert new print section
  let elem = document.getElementById(divName);
  let domClone = elem.cloneNode(true);

  let printSection = document.createElement('div');

  printSection.id = 'printSection';

  printSection.appendChild(domClone);

  let lastChild = document.body.lastChild;
  document.body.insertBefore(printSection, document.body.firstChild);
  document.body.removeChild(document.body.lastChild);

  window.print();

  document.body.appendChild(lastChild);

  // Clean up print section for future use
  let oldElem = document.getElementById('printSection');

  if (oldElem != null) {
    oldElem.parentNode.removeChild(oldElem);
  }
  // oldElem.remove() not supported by IE

  return true;
};

const customerNameShortHelperFn = (name = '') => {
  if (name.length > 15) {
    return `${name.substr(0, 14)}...`;
  } else {
    return name;
  }
};

const formatToDateHelper = (date) => {
  let formatDate = new Date(date);
  formatDate.setHours(23);
  formatDate.setMinutes(59);
  formatDate.setSeconds(59);

  return formatDate.toISOString();
};
const roundValue = (
  value,
  precision = defaultPrecisionPoint,
  type = defaultRoundUpType
) => {
  try {
    let checkType = getType(value);

    if (checkType === 'number' || checkType === 'string') {
      let temp = parseFloat(value);

      switch (type) {
        case roundUpTypes.ceil:
          temp = _.ceil(value, precision);
          break;
        case roundUpTypes.floor:
          temp = _.floor(value, precision);
          break;
        case roundUpTypes.round:
          temp = _.round(value, precision);
          break;
        default:
          let re = new RegExp(
            '^-?\\d+(?:\\.\\d{0,' + (precision || -1) + '})?'
          );
          return temp.toString().match(re)[0];
      }

      return temp.toFixed(precision);
    } else {
      return defaultPrecisionValue;
    }
  } catch (Exception) {
    return defaultPrecisionValue;
  }
};

const removeFeildsFromBulkPrintPackages = (packageObject) => {
  return {
      id: packageObject?.id,
      order: packageObject?.order,
      articleNo: packageObject?.articleNo,
      category: packageObject?.category,
      merchant: packageObject?.merchant,
      merchantBranch: packageObject?.merchantBranch,
      notesPublic: packageObject?.notesPublic
  }
}

const convertToCsvValue = (value) => {
  if (value) {
      let finalString = value.replace(/"/g, '""');
      return finalString
  } else {
      return "-"
  }
}

const clearSelectedPackages = (formState, setBulkValues) => {
  let chkList = get(formState, 'packageListAPIKey', null);
  let selectedKeys = [];
  if (chkList !== null) {
    for (const [key, value] of Object.entries(chkList)) {
      if (value === true) {
        selectedKeys.push(key);
      }
    }
  }

  let selecteditemList = [];
  selecteditemList = selectedKeys.map((item) => {
      if (_.startsWith(item, 'printChk')) {
        let pair = {};
        let value = item;
        pair[value] = false;
        return pair;
      }
      if (_.startsWith(item, 'addChk')) {
        let pair = {};
        let value = item;
        pair[value] = false;
        return pair;
      }
  });
  if (get(formState, 'packageListAPIKey.selectall', null) !== null) {
    let selectAll = {};
    selectAll['selectall'] = false;
    selecteditemList.push(selectAll);
  }
  for (let i = 0; i < selecteditemList?.length; i += 1) {
    setBulkValues("packageListAPIKey", selecteditemList[i])
  }
  sessionStorage.removeItem('selectedPackages')
}

export {
  getType,
  getDataByFormObject,
  fromObjectToQueryString,
  sortObjectToQueryString,
  generateQueryString,
  getInputsForValidate,
  mapInputKey,
  getValueByFilter,
  toBoolean,
  isEmptyValue,
  formObjectToTrimValues,
  isMobile,
  formObjectValidationHelper,
  convertPhoneNumber,
  downloadCSV,
  getCountryCodePrefix,
  printModalHelperFn,
  customerNameShortHelperFn,
  formatToDateHelper,
  roundValue,
  removeFeildsFromBulkPrintPackages,
  convertToCsvValue,
  clearSelectedPackages
};
