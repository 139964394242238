import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputButtonWithState
} from "../../../../components/boda-boda/Forms";
import {
    countryCodeAPI,
    getMerchantBranchAllAPI, getUserStaffList
} from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { updateUserTrFn } from "../../../../store/boda-boda/transaction_request/actions";


class EditUI extends Component {
    render() {
        let { match, formState, history, updateUserTrFn } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getUserStaffList.key}_edit`}
                    apiUrl={`${getUserStaffList.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: response.name,
                            identification: response.identification,
                            merchantBranch: response.merchantBranchId,
                            role: response.user.role.name,
                            credential: response.user.credential,
                            phone: response.phone,
                            email: response.email,
                            category: "MERCHANT",
                            status: response.status,
                            userId: response.userId,
                            staffId: response.id,
                            authEmail: response.email,
                            countryCode: {
                                id: `${get(response, "countryCode.id", "")}/${get(response, "countryCode.code", "")}/${get(response, "countryCode.prefix", "")}`
                            },
                            countryCodePrefix: get(response, `countryCodePrefix`, ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Update a Staff</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <InputButtonWithState
                                    elementWrapperStyle={"mr-2"}
                                    btnText={"Update"}
                                    startIcon={"mdi mdi-content-save-all-outline"}
                                    formGroupName={`${getUserStaffList.key}_edit`}
                                    onClickBtnFn={(dataObject) => {
                                        updateUserTrFn({
                                            ...dataObject.formObject,
                                            history: history
                                        })
                                    }}
                                />

                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/users"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Role</dt>
                                                <dd className={"col-sm-7"}>{get(formState, `${getUserStaffList.key}_edit.role`, "")}</dd>

                                                <dt className={"col-sm-5"}>Status</dt>
                                                <dd className={"col-sm-7"}>{get(formState, `${getUserStaffList.key}_edit.status`, "")}</dd>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Credential</dt>
                                                <dd className={"col-sm-7"}>{get(formState, `${getUserStaffList.key}_edit.credential`, "")}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Name"
                                                placeHolderText={"Name"}
                                                inputName={"name"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                isRequired={true}
                                                maxLength={100}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Identification"
                                                placeHolderText={"Identification"}
                                                inputName={"identification"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                isRequired={true}
                                                maxLength={20}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <div className={"row"}>
                                                <div className={"col-md-3"}>
                                                    <SelectBoxWithState
                                                        isRequired={true}
                                                        labelText="Country Code"
                                                        inputStatePath={`${getUserStaffList.key}_edit.countryCode.id`}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        apiUrl={`${countryCodeAPI.url}/all`}
                                                        apiStateKey={countryCodeAPI.key}
                                                        onRebuildResponseFn={(response) => {
                                                            const data = [];

                                                            response.forEach((value) => {
                                                                data.push({
                                                                    id: `${value.id}/${value.code}/${value.prefix}`,
                                                                    value: value.code
                                                                });
                                                            });

                                                            return {
                                                                "data": {
                                                                    "data": data
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className={"col-md-9"}>
                                                    <InputBoxWithState
                                                        labelText="Phone"
                                                        inputName={"phone"}
                                                        placeHolderText={"Phone"}
                                                        formGroupName={`${getUserStaffList.key}_edit`}
                                                        isRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText="Email"
                                                inputName={"email"}
                                                placeHolderText={"Email"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                maxLength={50}
                                                isRequired={true}
                                            />
                                        </div>


                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText="Branch"
                                                inputName={"merchantBranch"}
                                                placeHolderText={"Branch"}
                                                formGroupName={`${getUserStaffList.key}_edit`}
                                                apiUrl={getMerchantBranchAllAPI.url}
                                                apiStateKey={getMerchantBranchAllAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </FormWrapper>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const UserEdit = connect(mapStateToProps, { updateUserTrFn })(EditUI);

export {
    UserEdit
}
