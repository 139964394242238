import {
    createUserTrKey, updateManifestIdTrKey, redraftManifestTrKey,
    changePasswordTrKey, updateUserProfileTrKey, updateUserTrKey
} from "./actionTypes";


export const createUserTrFn = (dataObj) => {
    return {
        type: createUserTrKey,
        payload: dataObj
    }
}

export const updateManifestId = (id, data, flashMsjObj) => {
    return {
        type: updateManifestIdTrKey,
        id: id,
        payload: data,
        messageObj: flashMsjObj
    }
}

export const redraftManifest = (data) => {
    return {
        type: redraftManifestTrKey,
        payload: data
    }
}

export const updateUserProfileTrFn = (dataObj) => {
    return {
        type: updateUserProfileTrKey,
        payload: dataObj
    }
}

export const changePasswordTrFn = (dataObj) => {
    return {
        type: changePasswordTrKey,
        payload: dataObj
    }
}

export const updateUserTrFn = (dataObj) => {
    return {
        type: updateUserTrKey,
        payload: dataObj
    }
}
