import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../../../../components/boda-boda/CRUD/CrudComponent";
import { getOrdersListAPI, getOrdersItemsListAPI, getPackageListAPI } from "../../../../../../config/boda-boda/apiUrls.config";
import { FormWrapper, InputButton } from "../../../../../../components/boda-boda/Forms";
import { BackBtnComponent } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { SubTab, TabComponent } from "../../../../../../components/boda-boda/Tab/Tab";
import ExpansionPanel from "../../../../../../components/boda-boda/ExpansionPanel/ExpansionPanel";
import UpdateStatusModal from "../../common-includes/UpdateStatusModal";
import {
    currency,
    mapOrderPaymentMethods,
    mapPackageStatusesNames
} from "../../../../../../config/boda-boda/db_config/db_status.config";
import ViewPackage from "../../../../pick-request/includes/common-includes/ViewPackage";
import { RemoveBtn, RemoveMessage } from "../../../../packages/includes/DeleteUI";
import { setFlashMessage } from "../../../../../../store/boda-boda/ui/actions";
import { UpdateOrderAmountModal } from "../../pending-orders/includes/view-includes/UpdateOrderAmount";


class ViewUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            orderPanel: true,
            receiverPanel: false,
            locationPanel: false,
            notePanel: false
        }
    }

    render() {
        let { match, formState, history, setFlashMessage } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getOrdersListAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getOrdersListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            name: response.name,
                            trackingNumber: response.trackingNumber,
                            email: response.email,
                            idVerificationRequired: response.idVerificationRequired,
                            idVerificationType: response.idVerificationType,
                            idVerificationReference: response.idVerificationReference,
                            phone: response.phone,
                            channelOrderNo: response.channelOrderNo,
                            channelOrderRef: response.channelOrderRef,
                            channel: response.channel,
                            status: response.status,
                            deliveryType: response.deliveryType,
                            amount: response.amount,
                            paymentMethod: mapOrderPaymentMethods[response.paymentMethod],
                            shippingLocation: response.shippingLocation,
                            consignmentNo: response.consignmentNo,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            deletedAt: response.deletedAt,
                            signatureRequired: response.signatureRequired,
                            packages: response.packages,
                            contract: response.contract,
                            codSettlementStatus: response.codSettlementStatus,
                            hubSettlement: response.hubSettlement,
                            headOfficeSettlement: response.headOfficeSettlement,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Picked Order</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    path={"/orders/picked"}
                                />

                                {
                                    (get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY) && (get(formState, `${getOrdersListAPI.key}_view.status`) !== "DELIVERED") ? (
                                        <UpdateOrderAmountModal
                                            orderId={match.params.id}
                                            amount={get(formState, `${getOrdersListAPI.key}_view.amount`, "")}
                                            history={history}
                                            path={`/orders/picked/view/${match.params.id}`}
                                        />
                                    ) : null
                                }

                                {
                                    (get(formState, `${getOrdersListAPI.key}_view.packages`, []).length > 0) ? (
                                        <UpdateStatusModal
                                            elementWrapperStyle={""}
                                            orderId={match.params.id}
                                            btnText={"Packed"}
                                            message={"Are you sure you want to update order status into PACKED?"}
                                            type={"packed"}
                                            status={"PACKED"}
                                        />
                                    ) : (
                                        <InputButton
                                            btnText={"Packed"}
                                            startIcon={"far fa-check-circle"}
                                            btnColor={"success"}
                                            onClickBtnFn={() => setFlashMessage({
                                                status: true,
                                                message: "Order should have at least one package",
                                                type: "danger",
                                            })}
                                        />
                                    )
                                }

                                <UpdateStatusModal
                                    elementWrapperStyle={"ml-2"}
                                    startIcon={"fas fa-undo-alt"}
                                    orderId={match.params.id}
                                    btnText={"Move to Pending"}
                                    btnColor={"info"}
                                    message={"Are you sure you want to update order status into PENDING?"}
                                    type={"pending"}
                                    status={"PENDING"}
                                />
                            </div>
                        </Col>
                    </Row>


                    <ExpansionPanel
                        title={"Order Details"}
                        isOpen={this.state.orderPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                orderPanel: !prevState.orderPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Consignment No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.consignmentNo`, "")}</dt>

                                    <dd className={"col-sm-5"}>Channel Order No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channelOrderNo`, "")}</dt>

                                    <dd className={"col-sm-5"}>Channel</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channel.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Channel Order Reference</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.channelOrderRef`, "")}</dt>

                                    <dd className={"col-sm-5"}>Delivery Type</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.deliveryType`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Tracking No</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.trackingNumber`, "-")}</dt>

                                    <dd className={"col-sm-5"}>Payment Method</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "")}</dt>

                                    <dd className={"col-sm-5"}>Signature Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.signatureRequired`, false) === false ? "No" : "Yes"}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "PREPAID") !== "PREPAID") ? (
                                            <Fragment>
                                                <dd className={"col-sm-5"}>Amount</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${getOrdersListAPI.key}_view.amount`, null) === null ? "" : get(formState, `${getOrdersListAPI.key}_view.amount`, "") + ` ${currency}`
                                                    }
                                                </dt>
                                            </Fragment>
                                        ) : null
                                    }

                                    <dd className={"col-sm-5"}>Status</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.status`, "")}</dt>

                                    {get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY && get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED" ?
                                        <Fragment> <dd className={"col-sm-5"}>Cash on Delivery Status</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "")}</dt>
                                        </Fragment> : null}

                                    {get(formState, `${getOrdersListAPI.key}_view.paymentMethod`, "") === mapOrderPaymentMethods.CASH_ON_DELIVERY && get(formState, `${getOrdersListAPI.key}_view.status`, "") === "DELIVERED" ?
                                        <Fragment>
                                            <dd className={"col-sm-5"}>Reference Number</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HUB_SETTLED" ?
                                                get(formState, `${getOrdersListAPI.key}_view.hubSettlement.reference`, "-")
                                                : get(formState, `${getOrdersListAPI.key}_view.codSettlementStatus`, "") === "HEAD_OFFICE_SETTLED" ?
                                                    get(formState, `${getOrdersListAPI.key}_view.headOfficeSettlement.reference`, "-")
                                                    : "N/A"}
                                            </dt>
                                        </Fragment> : null}
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Receiver Details"}
                        isOpen={this.state.receiverPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                receiverPanel: !prevState.receiverPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Customer Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Email</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.email`, "")}</dt>

                                    <dd className={"col-sm-5"}>Phone</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.phone`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>ID Verification Required</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === false ? "No" : "Yes"}</dt>

                                    {
                                        (get(formState, `${getOrdersListAPI.key}_view.idVerificationRequired`, false) === true) ? (
                                            <Fragment>
                                                {
                                                    (get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, null) !== null && get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, null) !== null) ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationType`, "")}</dt>
                                                        </Fragment>
                                                    ) : null
                                                }

                                                {
                                                    (get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, null) !== null) ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                            <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.idVerificationReference`, "")}</dt>
                                                        </Fragment>
                                                    ) : null
                                                }
                                            </Fragment>
                                        ) : null
                                    }
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        title={"Shipping Location"}
                        isOpen={this.state.locationPanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                locationPanel: !prevState.locationPanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Location Name</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>Address</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.address`, "")}</dt>

                                    <dd className={"col-sm-5"}>Postal Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.postalCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Latitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lat`, "")}</dt>

                                    <dd className={"col-sm-5"}>Longitude</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.lon`, "")}</dt>
                                </dl>
                            </div>

                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dd className={"col-sm-5"}>Plus Code</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.plusCode`, "")}</dt>

                                    <dd className={"col-sm-5"}>Province</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.province.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>District</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.district.name`, "")}</dt>

                                    <dd className={"col-sm-5"}>City</dd>
                                    <dt className={"col-sm-7"}>{get(formState, `${getOrdersListAPI.key}_view.shippingLocation.city.name`, "")}</dt>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <ExpansionPanel
                        elementWrapperStyle={"mb-3"}
                        title={"Notes"}
                        isOpen={this.state.notePanel}
                        onClickFn={() => this.setState((prevState) => {
                            return {
                                ...prevState,
                                notePanel: !prevState.notePanel
                            }
                        })}
                    >
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <dl className={"row"}>
                                    <dt className={"col-sm-3"}>Public Note</dt>
                                    <dd className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPublic`, "")}</dd>

                                    <dt className={"col-sm-3"}>Private Note</dt>
                                    <dd className={"col-sm-9"}>{get(formState, `${getOrdersListAPI.key}_view.notesPrivate`, "")}</dd>
                                </dl>
                            </div>
                        </div>
                    </ExpansionPanel>

                    <TabComponent
                        activeTab={this.state.activeTab}
                        onClickFn={(tab) => this.setState((prevState) => {
                            return {
                                ...prevState,
                                activeTab: tab
                            }
                        })}
                    >
                        <SubTab tabId={0} tabName={"Order Items"}>
                            <div className="row">
                                <CrudComponent
                                    title="Items"
                                    apiURL={`${getOrdersItemsListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                                    dataTableStateKey={`${getOrdersItemsListAPI.key}_orderItems`}
                                    isFilter={false}
                                    isSetAction={false}
                                    isSetCreateAction={false}
                                    tableHeaderList={[
                                        { displayName: "Product No", key: "productNo" },
                                        { displayName: "Name", key: "name" },
                                        { displayName: "Quantity", key: "quantity" },
                                        { displayName: "Status", key: "status" }
                                    ]}
                                />
                            </div>
                        </SubTab>

                        <SubTab tabId={1} tabName={"Packages"}>
                            <div className={"row"}>
                                <CrudComponent
                                    title="Packages"
                                    apiURL={`${getPackageListAPI.url}?page=1&limit=20&orderId=${match.params.id}`}
                                    dataTableStateKey={getPackageListAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={
                                        get(formState, `${getOrdersListAPI.key}_view.contract.maxPackageCount`) ? get(formState, `${getOrdersListAPI.key}_view.packages`, []).length < get(formState, `${getOrdersListAPI.key}_view.contract.maxPackageCount`) : true}
                                    createActionProps={{
                                        actionType: actionBtnTypes.create,
                                        isSetModel: false,
                                        onClickBtnFn: () => history.push(`/orders/picked/create/${match.params.id}`)
                                    }}
                                    isSetAction={true}
                                    tableHeaderList={[
                                        { displayName: "Article No", key: "articleNo" },
                                        { displayName: "Customer Name", key: "order.name", default: "-" },
                                        { displayName: "Delivery Type", key: "deliveryType" },
                                        { displayName: "Payment Method", key: "order.paymentMethod" },
                                        { displayName: "Package Category", key: "category.name" },
                                        { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                                    ]}
                                    actionList={[
                                        {
                                            actionType: actionBtnTypes.view,
                                            isSetModel: true,
                                            modalProps: {
                                                modalTitle: "View Package",
                                                modalType: "modal-lg",
                                                modalBodyComponent: ViewPackage,
                                                isSetClose: true,
                                                isSetFooterClose: true,
                                            }
                                        },
                                        {
                                            actionType: actionBtnTypes.edit,
                                            isSetModel: false,
                                            actionBtn: {
                                                onClickBtnFn: (rawData) => history.push(`/package/packages/edit/${rawData.id}`),
                                            }
                                        },
                                        {
                                            actionType: actionBtnTypes.delete,
                                            isSetModel: true,
                                            modalProps: {
                                                modalTitle: "Please Confirm",
                                                modalType: "",
                                                modalBodyComponent: RemoveMessage,
                                                modalFooterComponent: RemoveBtn,
                                                isSetClose: true,
                                                isSetFooterClose: true,
                                                parentToModelProps: {
                                                    type: "datatable"
                                                },
                                            },
                                            checkBeforeActionRenderFn: (rawData) => {
                                                return rawData.rowData.deletedAt === null && rawData.rowData.manifest === null;
                                            }
                                        }
                                    ]}
                                />
                            </div>
                        </SubTab>
                    </TabComponent>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, { setFlashMessage })(ViewUI);

export {
    ViewUIWithState
};
