import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { get } from "lodash";

import { FormWrapper, InputBoxWithState, SubmitButton } from "../../../components/boda-boda/Forms";
import { forgotPasswordResetAPI } from "../../../config/boda-boda/apiUrls.config";
import { SnackbarList } from "../../../components/boda-boda/Snackbar/Snackbar";
import { apiResponseStoringType } from "../../../config/boda-boda/apiResponseKey";
import Loader from "../../../components/Loader";
import { removeFromGroupFn } from "../../../store/boda-boda/form/actions";
import logo from "../../../assets/images/boda-logo3.png";


class ForgetPasswordResetPage extends Component {
  render() {
    let { formData, uiState, history, removeFromGroupFn } = this.props;

    return (

        <React.Fragment>
          {
            (get(formData, "forgotPasswordFrom", null) === null) ? (
                <Redirect to={"/forgot-password"} />
            ) : (
                <React.Fragment>
                  {
                    (uiState.setPageLoader === true) ? (
                        <Loader />
                    ) : null
                  }
                  <SnackbarList />
                  <div className="account-pages my-5 pt-5">
                    <div className="container">
                      <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                          <div className="position-relative">
                            <Card className="overflow-hidden">
                              <div className="bg-primary">
                                <div className="text-primary text-center p-4">
                                  <h5 className="text-white font-size-20">
                                    Forgot Password
                                  </h5>
                                  <p className="text-white-50">
                                    Enter new password to reset.
                                  </p>
                                  <Link to="/" className="logo logo-admin">
                                    <img src={logo} height="50" alt="logo" />
                                  </Link>
                                </div>
                              </div>

                              <CardBody className="p-4">
                                <div className="p-3">

                                  <div className="form-horizontal mt-4">

                                    <FormWrapper
                                        setGroupName={`forgotPasswordResetFrom`}
                                    >
                                      <InputBoxWithState
                                          labelText="New Password"
                                          formGroupName={`forgotPasswordResetFrom`}
                                          inputName={"password"}
                                          placeHolderText={"Enter New Password"}
                                          inputType={"password"}
                                      />
                                      <InputBoxWithState
                                          labelText="Confirm Password"
                                          formGroupName={`forgotPasswordResetFrom`}
                                          inputName={"confirm_password"}
                                          placeHolderText={"Re-type New Password"}
                                          inputType={"password"}
                                      />


                                    </FormWrapper>

                                    <div className="row">
                                      <Col sm={6}></Col>
                                      <Col sm={6} className="text-right">
                                        <SubmitButton
                                            btnText={"Save"}
                                            elementStyle="btn btn-primary w-md waves-effect waves-light"
                                            formGroupName={`forgotPasswordResetFrom`}
                                            dataTableKey={forgotPasswordResetAPI.key}
                                            isValidate={true}
                                            elementWrapperStyle={"mt-3"}
                                            flashMessages={{
                                              "success": {
                                                status: true,
                                                message: "Password has successfully updated",
                                                type: "success"
                                              },
                                            }}
                                            validationObject={{
                                              fields: {
                                                password: "New Password",
                                                confirm_password: "Confirm Password",
                                              },
                                              rules: {
                                                password: "required|passwordPolicy",
                                                confirm_password: "required|same:password"
                                              },
                                              message: {}
                                            }}
                                            callApiObject={{
                                              isSetHeaders: true,
                                              multipart: false,
                                              method: "post",
                                              onUpload: false
                                            }}
                                            apiDataStoringObject={{
                                              setLoader: true,
                                              storingType: apiResponseStoringType.apiRequestOnly,
                                              mergeToSuccessResponse: null,
                                              mergeToErrorResponse: null,
                                            }}
                                            onGetAPIEndPointFn={(formObject) => {
                                              return {
                                                url: `${forgotPasswordResetAPI.url}`,
                                                key: forgotPasswordResetAPI.key
                                              }
                                            }}
                                            onChangeRequestBodyFn={(formObject) => {
                                              return {
                                                "credential": get(formData, "forgotPasswordFrom.credential", ""),
                                                "pin": parseInt(get(formData, "forgotPasswordFrom.pin", "")),
                                                "password": formObject.password,
                                                "confirm_password": formObject.confirm_password,
                                              }
                                            }}
                                            onResponseCallBackFn={(error, result) => {
                                              removeFromGroupFn("forgotPasswordFrom");
                                              removeFromGroupFn("forgotPasswordOTPFrom");

                                              if (!error) {
                                                history.push(`/login`);
                                              }
                                            }}
                                        />
                                      </Col>
                                    </div>

                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                              <p>
                                Go back to{" "}
                                <Link
                                    to="/login"
                                    className="font-weight-medium text-primary"
                                >
                                  Login
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
            )
          }
        </React.Fragment>

    );
  }
}

const mapStatetoProps = state => {
  return {
    formData: state.FormData,
    uiState: state.UIState
  };
};

export default withRouter(
    connect(mapStatetoProps, { removeFromGroupFn })(ForgetPasswordResetPage)
);
