import _, { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap"
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { getPackageListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { resetDataTable, setBulkValues, setFlashMessage, setPageLoader } from "../../../../../store/actions";

const BulkAddLabelBtn = (props) => {

    const formState = useSelector((state) => state.FormData);
    const packages = useSelector(
        (state) => state?.CoreState?.dataTableResponses?.packageListAPIKey?.results
    );
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { id } = useParams();

    const getSelectedPackages = () => {
        const tempArray = JSON.parse(sessionStorage.getItem('selectedPackages'));
        const tempBulkPrint = tempArray ? tempArray : [];
        let selecteditemList = [];
        const chkList = get(formState, 'packageListAPIKey', null);

        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'addChk')) {
                return item.replace('addChk', '');
            }
        });

        selecteditemList = selecteditemList.filter(function (element) {
            return element !== undefined;
        });

        let selectedObjects = [];
        if (tempBulkPrint !== null) {
            for (let i = 0; i < tempBulkPrint?.length; i += 1) {
                for (let j = 0; j < selecteditemList?.length; j += 1) {
                    if (tempBulkPrint[i]?.id == selecteditemList[j]) {
                        selectedObjects.push(tempBulkPrint[i]);
                    }
                }
            }
        }

        return selectedObjects;
    };

    const clearSelectedPackages = () => {
        let chkList = get(formState, 'packageListAPIKey', null);
        let selectedKeys = [];
        if (chkList !== null) {
            for (const [key, value] of Object.entries(chkList)) {
                if (value === true) {
                    selectedKeys.push(key);
                }
            }
        }

        let selecteditemList = [];
        selecteditemList = selectedKeys.map((item) => {
            if (_.startsWith(item, 'addChk')) {
                let pair = {};
                let value = item;
                pair[value] = false;
                return pair;
            }
        });
        if (get(formState, 'packageListAPIKey.selectall', null) !== null) {
            let selectAll = {};
            selectAll['selectall'] = false;
            selecteditemList.push(selectAll);
        }

        for (let i = 0; i < selecteditemList?.length; i += 1) {
            dispatch(setBulkValues("packageListAPIKey", selecteditemList[i]))
        }
    }

    const addPackages = async () => {
        try {
            dispatch(setPageLoader(true));

            const selectedPackages = getSelectedPackages();
            if (selectedPackages?.length === 0) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: 'No packages selected',
                        type: 'danger',
                    })
                );
                setShow(false);
                dispatch(setPageLoader(false));
                return;
            }


            const body = {
                "manifest": {
                    "id": Number(id)
                }
            }
            let isError = false;
            for (const selectedPackage of selectedPackages) {
                const packageAPIResponse = await callApi(
                    `${getPackageListAPI.url}/${selectedPackage?.id}`
                )
                    .headers(true)
                    .method('put')
                    .body(body)
                    .send();
                if (packageAPIResponse._statue) {
                    dispatch(
                        setFlashMessage({
                            status: true,
                            message: `Package with article number ${selectedPackage?.articleNo} successfully added`,
                            type: 'success',
                        })
                    );
                } else {
                    isError = true;
                    break;
                }

            }

            dispatch(resetDataTable(`${getPackageListAPI.key}_manifest`));

            setShow(false);
            dispatch(setPageLoader(false));

            if (isError) {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: "Something went wrong!!",
                        type: "danger"
                    })
                );
            } else {
                dispatch(
                    setFlashMessage({
                        status: true,
                        message: 'Packages added successfully',
                        type: 'success',
                    })
                );
                clearSelectedPackages();
                sessionStorage.removeItem('selectedPackages')
            }

        } catch (error) {
            setShow(false);
            dispatch(setPageLoader(false));

            dispatch(
                setFlashMessage({
                    status: true,
                    message: 'An error has occurred',
                    type: 'danger',
                })
            );
        }
    }

    return (
        <React.Fragment>
            <Button
                color='primary'
                className='btn btn-primary waves-effect waves-light'
                onClick={() => setShow(true)}
            // disabled={getSelectedPackages()?.length === 0}
            >
                <i className='mdi mdi-plus-circle-outline mr-2'></i>
                Add Selected
            </Button>
            <ModalUI
                isOpen={show}
                modalTitle={'Please Confirm'}
                modalBodyComponent={`Are you sure you want to add ${getSelectedPackages()?.length
                    } packages`}
                showHideToggleFn={() => setShow(false)}
                closeBtnName='No'
                modalFooterComponent={
                    <Button
                        color='primary'
                        onClick={() => {
                            addPackages()
                        }
                        }
                    >
                        Yes
                    </Button>
                }
            />
        </React.Fragment>
    )
}

export default BulkAddLabelBtn;