import React, { Component, Fragment, useEffect } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import JsBarcode from "jsbarcode";

import { modalTypes, ModalUI } from "../../../../../../../components/boda-boda/Modal/ModalUI";
import { InputButton, FormWrapper } from "../../../../../../../components/boda-boda/Forms";
import PrintLabelBtn from "../../../../../packages/includes/PrintLabelBtn";
import { getOrdersItemsListAPI, getOrdersListAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { CrudComponent } from "../../../../../../../components/boda-boda/CRUD/CrudComponent";


class PickingSlip extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {
        return (
            <Fragment>
                <InputButton
                    elementWrapperStyle={"mr-2"}
                    btnText={"Picking Slip"}
                    startIcon={"fas fa-receipt"}
                    onClickBtnFn={() => this.setState({ isShow: true })}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Picking Slip"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<PickingSlipView orderId={this.props.orderId} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintLabelBtn type={"create"} />
                    }
                />
            </Fragment>
        )
    }
}

const PickingSlipView = (props) => {
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, "pickingSlipView._onLoad", true) === false) {
            JsBarcode("#pickingSlipBarcode", get(formState, `pickingSlipView.consignmentNo`, ""), {
                height: 50,
                margin: 0,
                marginBottom: 10,
            });
        }
    }, [
        get(formState, "pickingSlipView._onLoad", true)
    ])

    return (
        <div id={"print_preview"}>
            <div className={"printPreviewWrapper"}>
                <FormWrapper
                    setGroupName={"pickingSlipView"}
                    apiUrl={`${getOrdersListAPI.url}/${get(props, `orderId`, "")}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            consignmentNo: response.consignmentNo,
                            orderItems: response.orderItems,
                            notesPublic: response.notesPublic,
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Order ID</dd>
                                <dt className={"col-sm-7"}>{get(props, `orderId`, "")}</dt>

                                <dd className={"col-sm-5"}>Consignment No</dd>
                                <dt className={"col-sm-7"}>{get(formState, `pickingSlipView.consignmentNo`, "")}</dt>
                            </dl>
                        </div>

                        <div className={"col-md-6 text-center"}>
                            <img id="pickingSlipBarcode" />
                        </div>
                    </div>

                    <div className="row">
                        <CrudComponent
                            title="Item List"
                            apiURL={`${getOrdersItemsListAPI.url}/all?&orderId=${props.orderId}`}
                            dataTableStateKey={getOrdersItemsListAPI.key}
                            isFilter={false}
                            isSetCreateAction={false}
                            isPagingShow={false}
                            isSetAction={false}
                            tableHeaderList={[
                                { displayName: "Product No", key: "productNo" },
                                { displayName: "Name", key: "name" },
                                { displayName: "Quantity", key: "quantity" },
                            ]}
                            //tableBodyList={get(formState, `pickingSlipView.orderItems`, [])}
                        />
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-2"}>Notes</dd>
                                <dt className={"col-sm-10"}>
                                    <span style={{ marginLeft: "32px" }}>{get(formState, `pickingSlipView.notesPublic`, "")}</span>
                                </dt>
                            </dl>
                        </div>
                    </div>

                </FormWrapper>
            </div>
        </div>
    )
}

export default PickingSlip;
