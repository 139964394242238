import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { InputButton, SubmitButton } from "../../../../../components/boda-boda/Forms";
import { getOrdersListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";


const emptyFn = (...para) => undefined;

const UIMessage = ({
    message = ""
}) => {
    return (
        <p>{message}</p>
    )
}

const UpdateBtn = ({
    type = "picked",
    status = "",
    orderId = "",
    setModelStatusFn = emptyFn,
    history = emptyFn
}) => {
    return (
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getOrdersListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Status has successfully updated",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${orderId}`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    "status": status
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    setModelStatusFn(false);
                    history.push(`/orders/${type}`);
                }
            }}
        />
    )
}

const UpdateStatusModal = ({
    elementWrapperStyle = "mr-2",
    startIcon = "far fa-check-circle",
    btnText = "Picked",
    btnColor = "success",
    message = "",
    type = "picked",
    status = "",
    orderId = "",
    history = emptyFn
}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                elementWrapperStyle={elementWrapperStyle}
                btnText={btnText}
                startIcon={startIcon}
                btnColor={btnColor}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<UIMessage message={message} />}
                modalFooterComponent={<UpdateBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={type}
                    status={status}
                    orderId={orderId}
                    history={history}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export default withRouter(UpdateStatusModal);
