import { apiBaseUrl } from "./core.config";

export const loginAPI = {
  url: `${apiBaseUrl}auth/login`,
  key: "loginAPIKEY",
};

export const getRequirementsAPI = {
  url: `http://192.168.1.70:81/experince/api/inspection-service/requirements`,
  key: "requirementsAPIKEY",
};

export const createRequirementsAPI = {
  url: `http://192.168.1.70:81/inspection/api/inspection-service/v1/requirements`,
  key: "requirementsnewAPIKEY",
};

export const getGMPList = {
  url: "http://192.168.1.70:81/resource/api/resource-management-service/v1/elements/get",
  key: "gmpElementsListKEY",
};

export const getOrdersListAPI = {
  url: `${apiBaseUrl}order`,
  key: "ordersListAPIKey",
};

export const getOrderImageAPI = {
  url: `${apiBaseUrl}order-image`,
  key: "orderImageAPIKey",
};

export const getOrdersItemsListAPI = {
  url: `${apiBaseUrl}order-item`,
  key: "ordersItemsListAPIKey",
};

export const getPackageTypesListAPI = {
  url: `${apiBaseUrl}package-type`,
  key: "packageTypesListAPIKey",
};

export const getPackageCategoryListAPI = {
  url: `${apiBaseUrl}package-category/all`,
  key: "packageCategoryListAPIKey",
};

export const getRoleListAPI = {
  url: `${apiBaseUrl}role`,
  key: "roleListAPIKey",
};

export const getPackageListAPI = {
  url: `${apiBaseUrl}package`,
  key: "packageListAPIKey",
};

export const getPackageStatusListAPI = {
  url: `${apiBaseUrl}package-status`,
  key: "packageStatusListAPIKey",
};

export const getManifestListAPI = {
  url: `${apiBaseUrl}manifest`,
  key: "manifestListAPIKey",
};

export const getChannelListAPI = {
  url: `${apiBaseUrl}channel`,
  key: "channelListAPIKey",
};

export const getCityListAPI = {
  url: `${apiBaseUrl}city`,
  key: "cityListAPIKey",
};

export const getReasonListAPI = {
  url: `${apiBaseUrl}reason`,
  key: "reasonListAPIKey",
};

export const getUserStaffList = {
  url: `${apiBaseUrl}staff`,
  key: "getUserStaffListAPIKey",
};

export const getAllLocationListAPI = {
  url: `${apiBaseUrl}location/all`,
  key: "getAllLocationListKey",
};

export const createAuthUserAPI = {
  url: `${apiBaseUrl}user`,
  key: "createAuthUserAPIKey",
};

export const getMerchantBranchAllAPI = {
  url: `${apiBaseUrl}merchant-branch/all`,
  key: "getMerchantBranchAllAPIKey",
};

export const authProfileAPI = {
  url: `${apiBaseUrl}auth/me`,
  key: "authProfileKey",
};

export const merchantBranchAPI = {
  url: `${apiBaseUrl}merchant-branch`,
  key: "getMerchantBranchAPIKey",
};

export const merchantBranchContactAPI = {
  url: `${apiBaseUrl}merchant-branch-contact`,
  key: "getMerchantBranchContactAPIKey",
};

export const getMerchantContactAPI = {
  url: `${apiBaseUrl}merchant-contact`,
  key: "getMerchantContactAPIKey",
};

export const merchantAPI = {
  url: `${apiBaseUrl}merchant`,
  key: "merchantAPIKey",
};

export const merchantContractAPI = {
  url: `${apiBaseUrl}merchant-contract`,
  key: "getMerchantContractAPIKey",
};

export const forgotPasswordAPI = {
  url: `${apiBaseUrl}user/forgot-password`,
  key: "forgotPasswordAPIKey",
};
export const forgotPasswordOTPEmailAPI = {
  url: `${apiBaseUrl}user/get-pin-email`,
  key: "forgotPasswordOTPEmailAPIKey",
};
export const forgotPasswordOTPSMSAPI = {
  url: `${apiBaseUrl}user/get-pin-sms`,
  key: "forgotPasswordOTPSMSAPIKey",
};
export const forgotPasswordOTPVerifyAPI = {
  url: `${apiBaseUrl}user/verify-pin`,
  key: "forgotPasswordOTPVerifyAPIKey",
};
export const forgotPasswordResetAPI = {
  url: `${apiBaseUrl}user/reset-password`,
  key: "forgotPasswordResetAPIKey",
};

export const changePasswordAPI = {
  url: `${apiBaseUrl}user/change-password`,
  key: "changePasswordAPIKey",
};

export const fileUploadAPI = {
  url: `${apiBaseUrl}${process.env.REACT_APP_UPLOAD_URL}`,
  key: "fileUploadAPIKey",
};

export const orderCSVUploadAPI = {
  url: `${apiBaseUrl}upload-file`,
  key: "orderCSVUploadAPIKey",
};

export const reconciliationReportAPI = {
  url: `${apiBaseUrl}report/delivery-reconciliation`,
  key: "reconciliationReportAPIKey",
};

export const cashOnDeliveryReportAPI = {
  url: `${apiBaseUrl}report/cash-or-card-on-delivery`,
  key: "cashOnDeliveryReportAPIKey",
};

export const countryCodeAPI = {
  url: `${apiBaseUrl}country-code`,
  key: "countryCodeAPIKey",
};

export const getDistrictListAPI = {
  url: `${apiBaseUrl}district/all`,
  key: "getDistrictListAPIKey",
};
export const facilityRateAPI = {
  url: `${apiBaseUrl}facility-rate`,
  key: "facilityRateAPIKey",
};

export const facilityWeightRateAPI = {
  url: `${apiBaseUrl}facility-weight-rate`,
  key: "facilityWeightRateAPIKey",
};
export const merchantBranchRateAPI = {
  url: `${apiBaseUrl}merchant-branch-rate`,
  key: "merchantBranchRateAPIKey",
};
export const merchantBranchWeightRateAPI = {
  url: `${apiBaseUrl}merchant-branch-weight-rate`,
  key: "merchantBranchWeightRateAPIKey",
};

export const pickUpDeliveryGraphAPI = {
  url: `${apiBaseUrl}dashboard/pickedup-delivered-graph`,
  key: "pickUpDeliveryGraphAPIKey",
};

export const shipmentDataAPI = {
  url: `${apiBaseUrl}dashboard/shipment-data`,
  key: "shipmentDataAPIKey",
};
export const codTransactionDataAPI = {
  url: `${apiBaseUrl}dashboard/cod-transaction-data`,
  key: "codTransactionDataAPI",
};

export const packageStatusReportAPI = {
  url: `${apiBaseUrl}report/package-status`,
  key: "packageStatusReportAPIKey"
}

export const getAllFacilityAPI = {
  url: `${apiBaseUrl}facility/all`,
  key: "getAllFacilityAPIKey"
}

export const getUserDriverList = {
  url: `${apiBaseUrl}driver`,
  key: "getUserDriverListAPIKey"
}

export const getZoneAPI = {
  url: `${apiBaseUrl}zone`,
  key: "getZoneAPIKey"
}

export const getMerchantBankAllAPI = {
  url: `${apiBaseUrl}merchant-bank/all`,
  key: "getMerchantBankAllAPIKey"
}

export const merchantSettlementReportAPI = {
  url: `${apiBaseUrl}report/merchant-settlement-transaction`,
  key: "merchantSettlementReportAPIKey",
};

export const headOfficeSettlementAPI = {
  url: `${apiBaseUrl}head-office-settlement`,
  key: "headOfficeSettlementAPIKey",
};
