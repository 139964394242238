import React, { Component } from "react";
import { Col, Row, Label } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { InputButton } from "../../../components/boda-boda/Forms";
import { BackBtnComponent } from "../../../components/boda-boda/CommonElements/BaseElements";
import { fileGetAPIUrl } from "../../../config/boda-boda/core.config";


class UserProfile extends Component {
    render() {
        let { history, authState } = this.props;

        return (
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View User Profile</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <BackBtnComponent
                                elementWrapperStyle={"mr-2"}
                                path={"/"}
                            />

                            <InputButton
                                btnText={"Edit"}
                                startIcon={"mdi mdi-square-edit-outline"}
                                onClickBtnFn={() => history.push(`/user-profile/edit`)}
                            />

                        </div>
                    </Col>
                </Row>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">

                                <div className="row">
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>User ID</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.userId`, "")}</dt>

                                            <dd className={"col-sm-4"}>User Role</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.role.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Status</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.status`, "")}</dt>

                                            <dd className={"col-sm-4"}>Branch</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.merchantBranch.name`, "-")}</dt>
                                        </dl>
                                    </div>

                                    {
                                        (get(authState, `authUser.staff.image`, null) !== null) ? (
                                            <div className={"col-md-6"}>
                                                <div>
                                                    <Label>Profile Picture</Label>
                                                </div>

                                                <div>
                                                    <img
                                                        className={"img-thumbnail user-profile-picture"}
                                                        src={`${fileGetAPIUrl}${get(authState, `authUser.staff.image`, "")}`}
                                                    />
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Personal Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Name</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.name`, "")}</dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Identification</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.identification`, "")}</dt>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Contact Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>E-mail</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.email`, "")}</dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Phone</dd>
                                            <dt className={"col-sm-8"}>{get(authState, `authUser.staff.phone`, "")}</dt>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, null)(UserProfile);
