import React, { Fragment, useState } from "react";
import {
    CheckBoxWithState,
    FormWrapper,
    InputButton,
} from "../../../components/boda-boda/Forms";
import {
    ModalUI,
    modalTypes,
} from "../../../components/boda-boda/Modal/ModalUI";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TermsAndConditionUIMessage = ({ isShowRequired }) => {
    const [isShow, setIsShow] = useState(false);
    return (
        <React.Fragment>
            <FormWrapper
                setGroupName={`confirmation`}
                onDestroyUnsetFormObject={true}
            >
                <CheckBoxWithState
                    inputName={"termsAndConditions"}
                    formGroupName={`confirmation`}
                    labelText={
                        <p>
                            I agree to the{" "}
                            <button
                                className="terms_and_condition_btn"
                                onClick={() => setIsShow(true)}
                            >
                                terms and conditions
                            </button>
                            {" "}of BodaBoda (Pvt) Ltd.
                            {isShowRequired && <span style={{ color: 'red' }}>*</span>}
                        </p>
                    }
                />
            </FormWrapper>

            <ModalUI
                isOpen={isShow}
                modalTitle={"TERMS & CONDITIONS"}
                modalType={modalTypes.large}
                modalBodyComponent={<TermsAndConditionView />}
                showHideToggleFn={() => setIsShow(false)}
                modalFooterComponent={<DownloadPDFBtn />}
            />
        </React.Fragment>
    );
};

const TermsAndConditionView = () => {
    return (
        <Fragment>
            <div className="terms_and_condition_container">
                <div className="terms_and_condition_line">
                    <p>
                        By clicking “Accept”, logging into and utilising the Boda Boda Merchant Portal, you (hereinafter
                        referred to as the <strong>"Merchant"</strong> which term or expression shall, where the context so requires
                        or admits, mean and include you, your successors and permitted assigns) are accepting the
                        Terms and Conditions and entering into a contract as morefully described in clause 3.1 below,
                        with us, <strong>Boda Boda (Pvt) Ltd (PV00228903)</strong>, a Company duly incorporated under the
                        Companies Act No.07 of 2007, and having its’ registered office at No.7, Suleiman Terrace,
                        Colombo 5 in the Democratic Socialist Republic of Sri Lanka (hereinafter called and referred to
                        as <strong>"Boda Boda"</strong> which term or expression shall, where the context so requires or admits, mean
                        and include us, oursuccessors and permitted assigns), in relation to Services (as defined below)
                        in respect of the Cargo (as defined below)
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p>
                        In consideration of each of the premises and mutual covenants contained herein and for good
                        and valuable consideration, the receipt and adequacy of which are hereby acknowledged, you
                        and we agree as follows:-
                    </p>
                </div>
                <hr></hr>
                <h6>1. Definitions</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">1.1</p>
                    <p>
                        In these terms and conditions, unless the contrary intention appears:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Accepted Pick Request</strong> has the meaning given to that
                            term in clause 5.25.2.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Authority to Leave</strong> has the meaning given to that term in clause 6.4.3.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Business Days</strong> means a day that is not a Saturday, Sunday, Poya day or any other day
                            which is a public holiday or a bank holiday in Sri Lanka. </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Cargo</strong> means the goods or products or items described in an Accepted Pick Request
                            for which delivery services shall be provided by Boda Boda in terms of these terms
                            and conditions.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Claims</strong> includes actions, proceedings, suits, causes of action, arbitrations, verdicts and
                            judgments either at law or in equity or arising under a statute, debts, dues, demands,
                            claims of any nature, costs, and expenses.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Conforming Contact Number</strong> means a contact number provided to Boda Boda by the
                            Merchant in a format specified by Boda Boda. </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Consequential Loss</strong> means any loss or damage which does not arise in the ordinary
                            course of events from a breach, including, without limitation, any loss of opportunity,
                            business, goodwill or future reputation, any failure to realise anticipated savings, any
                            damage to credit rating, and any penalties payable under any contracts, and including,
                            for the avoidance of doubt:
                        </p>
                    </div>
                    <div className="ml-3">
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(a) loss of or damage to data or revenue;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(b) business interruption;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(c) loss, damage, costs or expense incurred by the Merchant in connection with
                                a third-party claim relating to the Services;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(d) cost or expenses relating to or in connection with the procurement of
                                replacement Cargo or services; or</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(e) other loss or damage that was in the reasonable contemplation of the parties
                                at the time of entry into the Services Contract.</p>
                        </div>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Control</strong> means and includes Shareholder control and/or control via a majority seats
                            in the Board of Directors.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Delay Fee</strong> means the amount payable by the Merchant to Boda Boda pursuant to
                            clause 6.3.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Delivery Address</strong> means the address specified in an Accepted Pick Request, or such
                            other address as may be agreed in writing between Boda Boda and the Merchant from
                            time to time.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Delivery Fee</strong> means the amount payable by the Merchant to Boda Boda pursuant to
                            clause 7.1.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Delivery Period</strong> means the period commencing on the time the Cargo are picked up
                            by Boda Boda and ending on the time the Cargo are delivery at the Deliver Address.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Estimated Delivery Time frame</strong> means the estimated delivery timeframe specified in
                            an Accepted Pick Request.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Fee List</strong> means the fee list for the Services published by Boda Boda from to time and
                            notified by Boda Boda to the Merchant either via the Merchant Portal or via email or
                            otherwise.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Fees</strong> means the amounts payable by the Merchant to Boda Boda in connection with
                            provision of Services under the Services Contract, including the Pick-up Fee, Delay Fee,
                            Futile Pick-up Fee, Delivery Fee, Return Fee and any additional charges.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Futile Pick-up Fee</strong> means the amount payable by the Merchant to Boda Boda
                            pursuant to clause 6.6.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Insolvency Event</strong> means, in relation to a person, any of the following:</p>
                    </div>
                    <div className="ml-3">
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(g) the person becomes insolvent;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(h) the person assigns any of its property for the benefit of creditors or any class
                                of them;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(i) a receiver, receiver and manager, administrator, controller, provisional
                                liquidator or liquidator is appointed to the person or the person enters into
                                a scheme of arrangement with its creditors or is wound up;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(j) the holder of a security interest takes possession of any assets of the person
                                or exercises any power of sale;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(k) a judgment or order is made against the person in an amount exceeding Rs.
                                5 million and that judgment or order is not appealed or satisfied; and</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(l) any event that is analogous or has a substantially similar effect to any of the
                                events specified in this definition.</p>
                        </div>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Initial Proposal</strong> means the pricing proposal provided by Boda Boda and accepted by
                            the Merchant prior to the commencement of the provision of the Services by Boda
                            Boda.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">Law includes any and all applicable legislation, ordinance, regulation, bylaw, local law,
                            proclamation, approval, authorization, code of conduct, government policy,
                            exemption, licence, permit, registration, waiver and conditions attaching to any of
                            them (as applicable) by any authority in effect in Sri Lanka from time to time and
                            includes any renewal of, amendment or variation to, any of them.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Liability</strong> includes all liabilities (whether actual, contingent, or prospective direct
                            and/or indirect), losses, damages, costs, and expenses of whatever description and
                            howsoever incurred.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Merchant Portal</strong> means the Web-based portal to which the Merchant is provided
                            access, to be utilized by the Merchant to initiate a Pick Request.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Minimum Delivery Quantity</strong> means the minimum delivery quantity specified in an
                            Accepted Pick Request as amended in accordance with clause 5.6.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Nominated Pick-up Location</strong> means the location from which Boda Boda is to pick-up
                            the Cargo as specified in an Accepted Pick Request.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Nominated Pick-up Time</strong> means the pick-up time specified in an Accepted Pick
                            Request, as varied by Boda Boda from time to time in accordance with clause 6.3.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Package</strong> shall mean and include the Cargo that Boda Boda has issued an accepted Pick
                            Request to deliver.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Pick-up Fee</strong> means the amount payable by the Merchant to Boda Boda pursuant to
                            clause 6.1.
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Pick Request</strong> has the meaning given to that term in clause 5.1.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Price List</strong> means the price list for the delivery services to be provided Cargo published
                            by Boda Boda on the Merchant Portal from to time and or notified by Boda Boda to
                            the Merchant (whether by email or otherwise).</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Return Fee</strong> means the amount payable by the Merchant to Boda Boda pursuant to
                            clause 7.9.2, 0 or 13.3.2.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Return Location</strong> means the location for which the Cargo are to be return if
                            undelivered, as specified in an Accepted Pick Request.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Service Contract</strong> means the contract between the Parties in respect of the Services
                            as morefully described in clause 3.1.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Services</strong> means the services to be provided by the Boda Boda under the Service
                            Contract and Accepted Pick Request as further described in the Initial Proposal.</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1"><strong>Terms and Conditions</strong> means the terms and conditions set out below.</p>
                    </div>
                </div>
                <hr></hr>
                <h6>2. Interpretation</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">2.1</p>
                    <p>
                        In these Terms and Conditions, unless the contrary intention appears:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.1</p>
                        <p>the clause headings are for ease of reference only and shall not be relevant
                            to interpretation;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.2</p>
                        <p>a reference to a clause number is a reference to its subclauses;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.3</p>
                        <p>words in the singular number include the plural and vice versa;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.4</p>
                        <p>words importing a gender include any other gender; </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.5</p>
                        <p>
                            the word 'includes' in any form is not a word of limitation;
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.6</p>
                        <p>a reference to a person includes bodies corporate and unincorporated
                            associations and partnerships;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.7</p>
                        <p>a reference to a clause is a reference to a clause or subclause of the Terms
                            and Conditions;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.8</p>
                        <p>a reference to a subclause is a reference to a subclause of the clause in which
                            that reference is made;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.9</p>
                        <p>where a word or phrase is given a particular meaning, other parts of speech
                            and grammatical forms of that word or phrase have corresponding
                            meanings;
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">2.1.10</p>
                        <p>monetary references are references to Sri Lankan currency.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">2.2</p>
                    <p>
                        In the interpretation of the Terms and Conditions, no rule of construction applies to
                        the disadvantage of the party preparing the document on the basis that it prepared
                        or put forward this document or any part of it.{" "}
                    </p>
                </div>
                <hr></hr>
                <h6>3. Scope and Term of Agreement</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">3.1</p>
                    <p>
                        The Parties agree that the Service Contract between the parties shall be comprised
                        of:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">3.1.1</p>
                        <p>the executed Initial Proposal;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">3.1.2</p>
                        <p>any Accepted Pick Request;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">3.1.3</p>
                        <p>the Terms and Conditions; and</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">3.1.4</p>
                        <p>any ancillary documents incorporated by reference into any of the above
                            documents.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">3.2</p>
                    <p>
                        In the event of any ambiguity, inconsistency or discrepancy between the documents
                        forming the Service Contract, the terms of the Terms and Conditions shall prevail to
                        the extent of the ambiguity, inconsistency or discrepancy in the documents listed in
                        3.2 above.{" "}
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">3.3</p>
                    <p>
                        The Parties specifically agree that the Service Contract shall be valid and effective
                        initially for a period of One year from the date of acceptance of the Terms and
                        Conditions and shall thereafter renew automatically on the anniversary of such date
                        unless and until terminated in accordance with the Terms and Conditions.{" "}
                    </p>
                </div>
                <hr></hr>
                <h6>4. Scope of Services</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">4.1</p>
                    <p>
                        Boda Boda is not a common carrier and reserves the right to accept
                        or refuse to carry any Cargo included in an Accepted Pick Request.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">4.2</p>
                    <p>
                        Boda Boda shall not deliver any package that includes cargo that Boad Boda knows, or
                        in its absolute discretion, deems to be:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">(a)</p>
                        <p>
                            dangerous cargo – Arms & Explosives, Hazardous Chemical including
                            radioactive material, flammable gases, liquids and solids;
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">(b)</p>
                        <p>perishable items such as food products;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">(c)</p>
                        <p>narcotics;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">(d)</p>
                        <p>alcohol; </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">(e)</p>
                        <p>animals; and/or</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">(f)</p>
                        <p>
                            any item which, under any applicable Law, is defined as illegal or the transport
                            of which is prohibited or restricted by the Government of Sri Lanka
                        </p>
                    </div>
                </div>
                <hr></hr>
                <h6>5. Pick Request </h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">5.1</p>
                    <p>
                        To request Services from Boda Boda, the Merchant shall log into the Merchant Portal
                        and complete a Pick Request setting out:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">5.1.1</p>
                        <p>the Services required;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">5.1.2</p>
                        <p>a description of the Cargo</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">5.1.3</p>
                        <p>a declaration that the Cargo does not include any item listed in 4.2 above;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">5.1.4</p>
                        <p>the quantity of Cargo to which the Services apply (which, in respect of each
                            Pick Request, must meet any Minimum Delivery Quantity); and </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">5.1.5</p>
                        <p>
                            any other information required by Boda Boda.
                        </p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">5.2</p>
                    <p>
                        A Pick Request is not binding until Boda Boda has, in its absolute discretion, accepted
                        the same and issued an Accepted Pick Request. {" "}
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">5.3</p>
                    <p>
                        The Parties expressly acknowledge and agree that terms of the Service Contract shall
                        apply to any Accepted Pick Request.{" "}
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">5.4</p>
                    <p>
                        Unless otherwise agreed by Boda Boda in writing, the Merchant cannot terminate a
                        Pick Request after an Accepted Pick Request has been issued. A Merchant may
                        request for an amendment through the Merchant Portal, and such amendment may
                        be effected by Boda Boda at its’ sole discretion by way of the issuance of an Amended
                        Accepted Pick Request
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">5.5</p>
                    <p>
                        Without limitation to its discretion, Boda Boda may reject a Pick Request that is
                        submitted by the Merchant if the Merchant fails to pay any money owing to Boda
                        Boda under the Service Contract.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">5.6</p>
                    <p>
                        Boda Boda may, by written amendment to the Initial Proposal, vary the rates, fees,
                        Minimum Delivery Quantity etc, with the variation applying to all subsequent Pick
                        Requests

                    </p>
                </div>
                <hr></hr>
                <h6>6. Pick up</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">6.1</p>
                    <p>
                        Subject to the Merchant agreeing to pay a Pick-up Fee, Boda Boda
                        will use its reasonable endeavours to pick up the Cargo at the
                        Nominated Pick-up Location at the Nominated Pick-up Time as set out
                        in the Accepted Pick Request.The Merchant shall pay to Boda Boda a pick-up fee calculated in accordance with the
                        Initial Proposal (<strong>Pick-up Fee</strong>) for each collection of Cargo.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">6.2</p>
                    <p>
                        Boda Boda shall use its reasonable endeavours to pick up the Cargo at the Nominated
                        Pick-up Location at the Nominated Pick-up Time in terms of the Accepted Pick
                        Request.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">6.3</p>
                    <p>
                        The Nominated Pick-up Time may be varied by Boda Boda from time to time without
                        prior notice to the Merchant. The Merchant acknowledges that Boda Boda shall not
                        breach the Service Contract or liable to the Merchant if the Cargo is not picked up by
                        the Nominated Pick-up Time.{" "}
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">6.4</p>
                    <p>
                        The Merchant shall pay to Boda Boda a delay fee calculated in accordance with the
                        Initial Proposal (Delay Fee) if Boda Boda is unable to pick up the Cargo at the
                        Nominated Pick-Up Location at the Nominated Pick-up Time due to the Merchant’s
                        failure to comply with these Terms and Conditions. The Merchant agrees that any
                        Delay Fee is payable in addition to the Pick-up Fee in respect of the Cargo.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">6.5</p>
                    <p>
                        Prior to the Cargo being picked up by Boda Boda, it is the Merchant’s responsibility
                        to ensure that:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">6.5.1</p>
                        <p>the Cargo:</p>
                    </div>
                    <div className="ml-3">
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(a)</p>
                            <p>
                                complies with any and all applicable Laws in any and all respects;
                            </p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(b)</p>
                            <p>
                                does not comprise or include, in whole or in part, any items listed in
                                clause 4.2 above;
                            </p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(c)</p>
                            <p>
                                is properly and appropriately prepared and Packaged in a manner
                                appropriate to ensure that the Cargo does not sustain damage in
                                transit and physical damage during handling, transportation and/or
                                storage on normal conditions;
                            </p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(d)</p>
                            <p>
                                is properly placed in the Nominated Pick-up Location in accordance
                                with pick up instructions (if any);
                            </p>
                        </div>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">6.5.2</p>
                        <p>
                            it has provided a Confirming Contact Number to Boda Boda if it elects to be
                            notified by text message upon delivery in the Pick Request;{" "}
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">6.5.3</p>
                        <p>
                            the Merchant has obtained express permission from the receiver of the
                            Cargo for Boda Boda to deliver/leave the Cargo at the Delivery Address
                            whether or not the Deliver Address is attended (Authority to Leave) if it has
                            so notified Boda Boda in the Pick Request;
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">6.5.4</p>
                        <p>
                            Boda Boda has all the information required to effect the delivery of the Cargo
                            (including any specific instructions in respect of the handling of the Cargo);
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">6.5.5</p>
                        <p>
                            a sufficient number of Merchant personnel is available at the Nominated
                            Pick-up Location at the Nominated Pick-up Time to duly dispatch the Cargo;
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">6.5.6</p>
                        <p>
                            That Merchant has provided, or made available, to Boda Boda all access and
                            other necessary authority to enable Boda Boda to pick up the Cargo at the
                            Nominated Pick-up Location, including any permits or passes necessary to
                            access and leave the Nominated Pick-up Location and/or Delivery Address,
                            passwords or keys to the Nominated Pick-up Location if the place is
                            unattended or any necessary inductions to the Nominated Pick-up Location.
                        </p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">6.6</p>
                    <p>
                        The Merchant shall pay a fee calculated in accordance with the Initial Proposal (<strong>Futile
                            Pick-up Fee</strong>) if Boda Boda is unable to pick up the Cargo from the Nominated Pick-up
                        Location due to the Merchant failing to comply with clause 6.5 above. The Merchant
                        agrees that any Futile Pick-up Fee is payable in addition to the Pick-up Fee in respect
                        of the Cargo.
                    </p>
                </div>
                <hr></hr>
                <h6>7. Delivery</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.1</p>
                    <p>
                        The Merchant shall pay a delivery fee in respect of delivery of the Cargo (Delivery
                        Fee) calculated in accordance with the Initial Proposal.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.2</p>
                    <p>
                        Boda Boda will, upon picking up the Cargo, use all reasonable endeavours to deliver
                        the Cargo to the Delivery Address within the Estimated Delivery Timeframe.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.3</p>
                    <p>
                        The Estimated Delivery Timeframe is an estimate only and Boda Boda shall not be in
                        breach of the Service Contract or be liable to the Merchant if the Cargo is not
                        delivered within the Estimated Delivery Timeframe.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.4</p>
                    <p>
                        Unless agreed otherwise in the Accepted Pick Request by Boda Boda no signature
                        from a receiver is required in respect of any Cargo being delivered at the Delivery
                        Location.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.5</p>
                    <p>
                        If the Pick Request states that Authority to Leave has been given, the Merchant will
                        be deemed to have given Boda Boda the Authority to Leave in respect of any delivered
                        Cargo and the Merchant accepts the risk of the Cargo being left in circumstances
                        where the receiver of the Cargo has not exercised the ability to provide a signature
                        on delivery.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.6</p>
                    <p>Where the Merchant has elected, in the Pick Request, to be notified by text message
                        upon delivery and has provided one or more Confirming Contact Numbers to Boda
                        Boda, Boda Boda shall notify the Merchant via text message upon delivery of the
                        Cargo at the Delivery Address. </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.7</p>
                    <p>The Merchant acknowledges and agrees that: </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">7.7.1</p>
                        <p>
                            it is the Merchant ’s responsible to ensure the validity/ accuracy of any
                            Confirming Contact Number it provides to Boda Boda; and
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">7.7.2</p>
                        <p>
                            Cargo shall be deemed to have been delivered to the Delivery Address
                            provided Boda Boda is able to demonstrate that it has sent a text message to
                            the Confirming Contact Number(s).
                        </p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.8</p>
                    <p>
                        If Boda Boda is unable to deliver the Cargo for any reason (including the Delivery
                        Address being incorrect) or as a result of any cause or circumstance outside the
                        reasonable control of Boda Boda, Boda Boda shall return the Cargo to the Merchant
                        at the Merchant ’s risk and the Merchant shall be liable to pay handling and/or storage
                        and additional charges applicable as determined by Boda Boda and notified to the
                        Merchant via the Merchant Portal.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.9</p>
                    <p>
                        Where Cargo is returned to the Merchant pursuant to clause 7.8:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">7.9.1</p>
                        <p>
                            unless otherwise agreed in the applicable accepted Pick Request, the Cargo
                            will be returned to the Return Location during the next Business Day; and
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">7.9.2</p>
                        <p>the Merchant will be liable to pay to Boda Boda a return fee in respect of the
                            return of the Cargo calculated in accordance with the Initial Proposal (<strong>Return
                                Fee</strong>).</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">7.10</p>
                    <p>
                        Notwithstanding any other provision in the Service Contract, the Merchant
                        acknowledges and agrees that:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">7.10.1</p>
                        <p>
                            Boda Boda shall not assume any risk in the Cargo and the cargo remains at
                            all times during the Delivery Period with the Merchant; and
                        </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">7.10.2</p>
                        <p>
                            Boda Boda is not liable to the Merchant, the receiver or any third party in
                            tort (including negligence), contract (including a fundamental breach of
                            contract) or for any act or omission, for breach of duty, for contravention of
                            any statute or breach of statutory duty or otherwise for any loss of, or
                            damage to, failure to deliver, delay in delivery of, or mis-delivery of the
                            Cargo, however caused.
                        </p>
                    </div>
                </div>
                <hr></hr>
                <h6>8. Fees and payment </h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">8.1</p>
                    <p>
                        The Merchant shall pay to Boda Boda all Fees in respect of the Services as set out in
                        the Terms and Conditions and calculated in accordance with the Initial Proposal. The
                        Merchant hereby agrees that such Fees as notified to the Merchant by way of a tax
                        invoice issued by Boda Boda in accordance with clause 8.3 shall constitute conclusive
                        evidence of the Fees owed by the Merchant to Boda Boda for Services rendered.

                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">8.2</p>
                    <p>
                        As set out in the Applicable Pick List unless otherwise notified by Boda Boda, the Fees,
                        for the Services are subject to 7.7 and 7.8, inclusive of all applicable charges and
                        expenses.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">8.3</p>
                    <p>
                        Boda Boda shall issue the tax invoice either within a specified number of days from
                        completed delivery and/or receipt of Cargo from the Merchant or on a fortnightly or
                        monthly basis asspecified in the Initial Proposal and the Merchant shall pay and settle
                        all such invoices in full within 7 days of receiving such tax invoice from Boda Boda
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">8.4</p>
                    <p>
                        All payments owed by the Merchant to Boda Boda under the Service Contract may be
                        paid in cash (up to any limits specified by Boda Boda), or by direct debit, electronic
                        fund transfer or credit card, unless otherwise advised by Boda Boda in writing.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">8.5</p>
                    <p>
                        Boda Boda may at its’ discretion charge the Merchant interest, at the rate of 10% on
                        any and all unpaid amounts payable by the Merchant under the Service Contract from
                        the due date until the date of payment in full. Interest accrues daily, may be
                        capitalized by Boda Boda daily and is payable on demand.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">8.6</p>
                    <p>
                        Without limiting any of its other rights under the Services Contract, if Boda Boda has
                        concerns about the Merchant’s solvency or its ability to pay future invoices, or the
                        Merchant is currently in breach of the Service Contract by failing to pay amounts due
                        to Boda Boda, Boda Boda may refuse to provide Services to the Merchant, or only do
                        so on advance payment of cash.
                    </p>
                </div>
                <hr></hr>
                <h6>9. Vale Added Tax </h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">9.1</p>
                    <p>
                        Unless otherwise specifically provided by Boda Boda the Fees quoted for the Services
                        shall be all inclusive with the exception of applicable Value Added Tax.
                    </p>
                </div>
                <hr></hr>
                <h6>10. Merchant warranties and indemnity </h6>

                <div className="terms_and_condition_line">
                    <p className="mr-1">10.1</p>
                    <p>The Merchant represents and warrants to Boda Boda that: </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.1.1</p>
                        <p>the Merchant: </p>
                    </div>
                    <div className="ml-3">
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(a)</p>
                            <p>has the legal right, power, capacity and authority to carry on its
                                business as presently conducted;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(b)</p>
                            <p>has the legal right, power, capacity and authority to enter into the
                                Service Contract and to perform its obligations under the Service
                                Contract;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(c)</p>
                            <p>the Service Contract constitutes valid and binding obligations of the
                                Merchant;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(d)</p>
                            <p>no actions, suits, proceedings or investigations are pending in relation
                                to the Merchant, or its respective properties or rights and neither the
                                Merchant nor any of its assets are subject to any order or resolution
                                for any insolvency, bankruptcy, winding-up or similar proceeding; and</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(e)</p>
                            <p>the Merchant has taken all action required by it to authorise the
                                Merchant to lawfully enter into and to perform the Service Contract.</p>
                        </div>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.1.2</p>
                        <p>the Merchant:</p>
                    </div>
                    <div className="ml-3">
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(a)</p>
                            <p>has all necessary legal right to the Cargo and to engage Boda Boda to
                                provide the Services in respect of such Cargo; </p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(b)</p>
                            <p>has obtained express Authority to Leave from the receiver in respect
                                of the Cargo where a Pick Request specifies that Authority to Leave has
                                been given; and
                            </p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(c)</p>
                            <p>has the right to handle the Cargo in accordance with all applicable Laws
                                prior to the Cargo being picked up by Boda Boda; and</p>
                        </div>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.1.3</p>
                        <p>the Cargo is and will remain at all times during the Delivery Period in the
                            custody of the Merchant and:</p>
                    </div>
                    <div className="ml-3">
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(a)</p>
                            <p>is, in all respects, compliant with any and all applicable Laws;</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(b)</p>
                            <p>does not comprise or include, in whole or in part, any items listed in
                                clause 4.2 above;
                            </p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(c)</p>
                            <p>has been properly and appropriately prepared and packaged in
                                accordance with any applicable Laws and industry standards to protect
                                from spoilage, contamination, deterioration and physical damage
                                during handling, transportation and/or storage; and</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p className="mr-1">(d)</p>
                            <p>is fit for its intended purpose; and</p>
                        </div>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.1.4</p>
                        <p>all information provided in the Pick Request is complete and accurate.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">10.2</p>
                    <p>The Merchant acknowledges and agrees that Boda Boda, in providing the Services in
                        respect of the Cargo, is acting in reliance of the representations and warranties set
                        out herein.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">10.3</p>
                    <p>The Merchant indemnifies Boda Boda from and against any Claim it may suffer or
                        Liability it may incur arising directly or indirectly as a result of or in connection with:</p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.3.1</p>
                        <p>any enforcement by Boda Boda of the Service Contract;</p>
                    </div>

                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.3.2</p>
                        <p>any breach by the Merchant of the Service Contract, including any
                            representations and warranties set out herein;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">10.3.3</p>
                        <p>any Claims by a third party in respect of personal injury, death or damage to
                            property, that relate to the Cargo delivered under the Service Contract.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">10.4</p>
                    <p>Without limitation to clause 10.3 above the Merchant agrees to indemnify, defend,
                        and protect Boda Boda from and against all lawsuits and costs of every kind pertaining
                        to any violation of any Law, the Service Contract, or the rights of any third party by
                        Boda Boda while acting pursuant to the Service Contract. Such costs include but are
                        not limited to reasonable legal fees.</p>
                </div>
                <hr></hr>
                <h6>11. Limitation of Liability </h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">11.1</p>
                    <p>To the extent Boda Boda is legally permitted to limit the remedies available to the
                        Merchant under the Service Contract, Boda Boda expressly limits its liability for breach
                        of the Service Contract to the following remedies:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">11.1.1</p>
                        <p>the supply of the Services again; or</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">11.1.2</p>
                        <p>payment of the cost of having the Services supplied again as determined by
                            Boda Boda.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">11.2</p>
                    <p>If clause 11.1 does not apply, then Boda Boda‘s total aggregate liability for any loss or
                        damage arising under or in connection with the Service Contract or the performance
                        of the Services whether arising out of breach of contract, in tort (including
                        negligence), under a statute or otherwise will be limited to an amount equal to 50%
                        of the Fees actually received by Boda Boda from the Merchant in the One (1) month
                        prior to the event giving rise to the liability occurring. </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">11.3</p>
                    <p> Boda Boda is not liable to the Merchant for any indirect and/or Consequential Loss in
                        connection with the Service Contract.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">11.4</p>
                    <p>To the maximum extent permitted by Law: </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">11.4.1</p>
                        <p> Boda Boda does not give any guarantee, indemnity or warranty nor make
                            any representation, express or implied, in respect of the Service Contract or
                            its’ performance; and</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">11.4.2</p>
                        <p>all warranties, terms and conditions which save for this clause would be
                            implied by use, statute or otherwise are excluded. </p>
                    </div>
                </div>
                <hr></hr>
                <h6>12. Governing Law and Dispute Resolution</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">12.1</p>
                    <p>The Parties expressly agree that the Service Contract shall be governed by, and
                        construed in accordance with, the laws of Sri Lanka.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">12.2</p>
                    <p>Where a dispute arises between the parties in connection with the Service Contract,
                        the Parties must enter into discussions in good faith to resolve the dispute before
                        initiating court proceedings. Unless the Parties otherwise agree, discussions between
                        the Parties under this clause 12 must continue for 30 days unless resolved earlier.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">12.3</p>
                    <p>If the dispute is not resolved under clause 12.1, either party may refer the Any doubt,
                        difference, dispute, controversy or claim arising from, out of or in connection with the
                        Service Contract, or on the interpretation thereof or on the rights, duties, obligation,
                        or liabilities of any parties thereto or on the operation, breach, termination or
                        invalidity thereof, shall be settled by arbitration in accordance with the Rules of the
                        Arbitration Centre of the Institute for the Development of Commercial Law and
                        Practice (ICLP)" and shall be settled by a panel of three arbitrators, with each party
                        nominating and appointing a single Arbitrator and the two arbitrators so appointed
                        nominating the Chairperson of the tribunal. It is mutually agreed by the Parties that
                        the arbitration will be conducted in accordance with the ICLP Arbitration Rules and
                        held in Colombo, Sri Lanka. The language of the arbitral proceedings shall be English.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">12.4</p>
                    <p>The award rendered by the arbitrators shall be final and binding upon the parties
                        concerned. Any Party may seek enforcement of any arbitration award in the courts
                        of Sri Lanka, to which the Parties hereby consent. Where mandatory applicable law
                        requires that a decision on a matter arising under or in connection with the Service
                        Contract or its performance be decided by the ordinary courts, the venue shall be the
                        Courts of Colombo, Sri Lanka.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">12.5</p>
                    <p>During the arbitration, the Service Contract shall continue to be observed and
                        enforced by all Parties except for the matters in dispute.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">12.6</p>
                    <p>Nothing in this clause 12 will prejudice the right of a party to seek urgent injunctive or
                        declaratory relief in respect of any matter arising out of the Service Contract.</p>
                </div>
                <hr></hr>
                <h6>13. Breach and Termination </h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">13.1</p>
                    <p>If the Merchant:</p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">13.1.1</p>
                        <p>breaches the Service Contract in a manner that cannot be remedied as
                            determined by Boda Boda; </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">13.1.2</p>
                        <p>breaches the Service Contract and does not remedy the breach within 14
                            days of receiving a written notice to rectify such breach from Boda Boda; or</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">13.1.3</p>
                        <p>is the subject of an Insolvency Event as determined by Boda Boda,</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">Boda Boda may:</p>
                    </div>
                    <div className="ml-2">
                        <div className="terms_and_condition_line">
                            <p>(a) cancel any unperformed Accepted Pick Requests and where Cargo has
                                been picked up by Boda Boda under the relevant Accepted Pick
                                Request prior to cancellation, Boda Boda will return the Cargo to the
                                Merchant and the Merchant shall pay to Boda Boda the Return Fee;
                                and/or</p>
                        </div>
                        <div className="terms_and_condition_line">
                            <p>(b) terminate the Service Contract, immediately by written notice to the
                                Merchant.</p>
                        </div>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">13.2</p>
                    <p>The Service Contract may be terminated by either Party without any cause by giving
                        5 Business Days' written notice of termination to the other Party.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">13.3</p>
                    <p>Where the Service Contract isterminated by Boda Boda under clause 13.1 or by either
                        Party under clause 13.2:
                    </p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">13.3.1</p>
                        <p>all money owing and/or outstanding by the Merchant to Boda Boda becomes
                            immediately due and payable without the need for further notice; and</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">13.3.2</p>
                        <p>subject to the Merchant complying with clause 13.3.1 above, Boda Boda shall
                            return all undelivered Cargo in its possession at the time of termination and
                            the Merchant shall pay the applicable Return Fees to Boda Boda.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">13.4</p>
                    <p>The termination of the Service Contract is without prejudice to any rights which may
                        have acquired to either Party before such termination.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">13.5</p>
                    <p>All of the rights and obligations of each Party to the Service Contract which are
                        expressed to survive termination of the Service Contract, or which by their nature or
                        context are intended to survive termination of the Service Contract, will survive the
                        termination of the Service Contract.</p>
                </div>
                <hr></hr>
                <h6>14. FORCE MAJEURE</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">14.1</p>
                    <p>No Party shall be deemed to be in breach of the Service Contract, or otherwise be liable
                        to the other, by reason of any delay in performance, or non-performance, of any of its
                        obligations hereunder to the extent that such delay or non-performance is due to any
                        Force Majeure of which it has notified the other Parties; and the time for performance
                        of that obligation shall be extended accordingly. ‘Force Majeure’ means any
                        circumstances or event beyond each Parties’ reasonable control and shall include
                        (without limitation) acts of God, fire or accident, war or threat of war, sabotage,
                        insurrection, civil disturbance or requisition, acts restrictions regulations by-laws,
                        prohibitions or measures of any kind on the part of any governmental, parliamentary
                        or local authority, pandemic, shortage of fuel or shortage or unavailability of machinery
                        and equipment, power, machinery or equipment failure.<br />
                        Provided however that the Merchant may not rely on this clause for non-performance
                        of any of their obligations hereunder and shall only operate as a reason for delay in
                        performance where all reasonable efforts for performance of such obligations have
                        been fully exhausted.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">14.2</p>
                    <p> For the purpose of clarity the parties agree that Boda Boda may cancel or suspend the
                        Services in whole or in part due to any fact, cause, thing or event beyond Boda Boda’s
                        reasonable control. Boda Boda will not be deemed to be in breach of the Service
                        Contract if it does so.
                    </p>
                </div>
                <hr></hr>
                <h6>15. Confidentiality and Intellectual Property</h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">15.1</p>
                    <p>The Merchant must ensure its employees, agents representatives and personnel,
                        keep confidential and secure Boda Boda’s confidential information (including the
                        terms of the Service Contract, templates of the Pick Request, Accepted Pick Request,
                        etc), and not disclose the same except with the prior written consent of Boda Boda or
                        where disclosure is required by law, in which case, the Merchant shall inform Bada
                        Boda of such Pick Request to disclose and the disclosure must be only to the extent
                        required by law.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">15.2</p>
                    <p>The Merchant acknowledges and agrees that all trademarks, patents and other
                        intellectual property rights in or relating to the Services belong to and are the
                        exclusive property of Boda Boda and/or its affiliates developed by Boda Boda as
                        significant investment. The Merchant will not and will not attempt to directly or
                        indirectly make any claim in connection with that intellectual property and/or act in
                        any manner that will circumvent Boda Boda benefiting and/or enjoying the value of
                        its Intellectual Property and/or the Merchant will not directly or indirectly assist any
                        third part to replicate the said system developed by Boda Boda or any part thereof to
                        act in direct and/or indirect competition with Boda Boda.</p>
                </div>
                <hr></hr>
                <h6>16. General </h6>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.1</p>
                    <p>The Service Contract is personal to the Merchant and the Merchant shall not transfer
                        (either by assignment or novation), allow a change in Control to occur to it, or
                        otherwise deal with the Service Contract except with the prior written consent of
                        Boda Boda, which consent must not be unreasonably withheld.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.2</p>
                    <p>No agency, partnership, joint venture, or employment relationship is created as a
                        result of the Service Contract and the Merchant does not have any authority of any
                        kind to bind Boda Boda in any respect.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.3</p>
                    <p>Boda Boda may subcontract the performance of all or part of the Services.
                    </p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.4</p>
                    <p> Boda Boda may vary or replace the Terms and Conditions, unilaterally in whole or in
                        part by notifying the Merchant in writing, or via the Merchant Portal and/or via email.
                        Any amended or new terms and conditions will form part of the Service Contract and
                        take effect from the date of the Merchant submitting the next Pick Request.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.5</p>
                    <p>Notices under the Service Contract may be delivered via the Merchant Portal and/or
                        via email, by hand, by mail addressed to the Merchant. Notice will be deemed given:</p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">16.5.1</p>
                        <p>in the case of hand delivery, upon written acknowledgment of receipt by an
                            officer or other duly authorised employee, agent or representative of the
                            receiving party;</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">16.5.2</p>
                        <p>in the case of posting, three days after dispatch; and</p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">16.5.3</p>
                        <p>in the case of publication on the Merchant Portal on publication, email, upon
                            receipt of transmission if received on a business day or otherwise at the
                            commencement of the first business day following transmission.</p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.6</p>
                    <p>The Service Contract, subject to 3.1 above:</p>
                </div>
                <div className="ml-5">
                    <div className="terms_and_condition_line">
                        <p className="mr-1">16.6.1</p>
                        <p>constitutes the entire Service Contract between the parties about the subject
                            matter contained in it; and </p>
                    </div>
                    <div className="terms_and_condition_line">
                        <p className="mr-1">16.6.2</p>
                        <p>supersedes and cancels any contract, deed, arrangement, related condition,
                            collateral arrangement, condition, warranty, indemnity or representation
                            relating to the subject matter contained in it, that was imposed, given or
                            made by a party (or an agent of the party) prior to entering into the Service
                            Contract.
                        </p>
                    </div>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.7</p>
                    <p>TIf a provision of the Service Contract is held to be illegal, invalid, void, voidable or
                        unenforceable, that provision must be read down to the extent necessary to ensure
                        that it is not illegal, invalid, void, voidable or unenforceable. If it is not possible to read
                        down a provision as required by this clause, that provision is severable without
                        affecting the validity or enforceability of the remaining part of that provision or the
                        other provisions in the Service Contract.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.8</p>
                    <p>The rights, powers, privileges and remedies provided under any provision of the
                        Service Contract are cumulative and are not exclusive of any rights, powers, privileges
                        or remedies provided under any other provision of the Service Contract or by
                        applicable law or otherwise.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.9</p>
                    <p>A single or partial exercise or waiver of a right relating to the Service Contract does
                        not prevent any other exercise of that right or the exercise of any other right. No
                        party will be liable for any loss or expenses incurred by another party caused or
                        contributed to by the waiver, exercise, attempted exercise, failure to exercise or delay
                        in the exercise of the right.</p>
                </div>
                <div className="terms_and_condition_line">
                    <p className="mr-1">16.10</p>
                    <p>Each party must promptly execute and deliver all documents and take all other action
                        necessary or desirable to effect, perfect or complete the transactions contemplated
                        by the Service Contract. </p>
                </div>
            </div>
        </Fragment>
    );
};

const DownloadPDFBtn = (props) => {
    return (
        <Link
            to="/pdf/Merchant Portal Terms and Conditions.pdf"
            target="_blank"
            download
        >
            <InputButton
                btnText={"Download PDF"}
                startIcon={"fas fa-file-pdf"}
                elementStyle={"btn btn-primary waves-effect waves-light"}
            />
        </Link>
    );
};

export default TermsAndConditionUIMessage;
