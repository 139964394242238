import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getUserStaffList, createAuthUserAPI } from "../../../config/boda-boda/apiUrls.config";
import validateMethod from '../../../helpers/boda-boda/validation';
import { setFormErrorFn } from '../form/actions';
import {defaultCountryCode} from "../../../config/boda-boda/core.config";
import parsePhoneNumber from "libphonenumber-js";

const emptyFn = (...para) => undefined;

function* createUserFn(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            identification: "Identification",
            merchantBranch: "Branch",
            email: "Email",
            role: "Role",
            credential: "Credential",
            password: "Password",
            confirm_password: "Confirm Password",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
            identification: "required|min:8|max:20",
            merchantBranch: "required",
            email: "required|email|max:50",
            role: "required",
            credential: "required|email|max:50",
            password: "required|passwordPolicy",
            confirm_password: "required|same:password"
        },
        {},
    )
    );

    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${getUserStaffList.key}_create`, validationResponse.errors));

    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_create`, []));

        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            credential: get(data, "payload.credential", ""),
            password: get(data, "payload.password", ""),
            merchant: {
                id: get(data, "payload.merchant", ""),
            },
            role: {
                id: get(data, "payload.role", ""),
            },
            canSwitch: false
        };

        const userAPIRequest = yield call(() => callApi(createAuthUserAPI.url).headers(true).method("post").body(userAPIRequestBody).send());

        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                user: {
                    id: get(userAPIRequest, "data.data.id", ""),
                },
                merchant: {
                    id: get(data, "payload.merchant", ""),
                },
                merchantBranch: {
                    id: get(data, "payload.merchantBranch", ""),
                },
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                category: "MERCHANT",
                status: "ACTIVE",
                countryCode: {
                    id: parseInt(get(countryCode, "[0]"))
                },
                countryCodePrefix: get(countryCode, "[2]")
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            const staffAPIRequest = yield call(() => callApi(getUserStaffList.url).headers(true).method("post").body(staffAPIRequestBody).send());

            if (staffAPIRequest._statue === false) {
                if (get(staffAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${getUserStaffList.key}_create`, get(staffAPIRequest, "data.errors", [])));
                    yield put(setFlashMessage({
                        status: true,
                        message: `${get(staffAPIRequest, "data.errors[0].error", "Something went wrong!")}`,
                        type: "danger"
                    }));
                } else if (get(staffAPIRequest, "data.statusCode", null) === 403) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Permission denied to access",
                        type: "danger"
                    }));
                } else {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Something went wrong...!!",
                        type: "danger"
                    }));
                }

                yield call(() => callApi(`${createAuthUserAPI.url}/${get(userAPIRequest, "data.data.id", "")}`).headers(true).method("delete").send());
            } else {
                get(data, "payload.historyFn.push", emptyFn)("/users");

                yield put(setPageLoader(false));
                yield put(setFlashMessage({
                    status: true,
                    message: "User has created successfully",
                    type: "success",
                }));
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(`${getUserStaffList.key}_create`, get(userAPIRequest, "data.errors", [])));
                yield put(setFlashMessage({
                    status: true,
                    message: get(userAPIRequest, "data.errors[0].error", "Something went wrong...!!"),
                    type: "danger"
                }));
            } else if (get(userAPIRequest, "data.statusCode", null) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger"
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger"
                }));
            }
        }
    }

    yield put(setPageLoader(false));
}

function* updateUserFn(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            phone: "Phone",
            identification: "Identification",
            merchantBranch: "Branch",
            email: "Email",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4|max:100",
            identification: "required|min:8|max:20",
            merchantBranch: "required",
            email: "required|email|max:50",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
        },
        {},
        )
    );

    if (validationResponse._status === false) {

        yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, validationResponse.errors));

    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, []));

        const userAPIRequestBody = {
            email: get(data, "payload.email", ""),
            status: get(data, "payload.status", ""),
        };

        const userAPIRequest = yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
            .headers(true)
            .method("put")
            .body(userAPIRequestBody)
            .send()
        );
        if (userAPIRequest._statue === true) {
            const staffAPIRequestBody = {
                name: get(data, "payload.name", ""),
                identification: get(data, "payload.identification", ""),
                merchantBranch: {
                    id: parseInt(get(data, "payload.merchantBranch", "")),
                },
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                countryCode: {
                    id: parseInt(get(countryCode, "[0]"))
                },
                countryCodePrefix: get(countryCode, "[2]")
            };

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    staffAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            const staffAPIRequest = yield call(() => callApi(`${getUserStaffList.url}/${get(data, "payload.staffId")}`)
                .headers(true)
                .method("put")
                .body(staffAPIRequestBody)
                .send()
            );

            if (staffAPIRequest._statue === false) {
                if (get(staffAPIRequest, "data.code", null) === 400) {
                    yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, get(staffAPIRequest, "data.errors", [])));
                    yield put(setFlashMessage({
                        status: true,
                        message: `${get(staffAPIRequest, "data.errors[0].error", [])}`,
                        type: "danger"
                    }));
                }

                yield call(() => callApi(`${createAuthUserAPI.url}/${get(data, "payload.userId")}`)
                    .headers(true)
                    .method("put")
                    .body({
                        email: get(data, "payload.authEmail", "")
                    })
                    .send()
                );
            } else {
                get(data, "payload.history.push", emptyFn)("/users");

                yield put(setPageLoader(false));
                yield put(setFlashMessage({
                    status: true,
                    message: "User details has updated successfully",
                    type: "success",
                }));
            }
        } else {
            if (get(userAPIRequest, "data.code", null) === 400) {
                yield put(setFormErrorFn(`${getUserStaffList.key}_edit`, get(userAPIRequest, "data.errors", [])));
                yield put(setFlashMessage({
                    status: true,
                    message: `${get(userAPIRequest, "data.errors[0].error", [])}`,
                    type: "danger"
                }));
            } else if (get(userAPIRequest, "data.statusCode", null) === 403) {
                yield put(setFlashMessage({
                    status: true,
                    message: "Permission denied to access",
                    type: "danger"
                }));
            } else {
                yield put(setFlashMessage({
                    status: true,
                    message: "Something went wrong...!!",
                    type: "danger"
                }));
            }
        }
    }

    yield put(setPageLoader(false));
}

export {
    createUserFn,
    updateUserFn
}
