import React, { Fragment, useState } from "react";

import { SubmitButton, InputButton } from "../../../../../components/boda-boda/Forms";
import { getManifestListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";


const CancelUIMessage = () => {
    return(
        <p>Are you sure you want to cancel this pick request?</p>
    );
};

const CancelUIBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getManifestListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Pick request has successfully cancelled",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getManifestListAPI.url}/${props.row.id}`,
                    key: getManifestListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    "status": "CANCELLED",
                    "locationPick": {
                        "id": props.row.locationPickId
                    }
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.history.goBack();
                }
            }}
        />
    );
};

const CancelUIModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                btnText={"Cancel"}
                startIcon={"far fa-times-circle"}
                btnColor={"danger"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<CancelUIMessage />}
                modalFooterComponent={<CancelUIBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    row={{ "id": props.id, "locationPickId": props.locationPickId }}
                    history={props.history}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    CancelUIBtn,
    CancelUIMessage,
    CancelUIModal
}
