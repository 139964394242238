import React, { Component } from "react";

import { InputBoxWithState, SelectBoxWithState } from "../../../../../components/boda-boda/Forms";
import { deliveryTypes, paymentMethods } from "../../../../../config/boda-boda/db_config/db_status.config";
import {
    getMerchantBranchAllAPI,
    getPackageCategoryListAPI,
} from "../../../../../config/boda-boda/apiUrls.config";


class PackagesFilterUI extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Article No"
                            inputName={"articleNo"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Article No"}
                            maxLength={17}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Customer Name"
                            inputName={"name"}
                            formGroupName={this.props.searchFromKey}
                            placeHolderText={"Customer Name"}
                            maxLength={50}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Delivery Type"}
                            inputName={"deliveryType"}
                            formGroupName={this.props.searchFromKey}
                            dataList={deliveryTypes}
                            placeHolderText={"Delivery Type"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Payment Method"}
                            inputName={"paymentMethod"}
                            formGroupName={this.props.searchFromKey}
                            dataList={paymentMethods}
                            placeHolderText={"Payment Method"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Package Category"}
                            inputName={"categoryId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getPackageCategoryListAPI.url}`}
                            apiStateKey={getPackageCategoryListAPI.key}
                            keyName={"id"}
                            valueName={"name"}
                            placeHolderText={"Package Category"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Pick Location"}
                            placeHolderText={"Pick Location"}
                            inputName={"locationPickId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={`${getMerchantBranchAllAPI.url}`}
                            apiStateKey={getMerchantBranchAllAPI.key}
                            keyName={"locationId"}
                            valueName={"name"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PackagesFilterUI;
