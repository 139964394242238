import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { actionBtnTypes, CrudComponent, } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import {
    FormWrapper,
    DatePickerBoxWithState,
    InputButton
} from "../../../../../components/boda-boda/Forms";
import {
    getPackageListAPI,
    getManifestListAPI,
} from "../../../../../config/boda-boda/apiUrls.config";
import ViewPackage from "./ViewPackage";
import { redraftManifest } from "../../../../../store/boda-boda/transaction_request/actions";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { mapPackageStatusesNames } from "../../../../../config/boda-boda/db_config/db_status.config";


class Redraft extends Component {
    render() {
        let { match, formState, history, redraftManifest } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={getManifestListAPI.key}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            code: response.code,
                            oldPickAt: new Date(response.pickAt),
                            pickAt: new Date(),
                            locationDrop: response.locationDrop,
                            locationPick: response.locationPick,
                            note: response.notes,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Re-Draft Pick Request</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <Fragment>
                                    <InputButton
                                        elementWrapperStyle={"mr-2"}
                                        btnText={"Create"}
                                        startIcon={"mdi mdi-content-save-all-outline"}
                                        onClickBtnFn={() => {
                                            redraftManifest({
                                                oldId: match.params.id,
                                                pickAt: get(formState, `${getManifestListAPI.key}.pickAt`, ""),
                                                locationPick: get(formState, `${getManifestListAPI.key}.locationPick.id`, ""),
                                                history: history
                                            })
                                        }}
                                    />

                                    <BackBtnComponent
                                        elementWrapperStyle={""}
                                        path={"/pick-request/rejected"}
                                        btnName={"Cancel"}
                                        icon={"far fa-times-circle"}
                                        btnColor={"danger"}
                                    />
                                </Fragment>
                            </div>

                        </Col>

                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className="card">
                                <div className="card-body">

                                    <div className="row">

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Manifest Code</dt>
                                                <dd className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}.code`, "")}</dd>

                                                <dt className={"col-sm-5"}>Old Pick Up Date</dt>
                                                <dd className={"col-sm-7"}>{dateObjectToString(get(formState, `${getManifestListAPI.key}.oldPickAt`, ""))}</dd>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Pick Up Location</dt>
                                                <dd className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}.locationPick.name`, "")}</dd>

                                                <dt className={"col-sm-5"}>Drop Location</dt>
                                                <dd className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}.locationDrop.name`, "")}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <DatePickerBoxWithState
                                                labelText={"New Pick Up Date"}
                                                isRequired={true}
                                                inputName={"pickAt"}
                                                formGroupName={getManifestListAPI.key}
                                                minDate={new Date()}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Packages"
                            apiURL={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=20`}
                            dataTableStateKey={`${getPackageListAPI.key}_manifest`}
                            isFilter={false}
                            isSetCreateAction={false}
                            isSetAction={true}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Customer Name", key: "order.name" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                                { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status]},
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: true,
                                    modalProps: {
                                        modalTitle: "View Package",
                                        modalType: "modal-lg",
                                        modalBodyComponent: ViewPackage,
                                        isSetClose: true,
                                        isSetFooterClose: true,
                                    }
                                },
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <label>Notes</label>
                                            {   
                                                get(formState, `${getManifestListAPI.key}.note`, []) !=null && get(formState, `${getManifestListAPI.key}.note`, []).length > 0 ? (
                                                    <ul style={{ paddingLeft: "17px" }}>
                                                        {
                                                            get(formState, `${getManifestListAPI.key}.note`, []).map((element, index) => {
                                                                return (
                                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                ) : (
                                                    <div>None</div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState
    };
};

const RedraftPickRequest = withRouter(Redraft);

export default connect(mapStateToProps, { redraftManifest })(RedraftPickRequest);
