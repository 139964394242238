import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { InputButton } from "../../../../../../../components/boda-boda/Forms";
import { orderCSVUploadAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { ModalUI } from "../../../../../../../components/boda-boda/Modal/ModalUI";
import UploadModalBody from "./UploadModalBody";
import { callApi } from "../../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { setPageLoader, setFlashMessage } from "../../../../../../../store/boda-boda/ui/actions";
import { resetDataTable } from "../../../../../../../store/boda-boda/core/actions";
import { removeFromGroupFn } from "../../../../../../../store/boda-boda/form/actions";


const UploadCSVBtn = (props) => {
    const formState = useSelector(state => state.FormData);
    const dispatch = useDispatch();

    const onSubmit = () => {
        if (get(formState, "upload_csv_data.data.file", null) === null) {
            dispatch(setFlashMessage({
                status: true,
                message: "Please select a csv file",
                type: "danger"
            }));
        } else {
            dispatch(setPageLoader(true));

            callApi(orderCSVUploadAPI.url)
                .headers(true)
                .method("post")
                .body({
                    name: get(formState, "upload_csv_data.data.fileName", ""),
                    url: get(formState, "upload_csv_data.data.url", ""),
                    fileName: get(formState, "upload_csv_data.data.file.name", ""),
                    category: "ORDER",
                    type: "CSV"
                })
                .send()
                .then((result) => {
                    dispatch(resetDataTable(orderCSVUploadAPI.key));
                    dispatch(removeFromGroupFn("upload_csv_data"));

                    props.setModelStatusFn(false);
                    dispatch(setPageLoader(false));
                    if (get(result, "data.statusCode", "") === 403){
                        dispatch(setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        }));
                    } else {
                        dispatch(setFlashMessage({
                            status: true,
                            message: "CSV file has uploaded successfully",
                            type: "success",
                        }));
                    }
                })
                .catch((error) => {
                    dispatch(setPageLoader(false));
                })
        }
    }

    return (
        <InputButton
            btnText={"Upload"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            onClickBtnFn={() => onSubmit()}
        />
    )
};

const UploadModalUI = () => {
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    return (
        <Fragment>
            <InputButton
                btnText={"Upload"}
                startIcon={"mdi mdi-content-save-all-outline"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Upload CSV"}
                modalBodyComponent={<UploadModalBody />}
                modalFooterComponent={<UploadCSVBtn
                    setModelStatusFn={(value) => setOpen(value)}
                />}
                showHideToggleFn={() => {
                    setOpen(false);
                    dispatch(removeFromGroupFn("upload_csv_data"));
                }}
            />
        </Fragment>
    )
}

export default UploadModalUI;
