import React, { Fragment, useState } from "react";

import { SubmitButton, InputButton } from "../../../../../../../components/boda-boda/Forms";
import { getOrdersListAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../../../../components/boda-boda/Modal/ModalUI";


const RemoveMessage = () => {
    return(
        <p>Are you sure you want to cancel this order?</p>
    );
};

const RemoveBtn = (props) => {
    return(
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getOrdersListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Order has successfully cancelled",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: props.type === "datatable" ? apiResponseStoringType.dateTableLinkedForm : apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getOrdersListAPI.url}/${props.row.id}`,
                    key: getOrdersListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    "status": "CANCELLED"
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.type === "datatable") {
                        props.setModelStatusFn(false);
                    } else {
                        props.history.push("/orders/pending");
                    }
                }
            }}
        />
    );
};

const CancelModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                btnText={"Cancel"}
                startIcon={"far fa-times-circle"}
                btnColor={"danger"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<RemoveMessage />}
                modalFooterComponent={<RemoveBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={"form"}
                    row={{ "id": props.orderId }}
                    history={props.history}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    RemoveBtn,
    RemoveMessage,
    CancelModal
}
