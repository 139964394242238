import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { get } from "lodash";

import { unauthorisedUserFn } from "../store/boda-boda/auth/actions";
import { setFlashMessage } from "../store/boda-boda/ui/actions";
import { fileGetAPIUrl } from "../config/boda-boda/core.config";
import profileImg from "../assets/images/profile_img.png";
import { setInitialStateFn as setInitFormState } from "../store/boda-boda/form/actions";
import { setInitialStateFn as setInitCoreState } from "../store/boda-boda/core/actions";


class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logOutFn = () => {
        let { unauthorisedUserFn, history, setFlashMessage, setInitFormState, setInitCoreState } = this.props;

        unauthorisedUserFn();
        setInitFormState();
        setInitCoreState();
        history.push("/login");
        setFlashMessage({
            status: true,
            message: "You have logged out successfully",
            type: "success"
        })
    }

    render() {
        let { authState } = this.props;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img
                            className="rounded-circle header-profile-user"
                            src={get(authState, `authUser.staff.image`, null) === null ? profileImg : `${fileGetAPIUrl}${get(authState, `authUser.staff.image`, "")}`}
                            alt="Header Avatar"
                        />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <Link
                            to={"/user-profile/view"}
                            className="dropdown-item">
                            <i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
                            <span>Profile</span>
                        </Link>

                        <div className="dropdown-divider"></div>

                        <Link
                            onClick={() => this.logOutFn()}
                            className="dropdown-item">
                            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                            <span>Logout</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        authState: state.AuthState
    };
};

export default connect(mapStateToProps, {
    unauthorisedUserFn,
    setFlashMessage,
    setInitFormState,
    setInitCoreState
})(withRouter(ProfileMenu));
