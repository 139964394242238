import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { findIndex, get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent, actionBtnTypes } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { FilterUI } from "./includes/FilterUI";
import { getOrdersListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { RemoveBtn, RemoveMessage } from "../pending-orders/includes/view-includes/DeleteUI";
import { RestoreBtn, RestoreUIMessage } from "../pending-orders/includes/view-includes/RestoreUI";
import {
  customerNameShortHelperFn,
  formatToDateHelper,
  isEmptyValue
} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { mapOrderPaymentMethods } from "../../../../../config/boda-boda/db_config/db_status.config";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";


class AllOrders extends Component {
  render() {
    let { history } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">All Orders</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">All Orders</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="All Orders"
              apiURL={`${getOrdersListAPI.url}?page=1&limit=20`}
              dataTableStateKey={getOrdersListAPI.key}
              filterFromComponent={FilterUI}
              searchButtonOnTop={true}
              defaultFilterFormObject={{
                status: "",
                paymentMethod: "",
                deliveryType: "",
                phone: "",
                name: "",
                consignmentNo: "",
                trackingNumber: "",
                dateTo: null,
                dateFrom: null,
                channelOrderNo: "",
              }}
              clearErrors={() => {
                const { setFormErrorFn } = this.props;
                setFormErrorFn("ordersListAPIKey_search", [])
              }}
              onRebuildFilterObjFn={(filterData) => {
                const data = filterData;
                let { setFormErrorFn } = this.props;
                const errors = [];

                if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                  errors.push({
                    "property":"dateTo",
                    "error":"The Date To field should not be less than the Date From field"
                  });
                }

                if(errors.length > 0 ){
                  setFormErrorFn("ordersListAPIKey_search", errors);
                  return ;
                }else{
                  setFormErrorFn("ordersListAPIKey_search", []);
                }

                if (!isEmptyValue(filterData.dateTo)) {
                  data["dateTo"] = formatToDateHelper(filterData.dateTo);
                }

                if (!isEmptyValue(filterData.dateFrom)) {
                  data["dateFrom"] = filterData.dateFrom.toISOString();
                }

                return data;
              }}
              isSetCreateAction={true}
              createActionProps={{
                actionType:actionBtnTypes.create,
                isSetModel:false,
                onClickBtnFn: () => history.push("/orders/pending/create")
              }}
              tableHeaderList={[
                { displayName: "Consignment No", key: "consignmentNo" },
                { displayName: "Tracking No", key: "trackingNumber", onPrintFn: (rawData) => (rawData.trackingNumber) ? 
                  <Link to={{ pathname: `${process.env.REACT_APP_BODA_CORPORATE_TRACK_BASE_URL}${rawData.trackingNumber}`}} target="_blank">{rawData.trackingNumber}</Link> : "-"
                },
                { displayName: "Customer Name", key: "name", onPrintFn: (rawData) => customerNameShortHelperFn(rawData.name)},
                { displayName: "Phone", key: "phone" },
                { displayName: "Delivery Type", key: "deliveryType" },
                { displayName: "Payment Method", key: "paymentMethod", onPrintFn: (rawData) => mapOrderPaymentMethods[rawData.paymentMethod] },
                { displayName: "Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt)},
                { displayName: "Status", key: "status", onPrintFn: (rawData) => {
                  if (rawData?.status === "PACKED" && rawData.packages[0]?.status !== "PACKED"){
                    return "PENDING DELIVERY";
                  }else{
                    return rawData.status;
                  }
                }},
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => {
                      if (rawData.status === "PENDING") {
                        history.push(`/orders/pending/view/${rawData.id}`);
                      } else if (rawData.status === "PICKED") {
                        history.push(`/orders/picked/view/${rawData.id}`);
                      } else if (rawData.status === "PACKED") {
                        history.push(`/orders/packed/view/${rawData.id}`);
                      } else if (rawData.status === "DELIVERED") {
                        history.push(`/orders/all/delivered/view/${rawData.id}`);
                      } else if (rawData.status === "TERMINATED") {
                        history.push(`/orders/all/terminated/view/${rawData.id}`);
                      } else if (rawData.status === "RETURNED_TO_MERCHANT") {
                        history.push(`/orders/all/returned/view/${rawData.id}`);
                      } else if (rawData.status === "READY_FOR_RETURN") {
                        history.push(`/orders/all/return/view/${rawData.id}`);
                      } else {
                        history.push(`/orders/all/cancelled/view/${rawData.id}`);
                      }
                    },
                  }
                },
                {
                  actionType:actionBtnTypes.edit,
                  isSetModel:false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/orders/pending/edit/${rawData.id}`),
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.status === "PENDING";
                  }
                },
                {
                  actionType: actionBtnTypes.other,
                  actionBtn: {
                    tooltip: "Cancel Order",
                    color: "danger",
                    icon: "far fa-times-circle",
                    btnText: "cancel",
                  },
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RemoveMessage,
                    modalFooterComponent: RemoveBtn,
                    isSetClose:true,
                    isSetFooterClose:true,
                    parentToModelProps: {
                      type: "datatable"
                    },
                    closeBtnName: "No"
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    if (rawData.rowData.status === "CANCELLED" || rawData.rowData.status === "PICKED" || rawData.rowData.status === "PACKED" || rawData.rowData.status === "TERMINATED") {
                      return false;
                    }else if (get(rawData, "rowData.packages", []).length === 0) {
                      return true;
                    } else {
                      const check = findIndex(get(rawData, "rowData.packages", []), (element) => {
                        return element.manifestId !== null;
                      });

                      return check === -1;
                    }
                  }
                },
                {
                  actionType: actionBtnTypes.other,
                  actionBtn: {
                    tooltip: "Restore",
                    color: "success",
                    icon: "fas fa-redo",
                    btnText: "restore",
                  },
                  isSetModel: true,
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: RestoreUIMessage,
                    modalFooterComponent: RestoreBtn,
                    isSetClose:true,
                    isSetFooterClose:true,
                    parentToModelProps: {
                      type: "datatable"
                    },
                    closeBtnName: "No"
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.status === "CANCELLED";
                  }
                },
                {
                  actionType: actionBtnTypes.create,
                  isSetModel: false,
                  actionBtn: {
                    tooltip: "Create Package",
                    onClickBtnFn: (rawData) => history.push(`/orders/picked/create/${rawData.id}`),
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    if (rawData.rowData.status === "PACKED" || rawData.rowData.status === "DELIVERED" || rawData.rowData.status === "CANCELLED" || rawData.rowData.status === "PENDING"){
                      return false;
                    }else if (rawData.rowData.contract !== null && rawData.rowData.contract.maxPackageCount !== null){
                      return rawData.rowData.packages.length !== rawData.rowData.contract.maxPackageCount ;
                    }else if (rawData.rowData.status === "PICKED"){
                      return true;
                    }else{
                      return false;
                    }
                  }
                }
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData
  };
};

export default connect(mapStateToProps, {
  setFormErrorFn,
}) (AllOrders);
