import React, { useEffect } from 'react';
import _, { findIndex, get } from 'lodash';
import { useSelector } from 'react-redux';
import JsBarcode from 'jsbarcode';
import ShippingLabelLogo from "../../../../assets/images/BodaBoda_logoFA-01.png"

import {
  FormWrapper,
} from '../../../../components/boda-boda/Forms';

const ShippingLabelUI = (props) => {
  const formState = useSelector((state) => state.FormData);

  useEffect(() => {
    if (get(formState, 'shippingLabelView._onLoad', true) === false) {
      props.packages.map((item, index) => {
        JsBarcode(`#shippingLabelBarcode${index}`, get(item, `articleNo`, ''), {
          width: 1,
          height: 50,
          margin: 0,
        });
      });
    }
  }, [get(formState, 'shippingLabelView._onLoad', true)]);

  const getPackageNo = (data = [], id) => {
    const index = findIndex(data, { id: parseInt(id) });

    if (index !== -1) {
      return `${index + 1}/${data.length}`;
    } else {
      return '';
    }
  };

  return (
    <div id={'print_preview_multiple'}>
      {props.packages.map((item, index) => {
        return (
          <div className={'printPreviewWrapper break_page content'}>
            <FormWrapper setGroupName={'shippingLabelView'}>
              <div>
                <img src={ShippingLabelLogo} style={{ width: '25%', height: '30%', marginBottom: '2%' }} />
                <div className={'row'}>
                  <div className={'col-sm-6'}>
                    <dl className={'row shipping-label-md-font'} >
                      <dd className={'col-sm-5 abc'}>Order No</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `order.channelOrderNo`, 'N/A') !== null ? get(item, `order.channelOrderNo`, 'N/A') : "N/A"}
                      </dt>

                      <dd className={'col-sm-5'}>Article ID</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `articleNo`, '')}
                      </dt>

                      <dd className={'col-sm-5'}>Consignment No</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `order.consignmentNo`, '')}
                      </dt>

                      <dd className={'col-sm-5'}>Package No</dd>
                      <dt className={'col-sm-7'}>
                        {getPackageNo(
                          get(item, `order.packages`, []),
                          get(item, `id`, '')
                        )}
                      </dt>
                    </dl>
                  </div>

                  <div className={'col-sm-6'}>
                    <dl className={'row shipping-label-md-font'} >
                      <dd className={'col-sm-5'}>Package Category</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `category.name`, '')}
                      </dt>

                      <dd className={'col-sm-5'}>Signature on Delivery</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `order.signatureRequired`, true) === true
                          ? 'Yes'
                          : 'No'}
                      </dt>

                      <dd className={'col-sm-5'}>Payment Type</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `order.paymentMethod`, '')}
                      </dt>
                    </dl>
                  </div>

                  <div className={'col-sm-6'} style={{ margin: ' 0 auto', width: '100%', fontSize: '16px' }}>
                    <img id={`shippingLabelBarcode${index}`} style={{ width: '100%', height: '90%' }} />
                  </div>
                </div>
                <hr />
                <div className={'row'}>
                  <div className={'col-sm-6'}>
                    <dl className={'row shipping-label-lg-font'} >
                      <dd className={'col-sm-5'}>Deliver To</dd>
                      <dt className={'col-sm-7'}>
                        <div>{get(item, `order.name`, '')}</div>
                        <div>
                          {get(item, `order.shippingLocation.address`, '')}
                        </div>
                        <div>
                          {get(item, `order.shippingLocation.city.name`, '')}
                        </div>
                        <div>
                          {get(
                            item,
                            `order.shippingLocation.city.district.name`,
                            ''
                          )}
                        </div>
                        <div>
                          {get(
                            item,
                            `order.shippingLocation.city.district.province.name`,
                            ''
                          )}
                        </div>
                      </dt>
                    </dl>
                  </div>

                  <div className={'col-sm-6'}>
                    <dl className={'row shipping-label-lg-font'} >
                      <dd className={'col-sm-5'}>Phone</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `order.phone`, '')}
                      </dt>
                    </dl>
                  </div>
                </div>
                <hr />
                <div className={'row'}>
                  <div className={'col-sm-6'}>
                    <dl className={'row shipping-label-md-font'} >
                      <dd className={'col-sm-5'}>Sender</dd>
                      <dt className={'col-sm-7'}>
                        <div>{get(item, `merchant.name`, '')}</div>
                        <div>{get(item, `merchantBranch.name`, '')}</div>
                        <div>
                          {get(item, `merchantBranch.location.address`, '')}
                        </div>
                        <div>
                          {get(item, `merchantBranch.location.city.name`, '')}
                        </div>
                        <div>
                          {get(item, `merchantBranch.location.postalCode`, '')}
                        </div>
                      </dt>
                    </dl>
                  </div>

                  <div className={'col-sm-6'}>
                    <dl className={'row shipping-label-md-font'} >
                      <dd className={'col-sm-5'}>Phone</dd>
                      <dt className={'col-sm-7'}>
                        {get(item, `merchant.contacts[0].phone`, '')}
                      </dt>
                    </dl>
                  </div>
                </div>
                <hr />
                <div className={'row'}>
                  <div className={'col-sm-12'}>
                    <dl className={'row shipping-label-md-font'} >
                      <dd className={'col-sm-2'}>Special Notes</dd>
                      <dt className={'col-sm-10'}>
                        <span style={{ marginLeft: '32px' }}>
                          {get(item, `notesPublic`, '')}
                        </span>
                      </dt>
                    </dl>
                  </div>
                </div>
              </div>
            </FormWrapper>
            <hr
              style={{ borderTop: '2px dotted #4453CC' }}
              className='seperator'
            />
          </div>
        );
      })}
    </div>
  );
};

export { ShippingLabelUI };
