import { takeEvery, fork, all } from 'redux-saga/effects';

import { createUserFn, updateUserFn } from "./userSaga";
import { updateManifestId, redraftManifest } from "./manifestSaga";
import {
    createUserTrKey,
    updateManifestIdTrKey,
    redraftManifestTrKey,
    updateUserProfileTrKey,
    changePasswordTrKey,
    updateUserTrKey
} from "./actionTypes";
import { changePassword, updateProfileDetails } from "./userProfileSaga";


function* watchAPIcallRequest() {
    yield takeEvery(createUserTrKey, createUserFn);
}

function* watchAPIUpdateManifestId() {
    yield takeEvery(updateManifestIdTrKey, updateManifestId);
}

function* watchAPIRedraftManifest() {
    yield takeEvery(redraftManifestTrKey, redraftManifest);
}

function* watchAPIUpdateUserProfile() {
    yield takeEvery(updateUserProfileTrKey, updateProfileDetails);
}

function* watchAPIChangePassword() {
    yield takeEvery(changePasswordTrKey, changePassword);
}

function* watchAPIUpdateUser() {
    yield takeEvery(updateUserTrKey, updateUserFn);
}

function* transactionSaga() {
    yield all([
        fork(watchAPIcallRequest),
        fork(watchAPIUpdateManifestId),
        fork(watchAPIRedraftManifest),
        fork(watchAPIUpdateUserProfile),
        fork(watchAPIChangePassword),
        fork(watchAPIUpdateUser),
    ])
}

export default transactionSaga;
