import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import FilterUI from "./user_includes/FilterUI";
import { Message, DeactivateBtn } from "./user_includes/DeactivateUI";
import { getUserStaffList } from "../../../config/boda-boda/apiUrls.config";


class UserUI extends Component {
  render() {
    let { history, authState } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Staff</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Staff</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Staff"
              filterFromComponent={FilterUI}
              apiURL={`${getUserStaffList.url}?page=1&limit=20`}
              dataTableStateKey={getUserStaffList.key}
              defaultFilterFormObject={{ name: "", phone: "", status: "", roleId: "" }}
              createActionProps={{
                actionType: actionBtnTypes.create,
                isSetModel: false,
                onClickBtnFn: () => history.push("/users/create")
              }}
              tableHeaderList={[
                { displayName: "Name", key: "name" },
                { displayName: "Phone", key: "phone", default: "-" },
                { displayName: "Branch", key: "merchantBranch.name", default: "-" },
                { displayName: "Role", key: "user.role.name", default: "-" },
                { displayName: "Status", key: "status" },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/users/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.edit,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/users/edit/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.delete,
                  isSetModel: true,
                  actionBtn: {
                    tooltip: "Deactivate",
                  },
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: Message,
                    modalFooterComponent: DeactivateBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                    parentToModelProps: {
                      type: "datatable",
                      status: "INACTIVE",
                      message: "Are you sure you want to deactivate this user?",
                      flashMessage: "Successfully deactivated the user"
                    },
                    closeBtnName: "No"
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.status === "ACTIVE" && rawData.rowData.userId !== get(authState, "authUser.id", "");
                  }
                },
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: true,
                  actionBtn: {
                    tooltip: "Activate",
                    color: "success",
                    icon: "far fa-check-circle",
                    btnText: "activate",
                  },
                  modalProps: {
                    modalTitle: "Please Confirm",
                    modalType: "",
                    modalBodyComponent: Message,
                    modalFooterComponent: DeactivateBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                    parentToModelProps: {
                      type: "datatable",
                      status: "ACTIVE",
                      message: "Are you sure you want to activate this user?",
                      flashMessage: "Successfully activated the user"
                    },
                    closeBtnName: "No"
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return rawData.rowData.status === "INACTIVE" && rawData.rowData.userId !== get(authState, "authUser.id", "");
                  }
                }
              ]}
            />
          </Row>


        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    authState: state.AuthState
  };
};

export default connect(mapStateToProps, null)(UserUI);
