import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { get, findIndex } from "lodash";

import { InputButton, FormWrapper } from "../../../../components/boda-boda/Forms";
import { getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { ShippingLabel } from "./ShipingLabel";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { DeleteModal } from "./DeleteUI";
import { roundValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import {
    currency,
    lengthMetric,
    mapOrderPaymentMethods,
    mapPackageStatusesNames,
    weightMetric
} from "../../../../config/boda-boda/db_config/db_status.config";
import { roundUpTypes } from "../../../../config/boda-boda/core.config";


class ViewUI extends Component {
    getPackageNo = (data = [], id) => {
        const index = findIndex(data, { id: parseInt(id) });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return "";
        }
    }

    render() {
        let { match, history, formState } = this.props;
        console.log()
        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${getPackageListAPI.key}_view`}
                    apiUrl={`${getPackageListAPI.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            id: response.id,
                            type: response.type,
                            code: response.code,
                            articleNo: response.articleNo,
                            category: response.category,
                            weight: response.weight,
                            height: response.height,
                            width: response.width,
                            length: response.length,
                            status: response.status,
                            deletedAt: response.deletedAt,
                            deliveryType: response.deliveryType,
                            order: response.order,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            merchantId: response.merchantId,
                            manifest: response.manifest,
                            expressFee: response.expressFee,
                            totalPrice: response.totalPrice,
                            totalFlatPrice: response.totalFlatPrice,
                            merchantFlatPrice: response.merchantFlatPrice,
                            priceType: response.priceType,
                            cashDeliveryAmount: response.cashDeliveryAmount,
                            cardDeliveryAmount: response.cardDeliveryAmount,
                            xlPackageHandlingFee: response.xlPackageHandlingFee,
                            merchantPrice: response.merchantPrice,
                            volumetric: response.volumetric,
                            metric: response.metric,
                            notDeliveredReason: response.notDeliveredReason,
                            merchantBranch: response.merchantBranch,
                            merchant: response.merchant,
                            pickUp: get(response, "locationPick.name", ""),
                            pendingPriceCalculation: response.pendingPriceCalculation,
                            contract: {
                                chargingMethod: response.contract.chargingMethod,
                            },
                            courier: response.courier,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View a Package</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    path={"/package/packages"}
                                    elementWrapperStyle={"mr-2"}
                                />

                                {
                                    get(formState, `${getPackageListAPI.key}_view.deletedAt`, "") === null ? (
                                        <Fragment>
                                            {
                                             
                                            get(formState, `${getPackageListAPI.key}_view.pendingPriceCalculation`, false) === true ?(
                                                null ) :
                                                (
                                                get(formState, `${getPackageListAPI.key}_view.status`, "") === "PACKED" ?(
                                                    <InputButton
                                                        elementWrapperStyle={"mr-2"}
                                                        btnText={"Edit"}
                                                        startIcon={"mdi mdi-square-edit-outline"}
                                                        onClickBtnFn={() => history.push(`/package/packages/edit/${match.params.id}`)}
                                                    />
                                                ) : null )
                                            } 

                                            <ShippingLabel
                                                package={{ ...get(formState, `${getPackageListAPI.key}_view`, {}), id: match.params.id }}
                                            />

                                            {
                                                get(formState, `${getPackageListAPI.key}_view.deletedAt`, null) === null && get(formState, `${getPackageListAPI.key}_view.status`, "") === "PACKED" ? (
                                                    <DeleteModal
                                                        packageId={match.params.id}
                                                        history={history}
                                                    />
                                                ) : null
                                            }
                                        </Fragment>
                                    ) : null
                                }
                            </div>
                        </Col>
                    </Row>


                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-3">Package Details</h4>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Article No</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.articleNo`, "")}</dt>

                                                <dd className={"col-sm-4"}>Consignment No</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.consignmentNo`, "")}</dt>

                                                <dd className={"col-sm-4"}>Package No</dd>
                                                <dt className={"col-sm-8"}>{this.getPackageNo(get(formState, `${getPackageListAPI.key}_view.order.packages`, []), match.params.id)}</dt>

                                                <dd className={"col-sm-4"}>Signature Required</dd>
                                                <dt className={"col-sm-8"}>
                                                    {
                                                        get(formState, `${getPackageListAPI.key}_view.order.signatureRequired`, false) === false ? "No" : "Yes"
                                                    }
                                                </dt>

                                                <dd className={"col-sm-4"}>Package Type</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.type.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Package Category</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "")}</dt>

                                                {
                                                    (get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "") !== "PREPAID") ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-4"}>Order Amount</dd>
                                                            <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.order.amount`, "")} {currency}</dt>
                                                        </Fragment>
                                                    ) : null
                                                }

                                                <dd className={"col-sm-4"}>Pick Up Location</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.pickUp`, "")}</dt>

                                                <dd className={"col-sm-4"}>Payment Method</dd>
                                                <dt className={"col-sm-8"}>{mapOrderPaymentMethods[get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "")]}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Weight</dd>
                                                <dt className={"col-sm-8"}>{ get(formState, `${getPackageListAPI.key}_view.weight`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.weight`, "")} ${weightMetric}`: "N/A"}</dt>

                                                <dd className={"col-sm-4"}>Length</dd>
                                                <dt className={"col-sm-8"}>{ get(formState, `${getPackageListAPI.key}_view.length`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.length`, "")} ${lengthMetric}`: "N/A"}</dt>

                                                <dd className={"col-sm-4"}>Width</dd>
                                                <dt className={"col-sm-8"}>{ get(formState, `${getPackageListAPI.key}_view.width`, "") !== null ? `${get(formState, `${getPackageListAPI.key}_view.width`, "")} ${lengthMetric}`: "N/A"}</dt>

                                                <dd className={"col-sm-4"}>Height</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.height`, "")  !== null ? `${get(formState, `${getPackageListAPI.key}_view.height`, "")} ${lengthMetric}`: "N/A"}</dt>
                                                {get(formState, `${getPackageListAPI.key}_view.contract.chargingMethod`, "") === "VOLUMETRIC" ?
                                                    <React.Fragment>
                                                        <dd className={"col-sm-4"}>Volumetric Weight</dd>
                                                        <dt className={"col-sm-8"}>{roundValue(get(formState, `${getPackageListAPI.key}_view.volumetric`, ""))}</dt>
                                                    </React.Fragment>
                                                    :
                                                        null
                                                }

                                                <dd className={"col-sm-4"}>Delivery Type</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.deliveryType`, "")}</dt>

                                                <dd className={"col-sm-4"}>Status</dd>
                                                <dt className={"col-sm-8"}>{mapPackageStatusesNames[get(formState, `${getPackageListAPI.key}_view.status`, "")]}</dt>

                                                {
                                                    get(formState, `${getPackageListAPI.key}_view.status`) === "NOT_DELIVERED" ? (
                                                        <Fragment>
                                                            <dd className={"col-sm-4"}>Reason</dd>
                                                            <dt className={"col-sm-8"}>{get(formState, `${getPackageListAPI.key}_view.notDeliveredReason`, "")}</dt>
                                                        </Fragment>
                                                    ) : null
                                                }

                                                {get(formState, `${getPackageListAPI.key}_view.deletedAt`) !== null ? (
                                                    <Fragment>
                                                        <dd className={"col-sm-4"}>Deleted At</dd>
                                                        <dt className={"col-sm-8"}>{dateObjectToString(
                                                            get(formState, `${getPackageListAPI.key}_view.deletedAt`, ""),
                                                            "YY-mm-dd HH:MM:II"
                                                        )
                                                        }</dt>
                                                    </Fragment>

                                                ) : null

                                                }

                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <h4 className="card-title mb-3">Receiver Details</h4>

                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Full Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>Email</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.email`, "")}</dt>

                                                <dd className={"col-sm-5"}>Phone</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.phone`, "")}</dt>

                                                {/* <dd className={"col-sm-5"}>Channel Order Number</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.channelOrderNo`, "")}</dt> */}

                                                {/*<dd className={"col-sm-5"}>Channel</dd>*/}
                                                {/*<dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.channel`, "")}</dt>*/}

                                                <dd className={"col-sm-5"}>ID Verification Required</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === false ? "No" : "Yes"
                                                    }
                                                </dt>

                                                {
                                                    get(formState, `${getPackageListAPI.key}_view.order.idVerificationRequired`, false) === true ? (
                                                        <Fragment>
                                                            {
                                                                (get(formState, `${getPackageListAPI.key}_view.order.idVerificationType`, null) !== null && get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, null) !== null) ? (
                                                                    <Fragment>
                                                                        <dd className={"col-sm-5"}>ID Verification Type</dd>
                                                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationType`, "")}</dt>
                                                                    </Fragment>
                                                                ) : null
                                                            }

                                                            {
                                                                get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, null) !== null ? (
                                                                    <Fragment>
                                                                        <dd className={"col-sm-5"}>ID Verification Reference</dd>
                                                                        <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.idVerificationReference`, "")}</dt>
                                                                    </Fragment>
                                                                ) : null
                                                            }
                                                        </Fragment>
                                                    ) : null
                                                }
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <h4 className="card-title mb-3">Shipping Location</h4>

                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Location Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>Address</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.address`, "")}</dt>

                                                <dd className={"col-sm-5"}>Postal Code</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.postalCode`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Plus Code</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.plusCode`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Latitude</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lat`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Longitude</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.lon`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Province</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.province.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>District</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.district.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>City</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.city.name`, "")}</dt>

                                                {/* <dd className={"col-sm-5"}>Status</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.order.shippingLocation.status`, "")}</dt> */}
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <h4 className="card-title mb-3">Notes</h4>

                                            <dl className={"row"}>
                                                <dd className={"col-sm-2"}>Public Note</dd>
                                                <dt className={"col-sm-10"}>
                                                    {
                                                        get(formState, `${getPackageListAPI.key}_view.notesPublic`, null) !== null ? get(formState, `${getPackageListAPI.key}_view.notesPublic`, null) : "-"
                                                    }
                                                </dt>

                                                <dd className={"col-sm-2"}>Private Note</dd>
                                                <dt className={"col-sm-10"}>
                                                    {
                                                        get(formState, `${getPackageListAPI.key}_view.notesPrivate`, null) !== null ? get(formState, `${getPackageListAPI.key}_view.notesPrivate`, null) : "-"
                                                    }
                                                </dt>
                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-3">Delivery Charges</h4>

                                    {
                                        (get(formState, `${getPackageListAPI.key}_view.status`, "") === "PACKED") ? 
                                            (<h8>Delivery charges might vary after updating the package dimensions or when it is picked up.</h8>) : (null)
                                    }

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Rate Category</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.category.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ?"Delivery Charge": "Delivery Flat charge"}</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ? `${roundValue(get(formState, `${getPackageListAPI.key}_view.merchantPrice`, "0"))} ${currency}` :  `${roundValue(get(formState, `${getPackageListAPI.key}_view.merchantFlatPrice`, ""))} ${currency}`}</dt>
                                                {/*{*/}
                                                {/*    (get(formState, `${getPackageListAPI.key}_view.order.paymentMethod`, "") === "CASH_ON_DELIVERY") ?*/}
                                                {/*        <dt className={"col-sm-7"}>{`${get(formState, `${getPackageListAPI.key}_view.cashDeliveryAmount`, "0")} ${currency}`}</dt>*/}
                                                {/*        : <dt className={"col-sm-7"}>{`${get(formState, `${getPackageListAPI.key}_view.cardDeliveryAmount`, "0")} ${currency}`}</dt>*/}
                                                {/*}*/}

                                                <dd className={"col-sm-4"}>Express Delivery Charge</dd>
                                                <dt className={"col-sm-7"}>{`${roundValue(get(formState, `${getPackageListAPI.key}_view.expressFee`, "0"), 2, roundUpTypes.round)} ${currency}`}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>XL Handling Charge</dd>
                                                <dt className={"col-sm-7"}>{`${roundValue(get(formState, `${getPackageListAPI.key}_view.xlPackageHandlingFee`, "0"))} ${currency}`}</dt>

                                                <dd className={"col-sm-4"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ?"Total Charge":"Total Flat Charge"}</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getPackageListAPI.key}_view.priceType`, "") === "VL_RATE" ?`${roundValue(get(formState, `${getPackageListAPI.key}_view.totalPrice`, "0"))} ${currency}` : `${roundValue(get(formState, `${getPackageListAPI.key}_view.totalFlatPrice`, "0"))} ${currency}`}</dt>
                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ViewUIWithState = connect(mapStateToProps, null)(ViewUI);

export {
    ViewUIWithState
};
