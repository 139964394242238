import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { findIndex, get } from "lodash";
import { Col, Row } from "reactstrap";

import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
    SubmitButton
} from "../../../../components/boda-boda/Forms";
import {
    getPackageTypesListAPI, getPackageCategoryListAPI,
    getPackageListAPI, getReasonListAPI, getMerchantBranchAllAPI
} from "../../../../config/boda-boda/apiUrls.config";
import { changeInputFn } from "../../../../store/actions";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import {
    formObjectValidationHelper,
    getDataByFormObject, isEmptyValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { weightMetric, lengthMetric } from "../../../../config/boda-boda/db_config/db_status.config";


class EditPackage extends Component {
    packageTypeOnchangeFn = (eventData) => {
        let { changeInputFn } = this.props;
        const typeObj = get(this.props, `coreState.apiResponses[${getPackageTypesListAPI.key}].result`, []).find(e => e.id === eventData.value);

        if (typeObj !== undefined) {
            changeInputFn("editPackage", "weight", "", typeObj.weight);
            changeInputFn("editPackage", "height", "", typeObj.height);
            changeInputFn("editPackage", "length", "", typeObj.length);
            changeInputFn("editPackage", "width", "", typeObj.width);
        }
    }

    checkRecalculate = () => {
        let { formState, coreState } = this.props;

        const packageType = get(formState, "editPackage.type.id", "");
        const currentType = get(formState, "editPackage.currentType", "");
        const typeObj = get(coreState, `apiResponses[${getPackageTypesListAPI.key}].result`, []).find(e => e.id === packageType);

        if (get(formState, "editPackage.weight", "") !== null) {
            if (get(formState, "editPackage.weight", "").toString() !== typeObj.weight.toString()) {
                return true;
            }
        }

        if (get(formState, "editPackage.height", "") !== null) {
            if (get(formState, "editPackage.height", "").toString() !== typeObj.height.toString()) {
                return true;
            }
        }

        if (get(formState, "editPackage.length", "") !== null) {
            if (get(formState, "editPackage.length", "").toString() !== typeObj.length.toString()) {
                return true;
            }
        }

        if (get(formState, "editPackage.width", "") !== null) {
            if (get(formState, "editPackage.width", "").toString() !== typeObj.width.toString()) {
                return true;
            }
        }

        if (packageType.toString() !== currentType.toString()) {
            return true;
        }

        return false;
    }

    getPackageNo = (data = [], id) => {
        const index = findIndex(data, { id: parseInt(id) });

        if (index !== -1) {
            return `${index + 1}/${data.length}`;
        } else {
            return "";
        }
    }

    render() {
        let { match, formState, history } = this.props;

        return (
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={"editPackage"}
                    apiUrl={`${getPackageListAPI.url}/${match.params.id}`}
                    onDestroyUnsetFormObject={true}
                    onRebuildResponseFn={(response) => {
                        return {
                            type: {
                                id: response.typeId
                            },
                            currentType: response.typeId,
                            category: {
                                id: response.categoryId
                            },
                            locationPick: {
                                id: response.locationPick.id
                            },
                            weight: response.weight,
                            height: response.height,
                            width: response.width,
                            length: response.length,
                            status: response.status,
                            deliveryType: response.deliveryType,
                            order: response.order,
                            articleNo: response.articleNo,
                            notesPublic: response.notesPublic,
                            notesPrivate: response.notesPrivate,
                            onHold: response.onHold,
                            onholdReason: response.onholdReason,
                            chargingMethod: response.contract.chargingMethod,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">Edit a Package</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <EditPackageBtn
                                    packageId={match.params.id}
                                    historyFn={history}
                                    formState={formState}
                                    checkRecalculateFn={this.checkRecalculate}
                                />

                                <BackBtnComponent
                                    path={"/package/packages"}
                                    btnName={"Cancel"}
                                    icon={"far fa-times-circle"}
                                    btnColor={"danger"}
                                    elementWrapperStyle={"mr-2"}
                                    isModal={true}
                                />
                            </div>

                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">

                                    <div className={"row"}>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Article No</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "editPackage.articleNo", "")}</dd>

                                                <dt className={"col-sm-5"}>Package No</dt>
                                                <dd className={"col-sm-7"}>{this.getPackageNo(get(formState, "editPackage.order.packages", []), match.params.id)}</dd>

                                                <dt className={"col-sm-5"}>Delivery Type</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "editPackage.deliveryType", "")}</dd>
                                            </dl>
                                        </div>

                                        <div className="col-md-6">
                                            <dl className={"row mb-0"}>
                                                <dt className={"col-sm-5"}>Order No</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "editPackage.order.id", "")}</dd>

                                                <dt className={"col-sm-5"}>Customer Name</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "editPackage.order.name", "")}</dd>

                                                <dt className={"col-sm-5"}>Status</dt>
                                                <dd className={"col-sm-7"}>{get(formState, "editPackage.status", "")}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText={"Package Type"}
                                                placeHolderText={"Package Type"}
                                                inputStatePath={"editPackage.type.id"}
                                                formGroupName={"editPackage"}
                                                apiUrl={`${getPackageTypesListAPI.url}/all`}
                                                apiStateKey={getPackageTypesListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                                onChangeFn={(eventData) => this.packageTypeOnchangeFn(eventData)}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText={"Package Category"}
                                                placeHolderText={"Package Category"}
                                                inputStatePath={"editPackage.category.id"}
                                                formGroupName={"editPackage"}
                                                apiUrl={`${getPackageCategoryListAPI.url}`}
                                                apiStateKey={getPackageCategoryListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? false : true}
                                                labelText={`Weight (${weightMetric})`}
                                                placeHolderText={"Weight"}
                                                inputName={"weight"}
                                                formGroupName={"editPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? true : false}
                                                labelText={`Height (${lengthMetric})`}
                                                placeHolderText={"Height"}
                                                inputName={"height"}
                                                formGroupName={"editPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? true : false}
                                                labelText={`Length (${lengthMetric})`}
                                                placeHolderText={"Length"}
                                                inputName={"length"}
                                                formGroupName={"editPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                isRequired={get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? true : false}
                                                labelText={`Width (${lengthMetric})`}
                                                placeHolderText={"Width"}
                                                inputName={"width"}
                                                formGroupName={"editPackage"}
                                                inputType={"number"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                labelText={"Pick Location"}
                                                isRequired={true}
                                                placeHolderText={"Pick Location"}
                                                inputStatePath={"editPackage.locationPick.id"}
                                                formGroupName={"editPackage"}
                                                apiUrl={`${getMerchantBranchAllAPI.url}`}
                                                apiStateKey={getMerchantBranchAllAPI.key}
                                                keyName={"locationId"}
                                                valueName={"name"}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText="OnHold"
                                                inputName={"onHold"}
                                                placeHolderText={"Select a OnHold"}
                                                formGroupName={"editPackage"}
                                                dataList={[
                                                    {
                                                        id: true,
                                                        value: "YES"
                                                    },
                                                    {
                                                        id: false,
                                                        value: "NO"
                                                    },
                                                ]}
                                            />
                                        </div>

                                        {
                                            (get(formState, `editPackage.onHold`, false) === true) ? (
                                                <Fragment>
                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            isRequired={true}
                                                            labelText={"OnHold Reason"}
                                                            placeHolderText={"OnHold Reason"}
                                                            inputName={"onholdReason"}
                                                            formGroupName={"editPackage"}
                                                            apiUrl={`${getReasonListAPI.url}/all?type=ONHOLD`}
                                                            apiStateKey={getReasonListAPI.key}
                                                            keyName={"name"}
                                                            valueName={"name"}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ) : null
                                        }
                                        {
                                            (get(formState, `editPackage.onholdReason`, "") === "Other") ? (
                                                <Fragment>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText={"Reason"}
                                                            placeHolderText={"Reason"}
                                                            inputName={"reason"}
                                                            formGroupName={"editPackage"}
                                                            inputType={"textarea"}
                                                            rows={3}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ) : null
                                        }



                                    </div>

                                    {/* <hr /> */}
                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={"Public Note"}
                                                placeHolderText={"Public Note"}
                                                inputName={"notesPublic"}
                                                formGroupName={"editPackage"}
                                                inputType={"textarea"}
                                                rows={3}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <InputBoxWithState
                                                labelText={"Private Note"}
                                                placeHolderText={"Private Note"}
                                                inputName={"notesPrivate"}
                                                formGroupName={"editPackage"}
                                                inputType={"textarea"}
                                                rows={3}
                                            />
                                        </div>

                                        {/* <div className="col-md-6">
                                            <SelectBoxWithState
                                                isRequired={true}
                                                labelText={"Status"}
                                                placeHolderText={"Select Status"}
                                                inputName={"status"}
                                                formGroupName={"editPackage"}
                                                apiUrl={`${getPackageStatusListAPI.url}/all`}
                                                apiStateKey={getPackageStatusListAPI.key}
                                                keyName={"id"}
                                                valueName={"name"}
                                            />
                                        </div> */}

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </FormWrapper>
            </div>
        )
    }
}

const EditPackageBtn = (props) => {
    const { formState } = props;
    return (
        <Fragment>
            <SubmitButton
                btnText={"Update"}
                elementWrapperStyle={"d-flex justify-content-end mr-2"}
                startIcon={"mdi mdi-content-save-all-outline"}
                isValidate={true}
                dataTableKey={getPackageListAPI.key}
                formGroupName={"editPackage"}
                validationObject={{
                    fields: {
                        "type.id": "Package Type",
                        "category.id": "Package Category",
                        "locationPick.id": "Pick Location",
                        weight: "Weight",
                        height: "Height",
                        length: "Length",
                        width: "Width",
                        onHold: "OnHold",
                        onholdReason: "OnHold Reason",
                    },
                    rules: {
                        "type.id": "required",
                        "category.id": "required",
                        "locationPick.id": "required",
                        weight: get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? "max:5" : "required|max:5",
                        height: get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? "required|max:5" : "max:5",
                        length: get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? "required|max:5" : "max:5",
                        width: get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC" ? "required|max:5" : "max:5",
                        onHold: "required",
                        onholdReason: "requiredIf:onHold,true",
                    },
                    message: {
                        "weight.max": "The Weight may not be greater than 5 digits",
                        "height.max": "The Height may not be greater than 5 digits",
                        "length.max": "The Length may not be greater than 5 digits",
                        "width.max": "The Width may not be greater than 5 digits",
                        "onholdReason.requiredIf": "The OnHold Reason field is required when OnHold is YES",
                    }
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Package has successfully updated",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.apiRequestOnly,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${getPackageListAPI.url}/${props.packageId}`,
                        key: getPackageListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);
                    let { chargingMethod, ...newData } = data;

                    let temp = {
                        ...newData,
                        weight: formObjectValidationHelper(data, "weight", (value) => { return Number(value) }),
                        height: formObjectValidationHelper(data, "height", (value) => { return Number(value) }),
                        length: formObjectValidationHelper(data, "length", (value) => { return Number(value) }),
                        width: formObjectValidationHelper(data, "width", (value) => { return Number(value) }),
                        order: undefined,
                        onHold: formObjectValidationHelper(data, "onHold", (data) => { return data === true }),
                        currentType: undefined
                    }

                    if (isEmptyValue(get(data, "notesPublic", ""))) {
                        delete temp["notesPublic"];
                    }

                    if (isEmptyValue(get(data, "notesPrivate", ""))) {
                        delete temp["notesPrivate"];
                    }

                    if (get(data, "onHold", "") === false) {
                        temp["onholdReason"] = null;
                    }

                    // if (props.checkRecalculateFn() === true) {
                    //     temp["recalculate"] = true;
                    // }

                    // if (get(formState, "editPackage.chargingMethod", "") === "VOLUMETRIC"){
                    //     if (isEmptyValue(get(data, "weight", ""))) {
                    //         temp["weight"] = 0;
                    //     }
                    // }
                    // if (get(formState, "editPackage.chargingMethod", "") === "WEIGHT"){
                    //     if (isEmptyValue(get(data, "length", ""))) {
                    //         temp["length"] = 0;
                    //     }

                    //     if (isEmptyValue(get(data, "height", ""))) {
                    //         temp["height"] = 0;
                    //     }

                    //     if (isEmptyValue(get(data, "width", ""))) {
                    //         temp["width"] = 0;
                    //     }
                    // }
                    temp["recalculate"] = true;
                    
                    return temp;
                }}
                onResponseCallBackFn={(error, result) => {
                    console.log(error, "err");
                    console.log(result, "result");
                    if (!error) {
                        props.historyFn.push("/package/packages");
                    }
                }}
            />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        coreState: state.CoreState,
        formState: state.FormData
    };
};

const EditPackageComponent = connect(mapStateToProps, { changeInputFn })(EditPackage);

export {
    EditPackageComponent,
    EditPackageBtn
};