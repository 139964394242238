import React, { Fragment } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { FormWrapper, InputBoxWithState, SubmitButton } from "../../../../../../../components/boda-boda/Forms";
import { getOrdersItemsListAPI } from "../../../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../../../config/boda-boda/apiResponseKey";
import { getDataByFormObject } from "../../../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { formObjectValidationHelper } from "../../../../../../../helpers/boda-boda/common-helpers/common.helpers";


const CreateOrderItem = (props) => {
    return (
        <Fragment>
            <FormWrapper
                setGroupName={`${getOrdersItemsListAPI.key}_create`}
                setFormObject={{
                    id: get(props, "row.id", ""),
                    name: get(props, "row.name", ""),
                    quantity: get(props, "row.quantity", ""),
                    productNo: get(props, "row.productNo", ""),
                    order: get(props, "orderId", ""),
                    status: get(props, "row.status", "PENDING"),
                    type: get(props, "type", ""),
                }}
            >
                <InputBoxWithState
                    formGroupName={`${getOrdersItemsListAPI.key}_create`}
                    isRequired={true}
                    labelText="Product Number"
                    placeHolderText={"Enter the Product No"}
                    inputName={"productNo"}
                    maxLength={10}
                />

                <InputBoxWithState
                    formGroupName={`${getOrdersItemsListAPI.key}_create`}
                    isRequired={true}
                    labelText="Item Name"
                    placeHolderText={"Enter the Item Name"}
                    inputName={"name"}
                    maxLength={100}
                />

                <InputBoxWithState
                    formGroupName={`${getOrdersItemsListAPI.key}_create`}
                    isRequired={true}
                    labelText="Quantity"
                    placeHolderText={"Enter the Quantity"}
                    inputName={"quantity"}
                    inputType={"number"}
                    maxLength={5}
                />

                {/*<SelectBoxWithState*/}
                {/*    isRequired={true}*/}
                {/*    labelText="Status"*/}
                {/*    inputName={"status"}*/}
                {/*    placeHolderText={"Select a Status"}*/}
                {/*    formGroupName={`${getOrdersItemsListAPI.key}_create`}*/}
                {/*    dataList={orderStatusList}*/}
                {/*/>*/}
            </FormWrapper>
        </Fragment>
    )
}

const CreateOrderItemBtn = (props) => {
    const formState = useSelector(state => state.FormData);

    return (
        <Fragment>
            <SubmitButton
                elementWrapperStyle={"d-flex justify-content-end"}
                btnText={get(formState, `${getOrdersItemsListAPI.key}_create.type`, "create") === "create" ? "Create" : "Update"}
                formGroupName={`${getOrdersItemsListAPI.key}_create`}
                dataTableKey={getOrdersItemsListAPI.key}
                isValidate={true}
                validationObject={{
                    fields: {
                        name: "Item Name",
                        quantity: "Quantity",
                        productNo: "Product No",
                        status: "Status",
                    },
                    rules: {
                        name: "required",
                        quantity: "required|max:5|minAmount:1|wholeNumber",
                        productNo: "required",
                        status: "required",
                    },
                    message: {
                        "quantity.wholeNumber": "The Quantity has to be a whole number",
                        "quantity.max": "The Quantity may not be greater than 5 digits",
                        "quantity.minAmount": "The Quantity must be at least 1"
                    }
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: get(formState, `${getOrdersItemsListAPI.key}_create.type`, "create") === "create" ? "Order Item has successfully created" : "Order Item has successfully updated",
                        type: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: get(formState, `${getOrdersItemsListAPI.key}_create.type`, "create") === "create" ? "post" : "put",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    if (get(formState, `${getOrdersItemsListAPI.key}_create.type`, "create") === "create") {
                        return {
                            url: getOrdersItemsListAPI.url,
                            key: getOrdersItemsListAPI.key
                        }
                    } else {
                        return {
                            url: `${getOrdersItemsListAPI.url}/${formObject.id}`,
                            key: getOrdersItemsListAPI.key
                        }
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    const data = getDataByFormObject(formObject);

                    return {
                        ...data,
                        order: {
                            id: Number(props.match.params.id)
                        },
                        quantity: formObjectValidationHelper(data, "quantity", (data) => { return Number(data) }),
                        type: undefined,
                        id: undefined
                    }
                }}
                onResponseCallBackFn={(error, result) => {
                    if (!error) {
                        props.setModelStatusFn(false);
                    }
                }}
            />
        </Fragment>
    )
}

const CreateOrderItemBtnWithProps = withRouter(CreateOrderItemBtn);

export {
    CreateOrderItem,
    CreateOrderItemBtnWithProps
}
