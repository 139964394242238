import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { packageStatusReportAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { callApi } from "../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { fromObjectToQueryString, isEmptyValue, roundValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { currency, mapPackageStatusesNames, weightMetric } from "../../../../../config/boda-boda/db_config/db_status.config";


const TableLastRow = (props) => {
    return (
        <Fragment>
            <tr>
                <td colSpan={"36"}></td>
                <td>Total</td>
                <td>{roundValue(get(props, "tableBodyLastProps.expressFee", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.xlPackageHandlingFee", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.merchantPrice", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.merchantFlatPrice", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.totalPrice", ""))}</td>
                <td>{roundValue(get(props, "tableBodyLastProps.totalFlatPrice", ""))}</td>
            </tr>
        </Fragment>
    )
}

class PackageStatusReportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaderStatus: false
        }
    }

    getQueryString = () => {
        let { formState } = this.props;

        let createdDateTo = new Date(get(formState, "package_status_search.createdDateTo", new Date()));
        createdDateTo = new Date(createdDateTo.setDate(createdDateTo.getDate() + 1));
        createdDateTo = new Date(createdDateTo.setSeconds(createdDateTo.getSeconds() - 1));

        let deliveredDateTo = new Date(get(formState, "package_status_search.deliveredDateTo"));
        deliveredDateTo = new Date(deliveredDateTo.setDate(deliveredDateTo.getDate() + 1));
        deliveredDateTo = new Date(deliveredDateTo.setSeconds(deliveredDateTo.getSeconds() - 1));

        let returnedDateTo = new Date(get(formState, "package_status_search.returnedDateTo"));
        returnedDateTo = new Date(returnedDateTo.setDate(returnedDateTo.getDate() + 1));
        returnedDateTo = new Date(returnedDateTo.setSeconds(returnedDateTo.getSeconds() - 1));

        const queryObj = {
            createdDateFrom: get(formState, "package_status_search.createdDateFrom", new Date()).toISOString(),
            createdDateTo: createdDateTo.toISOString(),
            deliveryType: get(formState, "package_status_search.deliveryType", ""),
            status: get(formState, "package_status_search.status", ""),
            paymentMethod: get(formState, "package_status_search.paymentMethod", ""),
            pickedUp: get(formState, "package_status_search.pickedUp", ""),
            returnedDateFrom: get(formState, "package_status_search.returnedDateFrom") ? get(formState, "package_status_search.returnedDateFrom").toISOString() : undefined,
            returnedDateTo: isEmptyValue(get(formState, "package_status_search.returnedDateTo")) ? undefined : returnedDateTo.toISOString(),
            deliveredDateFrom: get(formState, "package_status_search.deliveredDateFrom", new Date()) ? get(formState, "package_status_search.deliveredDateFrom", new Date()).toISOString() : undefined,
            deliveredDateTo: get(formState, "package_status_search.deliveredDateTo") ? deliveredDateTo.toISOString() : undefined,
            merchantBranchId: get(formState, "package_status_search.merchantBranchId").split("/")[0],
        }

        return `&${fromObjectToQueryString(queryObj)}`;
    }

    getAttemptedCount = (rawData) => {
        let counter = 0;
        if (rawData && rawData.packageHistories) {
            rawData.packageHistories.forEach((packageHistory) => {
                if (packageHistory && packageHistory.status === "DELIVERED" || packageHistory.status === "NOT_DELIVERED") {
                    counter++;
                }
            });
        }
        return counter;
    };

    render() {
        let { formState, coreState } = this.props;
        return (
            <React.Fragment>
                {
                    (get(formState, "package_status_search.createdDateFrom", "") === "" || get(formState, "package_status_search.createdDateTo", "") === "") ? (
                        <Redirect to={"/reports/package-status-report"} />
                    ) : (
                        <div className="container-fluid">
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <div className="page-title-box">
                                        <h4 className="font-size-18">Package Status Report</h4>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            Created From<strong className={"mx-2"}>{dateObjectToString(get(formState, "package_status_search.createdDateFrom"), "dd/mm/YY")}</strong>to<strong className={"mx-2"}>{dateObjectToString(get(formState, "package_status_search.createdDateTo"), "dd/mm/YY")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Delivered From:<strong className={"mx-2"}>{get(formState, "package_status_search.deliveredDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.deliveredDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.deliveredDateTo"), "dd/mm/YY")}` : "All"}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Returned From:<strong className={"mx-2"}>{get(formState, "package_status_search.returnedDateFrom") ? `${dateObjectToString(get(formState, "package_status_search.returnedDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "package_status_search.returnedDateTo"), "dd/mm/YY")}` : "All"}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Payment Type:<strong className={"mx-2"}>{get(formState, "package_status_search.paymentMethod", "") === "" ? "All" : get(formState, "package_status_search.paymentMethod", "")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Delivery Type:<strong className={"mx-2"}>{get(formState, "package_status_search.deliveryType", "") === "" ? "All" : get(formState, "package_status_search.deliveryType", "")}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Package Status:<strong className={"mx-2"}>{get(formState, "package_status_search.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "package_status_search.status", "")]}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Picked Up:<strong className={"mx-2"}>{get(formState, "package_status_search.pickedUp", "") === "" ? "All" : get(formState, "package_status_search.pickedUp", "") === "true" ? "Yes" : "No"}</strong>
                                        </div>

                                        <div className={"col-sm-12"}>
                                            Merchant Branch:<strong className={"mx-2"}>{get(formState, "package_status_search.merchantBranchId", "") === "" ? "All" : get(formState, "package_status_search.merchantBranchId", "").split("/")[1]}</strong>
                                        </div>

                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className={"d-flex justify-content-end"}>
                                        <BackBtnComponent
                                            path={"/reports/package-status-report"}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className={"row mt-3"}>
                                <CrudComponent
                                    title={"Package Status Report"}
                                    apiURL={`${packageStatusReportAPI.url}?page=1&limit=20${this.getQueryString()}`}
                                    dataTableStateKey={packageStatusReportAPI.key}
                                    isFilter={false}
                                    isSetCreateAction={false}
                                    isDropdownActionBtn={false}
                                    isSetAction={false}
                                    tableHeaderList={[
                                        {
                                            displayName: "Created Date", key: "createdAt",
                                            onPrintFn: (rawData) => {
                                                if (rawData?.createdAt) {
                                                    return dateObjectToString(rawData.createdAt)
                                                } else {
                                                    return ""
                                                }
                                            }
                                        },
                                        { displayName: "Article ID", key: "articleNo" },
                                        { displayName: "Tracking Number", key: "order.trackingNumber" },
                                        { displayName: "Consignment ID", key: "order.consignmentNo" },
                                        {
                                            displayName: "Order Number", key: "order.channelOrderNo", onPrintFn: (rawData) => {
                                                return isEmptyValue(rawData?.order?.channelOrderNo) ? "-" : rawData?.order?.channelOrderNo;
                                            }
                                        },
                                        {
                                            displayName: "Manifest Code", key: "packageHistories[0].manifest.code", default: "-",
                                            onPrintFn: (rawData) => {
                                                return rawData?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0]?.manifest?.code;
                                            },
                                        },
                                        {
                                            displayName: `Pickup Runsheet Code`, key: "packageHistories[0].manifest.runSheetLast.code", default: "-",
                                            onPrintFn: (rawData) => {
                                                return rawData?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0]?.manifest?.runSheetLast?.code;
                                            },
                                        },
                                        { displayName: "Customer Name", key: "order.name" },
                                        { displayName: "Address", key: "order.shippingLocation.address" },
                                        { displayName: "City", key: "order.shippingLocation.city.name" },
                                        { displayName: "Customer Contact Number", key: "order.phone" },
                                        { displayName: "Zone", key: "order.shippingLocation.city.zone.name" },
                                        { displayName: "Merchant Code", key: "merchant.code" },
                                        { displayName: "Merchant Branch", key: "merchantBranch.name", default: "-" },
                                        { displayName: "Delivery Type", key: "deliveryType" },
                                        {
                                            displayName: "XL Package", key: "xlHandling", onPrintFn: (rawData) => {
                                                return rawData.xlHandling === false ? "No" : "Yes";
                                            }
                                        },
                                        {
                                            displayName: "Payment Type", key: "order.paymentMethod", onPrintFn: (rawData) => {

                                                if (rawData.order.paymentMethod === "CASH_ON_DELIVERY") {

                                                    return "CASH ON DELIVERY";

                                                } else if (rawData.order.paymentMethod === "CARD_ON_DELIVERY") {

                                                    return "CARD ON DELIVERY";

                                                } else if (rawData.order.paymentMethod === "PREPAID") {

                                                    return "PREPAID";
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Lodgement Location", key: "packageHistories[0].manifest.locationDrop.name",
                                            onPrintFn: (rawData) => {
                                                // return ""
                                                return rawData?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0]?.manifest?.locationDrop?.name;
                                            },
                                        },
                                        { displayName: "Last Mile Driver", key: "courier.staffs[0].name", default: "-" },
                                        { displayName: "Destination Delivery Center", key: "locationFinalFacility.name" },
                                        {
                                            displayName: `Picked Up`, key: "tookOverAt", default: "-", onPrintFn: (rawData) => {
                                                return isEmptyValue(rawData.tookOverAt) ? "No" : "Yes"
                                            }
                                        },
                                        {
                                            displayName: "Pick Up Date", key: "tookOverAt",
                                            onPrintFn: (rawData) => {
                                                // return " "    
                                                if (rawData.tookOverAt) {
                                                    return dateObjectToString(rawData.tookOverAt)
                                                } else {
                                                    return ""
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Status", key: "status", onPrintFn: (rawData) => {

                                                if (rawData.status === "PACKED") {
                                                    if (rawData.onHold === true) {
                                                        return "Packed" + "(On Hold)";
                                                    } else
                                                        return "Packed";

                                                } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                                    if (rawData.onHold === true) {
                                                        return "Package Picked up" + "(On Hold)";
                                                    } else
                                                        return "Package Picked up";

                                                } else if (rawData.status === "RECEIVED") {
                                                    if (rawData.onHold === true) {
                                                        return "Received at processing Center" + "(On Hold)";
                                                    } else
                                                        return "Received at processing Center";

                                                } else if (rawData.status === "SORTED") {
                                                    if (rawData.onHold === true) {
                                                        return "Ready for Transit" + "(On Hold)";
                                                    } else
                                                        return "Ready for Transit";

                                                } else if (rawData.status === "IN_TRANSIT_LINE") {
                                                    if (rawData.onHold === true) {
                                                        return "Intransit to regional hub" + "(On Hold)";
                                                    } else
                                                        return "Intransit to regional hub";

                                                } else if (rawData.status === "IN_TRANSIT_LAST") {
                                                    if (rawData.onHold === true) {
                                                        return "Out for Delivery" + "(On Hold)";
                                                    } else
                                                        return "Out for Delivery";

                                                } else if (rawData.status === "DELIVERED") {
                                                    if (rawData.onHold === true) {
                                                        return "Delivered" + "(On Hold)";
                                                    } else
                                                        return "Delivered";

                                                } else if (rawData.status === "NOT_DELIVERED") {
                                                    if (rawData.onHold === true) {
                                                        return "Failed Delivery" + "(On Hold)";
                                                    } else
                                                        return "Failed Delivery";
                                                } else if (rawData.status === "READY_FOR_RETURN") {
                                                    if (rawData.onHold === true) {
                                                        return "Ready for Return" + "(On Hold)";
                                                    } else
                                                        return "Ready for Return";
                                                } else if (rawData.status === "IN_TRANSIT_RETURN") {
                                                    if (rawData.onHold === true) {
                                                        return "Return Intransit" + "(On Hold)";
                                                    } else
                                                        return "Return Intransit";
                                                } else if (rawData.status === "RETURNED_TO_MERCHANT") {
                                                    if (rawData.onHold === true) {
                                                        return "Returned to Merchant" + "(On Hold)";
                                                    } else
                                                        return "Returned to Merchant";
                                                } else if (rawData.status === "NOT_RETURNED") {
                                                    if (rawData.onHold === true) {
                                                        return "Failed Return" + "(On Hold)";
                                                    } else
                                                        return "Failed Return";
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Status Reason", key: "status", onPrintFn: (rawData) => {

                                                if (rawData.status === "DELIVERED") {
                                                    return get(rawData, "order.remarkDelivery", "-");
                                                } else if (rawData.status === "NOT_DELIVERED" || rawData.status === "READY_FOR_RETURN" || rawData.status === "RETURNED_TO_MERCHANT") {
                                                    return get(rawData, "notDeliveredReason", "-")
                                                }
                                            }
                                        },
                                        {
                                            displayName: "COD Status", key: "order.codSettlementStatus",
                                            onPrintFn: (rawData) => {
                                                if (rawData?.order?.paymentMethod === "CASH_ON_DELIVERY") {
                                                    return rawData?.order?.codSettlementStatus
                                                } else {
                                                    return "-"
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Merchant Settled Date", key: "order.headOfficeSettlement.settledAt",
                                            onPrintFn: (rawData) => {
                                                if (rawData?.order?.headOfficeSettlement?.settledAt) {
                                                    return dateObjectToString(rawData?.order.headOfficeSettlement.settledAt)
                                                } else {
                                                    return ""
                                                }
                                            }
                                        },
                                        { displayName: "Merchant Settlement Reference", key: "order.headOfficeSettlement.reference" },
                                        { displayName: "Merchant Settlement Remark", key: "order.headOfficeSettlement.remark" },
                                        {
                                            displayName: "Closure Date/Time", key: "status", onPrintFn: (rawData) => {

                                                if (rawData.status === "DELIVERED") {
                                                    return (get(rawData, "deliveredAt", "-") !== null) ? dateObjectToString(get(rawData, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II") : "-";
                                                } else if (rawData.status === "RETURNED_TO_MERCHANT") {
                                                    return (get(rawData, "returnedAt", "-") !== null) ? dateObjectToString(get(rawData, "returnedAt", new Date()), "YY-mm-dd HH:MM:II") : "-";
                                                } else {
                                                    return "-";
                                                }
                                            }
                                        },
                                        {
                                            displayName: "Attempted Deliveries", key: "packageHistories[0].status", onPrintFn: (rawData) => {
                                                let counter = 1;
                                                return (
                                                    <React.Fragment>
                                                        {rawData?.packageHistories?.map((packageHistory, index) => {
                                                            if (packageHistory?.status === "DELIVERED" || packageHistory?.status === "NOT_DELIVERED") {
                                                                counter++;
                                                                return (
                                                                    <text>
                                                                        {`${counter - 1}) ${dateObjectToString(packageHistory?.updatedAt, "YY-mm-dd HH:MM:II")} - ${packageHistory?.driver?.name} - ${packageHistory?.status === "DELIVERED" ? "Delivered" : "Failed Delivery"}`} <br />
                                                                    </text>
                                                                )
                                                            }
                                                        })}
                                                    </React.Fragment>
                                                )
                                            }
                                        },
                                        {
                                            displayName: `Total Attempts`, key: "_count", default: "-", onPrintFn: (rawData) => {
                                                return this.getAttemptedCount(rawData)
                                            }
                                        },
                                        { displayName: `Current Location`, key: "locationCurrent.name", default: "-" },
                                        { displayName: `Actual Weight (${weightMetric})`, key: "weight", default: "-" },
                                        { displayName: `Volumetric Weight (${weightMetric})`, key: "volumetric", default: "-" },
                                        {
                                            displayName: `Order Amount (${currency})`, key: "order.amount", onPrintFn: (rawData) => {
                                                return isEmptyValue(rawData?.order.amount) ? "-" : rawData?.order.amount;
                                            }
                                        },
                                        {
                                            displayName: `Cash on Delivery Fee (${currency})`, key: "order.cashDeliveryPrice", onPrintFn: (rawData) => {
                                                return rawData?.order?.paymentMethod === "CASH_ON_DELIVERY" ? rawData?.order?.cashDeliveryPrice : "0.00"
                                            }
                                        },
                                        {
                                            displayName: `Card on Delivery Fee (${currency})`, key: "order.cardDeliveryPrice", onPrintFn: (rawData) => {
                                                return rawData?.order?.paymentMethod === "CARD_ON_DELIVERY" ? rawData?.order?.cardDeliveryPrice : "0.00"
                                            }
                                        },
                                        {
                                            displayName: `Express Fee (${currency})`, key: "expressFee", onPrintFn: (rawData) => {
                                                return rawData.expressFee ? roundValue(rawData.expressFee) : 0;
                                            }
                                        },
                                        {
                                            displayName: `XL Handling Fee (${currency})`, key: "xlPackageHandlingFee", onPrintFn: (rawData) => {
                                                return rawData.xlPackageHandlingFee ? roundValue(rawData.xlPackageHandlingFee) : 0;
                                            }
                                        },
                                        {
                                            displayName: `Delivery Fee (${currency})`, key: "merchantPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? roundValue(rawData.merchantPrice) : 0;
                                            }
                                        },
                                        {
                                            displayName: `Delivery Flat Fee (${currency})`, key: "merchantFlatPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? 0 : roundValue(rawData.merchantFlatPrice);
                                            }
                                        },
                                        {
                                            displayName: `Total Delivery Fee (${currency})`, key: "totalPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? roundValue(rawData.totalPrice) : 0;
                                            }
                                        },
                                        {
                                            displayName: `Total Delivery Flat Fee(${currency})`, key: "totalFlatPrice", onPrintFn: (rawData) => {
                                                return rawData.priceType === "VL_RATE" ? 0 : roundValue(rawData.totalFlatPrice);
                                            }
                                        },

                                    ]}
                                    tableBodyLastComponent={TableLastRow}
                                    tableBodyLastProps={get(coreState, `dataTableResponses.${packageStatusReportAPI.key}.totalsRow`, {})}
                                    isForceShowLoader={this.state.loaderStatus}
                                />
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        coreState: state.CoreState,
    };
};

export default connect(mapStateToProps, null)(PackageStatusReportView);
