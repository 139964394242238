import React, { Component } from "react";
import { groupBy, get } from "lodash";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { find } from "lodash";

import { CrudComponent } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { merchantBranchRateAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { connect } from "react-redux";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import {
  InputButton,
  SelectBoxWithState,
  FormWrapper,
} from "../../../../../components/boda-boda/Forms";
import {
  setFlashMessage,
  setPageLoader,
} from "../../../../../store/boda-boda/ui/actions";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";
import { DownloadModalUI } from "./volumetric-includes/DownloadModal";
import { getDistrictListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { FilterUI } from "./volumetric-includes/FilterUI"
import { roundValue } from "../../../../../helpers/boda-boda/common-helpers/common.helpers";

class Volumetric extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: false,
      districtId: "",
    };
  }

  validateFn = (formState, setFormErrorFn) => {
    const errors = [];
    if (
      get(formState, "branch_volumetric_weight_rates.districtId", "") === ""
    ) {
      errors.push({
        property: "districtId",
        error: "The District field is required",
      });
      setFormErrorFn("branch_volumetric_weight_rates", errors);
      return false;
    } else {
      setFormErrorFn("branch_volumetric_weight_rates", []);
      return true;
    }
  };

  findRange = (data, range) => {
    let result = find(data, function (obj) {
      if (obj.volumeMin == range) {
        return true;
      }
    });
    return result;
  };

  buildResponse = (data) => {
    let city = groupBy(data, "cityId");
    let temp2 = [];

    Object.keys(city).forEach((values, index) => {
      temp2.push({
        city: city[values][0].city.name,
        range1: {
          price: this.findRange(city[values], 0)
            ? roundValue(this.findRange(city[values], 0).merchantPrice)
            : "-",
        },
        range2: {
          price: this.findRange(city[values], 0.5)
            ? roundValue(this.findRange(city[values], 0.5).merchantPrice)
            : "-",
        },
        range3: {
          price: this.findRange(city[values], 1)
            ? roundValue(this.findRange(city[values], 1).merchantPrice)
            : "-",
        },

        range4: {
          price: this.findRange(city[values], 2)
            ? roundValue(this.findRange(city[values], 2).merchantPrice)
            : "-",
        },

        range5: {
          price: this.findRange(city[values], 3)
            ? roundValue(this.findRange(city[values], 3).merchantPrice)
            : "-",
        },

        range6: {
          price: this.findRange(city[values], 4)
            ? roundValue(this.findRange(city[values], 4).merchantPrice)
            : "-",
        },

        range7: {
          price: this.findRange(city[values], 5)
            ? roundValue(this.findRange(city[values], 5).merchantPrice)
            : "-",
        },

        range8: {
          price: this.findRange(city[values], 6)
            ? roundValue(this.findRange(city[values], 6).merchantPrice)
            : "-",
        },

        range9: {
          price: this.findRange(city[values], 7)
            ? roundValue(this.findRange(city[values], 7).merchantPrice)
            : "-",
        },

        range10: {
          price: this.findRange(city[values], 8)
            ? roundValue(this.findRange(city[values], 8).merchantPrice)
            : "-",
        },

        range11: {
          price: this.findRange(city[values], 9)
            ? roundValue(this.findRange(city[values], 9).merchantPrice)
            : "-",
        },
        range12: {
          price: this.findRange(city[values], 10)
            ? roundValue(this.findRange(city[values], 10).merchantPrice)
            : "-",
        },
        range13: {
          price: this.findRange(city[values], 11)
            ? roundValue(this.findRange(city[values], 11).merchantPrice)
            : "-",
        },
        range14: {
          price: this.findRange(city[values], 12)
            ? roundValue(this.findRange(city[values], 12).merchantPrice)
            : "-",
        },
        range15: {
          price: this.findRange(city[values], 13)
            ? roundValue(this.findRange(city[values], 13).merchantPrice)
            : "-",
        },
        range16: {
          price: this.findRange(city[values], 14)
            ? roundValue(this.findRange(city[values], 14).merchantPrice)
            : "-",
        },
        range17: {
          price: this.findRange(city[values], 15)
            ? roundValue(this.findRange(city[values], 15).merchantPrice)
            : "-",
        },
        range18: {
          price: this.findRange(city[values], 16)
            ? roundValue(this.findRange(city[values], 16).merchantPrice)
            : "-",
        },
        range19: {
          price: this.findRange(city[values], 17)
            ? roundValue(this.findRange(city[values], 17).merchantPrice)
            : "-",
        },
        range20: {
          price: this.findRange(city[values], 18)
            ? roundValue(this.findRange(city[values], 18).merchantPrice)
            : "-",
        },
        range21: {
          price: this.findRange(city[values], 19)
            ? roundValue(this.findRange(city[values], 19).merchantPrice)
            : "-",
        },
        range22: {
          price: this.findRange(city[values], 20)
            ? roundValue(this.findRange(city[values], 20).merchantPrice)
            : "-",
        },
      });
    });

    return temp2;
  };

  // onClickDownloadFn = () => {
  //   let { match, setPageLoader } = this.props;

  //   setPageLoader(true);

  //   callApi(
  //     `${merchantBranchRateAPI.url}/all?&merchantBranchId=${match.params.id}`
  //   )
  //     .headers(true)
  //     .method("get")
  //     .send()
  //     .then((result) => {
  //       this.generateCSVData(
  //         get(result, "data.data", []),
  //         `Volumetric Rates - ${match.params.name} - ${Date.now()} .csv`
  //       );
  //       setPageLoader(false);
  //     });
  // };

  // generateCSVData = (data = [], fileName) => {
  //   const headers = [
  //     "CityId",
  //     "City",
  //     "Price(0-0.5)",
  //     "Price(0.5-1)",
  //     "Price(1-2.5)",
  //     "Price(2.5-5)",
  //     "Price(5-7.5)",
  //     "Price(7.5-10)",
  //     "Price(10-12.5)",
  //     "Price(12.5-15)",
  //     "Price(15-17.5)",
  //     "Price(17.5-20)",
  //     "Price(20+)",
  //   ];

  //   const dataList = [];
  //   let city = groupBy(data, "cityId");

  //   Object.keys(city).forEach((values, index) => {
  //     let temp2 = [];

  //     temp2.push(city[values][0].city.id);
  //     temp2.push(city[values][0].city.name);
  //     temp2.push(
  //       this.findRange(city[values], 0)
  //         ? this.findRange(city[values], 0).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 0.5)
  //         ? this.findRange(city[values], 0.5).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 1)
  //         ? this.findRange(city[values], 1).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 2.5)
  //         ? this.findRange(city[values], 2.5).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 5)
  //         ? this.findRange(city[values], 5).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 7.5)
  //         ? this.findRange(city[values], 7.5).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 10)
  //         ? this.findRange(city[values], 10).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 12.5)
  //         ? this.findRange(city[values], 12.5).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 15)
  //         ? this.findRange(city[values], 15).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 17.5)
  //         ? this.findRange(city[values], 17.5).merchantPrice
  //         : "-"
  //     );
  //     temp2.push(
  //       this.findRange(city[values], 20)
  //         ? this.findRange(city[values], 20).merchantPrice
  //         : "-"
  //     );

  //     dataList.push(temp2);
  //   });

  //   const finalDataSet = [headers];

  //   dataList.forEach((value) => {
  //     finalDataSet.push(value);
  //   });

  //   const csvDataContent = finalDataSet.map((e) => e.join(",")).join("\n");
  //   downloadCSV(csvDataContent, fileName);
  // };

  onClickView = (formState, setFormErrorFn) => {
    if (this.validateFn(formState, setFormErrorFn) === true) {
      this.setState({
        selected: true,
        districtId: get(
          this.props.formState,
          "branch_volumetric_weight_rates.districtId",
          ""
        ),
      });
    }
  };

  render() {
    let { match, formState, setFormErrorFn } = this.props;
    const initApiUrl = get(formState, `${merchantBranchRateAPI.key}_search.districtId`, '') === '' ? `&districtId=25`: ``;

    return (
      <React.Fragment>
        <FormWrapper
          setGroupName={"branch_volumetric_weight_rates"}
          onDestroyUnsetFormObject={false}
          setFormObject={{
            districtId: "",
          }}
        >
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Volumetric Weights</h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      View Volumetric Weight
                    </li>
                  </ol>
                </div>
              </Col>

              <Col sm={6}>
                <div className={"d-flex justify-content-end"}>
                  <BackBtnComponent
                    elementWrapperStyle={"mr-2"}
                    isGoBack={true}
                  />

                  <DownloadModalUI
                    merchantBranchid={match.params.id}
                    merchantBranchName={match.params.name}
                  />
                </div>
              </Col>
            </Row>

            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className={"card mb-1"}>
                  <div className={"card-body"}>
                    <dl className={"row mb-0"}>
                      <dd className={"col-sm-2"}>Branch</dd>
                      <dt className={"col-sm-4"}>{match.params.name}</dt>

                      {/* <div className="col-md-6">
                        <SelectBoxWithState
                          labelText={"District"}
                          apiUrl={`${getDistrictListAPI.url}`}
                          apiStateKey={getDistrictListAPI.key}
                          inputName={"districtId"}
                          placeHolderText={"District"}
                          formGroupName={"branch_volumetric_weight_rates"}
                          onRebuildResponseFn={(response) => {
                            const data = [];

                            response.forEach((value) => {
                              data.push({
                                id: `${value.id}`,
                                value: `${value.name}`,
                              });
                            });

                            return {
                              data: {
                                data: data,
                              },
                            };
                          }}
                        />
                        <InputButton
                          btnText={"View"}
                          onClickBtnFn={() =>
                            this.onClickView(formState, setFormErrorFn)
                          }
                        />
                      </div> */}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            {/* {this.state.selected === false ? null : ( */}
              <Row>
                <CrudComponent
                  title={match.params.name + " Volumetric Weights Table"}
                  apiURL={`${merchantBranchRateAPI.url}/all?merchantBranchId=${match.params.id}&status=ACTIVE${initApiUrl}`}
                  dataTableStateKey={merchantBranchRateAPI.key}
                  isSetCreateAction={false}
                  isSetAction={false}
                  isPagingShow={false}
                  isFilter={false}
                  filterFromComponent={FilterUI}
                  defaultFilterFormObject={{ districtId: "25" }}
                  isOpenFilterCollapse={true}
                  onRebuildResponseFn={(response) => {
                    return {
                      data: {
                        data: this.buildResponse(response),
                      },
                    };
                  }}
                  tableHeaderList={[
                    { displayName: "City", key: "city" },

                    { displayName: "Price(0-0.5)", key: "range1.price" },

                    { displayName: "Price(0.5-1)", key: "range2.price" },

                    { displayName: "Price(1-2)", key: "range3.price" },

                    { displayName: "Price(2-3)", key: "range4.price" },

                    { displayName: "Price(3-4)", key: "range5.price" },

                    { displayName: "Price(4-5)", key: "range6.price" },

                    { displayName: "Price(5-6)", key: "range7.price" },

                    { displayName: "Price(6-7)", key: "range8.price" },

                    { displayName: "Price(7-8)", key: "range9.price" },

                    { displayName: "Price(8-9)", key: "range10.price" },

                    { displayName: "Price(9-10)", key: "range11.price" },

                    { displayName: "Price(10-11)", key: "range12.price" },

                    { displayName: "Price(11-12)", key: "range13.price" },

                    { displayName: "Price(12-13)", key: "range14.price" },

                    { displayName: "Price(13-14)", key: "range15.price" },

                    { displayName: "Price(14-15)", key: "range16.price" },

                    { displayName: "Price(15-16)", key: "range17.price" },

                    { displayName: "Price(16-17)", key: "range18.price" },

                    { displayName: "Price(17-18)", key: "range19.price" },

                    { displayName: "Price(18-19)", key: "range20.price" },

                    { displayName: "Price(19-20)", key: "range21.price" },

                    { displayName: "Price(20+)", key: "range22.price" },
                  ]}
                />
              </Row>
            {/* )} */}

          </div>
        </FormWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.FormData,
  };
};

const VolumetricView = connect(mapStateToProps, {
  setPageLoader,
  setFlashMessage,
  setFormErrorFn,
})(Volumetric);

export { VolumetricView };
