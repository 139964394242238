import { set } from 'lodash';
import {
  initFormGroupKey,
  removeFormGroupKey,
  setErrorsKey,
  setInputValueChangeKey,
  setComplexInputValueChangeKey,
  mergeFormObjectKey,
  setInitialState,
  setBulkValuesKey,
  setSelectAllValuesKey,
} from './actionTypes';

const initialState = {};

const FormData = (state = initialState, action) => {
  switch (action.type) {
    case `${initFormGroupKey}`:
      return {
        ...state,
        ...action.payload,
      };
    case removeFormGroupKey:
      delete state[action.playload];
      return state;
    case setInputValueChangeKey:
      return {
        ...state,
        [action.formGroupKey]: {
          ...state[action.formGroupKey],
          [action.inputKey]: action.value,
          _updateStatus: !state[action.formGroupKey]['_updateStatus'],
        },
      };
    case setErrorsKey:
      return {
        ...state,
        [action.formGroupKey]: {
          ...state[action.formGroupKey],
          _errors: action.playload,
          _updateStatus: !state[action.formGroupKey]['_updateStatus'],
        },
      };
    case setComplexInputValueChangeKey:
      return {
        ...state,
        [action.formGroupKey]: {
          ...set(state, action.inputStatePath, action.value)[
            action.formGroupKey
          ],
          _updateStatus: !state[action.formGroupKey]['_updateStatus'],
        },
      };
    case mergeFormObjectKey:
      return {
        ...state,
        [action.formGroupKey]: {
          ...state[action.formGroupKey],
          ...action.playload,
          _updateStatus: !state[action.formGroupKey]['_updateStatus'],
        },
      };
    case setInitialState:
      return initialState;

    case setBulkValuesKey:
      return {
        ...state,
        [action.formGroupKey]: {
          ...state[action.formGroupKey],
          ...action.payload,
          // _updateStatus:!state[action.formGroupKey]["_updateStatus"]
        },
      };

    case setSelectAllValuesKey:
      return {
        ...state,
        [action.formGroupKey]: {
          ...state[action.formGroupKey],
          [action.inputKey]: action.payload,
          // _updateStatus:!state[action.formGroupKey]["_updateStatus"]
        },
      };
    default:
      return state;
  }
};

export default FormData;
