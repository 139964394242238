import React, { Component } from "react";

import { DatePickerBoxWithState, InputBoxWithState, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { outboundStatuses } from "../../../../config/boda-boda/db_config/db_status.config";
import { getMerchantBranchAllAPI } from "../../../../config/boda-boda/apiUrls.config";


class FilterUI extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Manifest Code"
                            inputName={"code"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={17}
                            placeHolderText={"Manifest Code"}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText="Article ID"
                            inputName={"articleNo"}
                            formGroupName={this.props.searchFromKey}
                            maxLength={17}
                            placeHolderText={"Article ID"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"Pick Up From Date"}
                            inputName={"pickupDateFrom"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"Pick Up From Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <DatePickerBoxWithState
                            labelText={"Pick Up To Date"}
                            inputName={"pickupDateTo"}
                            formGroupName={this.props.searchFromKey}
                            placeholderText={"Pick Up To Date"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Pick Up Location"}
                            placeHolderText={"All"}
                            inputName={"locationPickId"}
                            formGroupName={this.props.searchFromKey}
                            apiUrl={getMerchantBranchAllAPI.url}
                            apiStateKey={getMerchantBranchAllAPI.key}
                            keyName={"locationId"}
                            valueName={"name"}
                        />
                    </div>

                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Status"}
                            inputName={"status"}
                            formGroupName={this.props.searchFromKey}
                            dataList={[
                                {
                                    "id": "",
                                    "value": "All"
                                },
                                ...outboundStatuses
                            ]}
                            placeHolderText={"All"}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default FilterUI;
