import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { merchantBranchAPI } from "../../../../config/boda-boda/apiUrls.config";


class MerchantBranch extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let { history } = this.props;

        return (
            <Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Merchant Branch</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Branch</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title="Merchant Branch"
                            // filterFromComponent={FilterUI}
                            apiURL={`${merchantBranchAPI.url}?page=1&limit=20`}
                            dataTableStateKey={merchantBranchAPI.key}
                            isSetCreateAction={false}
                            isFilter={false}
                            tableHeaderList={[
                                { displayName: "No.", key: "id" },
                                { displayName: "Branch Name", key: "name" },
                                { displayName: "Pick Up Address", key: "location.address", default: "-" },
                                { displayName: "City", key: "location.city.name", default: "-" },
                                { displayName: "Contact Person", key: "contacts[0].fullName", default: "-" },
                                { displayName: "Phone", key: "contacts[0].phone", default: "-" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant-branch/view/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </Row>

                </div>
            </Fragment>
        )
    }

}

export { MerchantBranch }
