import React, { Fragment, useState } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { FormWrapper, SubmitButton, InputButton, CheckBox, CheckBoxWithState } from "../../../../../components/boda-boda/Forms";
import { getManifestListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../../components/boda-boda/Modal/ModalUI";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setFlashMessageKey } from "../../../../../store/boda-boda/ui/actionTypes";
import TermsAndConditionUIMessage from "../../../terms-and-condition/TermsAndConditionUIMessage";


const SendRequestUIMessage = () => {
    return (
        <TermsAndConditionUIMessage/>
    );
};

const SendRequestUIBtn = (props) => {
    const dispatch = useDispatch();
    const submitBtnDisable = useSelector(state => state?.FormData?.confirmation?.termsAndConditions);

    const checkValidFn = () => {
        if (get(props, "type", "") !== "datatable" && get(props, "row.packages", []).length === 0) {
            return "empty_packages";
        } else if (get(props, "type", "") === "datatable" && get(props, "row._count.packages", 0) === 0) {
            return "empty_packages";
        } else if (new Date(dateObjectToString(get(props, "row.pickAt"))) < new Date(dateObjectToString(new Date()))) {
            return "back_dates";
        } else {
            return true;
        }
    }

    const showErrorMsjFn = () => {
        const msjObj = {
            status: true,
            message: checkValidFn() === "empty_packages" ? "Packages not available in the Pick Request" : "Pick-up date cannot be a past date",
            type: "danger"
        }

        dispatch({
            type: setFlashMessageKey,
            playload: {
                ...msjObj,
                key: dateObjectToString(new Date(), "YYmmddHHMMII")
            }
        });
    }

    return (
        <Fragment>
            {
                (checkValidFn() === true) ? (
                    <SubmitButton
                        btnText={"Yes"}
                        isBtnDisabled={submitBtnDisable ? false : true}
                        elementStyle={"btn btn-primary waves-effect waves-light"}
                        dataTableKey={getManifestListAPI.key}
                        isValidate={false}
                        flashMessages={{
                            "success": {
                                status: true,
                                message: "Request has successfully sent",
                                type: "success"
                            }
                        }}
                        callApiObject={{
                            isSetHeaders: true,
                            multipart: false,
                            method: "put",
                            onUpload: false
                        }}
                        apiDataStoringObject={{
                            setLoader: true,
                            storingType: props.type === "datatable" ? apiResponseStoringType.dateTableLinkedForm : apiResponseStoringType.apiRequestOnly,
                            mergeToSuccessResponse: null,
                            mergeToErrorResponse: null,
                        }}
                        onGetAPIEndPointFn={(formObject) => {
                            return {
                                url: `${getManifestListAPI.url}/${props.row.id}`,
                                key: getManifestListAPI.key
                            }
                        }}
                        onChangeRequestBodyFn={(formObject) => {
                            return {
                                "status": "PUBLISH",
                                "locationPick": {
                                    "id": props.row.locationPickId
                                }
                            }
                        }}
                        onResponseCallBackFn={(error, result) => {
                            if (!error) {
                                if (props.type === "datatable") {
                                    props.setModelStatusFn(false);
                                } else {
                                    props.history.goBack();
                                }
                            }
                        }}
                    />
                ) : (
                    <InputButton
                        btnText={"Yes"}
                        isBtnDisabled={submitBtnDisable ? false : true}
                        elementStyle={"btn btn-primary waves-effect waves-light"}
                        onClickBtnFn={showErrorMsjFn}
                    />
                )
            }
        </Fragment>
    );
};

const SendRequestUIModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                elementWrapperStyle={"mr-2"}
                btnText={"Send Request"}
                startIcon={"mdi mdi-send"}
                btnColor={"success"}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<SendRequestUIMessage />}
                modalFooterComponent={<SendRequestUIBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={"form"}
                    row={{ "id": props.id, "locationPickId": props.locationPickId, "packages": props.packages, "pickAt": props.pickAt }}
                    history={props.history}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    SendRequestUIBtn,
    SendRequestUIMessage,
    SendRequestUIModal
}
