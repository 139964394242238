import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { get } from "lodash";
import axios from "axios";

import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { loginAPI, authProfileAPI } from "../../../config/boda-boda/apiUrls.config";
import { getFromLocalStorage, setAuthData } from "../../../helpers/boda-boda/common-helpers/manageStorage.helpers";
import { requestLoginAPICallKey, setAuthTokenKey, updateAuthUserInfoKey } from "./actionTypes";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import { setAuthUserFn } from "./actions";
import { authTokenStorageKey } from "../../../config/boda-boda/core.config";
import validateMethod from "../../../helpers/boda-boda/validation";
import { setFormErrorFn } from "../form/actions";


function* authUserAPICall(data) {
    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload.formObject', {}),
        {
            credential: "Credential",
            password: "Password"
        },
        {
            credential: "required",
            password: "required",
        },
        {},
    ));

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`loginFrom`, validationResponse.errors));
    } else {
        yield put(setFormErrorFn(`loginFrom`, []));
        yield put(setPageLoader(true));

        try {
            const response = yield call(() => callApi(loginAPI.url).headers(false).method("post").body(data.payload.formObject).send());

            if (get(response,'data.data.access_token',null)!==null) {
                const headers = {
                    "accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + get(response,'data.data.access_token',"")
                };

                const authUserResponse = yield call(() => axios({ method: "get", url: authProfileAPI.url, headers: headers }));

                if (get(authUserResponse, "data.data.role.type", null) === "MERCHANT") {
                const authObject = {
                    id: get(authUserResponse, "data.data.id", null),
                    email: get(authUserResponse, "data.data.email", null),
                    credential: get(authUserResponse, "data.data.credential", null),
                    role: {
                        name: get(authUserResponse, "data.data.role.name", null),
                        type: get(authUserResponse, "data.data.role.type", null),
                    },
                    merchant: {
                        id: get(authUserResponse, "data.data.merchant.id", null),
                        name: get(authUserResponse, "data.data.merchant.name", null),
                        code: get(authUserResponse, "data.data.merchant.code", null),
                        registrationNo: get(authUserResponse, "data.data.merchant.registrationNo", null),
                        type: get(authUserResponse, "data.data.merchant.type", null),
                        rating: get(authUserResponse, "data.data.merchant.rating", null),
                    },
                    permissions: get(authUserResponse, "data.data.role.permissions", []),
                    facility: get(authUserResponse, "data.data.facility", null),
                    staff: get(authUserResponse, "data.data.staffs", [])[0],
                }

                yield setAuthData({ ...response.data.data, authUser: authObject });
                yield put({ type: setAuthTokenKey, payload: response.data.data });
                yield put(setAuthUserFn(authObject));
                yield put(setPageLoader(false));

                yield put(setFlashMessage({
                    status: true,
                    message: `Welcome ${get(authObject, "staff.name", "")}`,
                    type: "success"
                }));
            } else {
                yield put(setPageLoader(false));
                yield put(setFlashMessage({
                    status: true,
                    message: "Invalid credential or password",
                    type: "danger"
                }));
            }
            } else {
                yield put(setPageLoader(false));
                yield put(setFlashMessage({
                    status: true,
                    message: "Invalid credential or password",
                    type: "danger"
                }));
                // yield put(setFormErrorFn(data.payload.formGroupKey, [
                //     {
                //         "property":"credential",
                //         "error":"Invalid username or password"
                //     },
                //     {
                //         "property":"password",
                //         "error":"Invalid username or password"
                //     }
                // ]));
            }
        } catch(ex) {
            yield put(setPageLoader(false));
            yield put(setFlashMessage({
                status: true,
                message: "Invalid credential or password",
                type: "danger"
            }));
            // yield put(setFormErrorFn(data.payload.formGroupKey, [
            //     {
            //         "property":"credential",
            //         "error":"Invalid username or password"
            //     },
            //     {
            //         "property":"password",
            //         "error":"Invalid username or password"
            //     }
            // ]));
        }
    }
}

function* updateAuthUserInfo() {
    const authUserResponse = yield call(() => callApi(authProfileAPI.url).headers(true).method("get").send());

    if (authUserResponse._statue === true) {
        const authObject = {
            id: get(authUserResponse, "data.data.id", null),
            email: get(authUserResponse, "data.data.email", null),
            credential: get(authUserResponse, "data.data.credential", null),
            role: {
                name: get(authUserResponse, "data.data.role.name", null),
                type: get(authUserResponse, "data.data.role.type", null),
            },
            permissions: get(authUserResponse, "data.data.role.permissions", []),
            facility: get(authUserResponse, "data.data.facility", null),
            staff: get(authUserResponse, "data.data.staffs", [])[0],
        }

        const authData = getFromLocalStorage(authTokenStorageKey);

        yield setAuthData({ ...authData, authUser: authObject });
        yield put(setAuthUserFn(authObject));
    }
}

function* watchAPICallRequest() {
    yield takeEvery(requestLoginAPICallKey,authUserAPICall);
}

function* watchAPIUpdateAuthUserInfoFn() {
    yield takeEvery(updateAuthUserInfoKey, updateAuthUserInfo);
}

function* authUserSaga() {
    yield all([
        fork(watchAPICallRequest),
        fork(watchAPIUpdateAuthUserInfoFn)
    ])
}

export default authUserSaga;
