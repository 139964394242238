import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { CrudComponent, actionBtnTypes } from "../../../../../components/boda-boda/CRUD/CrudComponent";
import { FilterUI } from "./includes/FilterUI";
import { getOrdersListAPI } from "../../../../../config/boda-boda/apiUrls.config";
import {
  customerNameShortHelperFn,
  formatToDateHelper,
  isEmptyValue
} from "../../../../../helpers/boda-boda/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {mapOrderPaymentMethods} from "../../../../../config/boda-boda/db_config/db_status.config";
import { setFormErrorFn } from "../../../../../store/boda-boda/form/actions";


class PackedOrders extends Component {
  render() {
    let { history } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Packed Orders</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Packed Orders</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title="Packed Orders"
              apiURL={`${getOrdersListAPI.url}?page=1&limit=20&status=PACKED`}
              dataTableStateKey={getOrdersListAPI.key}
              searchButtonOnTop={true}
              defaultFilterFormObject={{
                  status: "PACKED",
                  paymentMethod: "",
                  deliveryType: "",
                  phone: "",
                  name: "",
                  consignmentNo: "",
                  trackingNumber: "",
                  dateTo: null,
                  dateFrom: null,
                  channelOrderNo: "",
              }}
              clearErrors={() => {
                const { setFormErrorFn } = this.props;
                setFormErrorFn("ordersListAPIKey_search", []);
              }}
              onRebuildFilterObjFn={(filterData) => {
                const data = filterData;
                let { setFormErrorFn } = this.props;
                const errors = [];

                if (new Date(filterData.dateFrom) > new Date(filterData.dateTo)) {
                  errors.push({
                    "property":"dateTo",
                    "error":"The Date To field should not be less than the Date From field"
                  });
                }

                if(errors.length > 0 ){
                  setFormErrorFn("ordersListAPIKey_search", errors);
                  return ;
                }else{
                  setFormErrorFn("ordersListAPIKey_search", []);
                }

                if (!isEmptyValue(filterData.dateTo)) {
                  data["dateTo"] = formatToDateHelper(filterData.dateTo);
                }

                if (!isEmptyValue(filterData.dateFrom)) {
                  data["dateFrom"] = filterData.dateFrom.toISOString();
                }

                return data;
              }}
              filterFromComponent={FilterUI}
              isSetCreateAction={false}
              tableHeaderList={[
                { displayName: "Consignment No", key: "consignmentNo" },
                { displayName: "Tracking No", key: "trackingNumber", onPrintFn: (rawData) => (rawData.trackingNumber)? 
                <Link to={{ pathname: `${process.env.REACT_APP_BODA_CORPORATE_TRACK_BASE_URL}${rawData.trackingNumber}`}} target="_blank">{rawData.trackingNumber}</Link> :"-"
                },
                { displayName: "Customer Name", key: "name", onPrintFn: (rawData) => customerNameShortHelperFn(rawData.name)},
                { displayName: "Phone", key: "phone" },
                { displayName: "Delivery Type", key: "deliveryType" },
                { displayName: "Payment Method", key: "paymentMethod", onPrintFn: (rawData) => mapOrderPaymentMethods[rawData.paymentMethod] },
                { displayName: "Date", key: "createdAt", onPrintFn: (rawData) => dateObjectToString(rawData.createdAt)},
                { displayName: "Status", key: "status" },
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/orders/packed/view/${rawData.id}`),
                  }
                }
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData
  };
};

export default connect(mapStateToProps, {
  setFormErrorFn,
}) (PackedOrders);
