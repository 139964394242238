import React, { Fragment, useState } from "react";

import { InputButton, SubmitButton } from "../../../../components/boda-boda/Forms";
import { getUserStaffList } from "../../../../config/boda-boda/apiUrls.config";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";


const Message = (props) => {
    return (
        <p>{props.message}</p>
    );
}

const DeactivateBtn = (props) => {
    return (
        <SubmitButton
            btnText={"Yes"}
            elementStyle={"btn btn-primary waves-effect waves-light"}
            dataTableKey={getUserStaffList.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: props.flashMessage,
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: props.type === "datatable" ? apiResponseStoringType.dateTableLinkedForm : apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getUserStaffList.url}/${props.row.id}/status`,
                    key: getUserStaffList.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return {
                    status: props.status
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    if (props.type === "datatable") {
                        props.setModelStatusFn(false);
                    } else {
                        props.setModelStatusFn(false);
                        props.history.push("/users");
                    }
                }
            }}
        />
    );
}

const DeactivateModel = (props) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Fragment>
            <InputButton
                btnText={props.btnText}
                startIcon={props.startIcon}
                btnColor={props.btnColor}
                onClickBtnFn={() => setOpen(true)}
            />

            <ModalUI
                isOpen={isOpen}
                modalTitle={"Please Confirm"}
                modalBodyComponent={<Message message={props.message} />}
                modalFooterComponent={<DeactivateBtn
                    setModelStatusFn={(value) => setOpen(value)}
                    type={"form"}
                    row={{ "id": props.id }}
                    history={props.history}
                    status={props.status}
                    flashMessage={props.flashMessage}
                />}
                showHideToggleFn={() => setOpen(false)}
                closeBtnName={"No"}
            />
        </Fragment>
    )
}

export {
    Message,
    DeactivateBtn,
    DeactivateModel
}
