/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-04 12:47:04 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-04 12:48:45
 */


const userStatusList = [
    {
      id: "ACTIVE",
      value: "Active",
    },
    {
      id: "INACTIVE",
      value: "Inactive",
    },
];

const orderStatusObject = {
    "PENDING": "PENDING",
    "PICKED": "PICKED",
    "PACKED": "PACKED",
    "UNAVAILABLE": "UNAVAILABLE",
    "CANCELLED": "CANCELLED",
};

const pickUpRequestStatus = [
    {
        id: "DRAFT",
        value: "Drafts"
    },
    {
        id: "PUBLISH",
        value: "Pending"
    },
    {
        id: "ACCEPTED",
        value: "Accepted"
    },
    {
        id: "REJECTED",
        value: "Rejected"
    }
];

const orderStatusList = [
    {
        id: "PENDING",
        value: "PENDING",
    },
    {
        id: "PICKED",
        value: "PICKED",
    },
    {
        id: "PACKED",
        value: "PACKED",
    },
    {
        id: "CANCELLED",
        value: "CANCELLED",
    },
    {
        id: "DELIVERED",
        value: "DELIVERED",
    },
    {
        id: "TERMINATED",
        value: "TERMINATED",
    },
    {
        id: "RETURNED_TO_MERCHANT",
        value: "RETURNED_TO_MERCHANT",
    },
    {
        id: "READY_FOR_RETURN",
        value: "READY_FOR_RETURN",
    }
];

const idVerificationTypes = [
    {
        id: "NIC",
        value: "NIC",
    },
    {
        id: "PASSPORT",
        value: "PASSPORT",
    },
    {
        id: "DRIVERS_LICENSE",
        value: "DRIVING LICENSE",
    }
];

const deliveryTypes = [
    {
        id: "EXPRESS",
        value: "EXPRESS",
    },
    {
        id: "STANDARD",
        value: "STANDARD",
    }
];

const staffCategories = [
    {
        id: "STORE",
        value: "STORE",
    },
    {
        id: "DRIVER",
        value: "DRIVER",
    }
];

const paymentMethods = [
    {
        id: "CASH_ON_DELIVERY",
        value: "CASH ON DELIVERY",
    },
    {
        id: "CARD_ON_DELIVERY",
        value: "CARD ON DELIVERY",
    },
    {
        id: "PREPAID",
        value: "PREPAID",
    }
];

const currency = "LKR";

const packageStatuses = [
    {
        id: "PACKED",
        value: "Packed",
    },
    {
        id: "IN_TRANSIT",
        value: "Intransit",
    },
    {
        id: "RECEIVED",
        value: "Received at processing center",
    },
    {
        id: "SORTED",
        value: "Ready for Transit",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub",
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for delivery",
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package picked up",
    },
    {
        id: "DELIVERED",
        value: "Delivered",
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed delivery",
    },
    {
        id: "ON_HOLD",
        value: "On hold",
    },
    {
        id: "DAMAGED",
        value: "Damaged",
    },
    {
        id: "LOST",
        value: "Lost",
    },
];

const packageSearchFilters = [
    {
        id: "PACKED",
        value: "Packed",
    },
    {
        id: "RECEIVED",
        value: "Received at processing center",
    },
    {
        id: "SORTED",
        value: "Ready for Transit",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub",
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package picked up",
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for delivery",
    },
    {
        id: "DELIVERED",
        value: "Delivered",
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed delivery",
    },
    {
        id: "READY_FOR_RETURN",
        value: "Ready for Return",
    },
    {
        id: "IN_TRANSIT_RETURN",
        value: "Return Intransit",
    },
    {
        id: "RETURNED_TO_MERCHANT",
        value: "Returned to Merchant",
    },
    {
        id: "NOT_RETURNED",
        value: "Failed Return",
    },
];

const shippedSearchFilters = [
    {
        id: "RECEIVED",
        value: "Received at processing center",
    },
    {
        id: "SORTED",
        value: "Ready for Transit",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub",
    },
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package picked up",
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for delivery",
    },
    {
        id: "DELIVERED",
        value: "Delivered",
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed delivery",
    }
];

const shippedStatusList = [`"RECEIVED"`, `"SORTED"`, `"IN_TRANSIT_LINE"`, `"IN_TRANSIT_LAST"`, `"IN_TRANSIT_FIRST"`, `"DELIVERED"`, `"NOT_DELIVERED"`];

const outboundStatuses = [
    {
        id: "IN_TRANSIT_FIRST",
        value: "Picked Up",
    },
    {
        id: "COMPLETED",
        value: "Pick Up Completed",
    }
];

const mapStatusesNames = {
    "IN_TRANSIT_FIRST": "Picked Up",
    "COMPLETED": "Pick Up Completed",
    "DRAFT": "Draft",
    "PUBLISH": "Pending",
    "ACCEPTED": "Accepted",
    "REJECTED": "Rejected",
    "IN_TRANSIT_LINE": "In Transit Line",
    "IN_TRANSIT_LAST": "In Transit Last",
    "CANCELLED": "Cancelled",
}

const reconciliationReportStatuses = [
    {
        id: "IN_TRANSIT_FIRST",
        value: "Package Picked up",
    },
    {
        id: "RECEIVED",
        value: "Received at processing Center",
    },
    {
        id: "SORTED",
        value: "Ready for Transit",
    },
    {
        id: "IN_TRANSIT_LINE",
        value: "Intransit to regional hub",
    },
    {
        id: "IN_TRANSIT_LAST",
        value: "Out for Delivery",
    },
    {
        id: "DELIVERED",
        value: "Delivered",
    },
    {
        id: "NOT_DELIVERED",
        value: "Failed Delivery",
    }
];

const mapPackageStatusesNames = {
    "PACKED": "Packed",
    "IN_TRANSIT": "Intransit",
    "RECEIVED": "Received at processing center",
    "SORTED": "Ready for Transit",
    "IN_TRANSIT_LINE": "Intransit to regional hub",
    "IN_TRANSIT_LAST": "Out for delivery",
    "IN_TRANSIT_FIRST": "Package picked up",
    "DELIVERED": "Delivered",
    "NOT_DELIVERED": "Failed Delivery",
    "ON_HOLD": "On hold",
    "DAMAGED": "Damaged",
    "LOST": "Lost",
    "IN_TRANSIT_RETURN": "Return Intransit",
    "NOT_RETURNED": "Failed Return",
    "RETURNED_TO_MERCHANT": "Returned to Merchant",
    "READY_FOR_RETURN": "Ready for Return",
}

const weightMetric = "kg";
const lengthMetric = "cm";

const mapOrderPaymentMethods = {
    "PREPAID": "Prepaid",
    "CASH_ON_DELIVERY": "Cash On Delivery",
    "CARD_ON_DELIVERY": "Card On Delivery"
};

const codStatuses = [
    {
        id: "PENDING",
        value: "Pending",
    },
    {
        id: "DRIVER_SETTLED",
        value: "Driver Settled",
    },
    {
        id: "HUB_SETTLED",
        value: "Hub Settled",
    },
    {
        id: "HEAD_OFFICE_RECEIVED",
        value: "Head Office Recieved",
    },
    {
        id: "HEAD_OFFICE_SETTLED",
        value: "Head Office Settled",
    },
]


export {
    userStatusList,
    orderStatusObject,
    pickUpRequestStatus,
    orderStatusList,
    idVerificationTypes,
    deliveryTypes,
    staffCategories,
    paymentMethods,
    currency,
    packageStatuses,
    packageSearchFilters,
    shippedSearchFilters,
    shippedStatusList,
    outboundStatuses,
    mapStatusesNames,
    reconciliationReportStatuses,
    mapPackageStatusesNames,
    weightMetric,
    lengthMetric,
    mapOrderPaymentMethods,
    codStatuses,
}
