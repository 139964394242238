import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { FormWrapper } from "../../../../components/boda-boda/Forms";
import { authProfileAPI, getMerchantBankAllAPI, getMerchantContactAPI } from "../../../../config/boda-boda/apiUrls.config";
import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { isEmptyValue } from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class MerchantProfile extends Component {
    render() {
        let { formState } = this.props;

        return (
            <Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Merchant Profile</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Merchant Profile</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-3">View Profile Details</h4>

                                    <FormWrapper
                                        setGroupName={`${authProfileAPI.key}`}
                                        onDestroyUnsetFormObject={true}
                                        apiUrl={`${authProfileAPI.url}`}
                                        onRebuildResponseFn={(response) => {
                                            return {
                                                merchant: get(response, "merchant", {}),
                                                "_onLoad": false,
                                            }
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    <dd className={"col-sm-4"}>Merchant Code</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.code`, "")}</dt>

                                                    <dd className={"col-sm-4"}>Status</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.status`, "")}</dt>

                                                    <dd className={"col-sm-4"}>Business Reg.No.</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.registrationNo`, "")}</dt>

                                                    <dd className={"col-sm-4"}>Send Customer Notifications</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.isCustomerNotificationEnable`, "") ? "ENABLED" : "DISBALED"}</dt>
                                                </dl>
                                            </div>

                                            <div className={"col-md-6"}>
                                                <dl className={"row"}>
                                                    {/*<dd className={"col-sm-4"}>Rating (Accuracy %)</dd>*/}
                                                    {/*<dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.rating`, "")}</dt>*/}

                                                    <dd className={"col-sm-4"}>Merchant Name</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.name`, "")}</dt>

                                                    <dd className={"col-sm-4"}>Website</dd>
                                                    <dt className={"col-sm-8"}>{get(formState, `${authProfileAPI.key}.merchant.website`, "") !== null ? get(formState, `${authProfileAPI.key}.merchant.website`, "") : "-"}</dt>

                                                    {get(formState, `${authProfileAPI.key}.merchant.trackByChannelOrderNo`, false) ? (
                                                        <React.Fragment>
                                                            <dd className={"col-sm-4"}>Tracking Number Prefix</dd>
                                                            <dt className={"col-sm-8"}>{isEmptyValue(get(formState, `${authProfileAPI.key}.merchant.trackByChannelOrderNoPrefix`, "")) ? "-" : get(formState, `${authProfileAPI.key}.merchant.trackByChannelOrderNoPrefix`, "-")}</dt>
                                                        </React.Fragment>
                                                    ) : null}
                                                </dl>
                                            </div>
                                        </div>

                                        <h4 className="card-title mb-3">Bank Detail</h4>
                                        <FormWrapper
                                            setGroupName={`${getMerchantBankAllAPI.key}`}
                                            onDestroyUnsetFormObject={true}
                                            apiUrl={`${getMerchantBankAllAPI.url}?merchantId=${get(formState, `${authProfileAPI.key}.merchant.id`, "")}`}
                                            onRebuildResponseFn={(response) => {
                                                return {
                                                    merchantBank: get(response, "[0]", {}),
                                                    _onLoad: false,
                                                };
                                            }}
                                        >
                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <dl className={"row"}>
                                                        <dd className={"col-sm-4"}>Merchant Bank</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantBankAllAPI.key}.merchantBank.bank`, "-")}</dt>

                                                        <dd className={"col-sm-4"}>Branch</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantBankAllAPI.key}.merchantBank.branch`, "-")}</dt>

                                                        <dd className={"col-sm-4"}>Account Number</dd>
                                                        <dt className={"col-sm-8"}>{get(formState, `${getMerchantBankAllAPI.key}.merchantBank.accountNumber`, "-")}
                                                        </dt>
                                                    </dl>
                                                </div>
                                            </div>
                                        </FormWrapper>


                                        <CrudComponent
                                            title={"Contact Details"}
                                            isSetAction={false}
                                            dataTableStateKey={getMerchantContactAPI.key}
                                            apiURL={`${getMerchantContactAPI.url}?merchantId=${get(formState, `${authProfileAPI.key}.merchant.id`, "")}&page=1&limit=20`}
                                            isSetCreateAction={false}
                                            isFilter={false}
                                            tableHeaderList={[
                                                { displayName: "Contact Person", key: "fullName" },
                                                { displayName: "Phone No.", key: "phone" },
                                                { displayName: "Email", key: "email" },
                                                { displayName: "Contact Type", key: "departmentType" },
                                                {
                                                    displayName: "Receive Notification", key: "notification", onPrintFn: (rawData) => {
                                                        return rawData.notification === true ? "Yes" : "No"
                                                    }
                                                },
                                            ]}
                                        />
                                    </FormWrapper>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(MerchantProfile);