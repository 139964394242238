import {
    initDataTableKey, setDataTableKey, updateDataTableObjectKey,
    shortDataTableDataKey, setReloadDataTableKey, setApiResponseKey,
    updateDataTableFieldValueKey, initResponseKey, updateDataTableRowKey,
    setApiResponseErrorKey, requestAPIDataKey, setInitialState,
    removeDatatable, setFetchingInit
} from "./actionTypes";


const emptyFn = (...para) => undefined;

export const initDataTable = (stateKey) => {
    return {
        type: initDataTableKey,
        stateKey: stateKey
    }
}

export const initResponse = (stateKey) => {
    return {
        type: initResponseKey,
        stateKey: stateKey
    }
}

export const setDataTable = (stateKey, results = [], fetching = "init", current_page = 1, page_count = null, per_page = 10, total = null,page_size=10,  totalsRow = null) => {
    return {
        type: setDataTableKey,
        stateKey: stateKey,
        fetching: fetching,
        current_page: current_page,
        page_count: page_count,
        per_page: per_page,
        total: total,
        results: results,
        page_size: page_size,
        totalsRow: totalsRow
    }
}

export const mergedDataTableResult = (stateKey, result) => {
    return {
        type: updateDataTableObjectKey,
        stateKey: stateKey,
        playload: result
    }
}

export const onShortFn = (datatableKey, key, status) => {
    return {
        type: shortDataTableDataKey,
        stateKey: datatableKey,
        key: key,
        status: status
    }
}

export const resetDataTable = (stateKey) => {
    return {
        type: setReloadDataTableKey,
        stateKey: stateKey
    }
}

export const onChangeDataTableFieldValue = (stateKey, inputKey, value) => {
    return {
        type: updateDataTableFieldValueKey,
        stateKey: stateKey,
        inputKey: inputKey,
        value: value
    }
}

export const setApiResponse = (stateKey, result) => {
    return {
        type: setApiResponseKey,
        stateKey: stateKey,
        playload: result
    }
}

export const updateDataTableRow = (stateKey, index, result) => {
    return {
        type: updateDataTableRowKey,
        stateKey: stateKey,
        index: index,
        playload: result
    }
}

export const setErrorObject = (path, result) => {
    return {
        type:setApiResponseErrorKey,
        path:path,
        playload:result
    }
}


export const generateApiCallObjFn = (apiUrl = null) => {

    var dataStoring = {
        setLoader: false,
        storingType: "",
        storingKey: "",
        mergeToSuccessResponse: null,
        mergeToErrorResponse: null,
        flashMessages: {},
        validationObject: null,
        onRebuildResponseFn: false,
        onResponseCallBackFn:emptyFn,
        callApiObj: {
            apiUrl: apiUrl,
            isSetHeaders: true,
            multipart: false,
            method: "get",
            body: {},
            onUpload: false
        },

    }

    return {
        /**
         * @description: set headers
         * @param {Boolean} status set headers
         */
        headers: function (status = true) {
            dataStoring = {
                ...dataStoring,
                callApiObj: {
                    ...dataStoring.callApiObj,
                    isSetHeaders: status
                }
            };
            return this;
        },
        /**
         * @description: set multipart
         * @param {*} status
         */
        isMultipart: function (status = true) {
            dataStoring = {
                ...dataStoring,
                callApiObj: {
                    ...dataStoring.callApiObj,
                    multipart: status
                }
            };
            return this;
        },
        /**
         * @description: set api method type
         * @param {String} method set api method type
         */
        method: function (method = "post") {
            dataStoring = {
                ...dataStoring,
                callApiObj: {
                    ...dataStoring.callApiObj,
                    method: method
                }
            };
            return this;
        },
        /**
         * @description: set api body
         * @param {Object|Array} status set api body
         */
        body: function (body = {}) {
            dataStoring = {
                ...dataStoring,
                callApiObj: {
                    ...dataStoring.callApiObj,
                    body: body
                }
            };
            return this;
        },
        /**
         * @description: tracking uploading process
         * @param {Funtion} onUploadFn
         */
        onUpload: function (onUploadFn) {
            dataStoring = {
                ...dataStoring,
                callApiObj: {
                    ...dataStoring.callApiObj,
                    onUpload: onUploadFn
                }
            };
            return this;
        },
        /**
         * @description: set loader
         * @param {string} state
         */
        setLoader: function (state = false) {
            dataStoring = {
                ...dataStoring,
                setLoader: state
            };
            return this;
        },
        /**
         * @description: set Init Storing
         * @param {string} type storing Type
         * @param {string} key storing key
         */
        setInitStoring: function (type = null, key = null) {
            dataStoring = {
                ...dataStoring,
                storingKey: key,
                storingType: type
            };
            return this;
        },
        /**
         * @description: set onRebuildResponseFn
         * @param {Function} onRebuildResponseFn callback funtion
         */
        setOnRebuildResponseFn: function (onRebuildResponseFn) {
            dataStoring = {
                ...dataStoring,
                onRebuildResponseFn: onRebuildResponseFn
            };
            return this;
        },
        /**
         * @description: set Data Storing Object
         * @param {string} object storing Object
         */
        setFullObject: function (dataStoringObject = {}, callApiObject = {}) {
            dataStoring = {
                ...dataStoring,
                ...dataStoringObject,
                callApiObj: {
                    ...dataStoring.callApiObj,
                    ...callApiObject
                }
            }
            return this;
        },
        /**
         * @description: set validation Object
         * @param {string} object storing Object
         */
        setValidationObject: function (fileds = {}, rules = {}, message = {}) {
            dataStoring = {
                ...dataStoring,
                validationObject: {
                    fileds: fileds,
                    rules: rules,
                    message: message
                }
            }
            return this;
        },
        /**
         * @description: set validation Object
         * @param {string} Object Meesage Object
         */
        setFlashMessages: function (messageList = {}) {
            dataStoring = {
                ...dataStoring,
                flashMessages: messageList
            }
            return this;
        },
        setOnResponseCallBackFn: function (onResponseCallBackFn=emptyFn) {
            dataStoring = {
                ...dataStoring,
                onResponseCallBackFn: onResponseCallBackFn
            }
            return this;
        },
        /**
         * @description: send request to end-point
         * @param {Function} cb callback function
         */
        generate: function () {
            return  dataStoring;
        }
    };


}

export const requestAPICallFn = (apiObject) => {
    return {
        type: requestAPIDataKey,
        playload: apiObject
    }
}

export const setInitialStateFn = () => {
    return {
        type: setInitialState
    }
}

export const removeDataTable = (stateKey) => {
    return {
        type: removeDatatable,
        stateKey: stateKey
    }
}

export const setDataTableFetchingToInit = (stateKey) => {
    return {
        type: setFetchingInit,
        stateKey: stateKey
    }
}
