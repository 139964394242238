import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { actionBtnTypes, CrudComponent } from "../../../components/boda-boda/CRUD/CrudComponent";
import { dateObjectToString } from "../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import FilterUI from "./includes/FilterUI";
import { getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";
import { formatToDateHelper, isEmptyValue } from "../../../helpers/boda-boda/common-helpers/common.helpers";
import { modalTypes } from "../../../components/boda-boda/Modal/config/model.config";
import { mapStatusesNames } from "../../../config/boda-boda/db_config/db_status.config";
import { ManifestPrintBody, PrintManifestBtn } from "../pick-request/includes/common-includes/PrintUI";
import { setFormErrorFn } from "../../../store/boda-boda/form/actions";


class Outbound extends Component {
  render() {
    let { history } = this.props;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Outbound</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Outbound</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row>
            <CrudComponent
              title={"Shipping Outbound"}
              apiURL={`${getManifestListAPI.url}/compressed?page=1&limit=20&statuses=["IN_TRANSIT_FIRST","COMPLETED"]`}
              dataTableStateKey={getManifestListAPI.key}
              isFilter={true}
              filterFromComponent={FilterUI}
              defaultFilterFormObject={{
                pickupDateFrom: null,
                pickupDateTo: null,
                code: "",
                articleNo: "",
                status: "",
                locationPickId: ""
              }}
              clearErrors={() => {
                const { setFormErrorFn } = this.props;
                setFormErrorFn("manifestListAPIKey_search", [])
              }}
              onRebuildFilterObjFn={(filterData) => {
                const data = filterData;
                let { setFormErrorFn } = this.props;
                const errors = [];

                if (new Date(filterData.pickupDateFrom) > new Date(filterData.pickupDateTo)) {
                  errors.push({
                    "property":"pickupDateTo",
                    "error":"The Date To field should not be less than the Date From field"
                  });
                }

                if(errors.length > 0 ){
                  setFormErrorFn("manifestListAPIKey_search", errors);
                  return ;
                }else{
                  setFormErrorFn("manifestListAPIKey_search", []);
                }

                if (!isEmptyValue(filterData.pickupDateTo)) {
                  data["pickupDateTo"] = formatToDateHelper(filterData.pickupDateTo);
                }

                if (!isEmptyValue(filterData.pickupDateFrom)) {
                  data["pickupDateFrom"] = filterData.pickupDateFrom.toISOString();
                }

                return data;
              }}
              isSetCreateAction={false}
              tableHeaderList={[
                { displayName: "Manifest Code", key: "code" },
                { displayName: "Created Time", key: "createdAt", onPrintFn: (rawData) => (rawData.createdAt) ? dateObjectToString(rawData.createdAt, "YY-mm-dd HH:MM:II") : "-" },
                { displayName: "To be Picked Up", key: "pickAt", onPrintFn: (rawData) => (rawData.pickAt) ? dateObjectToString(rawData.pickAt, "YY-mm-dd") : "-" },
                { displayName: "Picked up Time", key: "pickedAt", onPrintFn: (rawData) => (rawData.pickedAt) ? dateObjectToString(rawData.pickedAt, "YY-mm-dd HH:MM:II") : "-" },
                { displayName: "Pick Up Location", key: "locationPick.name" },
                {
                  displayName: "Status", key: "status", onPrintFn: (rawData) => {
                    return mapStatusesNames[rawData.status];
                  }
                }
              ]}
              actionList={[
                {
                  actionType: actionBtnTypes.view,
                  isSetModel: false,
                  actionBtn: {
                    onClickBtnFn: (rawData) => history.push(`/shipping/outbound/view/${rawData.id}`),
                  }
                },
                {
                  actionType: actionBtnTypes.other,
                  isSetModel: true,
                  actionBtn: {
                    icon: "fas fa-print",
                    color: "primary",
                    tooltip: "Print",
                    btnText: "print"
                  },
                  modalProps: {
                    modalTitle: "Manifest",
                    modalType: modalTypes.large,
                    modalBodyComponent: ManifestPrintBody,
                    modalFooterComponent: PrintManifestBtn,
                    isSetClose: true,
                    isSetFooterClose: true,
                  }
                }
              ]}
            />
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.FormData
  };
};

export default connect(mapStateToProps, {
  setFormErrorFn,
}) (Outbound);
