import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { actionBtnTypes, CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { merchantContractAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";


class MerchantContract extends Component {
    render() {
        let { history } = this.props;

        return (
            <Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Merchant Contract</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Contract</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CrudComponent
                            title="Merchant Contract"
                            // filterFromComponent={FilterUI}
                            apiURL={`${merchantContractAPI.url}?page=1&limit=20`}
                            dataTableStateKey={merchantContractAPI.key}
                            isSetCreateAction={false}
                            isFilter={false}
                            tableHeaderList={[
                                { displayName: "No", key: "id" },
                                { displayName: "Contract Name", key: "name", default: "-" },
                                { displayName: "Contract Type", key: "pricingModelType", onPrintFn:(rawData) => rawData.pricingModelType === "TYPE_ONE" ? "Type 1" : "Type 2"},
                                { displayName: "Effective Date-From", key: "start", default: "-", onPrintFn: (rawData) => dateObjectToString(rawData.start, "YY-mm-dd") },
                                { displayName: "Effective Date-To", key: "end", default: "-", onPrintFn: (rawData) =>  rawData.end ? dateObjectToString(rawData.end, "YY-mm-dd") : '-' },
                                { displayName: "Status", key: "status" },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/merchant-contract/view/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </Row>

                </div>
            </Fragment>
        )
    }

}

export { MerchantContract }
