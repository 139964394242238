import React from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";

import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { useSelector } from "react-redux";
import { FormWrapper } from "../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { mapPackageStatusesNames, mapStatusesNames } from "../../../../config/boda-boda/db_config/db_status.config";


const ViewUI = (props) => {
    let { match } = props;

    const formState = useSelector(state => state.FormData);

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        id: response.id,
                        status: response.status,
                        createdAt: response.createdAt,
                        pickedAt: response.pickedAt,
                        locationPick: response.locationPick,
                        locationDrop: response.locationDrop,
                        notes: response.notes,
                        pickAt: response.pickAt,
                        "_onLoad": false,
                    }
                }}
            >
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                            <h4 className="font-size-18">View Manifest</h4>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className={"d-flex justify-content-end"}>
                            <BackBtnComponent
                                path={`/shipping/outbound`}
                                btnName={"Back"}
                                btnColor={"primary"}
                            />
                        </div>
                    </Col>
                </Row>


                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest ID</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.id`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>To Be Picked Up</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""),"YY-mm-dd")}
                                            </dt>


                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Status</dd>
                                            <dt className={"col-sm-8"}>
                                                {
                                                    mapStatusesNames[get(formState, `${getManifestListAPI.key}_view.status`, "")]
                                                }
                                            </dt>
                                            <dd className={"col-sm-4"}>Pick Up Created Time</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.createdAt`, ""), "YY-mm-dd HH:MM:II")}
                                            </dt>
                                            <dd className={"col-sm-4"}>Picked Up Time</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickedAt`, "-"), "YY-mm-dd HH:MM:II")}
                                            </dt>

                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Packages"
                        apiURL={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=20`}
                        dataTableStateKey={getPackageListAPI.key}
                        isFilter={false}
                        isSetAction={false}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Article No", key: "articleNo" },
                            { displayName: "Customer Name", key: "order.name" },
                            { displayName: "Delivery Type", key: "deliveryType" },
                            { displayName: "Payment Method", key: "order.paymentMethod" },
                            { displayName: "Package Category", key: "category.name" },
                            { displayName: "Status", key: "status", onPrintFn: (rawData) => mapPackageStatusesNames[rawData.status] },
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notes</h4>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        {
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                <div>None</div>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

export { ViewUI }
