import React, { Fragment, memo } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import { formCacheLevel } from "../../../config/boda-boda/template.config";


const emptyFn = (...para) => undefined;

const inputBoxInputTypes = {
    "text": "text",
    "textarea": "textarea",
    "number": "number",
    "password": "password"
}

const InputBox = ({
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    isRequired = false,
    placeHolderText = "",
    inputType = inputBoxInputTypes.text,
    inputName = "",
    inputValue = "",
    inputError = "",
    isDisabled = false,
    rows = 1,
    maxLength = 225,
    helperText = "",
    onChangeFn = emptyFn,
    onClickFn = emptyFn
}) => {
    return (
        <Fragment>
            <FormGroup className={`${elementWrapperStyle} ${inputError ? "text-danger" : ""}`}>
                <Label>{labelText} {isRequired ? (<span style={{ color: "#ec4561" }}>*</span>) : null}</Label>
                <Input
                    type={inputType}
                    className={`defaultInputBox ${elementStyle} ${inputError ? "is-touched is-pristine av-invalid is-invalid" : ""}`}
                    name={inputName}
                    value={inputValue}
                    disabled={isDisabled}
                    rows={rows}
                    placeholder={placeHolderText}
                    maxLength={maxLength}
                    onKeyDown={inputType == "number" ? (evt) => ["e", "E"].includes(evt.key) && evt.preventDefault() : null}
                    onChange={(event) => {
                        onChangeFn({
                            value: event.target.value,
                            name: inputName,
                            eventInfo: event
                        })
                    }}
                    onClick={(event) => {
                        onClickFn({
                            name: inputName,
                            eventInfo: event
                        })
                    }}
                />
                <small
                    className={`${inputError ? "invalid-feedback" : ""}`}
                >
                    {inputError ? inputError : helperText}
                </small>
            </FormGroup>
        </Fragment>
    )
}

const areEqual = (prevProps, nextProps) => {
    if (nextProps.cacheLevel===formCacheLevel.none) {
        return false;
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.inputType===nextProps.inputType) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.rows===nextProps.rows) && (prevProps.isDisabled===nextProps.isDisabled) &&
            (prevProps.isRequired===nextProps.isRequired) && (prevProps.inputValue===nextProps.inputValue) &&
            (prevProps.updateStatus===nextProps.updateStatus)
            )
    } else if (nextProps.cacheLevel===formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
            (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
            (prevProps.labelText===nextProps.labelText) && (prevProps.inputType===nextProps.inputType) &&
            (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
            (prevProps.rows===nextProps.rows) && (prevProps.isDisabled===nextProps.isDisabled) &&
            (prevProps.isRequired===nextProps.isRequired) && (prevProps.inputValue===nextProps.inputValue)
        )
    }
}
  
const InputBoxMemo = memo(InputBox, areEqual);

export {
    InputBox,
    InputBoxMemo,
    inputBoxInputTypes
}
