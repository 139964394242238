import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import { FormWrapper } from "../../../../../components/boda-boda/Forms";
import { merchantContractAPI } from "../../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { currency } from "../../../../../config/boda-boda/db_config/db_status.config";


class ContractView extends Component {
    render() {
        let { match, formState } = this.props;

        return (
            <div className="container-fluid">
                <FormWrapper
                    setGroupName={`${merchantContractAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${merchantContractAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        return {
                            name: get(response, "name", ""),
                            discount: get(response, "discount", ""),
                            xlPackageHandling: get(response, "xlPackageHandling", ""),
                            expressMultiplier: get(response, "expressMultiplier", ""),
                            cashChargingType: get(response, "cashChargingType", ""),
                            cashDelivery: get(response, "cashDelivery", ""),
                            status: get(response, "status", ""),
                            cardChargingType: get(response, "cardChargingType", ""),
                            cardDelivery: get(response, "cardDelivery", ""),
                            start: get(response, "start", ""),
                            end: get(response, "end", ""),
                            cardOnDelivery: get(response, "cardOnDelivery", ""),
                            cashOnDelivery: get(response, "cashOnDelivery", ""),
                            settlementPeriod: get(response, "settlementPeriod", ""),
                            maxPackageCount: get(response, "maxPackageCount", ""),
                            flatRate: get(response, "flatRate", ""),
                            continuation: get(response, "continuation", ""),
                            pricingModelType: get(response, "pricingModelType", ""),
                            chargingMethod: get(response, "chargingMethod", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Merchant Contract</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    path={"/merchant-contract"}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    {/*<h4 className="card-title mb-3">User Details</h4>*/}

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Contract Type</dd>
                                                <dt className={"col-sm-7"}>{(get(formState, `${merchantContractAPI.key}_view.pricingModelType`, "") === "TYPE_ONE" ? "Type 1" : "Type 2")}</dt>

                                                <dd className={"col-sm-5"}>Contract Name</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.name`, "")}</dt>

                                                <dd className={"col-sm-5"}>Effective From</dd>
                                                <dt className={"col-sm-7"}>{dateObjectToString(get(formState, `${merchantContractAPI.key}_view.start`, ""))}</dt>

                                                <dd className={"col-sm-5"}>Effective To</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.end`, "") ? dateObjectToString(get(formState, `${merchantContractAPI.key}_view.end`, "")) : "Indefinite"}</dt>

                                                <dd className={"col-sm-5"}>Discount</dd>
                                                <dt className={"col-sm-7"}>{`${get(formState, `${merchantContractAPI.key}_view.discount`, "")}%`}</dt>

                                                <dd className={"col-sm-5"}>XL Package Handling fee</dd>
                                                <dt className={"col-sm-7"}>{`${get(formState, `${merchantContractAPI.key}_view.xlPackageHandling`, "")} ${currency}`}</dt>

                                                <dd className={"col-sm-5"}>Status</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.status`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Settlement Period</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.settlementPeriod`, "")}</dt>

                                                <dd className={"col-sm-5"}>Express Delivery Charge Multiplier</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.expressMultiplier`, "")}</dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Cash on Delivery</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${merchantContractAPI.key}_view.cashOnDelivery`, false) === true ? "Enabled" : "Disabled"
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Cash on Delivery Value</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${merchantContractAPI.key}_view.cashChargingType`, "FEE") === "FEE" ?
                                                            `${get(formState, `${merchantContractAPI.key}_view.cashDelivery`, "")} ${currency}` : `0 ${currency}`
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Cash on Delivery %</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${merchantContractAPI.key}_view.cashChargingType`, "PERCENTAGE") === "PERCENTAGE" ?
                                                            `${get(formState, `${merchantContractAPI.key}_view.cashDelivery`, "")}%` : "0%"
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Card on Delivery</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${merchantContractAPI.key}_view.cardOnDelivery`, false) === true ? "Enabled" : "Disabled"
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Card on Delivery Value</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${merchantContractAPI.key}_view.cardChargingType`, "FEE") === "FEE" ?
                                                            `${get(formState, `${merchantContractAPI.key}_view.cardDelivery`, "")} ${currency}` : `0 ${currency}`
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Card on Delivery %</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${merchantContractAPI.key}_view.cardChargingType`, "PERCENTAGE") === "PERCENTAGE" ?
                                                            `${get(formState, `${merchantContractAPI.key}_view.cardDelivery`, "")}%` : "0%"
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Upon Contract Expiry</dd>
                                                {
                                                    (get(formState, `${merchantContractAPI.key}_view.continuation`, true) === true) ? (
                                                        <dt className={"col-sm-7"}>Refer to base table and continue current discount</dt>
                                                    ) : (
                                                        <dt className={"col-sm-7"}>Refer to base table and discontinue current discount</dt>
                                                    )
                                                }

                                                <dd className={"col-sm-5"}>Flat Rate</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.flatRate`, "") !== null ? `${get(formState, `${merchantContractAPI.key}_view.flatRate`, "0")} ${currency}` : "-"}</dt>

                                                <dd className={"col-sm-5"}>Maximum Number of Packages per Order</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.maxPackageCount`, "-")}</dt>

                                                <dd className={"col-sm-5"}>Charging Method</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${merchantContractAPI.key}_view.chargingMethod`, "-") === "VOLUMETRIC" ? "Volumetric Weight Based" : get(formState, `${merchantContractAPI.key}_view.chargingMethod`, "-") === "WEIGHT" ? "Actual Weight Based" : "-"}</dt>

                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </FormWrapper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

const ContractViewWithState = connect(mapStateToProps, null)(ContractView);

export {
    ContractViewWithState
}
